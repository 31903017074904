import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import { Field, reduxForm } from 'redux-form';
import { Spin, Icon } from 'antd';
import { connect } from 'react-redux';
import { createFeedback } from '../../actions';

class Contact extends React.Component {
    onSubmit = (formValues) => {
        console.log('hey');
        this.props.createFeedback(formValues, this.props.translate);
    }
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <section className="footer-contact-area section_padding_100 clearfix" id="contact">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    {/* Heading Text  */}
                                    <div className="section-heading">
                                        <h2>{translate("landing.contact.title")}</h2>
                                        <div className="line-shape"></div>
                                    </div>

                                    <div className="email-text">
                                        <p><span>Email: </span>info@dzscience.com</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {/* Form Start*/}
                                    <div className="contact_from">
                                        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                                            {/* Message Input Area Start */}
                                            <div className="contact_input_area">
                                                <div className="row">
                                                    {/* Single Input Area Start */}
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <Field
                                                                name="name"
                                                                component={(formProps) =>
                                                                    <input type="text" {...formProps.input} className="form-control" name="name" disabled={this.props.loading} id="name" placeholder={translate("landing.contact.name")} required />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Single Input Area Start */}
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <Field
                                                                name="email"
                                                                component={(formProps) =>
                                                                    <input type="email" {...formProps.input} className="form-control" disabled={this.props.loading} name="email" id="email" placeholder={translate("landing.contact.email")} required />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Single Input Area Start */}
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <Field
                                                                name="message"
                                                                component={(formProps) =>
                                                                    <textarea {...formProps.input} name="message" disabled={this.props.loading} className="form-control" id="message" cols="30" rows="4" placeholder={translate("landing.contact.message")} required />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Single Input Area Start */}
                                                    <div className="col-12">
                                                        <button type="submit" className="btn submit-btn">
                                                            {this.props.loading ?
                                                                <Spin indicator={<Icon type="loading" style={{ fontSize: 30, color: 'white' }} />} />
                                                                :
                                                                 `${translate("landing.contact.action-btn")}`
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Message Input Area End */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </Translate>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        loading: state.feedback.loading,
    };
}

const formWrapped = reduxForm({
    form: 'feedbackForm',
})(Contact);

export default connect(mapStateToProps, { createFeedback })(withLocalize(formWrapped));