import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import {connect} from "react-redux";
import {fetchFacts} from "../../actions";



class Facts extends React.Component {
    componentDidMount() {
        this.props.fetchFacts()
    }

    render() {
        const {factsCount} = this.props
        return (
            <Translate>
                {({ translate }) =>
                    <section className="cool_facts_area clearfix">
                        <div className="container">
                            <div className="row">
                                {/* Single Cool Fact*/}
                                <div className="col-12 col-md-4 col-lg-4">
                                    <div className="single-cool-fact d-flex justify-content-center wow fadeInUp" data-wow-delay="0.2s">
                                        <div className="counter-area">
                                            <h3><span className="">{factsCount && factsCount.experiences_count || 0}</span></h3>
                                        </div>
                                        <div className="cool-facts-content">
                                            <i className="far fa-user-tie"></i>
                                            <p>Collected <br /> Expertises </p>
                                        </div>
                                    </div>
                                </div>
                                {/* Single Cool Fact*/}
                                <div className="col-12 col-md-4 col-lg-4">
                                    <div className="single-cool-fact d-flex justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                                        <div className="counter-area">
                                            <h3><span className="">{factsCount && factsCount.countries_count || 0}</span></h3>
                                        </div>
                                        <div className="cool-facts-content">
                                            <i className="far fa-globe-europe"></i>
                                            <p>Total <br /> Countries</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Single Cool Fact*/}
                                <div className="col-12 col-md-4 col-lg-4">
                                    <div className="single-cool-fact d-flex justify-content-center wow fadeInUp" data-wow-delay="0.6s">
                                        <div className="counter-area">
                                            <h3><span className="">{factsCount && factsCount.fields_count || 0}</span></h3>
                                        </div>
                                        <div className="cool-facts-content">
                                            <i className="far fa-atom-alt"></i>
                                            <p>Entered <br />Fields</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Single Cool Fact*/}
                                {/*<div className="col-12 col-md-3 col-lg-3">*/}
                                {/*    <div className="single-cool-fact d-flex justify-content-center wow fadeInUp" data-wow-delay="0.8s">*/}
                                {/*        <div className="counter-area">*/}
                                {/*            <h3><span className="counter">10</span></h3>*/}
                                {/*        </div>*/}
                                {/*        <div className="cool-facts-content">*/}
                                {/*            <i className="ion-ios-star-outline"></i>*/}
                                {/*            <p>People <br />Likes</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </section>
                }
            </Translate>
        )
    }
}

function mapStateToProps(state) {
    return {
        factsCount: state.landing.factsCount,
    };
}

export default connect(mapStateToProps, { fetchFacts })(withLocalize(Facts));


