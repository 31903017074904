export const docs_en =
`

## Introduction

R&D projects are seldom unpredictable when it comes to expected results, such as a technique that we discover or an innovation that we develop, etc.
This unpredictability makes it difficult for researchers to estimate project results, or to estimate the valorisations of the project.
Generally, R&D projects are valued by the three valorisations which are publication of scientific results, training of
students and products realisations. Making an objective to estimate valorisations of a three-year R&D project for example is not an
easy task. However, not having a very clear idea about our ability to produce valorisations, surely does not help to properly execute the project.

\`Bahith\` aims to help researchers properly describe their R&D projects, among other things to properly assess its valorisations.
From the expertise of the community, \`Bahith\` creates a form of reference in relation to many aspects of R&D projects,
as the expertise of the project team, its field, type of project, etc. With this reference, \`Bahith\` will guide the
researcher to properly situate the capacity of the project team in relation to this reference, and thus helps him to properly
assess the valorisations of his project. Such a more or less intelligent description can indeed be considered as a realistic
project plan and increase its chances of success.

\`Bahith\` only considers the objective valorisations of the project. It does not deal with it's subjective aspect, generally
associated with the field of research or quality of research the case of R&D projects. Nevertheless, research of a given quality will 
be more likely to be published in quality journals, and \`Bahith\` intends to treat it later!

### Gathering Expertises

The intelligent core of \`Bahith\` is based on the expertise of researchers. \`Bahith\` has a structure allowing researchers
to include their expertise. As much as the number of expertises is large as much the reference of \`Bahith\` is more realistic.
We are counting on you for this purpose by filling in the expertise forms.





## Description of Bahith
![Bahith - how to image](/img/Bahith%20-%20How%20to.png)

\`Bahith\` can be used as an intelligent kernel which can be integrated into the description protocols of R&D projects.
This possible intelligence is produced from a model of collection of expertise from researchers, or more generally,
from the human resources involved in R&D projects, whatever their grades and experiences.

The following figure illustrates the functional architecture of \`Bahith\`.

### R&D project

Given a project team with one or more members. This team has the capacity to promote this project in terms of publications,
product achievements as well as student training. For example, it makes sense that a doctor would have a greater journalistic
publishing capacity than that of an engineer. Is it possible to estimate this recovery capacity? \`Bahith\` proposes the calculation
of this capacity from the expertise of researchers.

### Expertise

An expertise (Grade, experience) is the couple formed of the grade or diploma, followed by at least one year of work experience in the area of the grade.

An engineer who worked 2 years as an engineer, then did a master's degree, followed by a doctorate, and he is currently a doctor for 5 years,
will have two areas of expertise. The first as an engineer (engineer, 2 years), and the second as a doctor (doctor, 5 years).

### Valorisations

In \`Bahith\`, an R&D project is valued by three different valorisations which are publications, student training and
product achievements. Each researcher, according to his expertise, has a capacity to produce these valorisations.

### Capacities of valorisations

There are no general rules on the calculation of a theoretical capacity of a researcher to produce valorisations, despite the fact
that these constitute the basic elements on which any evaluation of a project is built. . Many researchers do not take the time
to properly analyze their project, and are forced to put quantities of valorisations more or less at random. \`Bahith\` has, among other things,
the objective of helping these researchers to establish good valorisation estimates.

### Estimated valorisaations

From the valorisation capacity of the research team, it is possible to calculate for each valorisation the probable quantities,
taking into account the expertise of the researchers.

### Comparison

We compare the estimated valorisations with those proposed in the project on a scale going from Low to Risky, or low means that the
production capacity is large, but the proposed valorisations are low, and risky when the capacity is small and the proposed valorisations are large.

### interpretations

We will display the detailed results of the comparison to give the researcher the opportunity to interpret and take them into account to improve the projet description.

## Expertise of researchers

To be able to include a form of intelligence to guide researchers in the description of their projects, \`Bahith\` offers a modeling of researchers' expertise as follows:

### Grade

We define in \`Bahith\` four grades which are Doctor, Master, Engineer, Technician.

- \`Doctor\`: 3rd cycle (Ph.D.)

- \`Master\`: 2nd cycle (Master, Master II)

- \`Engineer\`: School of Engineering (Master I)

- \`Technician\`: Three years of technical studies. The 3-year license is categorized as Technical.

### Experience

we model the experience with the number of years spent working with the grade, according to the scale 1, 2, 5, 10 and 20 years of experience.

### Type of expertise

We consider in Bahith three types of expertise, Research, Development and Research and Development (R&D).

- \`Research\`

These are projects which mainly include studies and analyzes in order to arrive at a new understanding, mainly valued by publications.

- \`Development\`

These are  projects that have the objective of achieving a final product, with a priori knowledge of the product specifications.

- \`R & D\`

These are projects that include studies and analyzes in order to determine the specifications of a product to be produced in the project.

### Structure for the representation of expertise

In \`Bahith\`, we use the university structure to classify fields of science:

\`University ⇒ Faculty ⇒ Department ⇒ Field ⇒ Specialty\`


As a comprehensive approach, we consider the following:

- A doctor must give information on his faculty, department, field and finally specialty of his doctorate.
- A Master must give information on his faculty, department and field
- An Engineer must give information on his faculty and his department
- A Technician must give information on his faculty and his department.

## Valorisations

In \`Bahith\`, an R&D project is valued by three different valorisations which are publications, student training and product achievements.
There are no general rules on the calculation of a theoretical capacity of a researcher to produce these valorisations, despite the fact that these
constitute the basic elements on which any evaluation of the project is built. Many researchers do not take the time to properly analyze their
project, and are forced to put amounts of valorisations in an arbitrary manner. \`Bahith\` has, among other things, the objective of helping these
researchers, by comparing their valorisations with a benchmark calculated from the experience of other researchers.

### Training

In the work of a researcher or even a graduate, there is always the noble task of training students. To this end,
training is one of the valorisations of a researcher's work. We use the same grades mentioned above (Doctor, Master, Engineer, Technician) as
possible training results, with the constraint that a researcher cannot form a grade above his own grade.

### Publication

The publication of the results of a research work, mainly in specialized journals with reading committee, constitutes the most popular valorisation.
Indeed, this valorisation includes a large part of the subjective evaluation of the quality of research. A good publication in itself justifies the
quality and the research work, and the results obtained. \`Bahith\` categorizes publications as follows:

- International journal
- National journal
- International conference
- National conference.
- Report

\`Co-auteur\`

In the majority of cases, a publication has multiple authors, and generally the position of the author's name gives information on the contribution,
where the first author being the main contributor. We do not consider in this first version the position of the author's name in the publication, but just its presence.

\`The impact factor of journals\`

In this first version of \`Bahith\`, we only work with one level of hierarchy to describe the categories of publications. It is possible later,
to refine the model, to add layers in the hierarchy of each category, as in the case of sorting journals by their impact factor.

### Production

Product realization is in general related to development projects. To this end, we appreciate the expertise of people who
have worked in the industry. In fact, the majority of industrial projects are of product realisation types.

We categorize product realisation in this first version of Bahith by:

- \`Patent\` (patent)
- \`Deployed product\`: Product that is used by other customers.
- \` Finalized product\`: Product produced, but not deployed
- \`Demonstration product\`: Product used to demonstrate the capability of the solution for customers
- \`Prototype\`: The implementation of a concept from which other forms or models are made



`
