import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class Team extends React.Component {
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <section className="our-Team-area bg-white section_padding_100_50 clearfix"
                             // style={{marginTop:"-101px"}}
                             id="team">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    {/* Heading Text  */}
                                    <div className="section-heading">
                                        <h2>{translate("landing.team.title")}</h2>
                                        <div className="line-shape"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="single-team-member">
                                        <div className="member-image">
                                            <img src="img/team-img/mehdi.jpg" alt="" />
                                            <div className="team-hover-effects">
                                                <div className="team-social-icon">
                                                    <a href="https://www.linkedin.com/in/elmehdi-aitnouri">{null}<i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="member-text">
                                            <h4>Elmehdi Aitnouri</h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="single-team-member">
                                        <div className="member-image">
                                            <img src="img/team-img/mouad.jpg" alt="" />
                                            <div className="team-hover-effects">
                                                <div className="team-social-icon">
                                                    <a href="http://fb.com/benyamnamouad">{null}<i className="fa fa-facebook" aria-hidden="true"></i></a>
                                                    <a href="https://www.linkedin.com/in/benyamnamouad/">{null}<i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                                    <a href="https://www.instagram.com/benyamnamouad/">{null}<i className="fa fa-instagram" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="member-text">
                                            <h4>Mouad Benyamna</h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="single-team-member">
                                        <div className="member-image">
                                            <img src="img/team-img/oussama.jpg" alt="" />
                                            <div className="team-hover-effects">
                                                <div className="team-social-icon">
                                                    <a href="http://fb.com/oussama.bougaila">{null}<i className="fa fa-facebook" aria-hidden="true"></i></a>
                                                    <a href="https://www.linkedin.com/in/obougaila/">{null}<i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                                    <a href="https://www.instagram.com/oussama_bougy/">{null}<i className="fa fa-instagram" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="member-text">
                                            <h4>Oussama Bougaila</h4>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                }
            </Translate>
        )
    }
}

export default withLocalize(Team);