import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import {Col, Row} from "antd";

class Footer extends React.Component {
    render() {
        const {activeLanguage}  = this.props

        return (
            <Translate>
                {({ translate }) =>
                    <footer className="clearfix" id="footer">
                        <Row>
                            <Col xs={24} md={12}>
                                <div style={{display:'inline-flex',width:'100%'}} className="text-left">
                                    <img src="img/logo/bahith-logo-inline-gray.svg" alt="Bahith logo gray" style={{marginRight:'20px'}}/>
                                    <p>Product by DzConseil. Copyright ©2020 Bahith.</p>
                                    <br/>

                                </div>
                            </Col>

                            <Col xs={24} md={12}>

                                <div style={{width:'100%',lineHeight:'43px'}} className="text-right d-none d-md-block">
                                    <ul style={{display:'inline-flex'}} className="footer-pages-items" >
                                        <li className="footer-pages-items"><a href="/">{translate("pages-footer.home")}</a></li>
                                        <li className="footer-pages-items"><a href={`/doc/${activeLanguage ? activeLanguage.code : '#'}`}>{translate("pages-footer.doc")}</a></li>
                                        <li className="footer-pages-items"><a href="/#team">{translate("pages-footer.team")}</a></li>
                                    </ul>
                                </div>

                            </Col>
                            <p className="w-md-50 d-none d-md-block">
                                Un portail intelligent de gestion et d’administration des projets de recherche et développement (R&D).
                            </p>
                        </Row>
                    </footer>
                }
            </Translate>
        )
    }
}

export default withLocalize(Footer);