import {
    CREATE_FEEDBACK,
    CREATE_FEEDBACK_SUCCESS,
    SHOW_EXPERIENCE_FORM_HINT,
    HIDE_EXPERIENCE_FORM_HINT,
    SHOW_HINT_DETAILS,
    HIDE_HINT_DETAILS,
    EVALUATION,
    EVALUATION_SUCCESS,
    FETCH_FACTS
} from './types';
import axios from 'axios';
import Swal from 'sweetalert2';
import G2 from '@antv/g2';
import history from '../history';
const {
    REACT_APP_FEEDBACK_SHEET_URL,
    REACT_APP_CONFIGURATION_SHEET_URL,
    REACT_APP_FETCH_STATE_SHEET_URL,
    REACT_APP_EVALUATE_SHEET_URL
} = process.env;

export const createFeedback = (params, translate) => {
    return async (dispatch, getState) => {
        dispatch({ type: CREATE_FEEDBACK });
        axios.get(REACT_APP_FEEDBACK_SHEET_URL, {
            params
        })
            .then(function (response) {
                console.log(response);
                Swal.fire({
                    title: translate("landing.modal.title"),
                    text: translate("landing.modal.text"),
                    icon: 'success',
                    showCloseButton: true,
                })
                dispatch({ type: CREATE_FEEDBACK_SUCCESS });
            })
            .catch(function (error) {
                console.log(error);
            })
    }
};
export const addConfiguration = (params, translate) => {
    return async (dispatch, getState) => {
        //dispatch({ type: CREATE_FEEDBACK });
        Swal.fire({
            title: translate("landing.experience.modal.title_wait"),
            text: '',
            icon: 'info',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        })
        axios.get(REACT_APP_CONFIGURATION_SHEET_URL, {
            params
        })
            .then(async(response) => {
                console.log(response);
                let reason = await Swal.fire({
                    title: translate("landing.experience.modal.title_done"),
                    text: translate("landing.experience.modal.text_done"),
                    icon: 'success',
                    showCloseButton: true,
                    showCancelButton: true,
                    cancelButtonText: translate("landing.experience.modal.text_add_other_one"),
                })
                if(reason.dismiss == 'cancel')
                    window.location.reload();
                else history.goBack();
                //dispatch({ type: CREATE_FEEDBACK_SUCCESS });
            })
            .catch(function (error) {
                console.log(error);
            })
    }
};
/**
 * asynchrone action to show the experience from hint
 * @returns {Function}
 */
export const showExperienceFormHint = () => async (dispatch) => {
    try {
        dispatch({type: SHOW_EXPERIENCE_FORM_HINT });
    } catch (err) {
        return err;
    }
}
/**
 * asynchrone action to hide the experience from hint
 * @returns {Function}
 */
export const hideExperienceFormHint = () => async (dispatch) => {
    try {
        dispatch({type: HIDE_EXPERIENCE_FORM_HINT });
    } catch (err) {
        return err;
    }
}
/**
 * asynchrone function to show more details for the hint
 * @param data to show in the hint details
 * @returns {Function}
 */
export const showHintDetails = (data) => async (dispatch) => {
    try {
        dispatch({
            data: data,
            type: SHOW_HINT_DETAILS });
    } catch (err) {
        return err;
    }
}
/**
 * asynchrone function to hide the details of the hint when the input is onBlur
 * @returns {Function}
 */
export const hideHintDetails = () => async (dispatch) => {
    try {
        dispatch({
            type: HIDE_HINT_DETAILS });
    } catch (err) {
        return err;
    }
}
export const evaluate = (params) => {
    console.log('paramas: ', params)
    return async (dispatch, getState) => {
        dispatch({ type: EVALUATION });
        axios.get(REACT_APP_EVALUATE_SHEET_URL, {
            params
        })
            .then(function (response) {
                console.log(response.data.data);
                var { data } = response.data;
                var result = [
                    { capacity: 'La Norme', type: 'Capacité Formation', value: data.cp_form_norm },
                    { capacity: 'La Norme', type: 'Capacité Publication', value: data.cp_pub_norm },
                    { capacity: 'La Norme', type: 'Capacité Production', value: data.cp_prod_norm },
                    { capacity: 'Real', type: 'Capacité Formation', value: data.cp_form },
                    { capacity: 'Real', type: 'Capacité Publication', value: data.cp_pub },
                    { capacity: 'Real', type: 'Capacité Production', value: data.cp_prod },
                ];
                const chart = new G2.Chart({
                    container: 'c1',
                    forceFit: true,
                    height: 500
                });
                chart.source(result);
                chart.interval().position('type*value').color('capacity')
                    .adjust([{
                        type: 'dodge',
                        marginRatio: 1 / 32


                    }]);
                chart.render();
                dispatch({ type: EVALUATION_SUCCESS, payload: result });
            })
            .catch(function (error) {
                console.log(error.message);
            })
    }
}

export const fetchFacts = () => {
    return function (dispatch) {
        axios.get(REACT_APP_FETCH_STATE_SHEET_URL).then(function (response) {
            // console.log(" facts here ",response)

            dispatch({type: FETCH_FACTS, payload: response.data});
            return true;

        }).catch(function (error) {
            return error;
        });
    }
}