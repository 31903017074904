import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import Evaluation from "../Evaluation";

class EvaluationSection extends React.Component {
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <section className="" id="evaluation">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {/* Section Heading Area */}
                                    <div className="section-heading text-center">
                                        <h2>Tester la performance de votre projet !</h2>
                                        <div className="line-shape"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-6">
                                    <img width={500} src="img/charts.svg" alt="a women looking for something and ? "/>
                                </div>

                                <div className="col-6">
                                    <div className="text-justify">
                                        <h6 className="blue-title">Tester l’efficacité  </h6>
                                        <p className="mx-auto">
                                            de votre projet dans Bahith. Vous allez comparer votre performance avec celle calculée à partir de toute l’expertise de tous les chercheurs. Allez-y, regardez ou vous en êtes!

                                        </p>

                                        <div className="about-btn">
                                            <a className="btn btn-danger" href="/evaluation">
                                            <i className="fad fa-chart-pie-alt"></i> &nbsp;
                                            Je veux tester mon projet</a></div>
                                        </div>

                                    <p className="info" style={{fontSize:"10px", marginTop:"120px"}}><i
                                        className="far fa-info-circle"></i> Note when you click on this button it will redirect you to a form when you are filling your expertise that will help us improve our tool to the best  </p>
                                    </div>

                                </div>
                            </div>
                    </section>
                }
            </Translate>
        )
    }
}

export default withLocalize(EvaluationSection);