import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class About extends React.Component {
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <section className="special-area bg-white section_padding_100" id="about">

                        <div className="container ">
                            <div className="row">
                                        <div className="col-12">
                                            {/* Section Heading Area */}
                                            <div className="section-heading text-center">
                                                <h2>{translate("landing.about.h2-title")}!</h2>
                                                <div className="line-shape"></div>
                                            </div>
                                        </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <img width={400} src="img/undraw_searching_p5ux.svg" alt="a women looking for something and ? "/>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6 mt-5 m5-md-0">
                                    <div className="text-justify px-3 px-md-0">
                                        <p className="mx-auto">
                                            {translate("landing.about.content")}
                                        </p>

                                       <div className="about-btn">
                                           <div><a className="btn btn-danger" href="/experience">
                                               <i className="fad fa-chart-pie-alt"></i> &nbsp;
                                               {translate("landing.about.contribute-btn")}

                                           </a></div>

                                           {/*hid this button for future versions*/}
                                           {/*<div><a className="btn btn-danger1 " href="#">*/}
                                           {/*    <i className="fad fa-share-square"></i> Je veux tester mon projet</a></div>*/}
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </section>
                }
            </Translate>
        )
    }
}

export default withLocalize(About);
