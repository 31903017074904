import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class Header extends React.Component {
    changeLanguage = (e, lang) => {
        e.preventDefault();
        this.props.setActiveLanguage(lang);
    }
    renderLanguagePicker() {
        const { languages, activeLanguage } = this.props;
        return (
            activeLanguage ?
                (<div className="dropdown show">
                    <button className="btn btn-secondary lang-btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="far fa-globe-europe"></i>
                        <span className="">  &nbsp; {activeLanguage.name}</span>
                    </button>
                    <div className="dropdown-menu">
                        {languages.map(lang => (
                            <a onClick={(e) => this.changeLanguage(e, lang.code)} key={lang.code} className="dropdown-item" href="#lang"><img alt="" style={{ height: '20px', marginRight: '15px' }} src={`img/icon/${lang.code}.png`}></img>{lang.name}</a>
                        ))}
                    </div>
                </div>)
                :
                null
        );
    }
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <header className="header_area animated">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                {/* Menu Area Start */}
                                <div className="col-12 col-lg-10">
                                    <div className="menu_area">
                                        <nav className="navbar navbar-expand-lg navbar-light">
                                            {/* Logo */}
                                            <img alt="" className="logo-brand" src="img/logo/bahith-logo-white.svg"></img>
                                            {/*<a className="navbar-brand" href="#">C</a>*/}
                                            <button className="navbar-toggler home-toggller" type="button" data-toggle="collapse" data-target="#ca-navbar" aria-controls="ca-navbar" aria-expanded="false" aria-label="Toggle navigation">
                                                <i  className="fal fa-bars"></i>
                                            </button>

                                            {/*<img alt="" className="logo-brand" src="img/logo/bahith-logo-white.svg"></img>*/}

                                            {/*<ul className="nav float-right">*/}

                                            {/*    <li className="nav-item active"><a className="nav-link" href="#home">{this.props.translate("landing.navbar.home")}</a></li>*/}
                                            {/*    <li className="nav-item"><a className="nav-link" href="#about">{translate("landing.navbar.about")}</a></li>*/}
                                            {/*    <li className="nav-item">*/}
                                            {/*        {this.renderLanguagePicker()}*/}
                                            {/*    </li>*/}
                                            {/*</ul>*/}


                                            {/* Menu Area */}
                                            <div className="collapse navbar-collapse" id="ca-navbar">
                                                <ul className="navbar-nav ml-auto" id="nav">
                                                    <li className="nav-item active"><a className="nav-link" href="#home">{this.props.translate("landing.navbar.home")}</a></li>
                                                    <li className="nav-item"><a className="nav-link" href="#about">{translate("landing.navbar.about")}</a></li>
                                                    <li className="nav-item"><a className="nav-link" href="#features">{translate("landing.navbar.features")}</a></li>
                                                    <li className="nav-item"><a className="nav-link" href="#team">{translate("landing.navbar.team")}</a></li>
                                                    <li className="nav-item"><a className="nav-link" href="#contact">{translate("landing.navbar.contact")}</a></li>
                                                </ul>
                                                <div className="sing-up-button d-lg-none">
                                                    {this.renderLanguagePicker()}
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                                {/* Signup btn */}
                                <div className="col-12 col-lg-2">
                                    <div className="sing-up-button d-none d-lg-block">
                                        {this.renderLanguagePicker()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                }
            </Translate>
        )
    }
}

export default withLocalize(Header);