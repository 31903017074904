import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class Video extends React.Component {
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <div className="video-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {/* Video Area Start */}
                                    <div className="video-area" style={{ backgroundImage: "url('img/cover.jpg')" }}>
                                        {/*<div className="video-play-btn">*/}
                                        {/*    <a href="https://www.youtube.com/watch?v=f5BBJ4ySgpo" className="video_btn">{null}<i className="fa fa-play" aria-hidden="true"> </i></a>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translate>
        )
    }
}

export default withLocalize(Video);