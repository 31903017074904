import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import {
    Steps,
    Form,
    Input,
    Icon,
    Select,
    Button,
    DatePicker,
    Progress,
    InputNumber, Row, Col,
} from 'antd';
import countries from '../data/countries';
import universities from '../data/universities';
import faculties from '../data/faculties';
import departments from '../data/departements';
import fields from '../data/fields';

import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import * as inputs from '../constants/FormInputsLabels';
import * as formSteps from '../constants/Steps';

//actions
import { addConfiguration,showExperienceFormHint,hideExperienceFormHint,showHintDetails,hideHintDetails } from '../actions';
import FormHint from "../components/FormHint";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {Scrollbars} from "react-custom-scrollbars";

const { Option } = Select;
const grades = ['technician', 'engineer', 'master', 'doctor'];
const type_of_experience = ['research', 'r_and_d', 'development'];
const nbYears = [1, 2, 5, 10, 20];
const stepsArray =[formSteps.RESPONDER,formSteps.EXPERIENCE,formSteps.WORKSHOP,formSteps.PUBLICATION,formSteps.REALISATION,formSteps.FINISH]

class Experience extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            steps: 6,
            confirmDirty: false,
            autoCompleteResult: [],
            grade: 0,
            nb_year: 0,
            focused_input:"",
            searchedValue:"",
            universities: universities,
            faculties: faculties,
            departments: departments,
            fields: fields,
            country_code: '',
            isObtainingCountrySelected:false,
            faculty_id: 0,
            departement_id: 0,
        };

    }

    next() {
        window.scrollTo(0, 280);
        var current = this.state.current
        this.props.hideExperienceFormHint();
        const steps = [
            ['name', 'email', 'country'],
            ['grade', 'date_obtaining', 'obtaining_country', 'university', 'faculty', 'department', 'field', 'specialty', 'type_experience', 'years_experience']
        ];
        this.props.form.validateFields(steps[current], (err, values) => {
            if (!err) {
                current = this.state.current + 1;
                this.setState({ current });
            }
            if (values.grade)
                this.setState({ grade: values.grade });
            if (values.years_experience)
                this.setState({ nb_year: values.years_experience });
        });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.addConfiguration(values, this.props.translate)
            }
        });
    };
    display(step) {
        const { current } = this.state;
        if (current !== step)
            return 'none';
        return '';
    }
    grade(gr) {
        const { grade } = this.state;
        if (gr > grade)
            return 'none';
        return '';
    }
    nbYear(nb) {
        const { nb_year } = this.state;
        if (nb > nb_year)
            return 'none';
        return '';
    }
    changeLanguage = (e, lang) => {
        e.preventDefault();
        this.props.setActiveLanguage(lang);
    }
    renderLanguagePicker() {
        const { languages, activeLanguage } = this.props;
        return (
            activeLanguage ?
                (<div className="dropdown show">
                    <button className="btn btn-secondary lang-btn " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="far fa-globe-europe"></i> &nbsp;
                        {activeLanguage.name}
                    </button>
                    <div className="dropdown-menu">
                        {languages.map(lang => (
                            <a onClick={(e) => this.changeLanguage(e, lang.code)} key={lang.code} className="dropdown-item" href="#lang"><img alt="" style={{ height: '20px', marginRight: '15px' }} src={`img/icon/${lang.code}.png`}></img>{lang.name}</a>
                        ))}
                    </div>
                </div>)
                :
                null
        );
    }


    handleInputClick = (input) =>{
        this.showHint(input);
    }

    showHintDetails = (values) =>{
        this.props.showHintDetails(values)
    }

    hideHintDetails = () =>{
        this.props.hideHintDetails()
    }

    showHint = (input) => {
        this.setState({
            focused_input : input,
        });
        this.props.showExperienceFormHint()
    }

    addUniversity = () => {
        const { universities } = this.state;
        this.setState({
          universities: [...universities, {id:this.state.searchedValue, name:this.state.searchedValue, code: this.state.country_code}],
        });
    };

    addFaculty = () => {
        const { faculties } = this.state;
        this.setState({
          faculties: [...faculties, {id: this.state.searchedValue, name:this.state.searchedValue, code: ''}],
        });
    };

    addDepartement = () => {
        const { departments } = this.state;
        this.setState({
          departments: [...departments, {id: this.state.searchedValue, name:this.state.searchedValue, faculty_id: this.state.faculty_id}],
        });
    };

    addField = () => {
        const { fields } = this.state;
        this.setState({
          fields: [...fields, {id: this.state.searchedValue, name:this.state.searchedValue, departement_id: this.state.departement_id}],
        });
    };

    render() {
        const { Step } = Steps;
        const { current, steps } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            colon:false,
            labelAlign:'left',
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <Translate>
                {({ translate }) =>
                    <div className="experience-page">
                        <Navbar renderLanguage={this.renderLanguagePicker()}/>
                        <div className="text-center pages-header pages-header-experience-bg" >
                            {translate('landing.experience.contribute_experience')}
                        </div>


                        <div className="container  mt-md-5" style={{background:'#fff'}}>
                            <Steps current={current} className="steps-wrapper text-center d-none d-md-block">
                                <Step title={translate(`steps.labels.${formSteps.RESPONDER}`)}   />
                                <Step title={translate(`steps.labels.${formSteps.EXPERIENCE}`)}   />
                                <Step title={translate(`steps.labels.${formSteps.WORKSHOP}`)}   />
                                <Step title={translate(`steps.labels.${formSteps.PUBLICATION}`)}   />
                                <Step title={translate(`steps.labels.${formSteps.REALISATION}`)}   />
                                <Step title={translate(`steps.labels.${formSteps.FINISH}`)}   />
                            </Steps>


                            <div className="container">


                                <div className="row form-wrapper">
                                    <div className="col-12 col-md-6">
                                        <div className="row steps-phone-layout">
                                            <div className="col-4">
                                                <Progress
                                                    strokeColor={{
                                                        '0%': '#77a4f2',
                                                        '100%' : '#87d068',
                                                    }}
                                                    width={74} type="circle" percent={((current+1)*100)/6} format={()=> `${current+1} of 6`} />

                                            </div>
                                            <div className="col-8 text-right">
                                                <h6 className="blue-title">{translate(`steps.labels.${stepsArray[this.state.current]}`)}  </h6>
                                                {
                                                    current === 5
                                                        ?
                                                        ''
                                                        :
                                                        <p style={{fontSize:"12px"}}>{translate("landing.experience.next")} : {translate(`steps.labels.${stepsArray[this.state.current+1]}`)}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="step-description">
                                            <p> {translate(`steps.${stepsArray[this.state.current]}`)} </p>
                                            <p className={`step-description-info ${this.state.current >=2 ? `d-block` : `d-none d-md-block`}`}><Icon type="info-circle" /> {translate("blue-title-info")}</p>
                                        </div>

                                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                            <div className="steps-contentr">

                                                {/*** FIRST STEP */}
                                                <div className={`experience-step${this.state.current}`}>
                                                    <Form.Item  label={translate(`input_labels.${inputs.NAME}`)} style={{ display: this.display(0) }}>
                                                        {getFieldDecorator('name', {
                                                            rules: [{

                                                                required: false, message: 'Please input your name!' }],
                                                        })
                                                        (
                                                            <Input
                                                                onFocus={() =>{this.handleInputClick(inputs.NAME)}}
                                                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                                placeholder={translate(`input_labels.${inputs.NAME}`)}
                                                            />,
                                                        )}
                                                    </Form.Item>

                                                    <Form.Item label={translate(`input_labels.${inputs.EMAIL}`)} style={{ display: this.display(0) }}>
                                                        {getFieldDecorator('email', {
                                                            rules: [
                                                                {
                                                                    type: 'email',
                                                                    message: 'The input is not valid E-mail!',
                                                                },
                                                                {
                                                                    required: false,
                                                                    message: 'Please input your E-mail!',
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                onFocus={() =>{this.handleInputClick(inputs.EMAIL)}}
                                                                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                                placeholder={translate(`input_labels.${inputs.EMAIL}`)}
                                                            />)}
                                                    </Form.Item>
                                                    <Form.Item
                                                        onFocus={() =>{
                                                            setTimeout( ()=> {
                                                                this.handleInputClick(inputs.COUNTRY)
                                                            }, 300);
                                                        }}
                                                        label={translate(`input_labels.${inputs.COUNTRY}`)}  style={{ display: this.display(0) }}>
                                                        {getFieldDecorator('country', {
                                                            rules: [{ required: false, message: 'Please select your country!' }],
                                                        })(
                                                            <Select
                                                                showSearch
                                                                placeholder={translate(`input_labels.${inputs.COUNTRY}`)}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                {countries.map((country, index) => (
                                                                    <Option  key={index} value={country.code}>{country.name}</Option>
                                                                ))}
                                                            </Select>,
                                                        )}
                                                    </Form.Item>
                                                </div>

                                                {/*** 2ND STEP */}
                                                <div className={`experience-step${this.state.current}`}>
                                                    <Row>
                                                        <Col xs={24} md={8}>
                                                            <div className="mr-md-2">
                                                                <Form.Item
                                                                    onFocus={()=>{
                                                                        setTimeout( ()=> {
                                                                            this.handleInputClick(inputs.GRADE);this.showHintDetails(grades)
                                                                        }, 300);
                                                                    }}

                                                                    label={translate(`input_labels.${inputs.GRADE}`)} style={{ display: this.display(1) }}>
                                                                    {getFieldDecorator('grade', {
                                                                        rules: [{ required: true, message: 'Please select your grade!' }],
                                                                    })(
                                                                        <Select
                                                                            onBlur={() => {this.hideHintDetails()}}
                                                                            showSearch
                                                                            style={{}}
                                                                            placeholder={translate(`input_labels.${inputs.GRADE}`)}
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            onChange={(grade) => this.setState({ grade })}
                                                                        >
                                                                            {grades.map((grade, index) => (
                                                                                <Option key={index} value={index + 1}>{translate(`${inputs.GRADE}.${grade}`)}</Option>
                                                                            ))}
                                                                        </Select>,
                                                                    )}
                                                                </Form.Item>
                                                            </div>

                                                        </Col>
                                                        <Col xs={24} md={8}>
                                                            <div className="ml-md-2 mr-md-2">
                                                                <Form.Item
                                                                    onFocus={()=>{this.handleInputClick(inputs.OBTAINING_DATE)}}
                                                                    label={translate(`input_labels.${inputs.OBTAINING_DATE}`)} style={{ display: this.display(1) }}>
                                                                    {getFieldDecorator('date_obtaining', {
                                                                        rules: [{ required: true, message: 'Please enter the date of obtaining!' }],
                                                                    })(
                                                                        <DatePicker placeholder="" />,
                                                                    )}
                                                                </Form.Item>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} md={8}>
                                                            <div className="ml-md-2">
                                                                <Form.Item
                                                                    onFocus={()=>{
                                                                        setTimeout( ()=> {
                                                                            this.handleInputClick(inputs.OBTAINING_COUNTRY);
                                                                        }, 300);
                                                                    }}

                                                                    label={translate(`input_labels.${inputs.OBTAINING_COUNTRY}`)} style={{ display: this.display(1) }}>
                                                                    {getFieldDecorator('obtaining_country', {
                                                                        rules: [{ required: true, message: 'Please select your obtaining country!' }],
                                                                    })(
                                                                        <Select
                                                                            onChange={(country) => this.setState({ isObtainingCountrySelected:true, country_code: country })}
                                                                            onBlur={() => {this.hideHintDetails()}}
                                                                            showSearch
                                                                            style={{}}
                                                                            placeholder={translate(`input_labels.${inputs.OBTAINING_COUNTRY}`)}
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {countries.map((country, index) => (
                                                                                <Option  key={index} value={country.code}>{country.name}</Option>
                                                                            ))}
                                                                        </Select>,
                                                                    )}
                                                                </Form.Item>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Form.Item
                                                        onFocus={()=>{
                                                            this.state.isObtainingCountrySelected ? this.handleInputClick(inputs.UNIVERSITY) : this.handleInputClick(inputs.PLEASE_SELECT_OBTAINING_COUNTRY)
                                                        }}
                                                        label={translate(`input_labels.${inputs.UNIVERSITY}`)} style={{ display: this.display(1) }}>
                                                        {getFieldDecorator('university', {
                                                            rules: [{ required: true, message: 'Please enter your university!' }],
                                                        })(
                                                            <Select
                                                                showSearch
                                                                placeholder={translate(`input_labels.${inputs.UNIVERSITY}`)}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                                                }
                                                                onSearch={(searchedValue) => this.setState({searchedValue})}
                                                                notFoundContent={
                                                                    this.state.isObtainingCountrySelected ?
                                                                        <Button icon="plus" onClick={this.addUniversity}>{translate('landing.experience.add')} {this.state.searchedValue}</Button> 
                                                                        :
                                                                        ''
                                                                }

                                                            >
                                                                {this.state.isObtainingCountrySelected ?
                                                                        (
                                                                            this.state.universities.filter(univ => univ.code===this.state.country_code).map((university, index) => (
                                                                                <Option  key={index} value={`${university.id}`}>{university.name}</Option>
                                                                            ))
                                                                        ) : ''
                                                                }
                                                            </Select>,
                                                        )}
                                                    </Form.Item>
                                                    <Row>
                                                        <Col span={24}>
                                                            <div className="mr-0">
                                                                <Form.Item
                                                                    onFocus={()=>{this.handleInputClick(inputs.FACULTY)}}
                                                                    label={translate(`input_labels.${inputs.FACULTY}`)}
                                                                    style={{ display: this.display(1) }}>
                                                                    {getFieldDecorator('faculty', {
                                                                        rules: [{ required: true, message: 'Please enter your faculty!' }],
                                                                    })(
                                                                        <Select
                                                                            onChange={(faculty_id) => this.setState({ faculty_id })}
                                                                            showSearch
                                                                            placeholder={translate(`input_labels.${inputs.FACULTY}`)}
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            onSearch={(searchedValue) => this.setState({searchedValue})}
                                                                            notFoundContent={<Button icon="plus" onClick={this.addFaculty}>{translate('landing.experience.add')} {this.state.searchedValue}</Button>}>
                                                                            {this.state.faculties.map((faculty, index) => (
                                                                                <Option  key={index} value={`${faculty.id}`}>{faculty.name}</Option>
                                                                            ))}
                                                                        </Select>,
                                                                    )}
                                                                </Form.Item>
                                                            </div>
                                                        </Col>
                                                        <Col span={24}>
                                                            <div className="ml-0">
                                                                <Form.Item
                                                                    onFocus={()=>{this.handleInputClick(inputs.DEPARTMENT)}}
                                                                    label={translate(`input_labels.${inputs.DEPARTMENT}`)} style={{ display: this.display(1) }}>
                                                                    {getFieldDecorator('department', {
                                                                        rules: [{ required: true, message: 'Please enter your department!' }],
                                                                    })(
                                                                        <Select
                                                                            onChange={(departement_id) => this.setState({ departement_id })}
                                                                            showSearch
                                                                            placeholder={translate(`input_labels.${inputs.DEPARTMENT}`)}
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            onSearch={(searchedValue) => this.setState({searchedValue})}
                                                                            notFoundContent={<Button icon="plus" onClick={this.addDepartement}>{translate('landing.experience.add')} {this.state.searchedValue}</Button>}>
                                                                            {this.state.departments.filter(dep => dep.faculty_id == this.state.faculty_id).map((department, index) => (
                                                                                <Option  key={index} value={`${department.id}`}>{department.name}</Option>
                                                                            ))}
                                                                        </Select>,
                                                                    )}
                                                                </Form.Item>
                                                            </div>

                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        {this.state.grade >= 3 ?
                                                            <Col span={24}>
                                                                <div className="mr-0">
                                                                    <Form.Item
                                                                        onFocus={()=>{this.handleInputClick(inputs.FIELD)}}
                                                                        label={translate(`input_labels.${inputs.FIELD}`)} style={{ display: this.display(1) }}>
                                                                        {getFieldDecorator('field', {
                                                                            rules: [{ required: (this.state.grade >= 3), message: 'Please enter your field!' }],
                                                                        })(
                                                                            <Select
                                                                                showSearch
                                                                                placeholder={translate(`input_labels.${inputs.FIELD}`)}
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                onSearch={(searchedValue) => this.setState({searchedValue})}
                                                                                notFoundContent={<Button icon="plus" onClick={this.addField}>{translate('landing.experience.add')} {this.state.searchedValue}</Button>}>
                                                                                {this.state.fields.filter(field => field.departement_id == this.state.departement_id).map((field, index) => (
                                                                                    <Option  key={index} value={`${field.id}`}>{field.name}</Option>
                                                                                ))}
                                                                            </Select>,
                                                                        )}
                                                                    </Form.Item>
                                                                </div>
                                                            </Col>
                                                            :
                                                            null
                                                        }
                                                        {this.state.grade >= 4 ?
                                                            <Col span={24}>
                                                                <div className="ml-0">
                                                                    <Form.Item
                                                                        onFocus={()=>{this.handleInputClick(inputs.SPECIALTY)}}
                                                                        label={translate(`input_labels.${inputs.SPECIALTY}`)} style={{ display: this.display(1) }}>
                                                                        {getFieldDecorator('specialty', {
                                                                            rules: [{ required: (this.state.grade >= 4), message: 'Please enter your specialty!' }],
                                                                        })(
                                                                            <Input
                                                                                prefix={<Icon type="branches" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                                                placeholder={translate(`input_labels.${inputs.SPECIALTY}`)}
                                                                            />,
                                                                        )}
                                                                    </Form.Item>

                                                                </div>
                                                            </Col>
                                                            :
                                                            null
                                                        }
                                                    </Row>
                                                    <Row>
                                                        <Col xs={24} md={14}>
                                                            <div className="mr-md-2">
                                                                <Form.Item
                                                                    onFocus={()=>{
                                                                        setTimeout( ()=> {
                                                                            this.handleInputClick(inputs.TYPE_OF_EXPERIENCE);
                                                                            this.showHintDetails(type_of_experience)
                                                                        }, 300);
                                                                    }}

                                                                    label={translate(`input_labels.${inputs.TYPE_OF_EXPERIENCE}`)} style={{ display: this.display(1) }}>
                                                                    {getFieldDecorator('type_experience', {
                                                                        rules: [{ required: true, message: 'Please enter your type of experience!' }],
                                                                    })(
                                                                        <Select
                                                                            onBlur={() => {this.hideHintDetails()}}
                                                                            showSearch
                                                                            style={{}}
                                                                            placeholder={translate(`input_labels.${inputs.TYPE_OF_EXPERIENCE}`)}
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }>
                                                                            {type_of_experience.map((type, index) => (
                                                                                <Option key={index} value={type}>{translate(`type_of_experience.${type}`)}</Option>
                                                                            ))}
                                                                        </Select>,
                                                                    )}
                                                                </Form.Item>
                                                            </div>

                                                        </Col>
                                                        <Col xs={24} md={10}>
                                                            <div className="ml-md-2">
                                                                <Form.Item
                                                                    onFocus={()=>{this.handleInputClick(inputs.YEARS_OF_EXPERIENCE)}}
                                                                    label={translate(`input_labels.${inputs.YEARS_OF_EXPERIENCE}`)} style={{ display: this.display(1) }}>
                                                                    {getFieldDecorator('years_experience', {
                                                                        rules: [{ required: true, message: 'Please enter your years of experience!' }],
                                                                    })(
                                                                        <Input
                                                                            type="number"
                                                                            // prefix={<Icon type="rise" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                                            placeholder={translate(`input_labels.${inputs.YEARS_OF_EXPERIENCE}`)} min={1}
                                                                        />,
                                                                    )}
                                                                </Form.Item>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                {/** 3RD STEP */}
                                                <div className={`experience-step${this.state.current}`}>


                                                    <div className='table-responsive' style={{ display: this.display(2) }}>
                                                        <div className="d-md-none" ><FormHint input={this.state.focused_input}  /></div>
                                                        <table className="table table-bordered text-center table-forms">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col" className="background-cell">
                                                                    <div><span className="bottom-cell">Grade</span>
                                                                        <span className="top-cell">Nb years</span>
                                                                        <div className="line-cell"></div>
                                                                    </div>
                                                                </th>

                                                                <th scope="col" style={{ display: this.nbYear(1) }}>1</th>
                                                                <th scope="col" style={{ display: this.nbYear(2) }}>2</th>
                                                                <th scope="col" style={{ display: this.nbYear(5) }}>5</th>
                                                                <th scope="col" style={{ display: this.nbYear(10) }}>10</th>
                                                                <th scope="col" style={{ display: this.nbYear(20) }}>20</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr style={{ display: this.grade(4) }}>
                                                                <th scope="row">{translate(`grade.${grades[3]}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`form_doctor_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.NB_DOCTOR)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr style={{ display: this.grade(3) }}>
                                                                <th scope="row">{translate(`grade.${grades[2]}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`form_magistere_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.NB_MASTER)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr style={{ display: this.grade(2) }}>
                                                                <th scope="row">{translate(`grade.${grades[1]}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`form_ingenieur_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.NB_ENGINEER)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr style={{ display: this.grade(1) }}>
                                                                <th scope="row">{translate(`grade.${grades[0]}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`form_technicien_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.NB_TECHNICIAN)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                                {/** 4TH STEP */}
                                                <div className={`experience-step${this.state.current}`}>
                                                    <div className='table-responsive' style={{ display: this.display(3) }}>
                                                        <div className="d-md-none" ><FormHint input={this.state.focused_input}  /></div>
                                                        <table className="table table-bordered table-forms">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col" className="background-cell">
                                                                    <div><span className="bottom-cell">Type</span>
                                                                        <span className="top-cell">Nb years</span>
                                                                        <div className="line-cell"></div>
                                                                    </div>
                                                                </th>

                                                                <th scope="col" style={{ display: this.nbYear(1) }}>1</th>
                                                                <th scope="col" style={{ display: this.nbYear(2) }}>2</th>
                                                                <th scope="col" style={{ display: this.nbYear(5) }}>5</th>
                                                                <th scope="col" style={{ display: this.nbYear(10) }}>10</th>
                                                                <th scope="col" style={{ display: this.nbYear(20) }}>20</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.REVUE_INT}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`pub_revue_int_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.REVUE_INT)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.REVUE_NAT}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`pub_revue_nat_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.REVUE_NAT)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.CONF_INT}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`pub_conf_int_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.CONF_INT)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.CONF_NAT}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`pub_conf_nat_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.CONF_NAT)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.REPORT}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`pub_rapport_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.REPORT)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>


                                                {/** 5TH STEP */}
                                                <div className={`experience-step${this.state.current}`}>
                                                    <div className='table-responsive' style={{ display: this.display(4) }}>
                                                        <div className="d-md-none" ><FormHint input={this.state.focused_input}  /></div>
                                                        <table className="table table-bordered table-forms">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col" className="background-cell">
                                                                    <div><span className="bottom-cell">Type</span>
                                                                        <span className="top-cell">Nb years</span>
                                                                        <div className="line-cell"></div>
                                                                    </div>
                                                                </th>
                                                                <th scope="col" style={{ display: this.nbYear(1) }}>1</th>
                                                                <th scope="col" style={{ display: this.nbYear(2) }}>2</th>
                                                                <th scope="col" style={{ display: this.nbYear(5) }}>5</th>
                                                                <th scope="col" style={{ display: this.nbYear(10) }}>10</th>
                                                                <th scope="col" style={{ display: this.nbYear(20) }}>20</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.PATENT_ACC}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`real_brevet_acc_${nbYear}`, {
                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.PATENT_ACC)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.PRODUCT_DEPLOYED}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`real_produit_deploye_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.PRODUCT_DEPLOYED)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.PRODUCT_REALIZED}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`real_produit_realise_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.PRODUCT_REALIZED)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.PRODUCT_DEMO}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`real_produit_demonstration_${nbYear}`, {

                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.PRODUCT_DEMO)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{translate(`input_labels.${inputs.PROTOTYPE}`)}</th>
                                                                {nbYears.map((nbYear, index) => (
                                                                    <td key={index} style={{ display: this.nbYear(nbYear) }}>
                                                                        <Form.Item style={{ marginBottom: 0 }}>
                                                                            {getFieldDecorator(`real_prototype_${nbYear}`, {
                                                                            })(
                                                                                <Input
                                                                                    onFocus={() =>{this.handleInputClick(inputs.PROTOTYPE)}}
                                                                                    placeholder="" min={0}
                                                                                />,
                                                                            )}
                                                                        </Form.Item>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="steps-action float-right">


                                                {current > 0 && (
                                                    <Button className="btn-regular" style={{ marginRight: 8 }} onClick={() => this.prev()}>
                                                        {translate("landing.experience.previous")}
                                                    </Button>
                                                )}

                                                {current === steps - 1 && (
                                                    <Button className="btn-primary"  htmlType="submit">
                                                        {translate("landing.experience.done")}
                                                    </Button>
                                                )}

                                                {current < steps - 1 && (
                                                    <Button  className="btn-primary" onClick={() => this.next()}>
                                                        {translate("landing.experience.next")}
                                                    </Button>
                                                )}
                                            </div>
                                        </Form>
                                    </div>

                                    <div className="col-6 d-none d-md-block" style={{marginTop:'30px'}}>
                                        <div className="text-center">
                                            <img src="img/forms-pic.svg" alt="" width={350}/>
                                        </div>
                                        <FormHint input={this.state.focused_input}  />

                                    </div>
                                </div>

                            </div>
                            <Footer/>
                        </div>
                    </div>

                }
            </Translate>

        )
    }
}

const WrappedExperienceForm = Form.create({ name: 'experience_form' })(Experience);

export default connect(null, {
    addConfiguration,
    showExperienceFormHint,
    hideExperienceFormHint,
    showHintDetails,
    hideHintDetails
})(withLocalize(WrappedExperienceForm));
