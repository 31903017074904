import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class Footer extends React.Component {
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <footer className="footer-social-icon text-center section_padding_70 clearfix" id="footer">
                        {/* footer logo */}
                        <div className="footer-text">
                            <img alt=""   className="logo-brand" src="img/logo/bahith-logo.svg"></img>
                           <div className="" >
                               <p className="w-md-50 w-75 mx-auto text-justify">
                                   {translate("landing.footer.description")}

                               </p>
                           </div>
                        </div>
                        {/* social icon*/}

                        <div className="footer-menu">
                            <nav>
                                <ul>
                                    <li><a href="#about">{translate("landing.footer.about-item")}</a></li>
                                    <li><a href="#contact">{translate("landing.footer.contact-item")}</a></li>
                                </ul>
                            </nav>
                        </div>
                        {/* Foooter Text*/}
                        <div className="copyright-text">

                            {/* ***** Removing this text is now allowed! This template is licensed under CC BY 3.0 ***** */}
                            <p>{translate("landing.footer.copyright-item")} ©2020 Bahith.</p>
                        </div>
                    </footer>
                }
            </Translate>
        )
    }
}

export default withLocalize(Footer);