export const CREATE_FEEDBACK = 'CREATE_FEEDBACK';
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS';
//Hints
export const SHOW_EXPERIENCE_FORM_HINT = 'SHOW_EXPERIENCE_FORM_HINT';
export const HIDE_EXPERIENCE_FORM_HINT = 'HIDE_EXPERIENCE_FORM_HINT';
export const SHOW_HINT_DETAILS = 'SHOW_HINT_DETAILS';
export const HIDE_HINT_DETAILS = 'HIDE_HINT_DETAILS';
//Evaluation
export const EVALUATION = 'EVALUATION';
export const EVALUATION_SUCCESS = 'EVALUATION_SUCCESS';
export const FETCH_FACTS = 'FETCH_FACTS';
