import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class Testimonial extends React.Component {
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <section className="clients-feedback-area bg-white section_padding_100 clearfix" id="testimonials">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10">
                                    <div className="slider slider-for">
                                        {/* Client Feedback Text  */}
                                        <div className="client-feedback-text text-center">
                                            <div className="client">
                                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                                            </div>
                                            <div className="client-description text-center">
                                                <p>“ I have been using it for a number of years. I use Colorlib for usability testing. It's great for taking images and making clickable image prototypes that do the job and save me the coding time and just the general hassle of hosting. ”</p>
                                            </div>
                                            <div className="star-icon text-center">
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                            </div>
                                            <div className="client-name text-center">
                                                <h5>Aigars Silkalns</h5>
                                                <p>Ceo Colorlib</p>
                                            </div>
                                        </div>
                                        {/* Client Feedback Text  */}
                                        <div className="client-feedback-text text-center">
                                            <div className="client">
                                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                                            </div>
                                            <div className="client-description text-center">
                                                <p>“ I use Colorlib for usability testing. It's great for taking images and making clickable image prototypes that do the job and save me the coding time and just the general hassle of hosting. ”</p>
                                            </div>
                                            <div className="star-icon text-center">
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                            </div>
                                            <div className="client-name text-center">
                                                <h5>Jennifer</h5>
                                                <p>Developer</p>
                                            </div>
                                        </div>
                                        {/* Client Feedback Text  */}
                                        <div className="client-feedback-text text-center">
                                            <div className="client">
                                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                                            </div>
                                            <div className="client-description text-center">
                                                <p>“ I have been using it for a number of years. I use Colorlib for usability testing. It's great for taking images and making clickable image prototypes that do the job.”</p>
                                            </div>
                                            <div className="star-icon text-center">
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                            </div>
                                            <div className="client-name text-center">
                                                <h5>Helen</h5>
                                                <p>Marketer</p>
                                            </div>
                                        </div>
                                        {/* Client Feedback Text  */}
                                        <div className="client-feedback-text text-center">
                                            <div className="client">
                                                <i className="fa fa-quote-left" aria-hidden="true"></i>
                                            </div>
                                            <div className="client-description text-center">
                                                <p>“ I have been using it for a number of years. I use Colorlib for usability testing. It's great for taking images and making clickable image prototypes that do the job and save me the coding time and just the general hassle of hosting. ”</p>
                                            </div>
                                            <div className="star-icon text-center">
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                                <i className="ion-ios-star"></i>
                                            </div>
                                            <div className="client-name text-center">
                                                <h5>Henry smith</h5>
                                                <p>Developer</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Client Thumbnail Area */}
                                <div className="col-12 col-md-6 col-lg-5">
                                    <div className="slider slider-nav">
                                        <div className="client-thumbnail">
                                            <img src="img/bg-img/client-3.jpg" alt="" />
                                        </div>
                                        <div className="client-thumbnail">
                                            <img src="img/bg-img/client-2.jpg" alt="" />
                                        </div>
                                        <div className="client-thumbnail">
                                            <img src="img/bg-img/client-1.jpg" alt="" />
                                        </div>
                                        <div className="client-thumbnail">
                                            <img src="img/bg-img/client-2.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </Translate>
        )
    }
}

export default withLocalize(Testimonial);