export default [
    { id: 1, name: 'Droit public', departement_id: 1},
    { id: 2, name: 'Droit privé', departement_id: 1},
    { id: 3, name: 'Sciences administratives', departement_id: 1},
    { id: 4, name: 'Organisation politique et administrative', departement_id: 2},
    { id: 5, name: 'Relations international', departement_id: 2},
    { id: 6, name: 'Anatomie Pathologique', departement_id: 19},
    { id: 7, name: 'Anesthésie-réanimation', departement_id: 19},
    { id: 8, name: 'Biochimie', departement_id: 19},
    { id: 9, name: 'Biologie clinique', departement_id: 19},
    { id: 10, name: 'Biophysique', departement_id: 19},
    { id: 11, name: 'Cardiologie', departement_id: 19},
    { id: 12, name: 'Chirurgie générale', departement_id: 19},
    { id: 13, name: 'Chirurgie orthopédique', departement_id: 19},
    { id: 14, name: 'Chirurgie pédiatrique', departement_id: 19},
    { id: 15, name: 'Chirurgie urologique', departement_id: 19},
    { id: 16, name: 'Chirurgie maxillo-faciale', departement_id: 19},
    { id: 17, name: 'Chirurgie cardio-vasculaire', departement_id: 19},
    { id: 18, name: 'Dermatologie', departement_id: 19},
    { id: 19, name: 'Endocrinologie – Diabétologie', departement_id: 19},
    { id: 20, name: 'Épidémiologie', departement_id: 19},
    { id: 21, name: 'Gastro-enterologie', departement_id: 19},
    { id: 22, name: 'Gynéco-Obstétrique', departement_id: 19},
    { id: 23, name: 'Hématologie', departement_id: 19},
    { id: 24, name: 'Hémobiologie', departement_id: 19},
    { id: 25, name: 'Histo-embryologie', departement_id: 19},
    { id: 26, name: 'Immunologie', departement_id: 19},
    { id: 27, name: 'Maladies Infectieuses', departement_id: 19},
    { id: 28, name: 'Médecine interne', departement_id: 19},
    { id: 29, name: 'Médecine légale', departement_id: 19},
    { id: 30, name: 'Médecine nucléaire', departement_id: 19},
    { id: 31, name: 'Médecine du travail', departement_id: 19},
    { id: 32, name: 'Microbiologie', departement_id: 19},
    { id: 33, name: 'Néphrologie', departement_id: 19},
    { id: 34, name: 'Neurochirurgie', departement_id: 19},
    { id: 35, name: 'Neurologie', departement_id: 19},
    { id: 36, name: 'ORL', departement_id: 19},
    { id: 37, name: 'Ophtalmologie', departement_id: 19},
    { id: 38, name: 'Oncologie médicale', departement_id: 19},
    { id: 39, name: 'Parasitologie', departement_id: 19},
    { id: 40, name: 'Pediatrie', departement_id: 19},
    { id: 41, name: 'Pharmacologie clinique', departement_id: 19},
    { id: 42, name: 'Physiologie', departement_id: 19},
    { id: 43, name: 'Pneumo-phtisiologie', departement_id: 19},
    { id: 44, name: 'Psychiatrie', departement_id: 19},
    { id: 45, name: 'Radiologie Et Imagerie Medicale', departement_id: 19},
    { id: 46, name: 'Radiothérapie', departement_id: 19},
    { id: 47, name: 'Rééducation fonctionnelle', departement_id: 19},
    { id: 48, name: 'Rhumatologie', departement_id: 19},
    { id: 49, name: 'Odontologie conservatrice', departement_id: 21},
    { id: 50, name: 'O.D.F', departement_id: 21},
    { id: 51, name: 'Parodontologie', departement_id: 21},
    { id: 52, name: 'Pathologie bucco-dentaire', departement_id: 21},
    { id: 53, name: 'Prothèse', departement_id: 21},
    { id: 54, name: 'Biologie et Physiologie des Organismes', departement_id: 22},
    { id: 55, name: 'Écologie et Environnement', departement_id: 22},
    { id: 56, name: 'Biologie Cellulaire et Moléculaire', departement_id: 22},
    { id: 57, name: 'Physique de Rayonnement', departement_id: 23},
    { id: 58, name: 'Matériaux et Composants', departement_id: 23},
    { id: 59, name: 'Energétique et Mécanique des Fluides', departement_id: 23},
    { id: 60, name: 'Physique Théorique', departement_id: 23},
    { id: 61, name: 'Recherche Opérationnelle', departement_id: 24},
    { id: 62, name: 'Algèbre et Théorie des Nombres', departement_id: 24},
    { id: 63, name: 'Analyse', departement_id: 24},
    { id: 64, name: 'Probabilités-Statistiques', departement_id: 24},
    { id: 65, name: 'Ingénierie du Logiciel', departement_id: 25},
    { id: 66, name: 'Réseaux et Systèmes Distribués', departement_id: 25},
    { id: 67, name: 'Systèmes Informatiques Intelligents', departement_id: 25},
    { id: 68, name: 'Sécurité des Systèmes Informatiques', departement_id: 25},
    { id: 69, name: 'Intelligence artificielle', departement_id: 25},
    { id: 70, name: 'Informatique Visuelle', departement_id: 25},
    { id: 71, name: 'Mathématiques et Informatique Décisionnelle', departement_id: 25},
    { id: 72, name: 'Géologie', departement_id: 26},
    { id: 73, name: 'Géophysique', departement_id: 26},
    { id: 74, name: 'Géographie et Aménagement du Territoire', departement_id: 26},
    { id: 75, name: 'Finance des entreprises', departement_id: 35},
    { id: 76, name: 'Comptabilité et audit', departement_id: 35},
    { id: 77, name: 'Comptabilité et recouvrement', departement_id: 35},
    { id: 78, name: 'Finance et comptabilité', departement_id: 35},
    { id: 79, name: 'Commerce international', departement_id: 36},
    { id: 80, name: 'Marketing', departement_id: 36},
    { id: 81, name: 'Administration des affaires', departement_id: 37},
    { id: 82, name: 'Gestion budgétaire', departement_id: 37},
    { id: 83, name: 'Administration financière', departement_id: 37},
    { id: 84, name: 'Gestion des ressources humaines', departement_id: 37},
    { id: 85, name: 'Économie quantitative', departement_id: 38},
    { id: 86, name: 'Économie monétaire et bancaire', departement_id: 38},
    { id: 87, name: 'Économie et gestion des entreprises', departement_id: 38},
    { id: 88, name: 'Analyse et perspectives économiques', departement_id: 38},
    { id: 89, name: 'Audiovisuel', departement_id: 39},
    { id: 90, name: 'Journalisme', departement_id: 39},
    { id: 91, name: 'Communication et relations publiques', departement_id: 40},
    { id: 92, name: 'Communication organisationnelle', departement_id: 40},
    { id: 93, name: 'Communication de masse', departement_id: 40},
    { id: 94, name: 'Automatisation Industries et Process', departement_id: 44},
    { id: 95, name: 'Automatique et systèmes', departement_id: 44},
    { id: 96, name: 'Instrumentation', departement_id: 44},
    { id: 97, name: 'Electronique et systèmes embarqués', departement_id: 44},
    { id: 98, name: 'Énergies renouvelables en Electrotechnique', departement_id: 45},
    { id: 99, name: 'Génie des Systèmes Industriels et Tertiaires', departement_id: 45},
    { id: 100, name: 'Électrotechnique Industrielle', departement_id: 45},
    { id: 101, name: 'Réseaux Électriques', departement_id: 45},
    { id: 102, name: 'Machines Electriques', departement_id: 45},
    { id: 103, name: 'Chimie Organique Appliquée', departement_id: 48},
    { id: 104, name: 'Chimie Physique et Théorique', departement_id: 48},
    { id: 105, name: 'Chimie Macromoleculaire', departement_id: 48},
    { id: 106, name: 'Chimie et Physique des Matériaux Inorganique', departement_id: 48},
    { id: 107, name: 'Equipement de l’Habitat', departement_id: 49},
    { id: 108, name: 'Hydraulique Urbaine', departement_id: 49},
    { id: 109, name: 'Structures', departement_id: 49},
    { id: 110, name: 'Voies et Ouvrages d’Art', departement_id: 49},
    { id: 111, name: 'Thermo Énergétique', departement_id: 51},
    { id: 112, name: 'Sciences des Matériaux', departement_id: 51},
    { id: 113, name: 'Construction Mécanique', departement_id: 51},
]