import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Views
import Landing from './views/Landing';
import Experience from './views/Experience';
import Evaluation from './views/Evaluation';
import test from './views/test';
import Doc from "./views/Doc";


class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route
          component={Landing}
          exact
          path="/"
        />
        <Route
          component={Experience}
          exact
          path="/experience"
        />
        <Route
          component={Evaluation}
          exact
          path="/evaluation"
        />
        <Route
          component={test}
          exact
          path="/test"
        />
        <Route
        component={Doc}
        exact
        path="/doc/:activeLanguage"
        />
        <Redirect to="/" />
      </Switch>
    );
  }
}


export default Routes;
