import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import {Layout,Menu} from 'antd';
import Navbar from "./Navbar";
import {docs_fr} from "./docs/bahith_docs_fr";
import {docs_en} from "./docs/bahith_docs_en";
import ReactMarkdown from "react-markdown";
import TocMenu from "../components/TocMenu";


let docs= null;
let tocArray = [];
const { Content, Sider } = Layout;

class Doc extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            firstRender : false,
            collapsed: false,
            tocArray: [],
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){

        if(nextProps.match.params.activeLanguage==='en') {docs = docs_en ; ;return (prevState.tocArray=[]);}
        if(nextProps.match.params.activeLanguage==='fr') {docs = docs_fr ; return (prevState.tocArray=[])}
        else return null;
    }


    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };
    changeLanguage = (e, lang) => {
        e.preventDefault();
        this.props.history.push(`/doc/${lang}`);
        this.props.setActiveLanguage(lang);
    }
    renderLanguagePicker() {
        const { languages, activeLanguage } = this.props
        return (
            activeLanguage ?
                (<div className="dropdown show">
                    <button className="btn btn-secondary lang-btn " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="far fa-globe-europe"></i> &nbsp;
                        {activeLanguage.name}
                    </button>
                    <div className="dropdown-menu">
                        {languages.map(lang => (
                            <a onClick={(e) => this.changeLanguage(e, lang.code)} key={lang.code} className="dropdown-item" href="#lang"><img alt="" style={{ height: '20px', marginRight: '15px' }} src={`/img/icon/${lang.code}.png`}></img>{lang.name}</a>
                        ))}
                    </div>
                </div>)
                :
                null
        );
    }

    flatten = (text, child) => {
        return typeof child === 'string'
            ? text + child
            : React.Children.toArray(child.props.children).reduce(this.flatten, text)
    }


    HeadingRenderer= (props) => {
        var children = React.Children.toArray(props.children);
        var text = children.reduce(this.flatten, '');
        var slug = text.toLowerCase().replace(/\W/g, '-');
        return React.createElement('h' + props.level, {id: slug}, props.children)
    };

    getToc= (props) => {

        var children = React.Children.toArray(props.children);
        var text = children.reduce(this.flatten, '');
        var slug = text.toLowerCase().replace(/\W/g, '-');
        let toc={level : props.level, id : slug, title:text };
        this.state.tocArray.push(toc);
        return null;
    };

    initTocArray(){
       tocArray = []
    }

    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <div >
                        <Navbar renderLanguage={this.renderLanguagePicker()}/>

                        {/*<Layout className="docs float-right">*/}
                        {/*    <ReactMarkdown*/}
                        {/*        className="d-none"*/}
                        {/*        renderers={{*/}
                        {/*            heading: this.getToc,*/}
                        {/*        }}*/}
                        {/*        source={docs}*/}
                        {/*    />*/}

                        {/*    <Sider*/}
                        {/*        // collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}*/}
                        {/*        breakpoint="lg"*/}
                        {/*        collapsedWidth="0"*/}
                        {/*        onBreakpoint={broken => {*/}
                        {/*            console.log(broken);*/}
                        {/*        }}*/}
                        {/*        onCollapse={(collapsed, type) => {*/}
                        {/*            console.log(collapsed, type);*/}
                        {/*        }}*/}
                        {/*        width={250}*/}
                        {/*        style={{*/}
                        {/*            background: '#fff',*/}
                        {/*            borderRight:'1px solid #cecece'*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <TocMenu tocArray={this.state.tocArray} />*/}

                        {/*    </Sider>*/}

                        {/*    <Content*/}
                        {/*        className="docs-content"*/}
                        {/*    >*/}

                        {/*        <h1 className="docs-main-title"> Bahith documentation </h1>*/}
                        {/*        <p> blabla à changer.... </p>*/}
                        {/*        <hr/>*/}
                        {/*        {this.initTocArray()}*/}
                        {/*        <ReactMarkdown*/}
                        {/*            renderers={{*/}
                        {/*                heading: this.HeadingRenderer,*/}
                        {/*            }}*/}
                        {/*            source={docs}*/}
                        {/*        />*/}

                        {/*    </Content>*/}

                        {/*</Layout>*/}

                        <Layout>
                            <ReactMarkdown
                                className="d-none"
                                renderers={{
                                    heading: this.getToc,
                                }}
                                source={docs}
                            />

                            <Sider
                                breakpoint="lg"
                                collapsedWidth="0"
                                onBreakpoint={broken => {
                                    console.log(broken);
                                }}
                                onCollapse={(collapsed, type) => {
                                    console.log(collapsed, type);
                                }}
                                width={250}
                                style={{
                                    background: '#fff',
                                    borderRight:'1px solid #cecece'
                                }}
                            >
                                <TocMenu tocArray={this.state.tocArray} />
                            </Sider>
                            <Layout>

                                <Content  className="docs-content">

                                    <h1 className="docs-main-title"> Bahith documentation </h1>
                                    <hr/>
                                    {this.initTocArray()}
                                    <ReactMarkdown
                                        renderers={{
                                            heading: this.HeadingRenderer,
                                        }}
                                        source={docs}
                                    />
                                </Content>

                            </Layout>
                        </Layout>
                    </div>
                }
            </Translate>
        )
    }
}

export default withLocalize(Doc);
