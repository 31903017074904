import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class Experience extends React.Component {
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <section className="" id="experience-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {/* Section Heading Area */}
                                    <div className="section-heading text-center">
                                        <h2>{translate("landing.experience.h2-title")}</h2>
                                        <div className="line-shape"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 order-last order-md-first">
                                    <div className="text-justify">
                                        <h6 className="blue-title">{translate("landing.experience.blue-title")} </h6>
                                        <p className="mx-auto">
                                            {translate("landing.experience.content")}
                                        </p>

                                        <div className="about-btn">
                                            <div><a className="btn btn-danger1 " href="/experience">
                                                <i className="fad fa-share-square"></i> {translate("landing.experience.action-btn")}</a></div>
                                        </div>
                                    </div>
                                    <p className="info" ><i
                                        className="far fa-info-circle"></i> {translate("landing.experience.info")}  </p>
                                </div>

                                <div className="col-12 col-md-6 mb-5 mb-md-0 order-first order-md-last">
                                    <img width={1000} src="img/experience.svg" alt="a women looking for something and ? "/>
                                </div>


                            </div>
                        </div>
                    </section>
                }
            </Translate>
        )
    }
}

export default withLocalize(Experience);