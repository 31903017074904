import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import FeedbackReducer from './FeedbackReducer';
import EvaluationReducer from './EvaluationReducer';
import LandingReducer from "./LandingReducer";

import HintReducer from "./HintReducer";
export default combineReducers({
    form: formReducer,
    feedback: FeedbackReducer,
    formHint : HintReducer,
    evaluation: EvaluationReducer,
    landing:LandingReducer

});