import {SHOW_EXPERIENCE_FORM_HINT,HIDE_EXPERIENCE_FORM_HINT,SHOW_HINT_DETAILS,HIDE_HINT_DETAILS} from '../actions/types';

const INITIAL_STATE = {
    isVisible: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_EXPERIENCE_FORM_HINT :
            return {...state,isVisible:true};
        case HIDE_EXPERIENCE_FORM_HINT :
            return {...state,isVisible:false};
        case SHOW_HINT_DETAILS :
            return {...state,hintDetails:true,values:action.data};
        case HIDE_HINT_DETAILS :
            return {...state,hintDetails:false};
        default:
            return state;
    }
};
