export default [
  { code: "AD", name: "University of Andorra", id: 1 },
  { code: "AE", name: "Abu Dhabi University", id: 2 },
  { code: "AE", name: "Ajman University of Science & Technology", id: 3 },
  { code: "AE", name: "Alain University of Science and Technology", id: 4 },
  { code: "AE", name: "Al Ghurair University", id: 5 },
  { code: "AE", name: "Alhosn University", id: 6 },
  { code: "AE", name: "Al Khawarizmi International College", id: 7 },
  { code: "AE", name: "American College Of Dubai", id: 8 },
  { code: "AE", name: "American University in Dubai", id: 9 },
  { code: "AE", name: "American University in the Emirates", id: 10 },
  { code: "AE", name: "American University of Sharjah", id: 11 },
  { code: "AE", name: "British University in Dubai", id: 12 },
  { code: "AE", name: "Dubai Medical College for Girls", id: 13 },
  { code: "AE", name: "Dubai Pharmacy College", id: 14 },
  { code: "AE", name: "Etisalat University College", id: 15 },
  { code: "AE", name: "Gulf Medical University", id: 16 },
  { code: "AE", name: "Hamdan Bin Mohammed e-University", id: 17 },
  { code: "AE", name: "Higher Colleges of Technology", id: 18 },
  { code: "AE", name: "Ittihad University", id: 19 },
  { code: "AE", name: "Jumeira University", id: 20 },
  { code: "AE", name: "Khalifa University", id: 21 },
  { code: "AE", name: "Khalifa University of Science", id: 22 },
  { code: "AE", name: "Masdar University Of Science And Technology", id: 23 },
  { code: "AE", name: "New York University", id: 24 },
  { code: "AE", name: "Paris-Sorbonne University Abu Dhabi", id: 25 },
  { code: "AE", name: "Rak Medical & Health Sciences University", id: 26 },
  { code: "AE", name: "Rochester Institute of Technology", id: 27 },
  { code: "AE", name: "Skyline University College", id: 28 },
  { code: "AE", name: "Synergy University", id: 29 },
  { code: "AE", name: "The Emirates Academy of Hotel Managment", id: 30 },
  { code: "AE", name: "The Petroleum Institute", id: 31 },
  { code: "AE", name: "United Arab Emirates University", id: 32 },
  { code: "AE", name: "University Of Dubai", id: 33 },
  { code: "AE", name: "University of Jazeera", id: 34 },
  { code: "AE", name: "University of Sharjah", id: 35 },
  { code: "AE", name: "University of Wollongong - Dubai Campus", id: 36 },
  { code: "AE", name: "Zayed University", id: 37 },
  { code: "AF", name: "Afghan University", id: 38 },
  { code: "AF", name: "Al-Birony University", id: 39 },
  { code: "AF", name: "American University of Afghanistan", id: 40 },
  { code: "AF", name: "Aria Institute of Higher Education", id: 41 },
  { code: "AF", name: "Badakhshan University", id: 42 },
  { code: "AF", name: "Baghlan University", id: 43 },
  { code: "AF", name: "Bakhtar University", id: 44 },
  { code: "AF", name: "Balkh University", id: 45 },
  { code: "AF", name: "Bamiyan University", id: 46 },
  { code: "AF", name: "Bost University", id: 47 },
  { code: "AF", name: "Dawat University", id: 48 },
  { code: "AF", name: "Dunya Institute of Higher Education", id: 49 },
  { code: "AF", name: "Faryab Higher Education Institute", id: 50 },
  { code: "AF", name: "Ghazni University", id: 51 },
  { code: "AF", name: "Herat University", id: 52 },
  { code: "AF", name: "Ibn Sina University", id: 53 },
  { code: "AF", name: "Jawzjan University", id: 54 },
  { code: "AF", name: "Kaboora Institute of Higher Education", id: 55 },
  { code: "AF", name: "Kabul Education University", id: 56 },
  { code: "AF", name: "Kabul Health Sciences Institute", id: 57 },
  { code: "AF", name: "Kabul Medical University", id: 58 },
  { code: "AF", name: "Kabul University", id: 59 },
  { code: "AF", name: "Kandahar University", id: 60 },
  { code: "AF", name: "Kardan University", id: 61 },
  { code: "AF", name: "Karwan Institute of Higher Education", id: 62 },
  { code: "AF", name: "Kateb Institute of Higher Education", id: 63 },
  { code: "AF", name: "Khana-e-Noor Institute of Higher Education", id: 64 },
  { code: "AF", name: "Khurasan University", id: 65 },
  { code: "AF", name: "Maryam Institute of Higher Education", id: 66 },
  { code: "AF", name: "Nangarhar University", id: 67 },
  { code: "AF", name: "National Military Academy of Afghanistan", id: 68 },
  { code: "AF", name: "Paktia University", id: 69 },
  { code: "AF", name: "Pamir University", id: 70 },
  { code: "AF", name: "Parwan University", id: 71 },
  { code: "AF", name: "Polytechnical University of Kabul", id: 72 },
  { code: "AF", name: "Rana Institute of Higher Education", id: 73 },
  { code: "AF", name: "Sadat Institute of Higher Education", id: 74 },
  { code: "AF", name: "Salam University", id: 75 },
  { code: "AF", name: "Shaikh Zayed University", id: 76 },
  { code: "AF", name: "Taj Institute of Higher Education", id: 77 },
  { code: "AF", name: "Takhar University", id: 78 },
  { code: "AG", name: "American University of Antigua", id: 79 },
  { code: "AG", name: "University of Health Sciences Antigua", id: 80 },
  { code: "AL", name: "Academy of Arts", id: 81 },
  { code: "AL", name: "Academy of Sports and Physical Training", id: 82 },
  { code: "AL", name: "Agricultural University of Tirane", id: 83 },
  { code: "AL", name: "American University of Tirana", id: 84 },
  { code: "AL", name: "Beder University", id: 85 },
  { code: "AL", name: "Epoka University", id: 86 },
  { code: "AL", name: "Polytechnic University of Tirane", id: 87 },
  { code: "AL", name: "University of Elbasan Aleksander Xhuvani", id: 88 },
  { code: "AL", name: "University of Gjirokstra Eqerem Cabej", id: 89 },
  { code: "AL", name: "University of Korca Fan Noli", id: 90 },
  { code: "AL", name: "University of New York Tirana", id: 91 },
  { code: "AL", name: "University of Shkodra Luigj Gurakuqi", id: 92 },
  { code: "AL", name: "University of Tirana", id: 93 },
  { code: "AL", name: "University of Vlora Ismail Qemali", id: 94 },
  { code: "AL", name: "University “Pavaresia” Vlore", id: 95 },
  { code: "AM", name: "American University of Armenia", id: 96 },
  { code: "AM", name: "Armenian State Agrarian University", id: 97 },
  { code: "AM", name: "Armenian State University of Economics", id: 98 },
  { code: "AM", name: "Eurasia International University", id: 99 },
  { code: "AM", name: "European Regional Educational Academy of Armenia", id: 100 },
  { code: "AM", name: "French University in Armenia (UFAR)", id: 101 },
  { code: "AM", name: "Mehrabyan Medical Institute and Medical College", id: 102 },
  { code: "AM", name: "Russian-Armenian (Slavonic) State University", id: 103 },
  { code: "AM", name: "State Engineering University of Armenia", id: 104 },
  { code: "AM", name: "Yerevan Haibusak University", id: 105 },
  { code: "AM", name: "Yerevan State Medical University", id: 106 },
  { code: "AM", name: "Yerevan State University", id: 107 },
  { code: "AN", name: "American University of the Caribbean", id: 108 },
  { code: "AN", name: "International University School of Medicine (IUSOM)", id: 109 },
  { code: "AN", name: "St.James's School of Medicine", id: 110 },
  { code: "AN", name: "University of Sint Eustatius School of Medicine", id: 111 },
  { code: "AN", name: "University of the Netherlands Antilles", id: 112 },
  { code: "AO", name: "Universidade Católica de Angola", id: 113 },
  { code: "AO", name: "Universidade Gregorio Semedo", id: 114 },
  { code: "AO", name: "Universidade Independente de Angola", id: 115 },
  { code: "AO", name: "Universidade Lusíada de Angola", id: 116 },
  { code: "AO", name: "Universidade Metodista de Angola", id: 117 },
  { code: "AO", name: "Universidade Metropolitana de Angola", id: 118 },
  { code: "AO", name: "Universidade Tecnica de Angola", id: 119 },
  { code: "AO", name: "Univesidade Agostinho Neto", id: 120 },
  { code: "AR", name: "Instituto de Enseñanza Superior del Ejército", id: 121 },
  { code: "AR", name: "Instituto Nacional de Educación Física General Manuel Belgrano", id: 122 },
  { code: "AR", name: "Instituto Tecnológico de Buenos Aires", id: 123 },
  { code: "AR", name: "Instituto Universitario Aeronáutico", id: 124 },
  { code: "AR", name: "Instituto Universitario CEMA", id: 125 },
  { code: "AR", name: "Instituto Universitario de Ciencias de la Salud Fundación H.A. Barceló", id: 126 },
  { code: "AR", name: "Instituto Universitario de Estudios Navales y Marítimos", id: 127 },
  { code: "AR", name: "Instituto Universitario de la Policia Federal", id: 128 },
  { code: "AR", name: "Instituto Universitario Nacional del Arte", id: 129 },
  { code: "AR", name: "Universidad Abierta Interamericana", id: 130 },
  { code: "AR", name: "Universidad Adventista del Plata", id: 131 },
  { code: "AR", name: "Universidad Argentina de la Empresa", id: 132 },
  { code: "AR", name: "Universidad Argentina John F. Kennedy", id: 133 },
  { code: "AR", name: "Universidad Atlantida Argentina", id: 134 },
  { code: "AR", name: "Universidad Austral Buenos Aires", id: 135 },
  { code: "AR", name: "Universidad Autónoma de Entre Ríos", id: 136 },
  { code: "AR", name: "Universidad Blas Pascal", id: 137 },
  { code: "AR", name: "Universidad CAECE", id: 138 },
  { code: "AR", name: "Universidad Católica Argentina Santa Maria de los Buenos Aires", id: 139 },
  { code: "AR", name: "Universidad Católica de Córdoba", id: 140 },
  { code: "AR", name: "Universidad Católica de Cuyo", id: 141 },
  { code: "AR", name: "Universidad Católica de La Plata", id: 142 },
  { code: "AR", name: "Universidad Católica de Salta", id: 143 },
  { code: "AR", name: "Universidad Católica de Santa Fé", id: 144 },
  { code: "AR", name: "Universidad Católica de Santiago del Estero", id: 145 },
  { code: "AR", name: "Universidad Champagnat", id: 146 },
  { code: "AR", name: "Universidad de Belgrano", id: 147 },
  { code: "AR", name: "Universidad de Bologna - Representación en Buenos Aires", id: 148 },
  { code: "AR", name: "Universidad de Buenos Aires", id: 149 },
  { code: "AR", name: "Universidad de Ciencias Empresariales y Sociales", id: 150 },
  { code: "AR", name: "Universidad de Concepción del Uruguay", id: 151 },
  { code: "AR", name: "Universidad de Congreso", id: 152 },
  { code: "AR", name: "Universidad de Flores - Buenos Aires", id: 153 },
  { code: "AR", name: "Universidad del Aconcagua", id: 154 },
  { code: "AR", name: "Universidad de la Cuenca del Plata", id: 155 },
  { code: "AR", name: "Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)", id: 156 },
  { code: "AR", name: "Universidad de la Marina Mercante", id: 157 },
  { code: "AR", name: "Universidad del Centro Educativo Latinoamericano Rosario", id: 158 },
  { code: "AR", name: "Universidad del Cine - Buenos Aires", id: 159 },
  { code: "AR", name: "Universidad del Museo Social Argentino", id: 160 },
  { code: "AR", name: "Universidad del Norte Santo Tomás de Aquino", id: 161 },
  { code: "AR", name: "Universidad del Salvador", id: 162 },
  { code: "AR", name: "Universidad de Mendoza", id: 163 },
  { code: "AR", name: "Universidad de Morón", id: 164 },
  { code: "AR", name: "Universidad de Palermo", id: 165 },
  { code: "AR", name: "Universidad de San Andres", id: 166 },
  { code: "AR", name: "Universidad Empresarial Siglio 21", id: 167 },
  { code: "AR", name: "Universidad ISALUD", id: 168 },
  { code: "AR", name: "Universidad Juan Agustín Maza", id: 169 },
  { code: "AR", name: "Universidad Maimónides", id: 170 },
  { code: "AR", name: "Universidad Nacional de Catamarca", id: 171 },
  { code: "AR", name: "Universidad Nacional de Córdoba", id: 172 },
  { code: "AR", name: "Universidad Nacional de Cuyo", id: 173 },
  { code: "AR", name: "Universidad Nacional de Entre Ríos", id: 174 },
  { code: "AR", name: "Universidad Nacional de Formosa", id: 175 },
  { code: "AR", name: "Universidad Nacional de General San Martín", id: 176 },
  { code: "AR", name: "Universidad Nacional de General Sarmiento", id: 177 },
  { code: "AR", name: "Universidad Nacional de Jujuy", id: 178 },
  { code: "AR", name: "Universidad Nacional de La Matanza", id: 179 },
  { code: "AR", name: "Universidad Nacional de Lanus", id: 180 },
  { code: "AR", name: "Universidad Nacional de La Pampa", id: 181 },
  { code: "AR", name: "Universidad Nacional de la Patagonia Austral", id: 182 },
  { code: "AR", name: "Universidad Nacional de la Patagonia San Juan Bosco", id: 183 },
  { code: "AR", name: "Universidad Nacional de La Plata", id: 184 },
  { code: "AR", name: "Universidad Nacional de La Rioja", id: 185 },
  { code: "AR", name: "Universidad Nacional del Centro de la Provincia de Buenos Aires", id: 186 },
  { code: "AR", name: "Universidad Nacional del Comahue", id: 187 },
  { code: "AR", name: "Universidad Nacional del Littoral", id: 188 },
  { code: "AR", name: "Universidad Nacional del Nordeste", id: 189 },
  { code: "AR", name: "Universidad Nacional del Noroeste de la Provincia de Buenos Aires", id: 190 },
  { code: "AR", name: "Universidad Nacional de Lomas de Zamora", id: 191 },
  { code: "AR", name: "Universidad Nacional del Sur", id: 192 },
  { code: "AR", name: "Universidad Nacional de Luján", id: 193 },
  { code: "AR", name: "Universidad Nacional de Mar del Plata", id: 194 },
  { code: "AR", name: "Universidad Nacional de Misiones", id: 195 },
  { code: "AR", name: "Universidad Nacional de Quilmes", id: 196 },
  { code: "AR", name: "Universidad Nacional de Río Cuarto", id: 197 },
  { code: "AR", name: "Universidad Nacional de Rosario", id: 198 },
  { code: "AR", name: "Universidad Nacional de Salta", id: 199 },
  { code: "AR", name: "Universidad Nacional de San Juan", id: 200 },
  { code: "AR", name: "Universidad Nacional de San Luis", id: 201 },
  { code: "AR", name: "Universidad Nacional de Santiago del Estero", id: 202 },
  { code: "AR", name: "Universidad Nacional de Tres de Febrero", id: 203 },
  { code: "AR", name: "Universidad Nacional de Tucumán", id: 204 },
  { code: "AR", name: "Universidad Nacional de Villa María", id: 205 },
  { code: "AR", name: "Universidad Notarial Argentina", id: 206 },
  { code: "AR", name: "Universidad Tecnológica Nacional", id: 207 },
  { code: "AR", name: "Universidad Torcuato di Tella", id: 208 },
  { code: "AT", name: "Akademie der bildenden Künste Wien", id: 209 },
  { code: "AT", name: "Donau-Universität Krems", id: 210 },
  { code: "AT", name: "Fachhochschule Burgenland", id: 211 },
  { code: "AT", name: "Fachhochschule für Bank- und Finanzwirtschaft", id: 212 },
  { code: "AT", name: "Fachhochschule JOANNEUM", id: 213 },
  { code: "AT", name: "Fachhochschule Kärnten", id: 214 },
  { code: "AT", name: "Fachhochschule Krems", id: 215 },
  { code: "AT", name: "Fachhochschule Kufstein (Tirol)", id: 216 },
  { code: "AT", name: "Fachhochschule Salzburg", id: 217 },
  { code: "AT", name: "Fachhochschule St. Pölten", id: 218 },
  { code: "AT", name: "Fachhochschule Vorarlberg", id: 219 },
  { code: "AT", name: "Fachhochschule Wiener Neustadt", id: 220 },
  { code: "AT", name: "Fachhochschulstudiengänge der Wiener Wirtschaft", id: 221 },
  { code: "AT", name: "Fachhochschulstudiengänge Hagenberg", id: 222 },
  { code: "AT", name: "Fachhochschulstudiengänge Krems IMC", id: 223 },
  { code: "AT", name: "Fachhochschulstudiengänge Steyr", id: 224 },
  { code: "AT", name: "Fachhochschulstudiengänge Wels", id: 225 },
  { code: "AT", name: "Institute of Science and Technology", id: 226 },
  { code: "AT", name: "Katholisch-Theologische Privatuniversität Linz", id: 227 },
  { code: "AT", name: "MCI-Management Center Innsbruck", id: 228 },
  { code: "AT", name: "Medizinische Universität Graz", id: 229 },
  { code: "AT", name: "Medizinische Universität Innsbruck", id: 230 },
  { code: "AT", name: "Medizinische Universität Wien", id: 231 },
  { code: "AT", name: "Montanuniversität Leoben", id: 232 },
  { code: "AT", name: "Private Universität für Medizinische Informatik und Technik Tirol", id: 233 },
  { code: "AT", name: "Technikum Wien", id: 234 },
  { code: "AT", name: "Technische Universität Graz", id: 235 },
  { code: "AT", name: "Technische Universität Wien", id: 236 },
  { code: "AT", name: "Universität für angewandte Kunst Wien", id: 237 },
  { code: "AT", name: "Universität für Bodenkultur Wien", id: 238 },
  { code: "AT", name: "Universität für künstlerische und industrielle Gestaltung Linz", id: 239 },
  { code: "AT", name: "Universität für Musik und darstellende Kunst Graz", id: 240 },
  { code: "AT", name: "Universität für Musik und darstellende Kunst  Wien", id: 241 },
  { code: "AT", name: "Universität Graz", id: 242 },
  { code: "AT", name: "Universität Innsbruck", id: 243 },
  { code: "AT", name: "Universität Klagenfurt", id: 244 },
  { code: "AT", name: "Universität Linz", id: 245 },
  { code: "AT", name: "Universität Mozarteum Salzburg", id: 246 },
  { code: "AT", name: "Universität Salzburg", id: 247 },
  { code: "AT", name: "Universität Vienna", id: 248 },
  { code: "AT", name: "Veterinärmedizinische Universität Wien", id: 249 },
  { code: "AT", name: "Webster University", id: 250 },
  { code: "AT", name: "Wirtschaftsuniversität Wien", id: 251 },
  { code: "AU", name: "Australian Catholic University", id: 252 },
  { code: "AU", name: "Australian Correspondence Schools", id: 253 },
  { code: "AU", name: "Australian Defence Force Academy", id: 254 },
  { code: "AU", name: "Australian Lutheran College", id: 255 },
  { code: "AU", name: "Australian Maritime College", id: 256 },
  { code: "AU", name: "Australian National University", id: 257 },
  { code: "AU", name: "Avondale College", id: 258 },
  { code: "AU", name: "Batchelor Institute of Indigenous Tertiary Education", id: 259 },
  { code: "AU", name: "Blue Mountains Hotel School", id: 260 },
  { code: "AU", name: "Bond University", id: 261 },
  { code: "AU", name: "Central Queensland University", id: 262 },
  { code: "AU", name: "Charles Darwin University", id: 263 },
  { code: "AU", name: "Charles Sturt University", id: 264 },
  { code: "AU", name: "Curtin University of Technology", id: 265 },
  { code: "AU", name: "Deakin University", id: 266 },
  { code: "AU", name: "Edith Cowan University", id: 267 },
  { code: "AU", name: "Flinders University of South Australia", id: 268 },
  { code: "AU", name: "Griffith University", id: 269 },
  { code: "AU", name: "Holmes Institute", id: 270 },
  { code: "AU", name: "Institute Of Technology", id: 271 },
  { code: "AU", name: "International College of Tourism and Hotel Management", id: 272 },
  { code: "AU", name: "James Cook University of North Queensland", id: 273 },
  { code: "AU", name: "La Trobe University", id: 274 },
  { code: "AU", name: "Macquarie University", id: 275 },
  { code: "AU", name: "Marcus Oldham College", id: 276 },
  { code: "AU", name: "Monash University", id: 277 },
  { code: "AU", name: "Murdoch University", id: 278 },
  { code: "AU", name: "Queensland University of Technology", id: 279 },
  { code: "AU", name: "Royal Melbourne Institute of Technology", id: 280 },
  { code: "AU", name: "Southern Cross University", id: 281 },
  { code: "AU", name: "Swinburne University of Technology", id: 282 },
  { code: "AU", name: "University of Adelaide", id: 283 },
  { code: "AU", name: "University of Ballarat", id: 284 },
  { code: "AU", name: "University of Canberra", id: 285 },
  { code: "AU", name: "University of Divinity", id: 286 },
  { code: "AU", name: "University of Melbourne", id: 287 },
  { code: "AU", name: "University of Newcastle", id: 288 },
  { code: "AU", name: "University of New England", id: 289 },
  { code: "AU", name: "University of New South Wales", id: 290 },
  { code: "AU", name: "University of Notre Dame Australia", id: 291 },
  { code: "AU", name: "University of Queensland", id: 292 },
  { code: "AU", name: "University of South Australia", id: 293 },
  { code: "AU", name: "University of Southern Queensland", id: 294 },
  { code: "AU", name: "University of Sydney", id: 295 },
  { code: "AU", name: "University of Tasmania", id: 296 },
  { code: "AU", name: "University of Technology Sydney", id: 297 },
  { code: "AU", name: "University of the Sunshine Coast", id: 298 },
  { code: "AU", name: "University of Western Australia", id: 299 },
  { code: "AU", name: "University of Western Sydney", id: 300 },
  { code: "AU", name: "University of Wollongong", id: 301 },
  { code: "AZ", name: "Academy of Public Administration", id: 302 },
  { code: "AZ", name: "Azerbaijan Diplomatic Academy", id: 303 },
  { code: "AZ", name: "Azerbaijan International University", id: 304 },
  { code: "AZ", name: "Azerbaijan Medical University", id: 305 },
  { code: "AZ", name: "Azerbaijan National Conservatorie", id: 306 },
  { code: "AZ", name: "Azerbaijan State Economic University", id: 307 },
  { code: "AZ", name: "Azerbaijan State Oil Academy", id: 308 },
  { code: "AZ", name: "Azerbaijan Technical University", id: 309 },
  { code: "AZ", name: "Azerbaijan Technology University", id: 310 },
  { code: "AZ", name: "Azerbaijan Toursim Institute", id: 311 },
  { code: "AZ", name: "Azerbaijan University", id: 312 },
  { code: "AZ", name: "Azerbaijan University ol Languages", id: 313 },
  { code: "AZ", name: "Baki Business University", id: 314 },
  { code: "AZ", name: "Baku Slavic University", id: 315 },
  { code: "AZ", name: "Baku State University", id: 316 },
  { code: "AZ", name: "Ganja State University ", id: 317 },
  { code: "AZ", name: "Khazar University", id: 318 },
  { code: "AZ", name: "Lankaran State University", id: 319 },
  { code: "AZ", name: "Nakhchivan Private University", id: 320 },
  { code: "AZ", name: "Nakhchivan State University", id: 321 },
  { code: "AZ", name: "National Aviation Academy", id: 322 },
  { code: "AZ", name: "Odlar Yurdu University", id: 323 },
  { code: "AZ", name: "Police Academy", id: 324 },
  { code: "AZ", name: "Qafqaz University", id: 325 },
  { code: "AZ", name: "Sumgait State University", id: 326 },
  { code: "AZ", name: "Western University", id: 327 },
  { code: "BA", name: "American University", id: 328 },
  { code: "BA", name: "International Burch University", id: 329 },
  { code: "BA", name: "International University of Sarajevo", id: 330 },
  { code: "BA", name: "International University of Travnik", id: 331 },
  { code: "BA", name: "Sarajevo Film Academy", id: 332 },
  { code: "BA", name: "Slobomir P Univerzitet", id: 333 },
  { code: "BA", name: "University of Banja Luka", id: 334 },
  { code: "BA", name: "University of Bihac", id: 335 },
  { code: "BA", name: "University of East Srarajevo", id: 336 },
  { code: "BA", name: "University of Mostar", id: 337 },
  { code: "BA", name: "University of Sarajevo", id: 338 },
  { code: "BA", name: "University of Tuzla", id: 339 },
  { code: "BA", name: "University of Zenica", id: 340 },
  { code: "BA", name: "University Vitez In Travnik", id: 341 },
  { code: "BA", name: "Univerzitet u Mostaru Dzemal Bijedic", id: 342 },
  { code: "BB", name: "University of the West Indies", id: 343 },
  { code: "BD", name: "Ahsanullah University of Science & Technology", id: 344 },
  { code: "BD", name: "American International University - Bangladesh", id: 345 },
  { code: "BD", name: "Asa University Bangladesh", id: 346 },
  { code: "BD", name: "Asian University of Bangladesh", id: 347 },
  { code: "BD", name: "Atish Dipankar University", id: 348 },
  { code: "BD", name: "Bangabandhu Sheikh Mujibur Rahman Agricultural University", id: 349 },
  { code: "BD", name: "Bangabandhu Sheikh Mujibur Rahman Medical University", id: 350 },
  { code: "BD", name: "Bangladesh Agricultural University", id: 351 },
  { code: "BD", name: "Bangladesh Open University", id: 352 },
  { code: "BD", name: "Bangladesh University", id: 353 },
  { code: "BD", name: "Bangladesh University of Business & Technology", id: 354 },
  { code: "BD", name: "Bangladesh University of Engineering and Technology", id: 355 },
  { code: "BD", name: "Bangladesh University of Professionals", id: 356 },
  { code: "BD", name: "Bangladesh University of Textiles", id: 357 },
  { code: "BD", name: "Begum Rokeya University", id: 358 },
  { code: "BD", name: "BGC Trust University", id: 359 },
  { code: "BD", name: "Brac University", id: 360 },
  { code: "BD", name: "Chittagong Independent University", id: 361 },
  { code: "BD", name: "Chittagong University of Engineering and Technology", id: 362 },
  { code: "BD", name: "City University", id: 363 },
  { code: "BD", name: "Comilla University", id: 364 },
  { code: "BD", name: "Daffodil International University", id: 365 },
  { code: "BD", name: "Darul Ihsan University", id: 366 },
  { code: "BD", name: "Dhaka International University", id: 367 },
  { code: "BD", name: "Dhaka University of Engineering and Technology", id: 368 },
  { code: "BD", name: "East Delta University", id: 369 },
  { code: "BD", name: "Eastern University", id: 370 },
  { code: "BD", name: "East West University", id: 371 },
  { code: "BD", name: "East-West University", id: 372 },
  { code: "BD", name: "Green University of Bangladesh", id: 373 },
  { code: "BD", name: "Hajee Mohammad Danesh Science and Technology University", id: 374 },
  { code: "BD", name: "IBAIS University", id: 375 },
  { code: "BD", name: "Independent University", id: 376 },
  { code: "BD", name: "International Culture University ", id: 377 },
  { code: "BD", name: "International Islamic University Chittagong", id: 378 },
  { code: "BD", name: "International University of Business Agriculture and Technology", id: 379 },
  { code: "BD", name: "Islamic University Kushtia", id: 380 },
  { code: "BD", name: "Islamic University of Technology", id: 381 },
  { code: "BD", name: "Jagannath University", id: 382 },
  { code: "BD", name: "Jahangirnagar University", id: 383 },
  { code: "BD", name: "Jatiya Kabi Kazi Nazrul Islam University", id: 384 },
  { code: "BD", name: "Khulna University", id: 385 },
  { code: "BD", name: "Khulna University of Engineering And Technology", id: 386 },
  { code: "BD", name: "Leading University", id: 387 },
  { code: "BD", name: "Manarat International University", id: 388 },
  { code: "BD", name: "Mawlana Bhashani Science And Technology University", id: 389 },
  { code: "BD", name: "Metropolitan University", id: 390 },
  { code: "BD", name: "Military Institute of Science and Technology", id: 391 },
  { code: "BD", name: "National University", id: 392 },
  { code: "BD", name: "Noakhali University of Science and Technology", id: 393 },
  { code: "BD", name: "North East University Bangladesh", id: 394 },
  { code: "BD", name: "Northern University Bangladesh", id: 395 },
  { code: "BD", name: "North South University", id: 396 },
  { code: "BD", name: "Pabna University of Science and Technology", id: 397 },
  { code: "BD", name: "Patuakhali Science and Technology University", id: 398 },
  { code: "BD", name: "People's University of Bangladesh", id: 399 },
  { code: "BD", name: "Premier University", id: 400 },
  { code: "BD", name: "Presidency University", id: 401 },
  { code: "BD", name: "Queens University", id: 402 },
  { code: "BD", name: "Rajshahi University of Engineering and Technology", id: 403 },
  { code: "BD", name: "Shahjalal University of Science and Technology", id: 404 },
  { code: "BD", name: "Southeast University", id: 405 },
  { code: "BD", name: "Southern University Bangladesh", id: 406 },
  { code: "BD", name: "Stamford University", id: 407 },
  { code: "BD", name: "Sylhet Agricultural University", id: 408 },
  { code: "BD", name: "Sylhet Engineering College", id: 409 },
  { code: "BD", name: "Sylhet International University", id: 410 },
  { code: "BD", name: "United International University", id: 411 },
  { code: "BD", name: "University of Asia Pacific", id: 412 },
  { code: "BD", name: "University of Chittagong", id: 413 },
  { code: "BD", name: "University of Development Alternative", id: 414 },
  { code: "BD", name: "University of Dhaka", id: 415 },
  { code: "BD", name: "University of Information Technology & Sciences", id: 416 },
  { code: "BD", name: "University of Liberal Arts", id: 417 },
  { code: "BD", name: "University of Rajshahi", id: 418 },
  { code: "BD", name: "University of Science & Technology Chittagong", id: 419 },
  { code: "BD", name: "World University of Bangladesh", id: 420 },
  { code: "BE", name: "Brexgata University Academy", id: 421 },
  { code: "BE", name: "Brussels Management School (ICHEC)", id: 422 },
  { code: "BE", name: "Brussels School of International Studies", id: 423 },
  { code: "BE", name: "College of Europe", id: 424 },
  { code: "BE", name: "Continental Theological Seminary", id: 425 },
  { code: "BE", name: "ECAM - Institut Supérieur Industriel", id: 426 },
  { code: "BE", name: "EHSAL - Europese Hogeschool Brussel", id: 427 },
  { code: "BE", name: "Erasmushogeschool Brussel", id: 428 },
  { code: "BE", name: "European Carolus Magnus University", id: 429 },
  { code: "BE", name: "European International University", id: 430 },
  { code: "BE", name: "Evangelische Theologische Faculteit", id: 431 },
  { code: "BE", name: "Faculté Polytechnique de Mons", id: 432 },
  { code: "BE", name: "Facultés Universitaires Catholiques de Mons", id: 433 },
  { code: "BE", name: "Facultés Universitaires Notre-Dame de la Paix", id: 434 },
  { code: "BE", name: "Facultés Universitaires Saint-Louis", id: 435 },
  { code: "BE", name: "Faculté Universitaire des Sciences Agronomiques de Gembloux", id: 436 },
  { code: "BE", name: "Fondation Universitaire Luxembourgeoise", id: 437 },
  { code: "BE", name: "Hasselt University", id: 438 },
  { code: "BE", name: "Hogere Zeevaartschool - Maritime Academy", id: 439 },
  { code: "BE", name: "Hogeschool Antwerpen", id: 440 },
  { code: "BE", name: "Hogeschool voor Wetenschap en Kunst (VLEKHO)", id: 441 },
  { code: "BE", name: "Hogeschool voor Wetenschap & Kunst", id: 442 },
  { code: "BE", name: "Hogeschool West-Vlaanderen (TU)", id: 443 },
  { code: "BE", name: "Karel De Grote Hogeschool", id: 444 },
  { code: "BE", name: "Katholieke Hogeschool Kempen", id: 445 },
  { code: "BE", name: "Katholieke Hogeschool Leuven", id: 446 },
  { code: "BE", name: "Katholieke Hogeschool Limburg", id: 447 },
  { code: "BE", name: "Katholieke Universiteit Brussel", id: 448 },
  { code: "BE", name: "Katholieke Universiteit Leuven", id: 449 },
  { code: "BE", name: "Royal Military Academy", id: 450 },
  { code: "BE", name: "Thierry Graduate School of Leadership", id: 451 },
  { code: "BE", name: "United Business Institute", id: 452 },
  { code: "BE", name: "Université Catholique de Louvain", id: 453 },
  { code: "BE", name: "Université de Liège", id: 454 },
  { code: "BE", name: "Université de Mons-Hainaut", id: 455 },
  { code: "BE", name: "Universiteit Antwerpen", id: 456 },
  { code: "BE", name: "Universiteit Antwerpen Management School", id: 457 },
  { code: "BE", name: "Universiteit Gent", id: 458 },
  { code: "BE", name: "Université Libre de Bruxelles", id: 459 },
  { code: "BE", name: "Vesalius College", id: 460 },
  { code: "BE", name: "Vlerick Leuven Gent Management School", id: 461 },
  { code: "BE", name: "Vrije Universiteit Brussel", id: 462 },
  { code: "BF", name: "Université de Ouagadougou", id: 463 },
  { code: "BG", name: "Academy of Economics Dimitur A. Tscenov", id: 464 },
  { code: "BG", name: "Academy of Music", id: 465 },
  { code: "BG", name: "Agricultural University of Plovdiv", id: 466 },
  { code: "BG", name: "American University in Bulgaria", id: 467 },
  { code: "BG", name: "Bourgas Free University", id: 468 },
  { code: "BG", name: "Bourgas University Prof. Assen Zlatarov", id: 469 },
  { code: "BG", name: "City University Programs in Bulgaria ", id: 470 },
  { code: "BG", name: "International University College", id: 471 },
  { code: "BG", name: "Medical University of Sofia", id: 472 },
  { code: "BG", name: "Medical University Pleven", id: 473 },
  { code: "BG", name: "Medical University Plovdiv", id: 474 },
  { code: "BG", name: "Medical University Varna", id: 475 },
  { code: "BG", name: "Military University Shoumen", id: 476 },
  { code: "BG", name: "National Academy for Theatre and Film Arts Krustju Sarafov", id: 477 },
  { code: "BG", name: "National Academy of Arts", id: 478 },
  { code: "BG", name: "National Academy of Music Pantcho Vladigerov", id: 479 },
  { code: "BG", name: "National Sports Academy Sofia", id: 480 },
  { code: "BG", name: "New Bulgarian University", id: 481 },
  { code: "BG", name: "Shoumen University Konstantin Preslavski", id: 482 },
  { code: "BG", name: "Sofia University St. Kliment Ohridski", id: 483 },
  { code: "BG", name: "South-West University Neofit Rilski", id: 484 },
  { code: "BG", name: "Technical University of Gabrovo", id: 485 },
  { code: "BG", name: "Technical University of Sofia", id: 486 },
  { code: "BG", name: "Technical University of Varna", id: 487 },
  { code: "BG", name: "Trakia University Stara Zagora", id: 488 },
  { code: "BG", name: "University of Architecture", id: 489 },
  { code: "BG", name: "University of Chemical Technology and Metallurgy", id: 490 },
  { code: "BG", name: "University of Economics Varna", id: 491 },
  { code: "BG", name: "University of Food Technology", id: 492 },
  { code: "BG", name: "University of Forestry Sofia", id: 493 },
  { code: "BG", name: "University of Mining and Geology St. Ivan Rils", id: 494 },
  { code: "BG", name: "University of National and World Economy", id: 495 },
  { code: "BG", name: "University of Plovdiv", id: 496 },
  { code: "BG", name: "University of Rousse", id: 497 },
  { code: "BG", name: "Varna Free University", id: 498 },
  { code: "BG", name: "Veliko Turnovo University Cyril and Methodius", id: 499 },
  { code: "BH", name: "Al Ahlia University", id: 500 },
  { code: "BH", name: "Ama International University", id: 501 },
  { code: "BH", name: "Applied Science University", id: 502 },
  { code: "BH", name: "Arabian Gulf University", id: 503 },
  { code: "BH", name: "Arab Open University", id: 504 },
  { code: "BH", name: "Bahrain Polytechnic", id: 505 },
  { code: "BH", name: "Gulf University College", id: 506 },
  { code: "BH", name: "New York Instiute of Technology", id: 507 },
  { code: "BH", name: "RCSI-Medical University of Bahrain", id: 508 },
  { code: "BH", name: "The Kingdom University", id: 509 },
  { code: "BH", name: "University College Bahrain", id: 510 },
  { code: "BH", name: "University of Bahrain", id: 511 },
  { code: "BI", name: "Hope Africa University", id: 512 },
  { code: "BI", name: "Université du Burundi", id: 513 },
  { code: "BI", name: "Université Lumière de Bujumbura", id: 514 },
  { code: "BJ", name: "Espam Formation University", id: 515 },
  { code: "BJ", name: "Houdegbe North American University Benin", id: 516 },
  { code: "BJ", name: "Université d'Abomey-Calavi (UAC)", id: 517 },
  { code: "BM", name: "Bermuda College", id: 518 },
  { code: "BN", name: "Institut Teknologi Brunei", id: 519 },
  { code: "BN", name: "Universiti Islam Sultan Sharif Ali", id: 520 },
  { code: "BN", name: "University of Brunei Darussalam", id: 521 },
  { code: "BO", name: "Escuela Militar de Ingeniería", id: 522 },
  { code: "BO", name: "Universidad Adventista de Bolivia", id: 523 },
  { code: "BO", name: "Universidad Amazonica de Pando", id: 524 },
  { code: "BO", name: "Universidad Andina Simón Bolivar", id: 525 },
  { code: "BO", name: "Universidad Autónoma del Beni José Ballivián", id: 526 },
  { code: "BO", name: "Universidad Autónoma Gabriel René Moreno", id: 527 },
  { code: "BO", name: "Universidad Autónoma Juan Misael Saracho", id: 528 },
  { code: "BO", name: "Universidad Autónoma Tomás Frías", id: 529 },
  { code: "BO", name: "Universidad Católica Boliviana", id: 530 },
  { code: "BO", name: "Universidad Central", id: 531 },
  { code: "BO", name: "Universidad de Aquino Bolivia", id: 532 },
  { code: "BO", name: "Universidad Domingo Savio", id: 533 },
  { code: "BO", name: "Universidad Empresarial Mateo Kuljis", id: 534 },
  { code: "BO", name: "Universidad Juan Misael Saracho", id: 535 },
  { code: "BO", name: "Universidad Los Andes", id: 536 },
  { code: "BO", name: "Universidad Mayor de San Andrés", id: 537 },
  { code: "BO", name: "Universidad Mayor de San Simón", id: 538 },
  { code: "BO", name: "Universidad Nacional Siglo XX Llallagua", id: 539 },
  { code: "BO", name: "Universidad Nuestra Senora de La Paz", id: 540 },
  { code: "BO", name: "Universidad NUR", id: 541 },
  { code: "BO", name: "Universidad Privada Abierta Latinoamericana", id: 542 },
  { code: "BO", name: "Universidad Privada Boliviana", id: 543 },
  { code: "BO", name: "Universidad Privada del Valle", id: 544 },
  { code: "BO", name: "Universidad Privada de Santa Cruz de la Sierra", id: 545 },
  { code: "BO", name: "Universidad Privada Franz Tamayo (UNIFRANZ)", id: 546 },
  { code: "BO", name: "Universidad Salesiana de Bolivia", id: 547 },
  { code: "BO", name: "Universidad San Francisco Xavier", id: 548 },
  { code: "BO", name: "Universidad San Francisco Xavier de Chuquisaca", id: 549 },
  { code: "BO", name: "Universidad Técnica de Oruro", id: 550 },
  { code: "BR", name: "Centro Regional Universitário de Espiríto Santo do Pinhal", id: 551 },
  { code: "BR", name: "Centro Universitário Adventista de São Paulo", id: 552 },
  { code: "BR", name: "Centro Universitário Barao de Maua", id: 553 },
  { code: "BR", name: "Centro Universitário Claretiano", id: 554 },
  { code: "BR", name: "Centro Universitário de Araraquara", id: 555 },
  { code: "BR", name: "Centro Universitário de João Pessoa", id: 556 },
  { code: "BR", name: "Centro Universitário Monte Serrat", id: 557 },
  { code: "BR", name: "Centro Universitário Newton Paiva", id: 558 },
  { code: "BR", name: "Centro Universitário Plinio Leite", id: 559 },
  { code: "BR", name: "Centro Universitário Senac", id: 560 },
  { code: "BR", name: "Centro Universitário Serra dos Órgãos", id: 561 },
  { code: "BR", name: "EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória", id: 562 },
  { code: "BR", name: "FAAP - Fundação Armando Alvares Penteado", id: 563 },
  { code: "BR", name: "Faculdade Integradas do Ceará", id: 564 },
  { code: "BR", name: "Faculdade Italo Brasileira", id: 565 },
  { code: "BR", name: "Faculdade Jaguariúna", id: 566 },
  { code: "BR", name: "Faculdades Integradas Curitiba", id: 567 },
  { code: "BR", name: "Faculdades Integradas do Brasil - UNIBRASIL", id: 568 },
  { code: "BR", name: "Faculdades Integradas Toledo", id: 569 },
  { code: "BR", name: "Faculdades Integradas UPIS", id: 570 },
  { code: "BR", name: "FAE Business School - Faculdade de Administração e Economia", id: 571 },
  { code: "BR", name: "Fundação Educacional de Ituverava", id: 572 },
  { code: "BR", name: "Instituto Mauá de Tecnologia", id: 573 },
  { code: "BR", name: "Instituto Nacional de Matemática Pura e Aplicada - IMPA", id: 574 },
  { code: "BR", name: "Instituto Tecnológico de Aeronáutica", id: 575 },
  { code: "BR", name: "Pontifícia Universidade Católica de Campinas", id: 576 },
  { code: "BR", name: "Pontifícia Universidade Católica de Minas Gerais", id: 577 },
  { code: "BR", name: "Pontifícia Universidade Católica de São Paulo", id: 578 },
  { code: "BR", name: "Pontificia Universidade Católica do Paraná", id: 579 },
  { code: "BR", name: "Pontifícia Universidade Católica do Rio de Janeiro", id: 580 },
  { code: "BR", name: "Pontifícia Universidade Católica do Rio Grande do Sul", id: 581 },
  { code: "BR", name: "Universidade Bandeirante de São Paulo", id: 582 },
  { code: "BR", name: "Universidade Braz Cubas", id: 583 },
  { code: "BR", name: "Universidade Camilo Castelo Branco", id: 584 },
  { code: "BR", name: "Universidade Candido Mendes", id: 585 },
  { code: "BR", name: "Universidade Castelo Branco", id: 586 },
  { code: "BR", name: "Universidade Católica de Brasília", id: 587 },
  { code: "BR", name: "Universidade Católica de Goiás", id: 588 },
  { code: "BR", name: "Universidade Católica de Pelotas", id: 589 },
  { code: "BR", name: "Universidade Católica de Pernambuco", id: 590 },
  { code: "BR", name: "Universidade Católica de Petrópolis", id: 591 },
  { code: "BR", name: "Universidade Católica de Salvador", id: 592 },
  { code: "BR", name: "Universidade Católica de Santos", id: 593 },
  { code: "BR", name: "Universidade Católica Dom Bosco", id: 594 },
  { code: "BR", name: "Universidade Cidade de São Paulo", id: 595 },
  { code: "BR", name: "Universidade Cruzeiro do Sul", id: 596 },
  { code: "BR", name: "Universidade da Amazônia", id: 597 },
  { code: "BR", name: "Universidade da Região da Campanha", id: 598 },
  { code: "BR", name: "Universidade de Alfenas", id: 599 },
  { code: "BR", name: "Universidade de Brasília", id: 600 },
  { code: "BR", name: "Universidade de Caxias do Sul", id: 601 },
  { code: "BR", name: "Universidade de Cruz Alta", id: 602 },
  { code: "BR", name: "Universidade de Fortaleza", id: 603 },
  { code: "BR", name: "Universidade de Franca", id: 604 },
  { code: "BR", name: "Universidade de Guarulhos", id: 605 },
  { code: "BR", name: "Universidade de Itaúna", id: 606 },
  { code: "BR", name: "Universidade de Marília", id: 607 },
  { code: "BR", name: "Universidade de Mogi das Cruzes", id: 608 },
  { code: "BR", name: "Universidade de Nova Iguaçu", id: 609 },
  { code: "BR", name: "Universidade de Passo Fundo", id: 610 },
  { code: "BR", name: "Universidade de Pernambuco", id: 611 },
  { code: "BR", name: "Universidade de Ribeirão Preto", id: 612 },
  { code: "BR", name: "Universidade de Santa Cruz do Sul", id: 613 },
  { code: "BR", name: "Universidade de Santo Amaro", id: 614 },
  { code: "BR", name: "Universidade de São Paulo", id: 615 },
  { code: "BR", name: "Universidade de Taubaté", id: 616 },
  { code: "BR", name: "Universidade de Tocantins", id: 617 },
  { code: "BR", name: "Universidade de Uberaba", id: 618 },
  { code: "BR", name: "Universidade de Vila Velha", id: 619 },
  { code: "BR", name: "Universidade do Amazonas", id: 620 },
  { code: "BR", name: "Universidade do Estado da Bahia", id: 621 },
  { code: "BR", name: "Universidade do Estado de Santa Catarina", id: 622 },
  { code: "BR", name: "Universidade do Estado do Rio de Janeiro", id: 623 },
  { code: "BR", name: "Universidade do Estado do Rio Grande do Norte", id: 624 },
  { code: "BR", name: "Universidade do Extremo Sul Catarinense", id: 625 },
  { code: "BR", name: "Universidade do Grande Rio", id: 626 },
  { code: "BR", name: "Universidade do Oeste de Santa Catarina", id: 627 },
  { code: "BR", name: "Universidade do Oeste Paulista", id: 628 },
  { code: "BR", name: "Universidade do Rio de Janeiro", id: 629 },
  { code: "BR", name: "Universidade do Rio Grande", id: 630 },
  { code: "BR", name: "Universidade do Sagrado Coração", id: 631 },
  { code: "BR", name: "Universidade do Sul de Santa Catarina", id: 632 },
  { code: "BR", name: "Universidade do Vale do Itajaí", id: 633 },
  { code: "BR", name: "Universidade Estácio de Sá", id: 634 },
  { code: "BR", name: "Universidade Estadual da Paraíba", id: 635 },
  { code: "BR", name: "Universidade Estadual de Campinas", id: 636 },
  { code: "BR", name: "Universidade Estadual de Feira de Santana", id: 637 },
  { code: "BR", name: "Universidade Estadual de Londrina", id: 638 },
  { code: "BR", name: "Universidade Estadual de Maringá", id: 639 },
  { code: "BR", name: "Universidade Estadual de Montes Claros", id: 640 },
  { code: "BR", name: "Universidade Estadual de Ponta Grossa", id: 641 },
  { code: "BR", name: "Universidade Estadual de Santa Cruz", id: 642 },
  { code: "BR", name: "Universidade Estadual do Ceará", id: 643 },
  { code: "BR", name: "Universidade Estadual do Centro-Oeste", id: 644 },
  { code: "BR", name: "Universidade Estadual do Maranhão", id: 645 },
  { code: "BR", name: "Universidade Estadual do Norte Fluminense", id: 646 },
  { code: "BR", name: "Universidade Estadual do Oeste do Paraná", id: 647 },
  { code: "BR", name: "Universidade Estadual do Piauí", id: 648 },
  { code: "BR", name: "Universidade Estadual do Vale do Acaraú", id: 649 },
  { code: "BR", name: "Universidade Estadual Paulista", id: 650 },
  { code: "BR", name: "Universidade Estadual Sudoeste da Bahia", id: 651 },
  { code: "BR", name: "Universidade Federal da Bahia", id: 652 },
  { code: "BR", name: "Universidade Federal da Grande Dourados", id: 653 },
  { code: "BR", name: "Universidade Federal da Paraíba", id: 654 },
  { code: "BR", name: "Universidade Federal de Alagoas", id: 655 },
  { code: "BR", name: "Universidade Federal de Campina Grande", id: 656 },
  { code: "BR", name: "Universidade Federal de Goiás", id: 657 },
  { code: "BR", name: "Universidade Federal de Juiz de Fora", id: 658 },
  { code: "BR", name: "Universidade Federal de Lavras", id: 659 },
  { code: "BR", name: "Universidade Federal de Mato Grosso", id: 660 },
  { code: "BR", name: "Universidade Federal de Mato Grosso do Sul", id: 661 },
  { code: "BR", name: "Universidade Federal de Minas Gerais", id: 662 },
  { code: "BR", name: "Universidade Federal de Ouro Preto", id: 663 },
  { code: "BR", name: "Universidade Federal de Pelotas", id: 664 },
  { code: "BR", name: "Universidade Federal de Pernambuco", id: 665 },
  { code: "BR", name: "Universidade Federal de Rondônia", id: 666 },
  { code: "BR", name: "Universidade Federal de Roraima", id: 667 },
  { code: "BR", name: "Universidade Federal de Santa Catarina", id: 668 },
  { code: "BR", name: "Universidade Federal de Santa Maria", id: 669 },
  { code: "BR", name: "Universidade Federal de São Carlos", id: 670 },
  { code: "BR", name: "Universidade Federal de São Paulo", id: 671 },
  { code: "BR", name: "Universidade Federal de Sergipe", id: 672 },
  { code: "BR", name: "Universidade Federal de Uberlândia", id: 673 },
  { code: "BR", name: "Universidade Federal de Viçosa", id: 674 },
  { code: "BR", name: "Universidade Federal do ABC", id: 675 },
  { code: "BR", name: "Universidade Federal do Acre", id: 676 },
  { code: "BR", name: "Universidade Federal do Amapá", id: 677 },
  { code: "BR", name: "Universidade Federal do Ceará", id: 678 },
  { code: "BR", name: "Universidade Federal do Espírito Santo", id: 679 },
  { code: "BR", name: "Universidade Federal do Maranhão", id: 680 },
  { code: "BR", name: "Universidade Federal do Pará", id: 681 },
  { code: "BR", name: "Universidade Federal do Paraná", id: 682 },
  { code: "BR", name: "Universidade Federal do Piauí", id: 683 },
  { code: "BR", name: "Universidade Federal do Rio de Janeiro", id: 684 },
  { code: "BR", name: "Universidade Federal do Rio Grande do Norte", id: 685 },
  { code: "BR", name: "Universidade Federal do Rio Grande do Sul", id: 686 },
  { code: "BR", name: "Universidade Federal do Vale do São Francisco", id: 687 },
  { code: "BR", name: "Universidade Federal Fluminense", id: 688 },
  { code: "BR", name: "Universidade Federal Rural de Pernambuco", id: 689 },
  { code: "BR", name: "Universidade Federal Rural do Rio de Janeiro", id: 690 },
  { code: "BR", name: "Universidade Federal Rural do Semi-Árido", id: 691 },
  { code: "BR", name: "Universidade Gama Filho", id: 692 },
  { code: "BR", name: "Universidade Ibirapuera", id: 693 },
  { code: "BR", name: "Universidade Luterana do Brasil", id: 694 },
  { code: "BR", name: "Universidade Mackenzie", id: 695 },
  { code: "BR", name: "Universidade Metodista de Piracicaba", id: 696 },
  { code: "BR", name: "Universidade Metropolitana de Santos", id: 697 },
  { code: "BR", name: "Universidade para o Desenvolvimento do Alto Vale do Itajaí", id: 698 },
  { code: "BR", name: "Universidade Paulista", id: 699 },
  { code: "BR", name: "Universidade Potiguar", id: 700 },
  { code: "BR", name: "Universidade Regional de Blumenau", id: 701 },
  { code: "BR", name: "Universidade Regional do Cariri", id: 702 },
  { code: "BR", name: "Universidade Regional do Noroeste do Estado do Rio Grande do Sul", id: 703 },
  { code: "BR", name: "Universidade Regional Integrada do Alto Uruguai e das Missões", id: 704 },
  { code: "BR", name: "Universidade Salgado de Oliveira", id: 705 },
  { code: "BR", name: "Universidade Salvador", id: 706 },
  { code: "BR", name: "Universidade Santa Cecília dos Bandeirantes", id: 707 },
  { code: "BR", name: "Universidade Santa Úrsula", id: 708 },
  { code: "BR", name: "Universidade São Francisco", id: 709 },
  { code: "BR", name: "Universidade São Judas Tadeu", id: 710 },
  { code: "BR", name: "Universidade São Marcos", id: 711 },
  { code: "BR", name: "Universidade Tiradentes", id: 712 },
  { code: "BR", name: "Universidade Vale do Paraiba", id: 713 },
  { code: "BR", name: "Universidade Vale do Rio Doce", id: 714 },
  { code: "BR", name: "Universidade Vale do Rio dos Sinos", id: 715 },
  { code: "BR", name: "Universidade Veiga de Almeida", id: 716 },
  { code: "BS", name: "The College of The Bahamas", id: 717 },
  { code: "BT", name: "Royal University of Bhutan", id: 718 },
  { code: "BW", name: "ABM University College", id: 719 },
  { code: "BW", name: "Botho University", id: 720 },
  { code: "BW", name: "Botswana Accountancy College", id: 721 },
  { code: "BW", name: "Botswana College of Agriculture", id: 722 },
  { code: "BW", name: "Botswana International University of Science & Technology", id: 723 },
  { code: "BW", name: "Gaborone Universal College of Law", id: 724 },
  { code: "BW", name: "New Era College of Arts", id: 725 },
  { code: "BW", name: "University of Botswana", id: 726 },
  { code: "BY", name: "Academy of Public Administration of Belarus", id: 727 },
  { code: "BY", name: "Academy of the Ministry of Internal Affairs of the Republic of Belarus", id: 728 },
  { code: "BY", name: "Belarusian-Russian University", id: 729 },
  { code: "BY", name: "Belarussian National Technical University", id: 730 },
  { code: "BY", name: "Belarussian State Academy of Music", id: 731 },
  { code: "BY", name: "Belarussian State Agrarian Technical University", id: 732 },
  { code: "BY", name: "Belarussian State Agricultural Academy", id: 733 },
  { code: "BY", name: "Belarussian State Economic University", id: 734 },
  { code: "BY", name: "Belarussian State Medical University", id: 735 },
  { code: "BY", name: "Belarussian State Pedagogical University M. Tanka", id: 736 },
  { code: "BY", name: "Belarussian State Polytechnical Academy", id: 737 },
  { code: "BY", name: "Belarussian State Technological University", id: 738 },
  { code: "BY", name: "Belarussian State University", id: 739 },
  { code: "BY", name: "Belarussian State University of Culture and Arts", id: 740 },
  { code: "BY", name: "Belarussian State University of Informatics and Radioelectronics", id: 741 },
  { code: "BY", name: "Belarussian State University of Transport", id: 742 },
  { code: "BY", name: "Brest State Technical University", id: 743 },
  { code: "BY", name: "Brest State University", id: 744 },
  { code: "BY", name: "European Humanities University", id: 745 },
  { code: "BY", name: "Gomel State Medical University", id: 746 },
  { code: "BY", name: "Gomel State Technical University Pavel Sukhoi", id: 747 },
  { code: "BY", name: "Gomel State University Francisk Scarnia", id: 748 },
  { code: "BY", name: "Grodno State Agrarian University", id: 749 },
  { code: "BY", name: "Grodno State Medical University", id: 750 },
  { code: "BY", name: "Grodno State University Yanka Kupaly", id: 751 },
  { code: "BY", name: "Institute for Command Engineers of The Ministry for Emergency Situations", id: 752 },
  { code: "BY", name: "International Sakharov Environmental University", id: 753 },
  { code: "BY", name: "Minsk Institute of Management", id: 754 },
  { code: "BY", name: "Minsk State Linguistic University", id: 755 },
  { code: "BY", name: "Mogilev State University A.A. Kuleshov", id: 756 },
  { code: "BY", name: "Mozyr State Pedagogical Institute", id: 757 },
  { code: "BY", name: "Polotsk State University", id: 758 },
  { code: "BY", name: "Vitebsk State Academy of Veterinary Medicine", id: 759 },
  { code: "BY", name: "Vitebsk State Medical University", id: 760 },
  { code: "BY", name: "Vitebsk State Technological University", id: 761 },
  { code: "BY", name: "Vitebsk State University", id: 762 },
  { code: "BZ", name: "American University of the Caribbean", id: 763 },
  { code: "BZ", name: "Central America Health Sciences University (Belize Medical College)", id: 764 },
  { code: "BZ", name: "Greenford International University", id: 765 },
  { code: "BZ", name: "University of Belize", id: 766 },
  { code: "BZ", name: "West Coast University (WCU) ", id: 767 },
  { code: "CA", name: "Acadia University", id: 768 },
  { code: "CA", name: "Algonquin College", id: 769 },
  { code: "CA", name: "Ashton College", id: 770 },
  { code: "CA", name: "Assumption University", id: 771 },
  { code: "CA", name: "Athabasca University", id: 772 },
  { code: "CA", name: "Augustana University College", id: 773 },
  { code: "CA", name: "Bishop's University", id: 774 },
  { code: "CA", name: "Bow Valley College", id: 775 },
  { code: "CA", name: "Brandon University", id: 776 },
  { code: "CA", name: "Brescia College", id: 777 },
  { code: "CA", name: "British Columbia Institute of Technology", id: 778 },
  { code: "CA", name: "British Columbia Open University", id: 779 },
  { code: "CA", name: "Brock University", id: 780 },
  { code: "CA", name: "Camosun College", id: 781 },
  { code: "CA", name: "Campion College", id: 782 },
  { code: "CA", name: "Canadian College of Business & Computers", id: 783 },
  { code: "CA", name: "Canadian Mennonite University", id: 784 },
  { code: "CA", name: "Capilano College", id: 785 },
  { code: "CA", name: "Carleton University", id: 786 },
  { code: "CA", name: "Centennial College", id: 787 },
  { code: "CA", name: "Collège Boréal", id: 788 },
  { code: "CA", name: "College of New Caledonia", id: 789 },
  { code: "CA", name: "College of the Rockies", id: 790 },
  { code: "CA", name: "Columbia College", id: 791 },
  { code: "CA", name: "Concordia University", id: 792 },
  { code: "CA", name: "Concordia University College of Alberta", id: 793 },
  { code: "CA", name: "Conestoga College", id: 794 },
  { code: "CA", name: "Dalhousie University", id: 795 },
  { code: "CA", name: "DeVry Institute of Technology", id: 796 },
  { code: "CA", name: "Dominican College of Philosophy and Theology", id: 797 },
  { code: "CA", name: "Douglas College", id: 798 },
  { code: "CA", name: "Durham College", id: 799 },
  { code: "CA", name: "École des Hautes Études Commerciales", id: 800 },
  { code: "CA", name: "École de technologie supérieure", id: 801 },
  { code: "CA", name: "École nationale d'administration publique", id: 802 },
  { code: "CA", name: "École Polytechnique de Montréal", id: 803 },
  { code: "CA", name: "Emily Carr Institute of Art + Design", id: 804 },
  { code: "CA", name: "First Nations University of Canada", id: 805 },
  { code: "CA", name: "George Brown College", id: 806 },
  { code: "CA", name: "Humber College", id: 807 },
  { code: "CA", name: "Huron University College", id: 808 },
  { code: "CA", name: "Institut Armand-Frappier", id: 809 },
  { code: "CA", name: "Institut National de la Recherche Scientifique", id: 810 },
  { code: "CA", name: "King's College", id: 811 },
  { code: "CA", name: "Kingston College", id: 812 },
  { code: "CA", name: "King's University College", id: 813 },
  { code: "CA", name: "Kwantleen University College", id: 814 },
  { code: "CA", name: "Lakehead University", id: 815 },
  { code: "CA", name: "Langara College", id: 816 },
  { code: "CA", name: "Lansbridge University", id: 817 },
  { code: "CA", name: "Laurentian University of Sudbury", id: 818 },
  { code: "CA", name: "Luther College", id: 819 },
  { code: "CA", name: "MacEwan University", id: 820 },
  { code: "CA", name: "Malaspina University College", id: 821 },
  { code: "CA", name: "McGill University", id: 822 },
  { code: "CA", name: "McMaster University", id: 823 },
  { code: "CA", name: "Memorial University of Newfoundland", id: 824 },
  { code: "CA", name: "Mount Allison University", id: 825 },
  { code: "CA", name: "Mount Royal College", id: 826 },
  { code: "CA", name: "Mount Saint Vincent University", id: 827 },
  { code: "CA", name: "Nicola Valley Institute of Technology", id: 828 },
  { code: "CA", name: "Nipissing University", id: 829 },
  { code: "CA", name: "Northern Alberta Institute of Technology", id: 830 },
  { code: "CA", name: "Northern Lights College", id: 831 },
  { code: "CA", name: "North Island College", id: 832 },
  { code: "CA", name: "Nova Scotia Agricultural College", id: 833 },
  { code: "CA", name: "Nova Scotia College of Art and Design", id: 834 },
  { code: "CA", name: "Okanagan University College", id: 835 },
  { code: "CA", name: "Ontario College of Art and Design", id: 836 },
  { code: "CA", name: "Pacific International College", id: 837 },
  { code: "CA", name: "Queen's University", id: 838 },
  { code: "CA", name: "Quest University", id: 839 },
  { code: "CA", name: "Redeemer College", id: 840 },
  { code: "CA", name: "Regent College", id: 841 },
  { code: "CA", name: "Royal Military College of Canada", id: 842 },
  { code: "CA", name: "Royal Roads University", id: 843 },
  { code: "CA", name: "Ryerson Polytechnic University", id: 844 },
  { code: "CA", name: "Saskatchewan Indian Federated College", id: 845 },
  { code: "CA", name: "Sauder School of Business", id: 846 },
  { code: "CA", name: "Selkirk College", id: 847 },
  { code: "CA", name: "Seneca College", id: 848 },
  { code: "CA", name: "Sheridan College", id: 849 },
  { code: "CA", name: "Simon Fraser University", id: 850 },
  { code: "CA", name: "Southern Alberta Institute of Technology", id: 851 },
  { code: "CA", name: "St. Anne University", id: 852 },
  { code: "CA", name: "St. Clair College", id: 853 },
  { code: "CA", name: "St. Francis Xavier University", id: 854 },
  { code: "CA", name: "St. Mary's University", id: 855 },
  { code: "CA", name: "St. Paul University", id: 856 },
  { code: "CA", name: "St. Thomas University", id: 857 },
  { code: "CA", name: "Télé-université", id: 858 },
  { code: "CA", name: "Thompson Rivers University", id: 859 },
  { code: "CA", name: "Trent University", id: 860 },
  { code: "CA", name: "Trinity Western University", id: 861 },
  { code: "CA", name: "Université de Montréal", id: 862 },
  { code: "CA", name: "Université de Sherbrooke", id: 863 },
  { code: "CA", name: "Université du Québec à Chicoutimi", id: 864 },
  { code: "CA", name: "Université du Québec à Montréal", id: 865 },
  { code: "CA", name: "Université du Québec à Rimouski", id: 866 },
  { code: "CA", name: "Université du Québec à Trois-Rivières", id: 867 },
  { code: "CA", name: "Université du Québec en Abitibi-Témiscamingue", id: 868 },
  { code: "CA", name: "Université du Québec en Outaouais", id: 869 },
  { code: "CA", name: "Université Laval", id: 870 },
  { code: "CA", name: "University Canada West", id: 871 },
  { code: "CA", name: "University College of Cape Breton", id: 872 },
  { code: "CA", name: "University College of Saint-Boniface", id: 873 },
  { code: "CA", name: "University College of the Cariboo", id: 874 },
  { code: "CA", name: "University College of the Fraser Valley", id: 875 },
  { code: "CA", name: "University of Alberta", id: 876 },
  { code: "CA", name: "University of British Columbia", id: 877 },
  { code: "CA", name: "University of Calgary", id: 878 },
  { code: "CA", name: "University of Guelph", id: 879 },
  { code: "CA", name: "University of King's College", id: 880 },
  { code: "CA", name: "University of Lethbridge", id: 881 },
  { code: "CA", name: "University of Manitoba", id: 882 },
  { code: "CA", name: "University of Moncton", id: 883 },
  { code: "CA", name: "University of New Brunswick", id: 884 },
  { code: "CA", name: "University of Northern British Columbia", id: 885 },
  { code: "CA", name: "University of Ontario Institute of Technology", id: 886 },
  { code: "CA", name: "University of Ottawa", id: 887 },
  { code: "CA", name: "University of Prince Edward Island", id: 888 },
  { code: "CA", name: "University of Québec", id: 889 },
  { code: "CA", name: "University of Regina", id: 890 },
  { code: "CA", name: "University of Saskatchewan", id: 891 },
  { code: "CA", name: "University of St. Jerome's College", id: 892 },
  { code: "CA", name: "University of St. Michael's College", id: 893 },
  { code: "CA", name: "University of Sudbury", id: 894 },
  { code: "CA", name: "University of Toronto", id: 895 },
  { code: "CA", name: "University of Trinity College", id: 896 },
  { code: "CA", name: "University of Victoria", id: 897 },
  { code: "CA", name: "University of Waterloo", id: 898 },
  { code: "CA", name: "University of Western Ontario", id: 899 },
  { code: "CA", name: "University of Windsor", id: 900 },
  { code: "CA", name: "University of Winnipeg", id: 901 },
  { code: "CA", name: "Vancouver Community College", id: 902 },
  { code: "CA", name: "Victoria University Toronto", id: 903 },
  { code: "CA", name: "Wilfrid Laurier University", id: 904 },
  { code: "CA", name: "William and Catherine Booth College", id: 905 },
  { code: "CA", name: "York University", id: 906 },
  { code: "CA", name: "Yukon College", id: 907 },
  { code: "CD", name: "Université Catholique de Bukavu", id: 908 },
  { code: "CD", name: "Université Chrétienne Bilingue du Congo", id: 909 },
  { code: "CD", name: "Université de Bandundu Ville", id: 910 },
  { code: "CD", name: "Université de Kamina", id: 911 },
  { code: "CD", name: "Université de Kikwite", id: 912 },
  { code: "CD", name: "Université de Kinshasa", id: 913 },
  { code: "CD", name: "Université de Kisangani", id: 914 },
  { code: "CD", name: "Université de Kolwezi", id: 915 },
  { code: "CD", name: "Université de Lubumbashi", id: 916 },
  { code: "CD", name: "Université de l'Uélé", id: 917 },
  { code: "CD", name: "Université Kongo", id: 918 },
  { code: "CD", name: "Université Protestante au Congo", id: 919 },
  { code: "CD", name: "Université Shalom de Bunia", id: 920 },
  { code: "CF", name: "Université de Bangui", id: 921 },
  { code: "CG", name: "University Marien Ngouabi Brazzaville", id: 922 },
  { code: "CH", name: "Business and Hotel Management School", id: 923 },
  { code: "CH", name: "Business School Lausanne (BSL)", id: 924 },
  { code: "CH", name: "Cubidor University Switzerland", id: 925 },
  { code: "CH", name: "DCT International Hotel & Business Management School", id: 926 },
  { code: "CH", name: "European Graduate School", id: 927 },
  { code: "CH", name: "European University", id: 928 },
  { code: "CH", name: "Fachhochschule Pur", id: 929 },
  { code: "CH", name: "Fachhochschule St. Gallen", id: 930 },
  { code: "CH", name: "Franklin College Switzerland", id: 931 },
  { code: "CH", name: "Geneva Business School", id: 932 },
  { code: "CH", name: "Glion Institute of Higher Education", id: 933 },
  { code: "CH", name: "Graduate School of Business Administration Zurich (GSBA Zurich)", id: 934 },
  { code: "CH", name: "Hochschule für Gestaltung und Kunst Zürich", id: 935 },
  { code: "CH", name: "International Culinary Institute (ICI)", id: 936 },
  { code: "CH", name: "International Hotel Management Institute", id: 937 },
  { code: "CH", name: "International School of Business Management", id: 938 },
  { code: "CH", name: "International University in Geneva", id: 939 },
  { code: "CH", name: "Lausanne Hotel School (EHL)", id: 940 },
  { code: "CH", name: "Les Roches Gruyère University of Applied Sciences", id: 941 },
  { code: "CH", name: "Les Roches International School of Hotel Management", id: 942 },
  { code: "CH", name: "Libera Università degli Studi di Scienze Umane e Tecnologiche", id: 943 },
  { code: "CH", name: "Lucerne University of Applied Sciences and Arts ", id: 944 },
  { code: "CH", name: "NTB Interstate University of Applied Sciences of Technology", id: 945 },
  { code: "CH", name: "Pädagogische Hochschule Zürich (Zurich School of Education)", id: 946 },
  { code: "CH", name: "Schiller International University", id: 947 },
  { code: "CH", name: "School of Management Fribourg", id: 948 },
  { code: "CH", name: "Swiss Business School Zurich (SBS)", id: 949 },
  { code: "CH", name: "Swiss Federal Institute of Technology", id: 950 },
  { code: "CH", name: "Swiss Management Center", id: 951 },
  { code: "CH", name: "University Center César Ritz", id: 952 },
  { code: "CH", name: "University of Applied Sciences Aargau", id: 953 },
  { code: "CH", name: "University of Applied Sciences Basel (FHBB )", id: 954 },
  { code: "CH", name: "University of Applied Sciences Chur", id: 955 },
  { code: "CH", name: "University of Applied Sciences Rapperswil", id: 956 },
  { code: "CH", name: "University of Applied Sciences Solothurn Northwestern Switzerland", id: 957 },
  { code: "CH", name: "University of Basel", id: 958 },
  { code: "CH", name: "University of Berne", id: 959 },
  { code: "CH", name: "University of Fribourg", id: 960 },
  { code: "CH", name: "University of Geneva", id: 961 },
  { code: "CH", name: "University of Lausanne", id: 962 },
  { code: "CH", name: "University of Lucerne", id: 963 },
  { code: "CH", name: "University of Neuchatel", id: 964 },
  { code: "CH", name: "University of St. Gallen", id: 965 },
  { code: "CH", name: "University of Switzerland", id: 966 },
  { code: "CH", name: "University of the Italian-speaking Part of Switzerland", id: 967 },
  { code: "CH", name: "University of Zürich", id: 968 },
  { code: "CH", name: "Victoria University", id: 969 },
  { code: "CH", name: "Webster University Geneva", id: 970 },
  { code: "CH", name: "Zurich University of Applied Sciences Winterthur", id: 971 },
  { code: "CI", name: "Université d'Abobo-Adjamé", id: 972 },
  { code: "CI", name: "Université de Bouaké", id: 973 },
  { code: "CI", name: "Université de Cocody", id: 974 },
  { code: "CL", name: "Escuela de Arquitectura y Diseño", id: 975 },
  { code: "CL", name: "Pontificia Universidad Catolica de Chile", id: 976 },
  { code: "CL", name: "Universidad Academia de Humanismo Cristiano", id: 977 },
  { code: "CL", name: "Universidad Adolfo Ibañez", id: 978 },
  { code: "CL", name: "Universidad Adolfo Ibáñez", id: 979 },
  { code: "CL", name: "Universidad Adventista de Chile", id: 980 },
  { code: "CL", name: "Universidad Alberto Hurtado", id: 981 },
  { code: "CL", name: "Universidad Arcis", id: 982 },
  { code: "CL", name: "Universidad Arturo Prat", id: 983 },
  { code: "CL", name: "Universidad Austral de Chile", id: 984 },
  { code: "CL", name: "Universidad Autonoma del Sur", id: 985 },
  { code: "CL", name: "Universidad Bernardo O'Higgins", id: 986 },
  { code: "CL", name: "Universidad Bolivariana", id: 987 },
  { code: "CL", name: "Universidad Católica Cardenal Raúl Silva Henríquez", id: 988 },
  { code: "CL", name: "Universidad Catolica de La Santísima Concepción", id: 989 },
  { code: "CL", name: "Universidad Catolica del Maule", id: 990 },
  { code: "CL", name: "Universidad Catolica del Norte", id: 991 },
  { code: "CL", name: "Universidad Catolica de Temuco", id: 992 },
  { code: "CL", name: "Universidad Catolica de Valparaiso", id: 993 },
  { code: "CL", name: "Universidad Central", id: 994 },
  { code: "CL", name: "Universidad Chileno Britanica de Cultura", id: 995 },
  { code: "CL", name: "Universidad de Aconcagua", id: 996 },
  { code: "CL", name: "Universidad de Antofagasta", id: 997 },
  { code: "CL", name: "Universidad de Artes", id: 998 },
  { code: "CL", name: "Universidad de Atacama", id: 999 },
  { code: "CL", name: "Universidad de Chile", id: 1000 },
  { code: "CL", name: "Universidad de Ciencias de la Informatica", id: 1001 },
  { code: "CL", name: "Universidad de Concepcion", id: 1002 },
  { code: "CL", name: "Universidad de La Frontera", id: 1003 },
  { code: "CL", name: "Universidad de Las Américas", id: 1004 },
  { code: "CL", name: "Universidad de La Serena", id: 1005 },
  { code: "CL", name: "Universidad del Bío-Bío", id: 1006 },
  { code: "CL", name: "Universidad del Desarrollo", id: 1007 },
  { code: "CL", name: "Universidad del Mar", id: 1008 },
  { code: "CL", name: "Universidad de Los Andes", id: 1009 },
  { code: "CL", name: "Universidad de Los Lagos", id: 1010 },
  { code: "CL", name: "Universidad del Pacífico", id: 1011 },
  { code: "CL", name: "Universidad de Magallanes", id: 1012 },
  { code: "CL", name: "Universidad de Playa Ancha de Ciencias de la Educacion", id: 1013 },
  { code: "CL", name: "Universidad de San Andres", id: 1014 },
  { code: "CL", name: "Universidad de Santiago de Chile", id: 1015 },
  { code: "CL", name: "Universidad de Talca", id: 1016 },
  { code: "CL", name: "Universidad de Tarapacá", id: 1017 },
  { code: "CL", name: "Universidad de Valparaiso", id: 1018 },
  { code: "CL", name: "Universidad de Viña del Mar", id: 1019 },
  { code: "CL", name: "Universidad Diego Portales", id: 1020 },
  { code: "CL", name: "Universidad Finis Terrae", id: 1021 },
  { code: "CL", name: "Universidad Francisco de Aguirre", id: 1022 },
  { code: "CL", name: "Universidad Gabriela Mistral", id: 1023 },
  { code: "CL", name: "Universidad Iberoamericana de Ciencias y Tecnologia", id: 1024 },
  { code: "CL", name: "Universidad International SEK", id: 1025 },
  { code: "CL", name: "Universidad José Santos Ossa", id: 1026 },
  { code: "CL", name: "Universidad La Republica", id: 1027 },
  { code: "CL", name: "Universidad Mariano Egaña", id: 1028 },
  { code: "CL", name: "Universidad Maritima de Chile", id: 1029 },
  { code: "CL", name: "Universidad Mayor", id: 1030 },
  { code: "CL", name: "Universidad Metropolitana de Ciencias de la Educación", id: 1031 },
  { code: "CL", name: "Universidad Miguel de Cervantes", id: 1032 },
  { code: "CL", name: "Universidad Nacional Andrés Bello", id: 1033 },
  { code: "CL", name: "Universidad San Sebastian", id: 1034 },
  { code: "CL", name: "Universidad Santo Tomás", id: 1035 },
  { code: "CL", name: "Universidad Técnica Federico Santa María", id: 1036 },
  { code: "CL", name: "Universidad Tecnológica Metropolitana", id: 1037 },
  { code: "CL", name: "Universidad Tecnologica Vicente Perez Rosales", id: 1038 },
  { code: "CM", name: "Bamenda University of Science & Technology", id: 1039 },
  { code: "CM", name: "Fomic Polytechnic", id: 1040 },
  { code: "CM", name: "Université de Bamenda", id: 1041 },
  { code: "CM", name: "Université de Buéa", id: 1042 },
  { code: "CM", name: "Université de Douala", id: 1043 },
  { code: "CM", name: "Université de Dschang", id: 1044 },
  { code: "CM", name: "Université de Ngaoundéré", id: 1045 },
  { code: "CM", name: "Université des Montagnes", id: 1046 },
  { code: "CM", name: "Université de Yaoundé I", id: 1047 },
  { code: "CM", name: "Université de Yaoundé II", id: 1048 },
  { code: "CN", name: "2nd Military Medical University", id: 1049 },
  { code: "CN", name: "3rd Military Medical University", id: 1050 },
  { code: "CN", name: "4th Military Medical University", id: 1051 },
  { code: "CN", name: "Anhui Medical University", id: 1052 },
  { code: "CN", name: "Anhui Normal University", id: 1053 },
  { code: "CN", name: "Anhui Technical College of Water Resources and Hydroelectric Power", id: 1054 },
  { code: "CN", name: "Anhui University", id: 1055 },
  { code: "CN", name: "Anhui University of Finance and Economics", id: 1056 },
  { code: "CN", name: "Anhui University of Traditional Chinese Medicine", id: 1057 },
  { code: "CN", name: "Beijing Foreign Studies University", id: 1058 },
  { code: "CN", name: "Beijing Forestry University", id: 1059 },
  { code: "CN", name: "Beijing Information Science and Technology University", id: 1060 },
  { code: "CN", name: "Beijing Institute of Technology", id: 1061 },
  { code: "CN", name: "Beijing International Studies University", id: 1062 },
  { code: "CN", name: "Beijing Language and Culture University", id: 1063 },
  { code: "CN", name: "Beijing Medical University", id: 1064 },
  { code: "CN", name: "Beijing New Asia University", id: 1065 },
  { code: "CN", name: "Beijing Normal University", id: 1066 },
  { code: "CN", name: "Beijing Petroleum University", id: 1067 },
  { code: "CN", name: "Beijing Polytechnic University", id: 1068 },
  { code: "CN", name: "Beijing Sport University", id: 1069 },
  { code: "CN", name: "Beijing Union University", id: 1070 },
  { code: "CN", name: "Beijing University of Aeronautics and Astronautics", id: 1071 },
  { code: "CN", name: "Beijing University of Agriculture", id: 1072 },
  { code: "CN", name: "Beijing University of Chemical Technology", id: 1073 },
  { code: "CN", name: "Beijing University of Chinese Medicine and Pharmacology", id: 1074 },
  { code: "CN", name: "Beijing University of Posts and Telecommunications", id: 1075 },
  { code: "CN", name: "Beijing University of Science and Technology", id: 1076 },
  { code: "CN", name: "Bohai University", id: 1077 },
  { code: "CN", name: "Buddhist Acamedy of China", id: 1078 },
  { code: "CN", name: "Capital Normal University", id: 1079 },
  { code: "CN", name: "Capital University of Economics and Business", id: 1080 },
  { code: "CN", name: "Capital University of Medical Sciences", id: 1081 },
  { code: "CN", name: "Central Academy of  Fine Art", id: 1082 },
  { code: "CN", name: "Central China Normal University", id: 1083 },
  { code: "CN", name: "Central Radio and TV University", id: 1084 },
  { code: "CN", name: "Central South Forestry University", id: 1085 },
  { code: "CN", name: "Central South University", id: 1086 },
  { code: "CN", name: "Central University for Nationalities", id: 1087 },
  { code: "CN", name: "Central University of Finance and Economics", id: 1088 },
  { code: "CN", name: "Changchun Teachers College", id: 1089 },
  { code: "CN", name: "Changchun University of Technology", id: 1090 },
  { code: "CN", name: "Changsha Railway University", id: 1091 },
  { code: "CN", name: "Changsha University of Electric Power", id: 1092 },
  { code: "CN", name: "Chengdu Institute of Sichuan International Studies University", id: 1093 },
  { code: "CN", name: "Chengdu University", id: 1094 },
  { code: "CN", name: "Chengdu University of Technology", id: 1095 },
  { code: "CN", name: "Chengdu University of Traditional Chinese Medicine", id: 1096 },
  { code: "CN", name: "China Academy of Art", id: 1097 },
  { code: "CN", name: "China Agricultural University", id: 1098 },
  { code: "CN", name: "China Agriculture University East", id: 1099 },
  { code: "CN", name: "China Foreign Affairs University", id: 1100 },
  { code: "CN", name: "China Medical University Shenyang", id: 1101 },
  { code: "CN", name: "China Pharmaceutical University Nanjing", id: 1102 },
  { code: "CN", name: "China Three Gorges University", id: 1103 },
  { code: "CN", name: "China University of Geoscience Beijing", id: 1104 },
  { code: "CN", name: "China University of Geosciences Wuhan", id: 1105 },
  { code: "CN", name: "China University of Mining Technology - Beijing", id: 1106 },
  { code: "CN", name: "China University of Mining Technology - Xuzhou", id: 1107 },
  { code: "CN", name: "China University of Political Science and Law", id: 1108 },
  { code: "CN", name: "China USA Business University", id: 1109 },
  { code: "CN", name: "China youth college for political science", id: 1110 },
  { code: "CN", name: "Chinese People's Public Security University", id: 1111 },
  { code: "CN", name: "Chongqing Education College", id: 1112 },
  { code: "CN", name: "Chongqing Medical University", id: 1113 },
  { code: "CN", name: "Chongqing Normal University", id: 1114 },
  { code: "CN", name: "Chongqing Normal University Foreign Trade and Business College", id: 1115 },
  { code: "CN", name: "Chongqing Technology and Business University", id: 1116 },
  { code: "CN", name: "Chongqing Telecommunication College", id: 1117 },
  { code: "CN", name: "Chongqing Three Gorges University", id: 1118 },
  { code: "CN", name: "Chongqing University", id: 1119 },
  { code: "CN", name: "Chongqing University of Communications", id: 1120 },
  { code: "CN", name: "Chongqing University of Post and Telecommunications", id: 1121 },
  { code: "CN", name: "Chongqing University of Science and Technology ", id: 1122 },
  { code: "CN", name: "Chongqing University of Technology", id: 1123 },
  { code: "CN", name: "Chongqing Vocational College of Public Transportation", id: 1124 },
  { code: "CN", name: "Chongqing Wenli University", id: 1125 },
  { code: "CN", name: "Civil Aviation University of China", id: 1126 },
  { code: "CN", name: "Communication University of China", id: 1127 },
  { code: "CN", name: "Dalian Martime University", id: 1128 },
  { code: "CN", name: "Dalian Medical University", id: 1129 },
  { code: "CN", name: "Dalian Polytechnic University", id: 1130 },
  { code: "CN", name: "Dalian University", id: 1131 },
  { code: "CN", name: "Dalian University of Foreign Language", id: 1132 },
  { code: "CN", name: "Dalian University of Technology", id: 1133 },
  { code: "CN", name: "Dongbei University of Finance And Economics", id: 1134 },
  { code: "CN", name: "Donghua University", id: 1135 },
  { code: "CN", name: "East China Jiao Tong University", id: 1136 },
  { code: "CN", name: "East China Normal University", id: 1137 },
  { code: "CN", name: "East China University of Science and Technology", id: 1138 },
  { code: "CN", name: "Emeishan Buddhist College", id: 1139 },
  { code: "CN", name: "Foshan University", id: 1140 },
  { code: "CN", name: "Fudan University", id: 1141 },
  { code: "CN", name: "Fujian Agricultural University", id: 1142 },
  { code: "CN", name: "Fujian Medical University", id: 1143 },
  { code: "CN", name: "Fujian Normal University", id: 1144 },
  { code: "CN", name: "Fujian University of Traditional Chinese Medicine", id: 1145 },
  { code: "CN", name: "Fushun Petroleum University", id: 1146 },
  { code: "CN", name: "Fuzhou University", id: 1147 },
  { code: "CN", name: "Gansu Agricultural University", id: 1148 },
  { code: "CN", name: "Gansu University of Technology", id: 1149 },
  { code: "CN", name: "Guangdong Peizheng College ", id: 1150 },
  { code: "CN", name: "Guangdong Polytechnic Normal University", id: 1151 },
  { code: "CN", name: "Guangdong Radio & TV University", id: 1152 },
  { code: "CN", name: "Guangdong University of Foreign Studies", id: 1153 },
  { code: "CN", name: "Guangdong University of Technology", id: 1154 },
  { code: "CN", name: "Guangxi Medical University", id: 1155 },
  { code: "CN", name: "Guangxi Normal University", id: 1156 },
  { code: "CN", name: "Guangxi Traditional Chinese Medical University", id: 1157 },
  { code: "CN", name: "Guangxi University", id: 1158 },
  { code: "CN", name: "Guangxi University for Nationalities", id: 1159 },
  { code: "CN", name: "Guangzhou Academy of Fine Art", id: 1160 },
  { code: "CN", name: "Guangzhou Normal University", id: 1161 },
  { code: "CN", name: "Guangzhou University", id: 1162 },
  { code: "CN", name: "Guangzhou University of Traditional Chinese Medicine", id: 1163 },
  { code: "CN", name: "Guizhou Normal University", id: 1164 },
  { code: "CN", name: "Guizhou University", id: 1165 },
  { code: "CN", name: "Hainan Normal University", id: 1166 },
  { code: "CN", name: "Hainan University", id: 1167 },
  { code: "CN", name: "Harbin Engineering University", id: 1168 },
  { code: "CN", name: "Harbin Institute of Technology", id: 1169 },
  { code: "CN", name: "Harbin Medical University", id: 1170 },
  { code: "CN", name: "Harbin Normal University", id: 1171 },
  { code: "CN", name: "Harbin University of Civil Engineering & Architecture", id: 1172 },
  { code: "CN", name: "Harbin University of Science and Technology", id: 1173 },
  { code: "CN", name: "Hebei Academy of Fine Art", id: 1174 },
  { code: "CN", name: "Hebei Agricultural University", id: 1175 },
  { code: "CN", name: "Hebei Medical University", id: 1176 },
  { code: "CN", name: "Hebei Normal University", id: 1177 },
  { code: "CN", name: "Hebei United University", id: 1178 },
  { code: "CN", name: "Hebei University", id: 1179 },
  { code: "CN", name: "Hebei University of Economics and Trade", id: 1180 },
  { code: "CN", name: "Hebei University of Science and Technology", id: 1181 },
  { code: "CN", name: "Hebei University of Technology", id: 1182 },
  { code: "CN", name: "Hefei University of Technology", id: 1183 },
  { code: "CN", name: "Hehai University", id: 1184 },
  { code: "CN", name: "Heilongjiang August 1st Reclamation University", id: 1185 },
  { code: "CN", name: "Heilongjiang Commercial University", id: 1186 },
  { code: "CN", name: "Heilongjiang University", id: 1187 },
  { code: "CN", name: "Henan Agriculture University", id: 1188 },
  { code: "CN", name: "Henan Buddhist College", id: 1189 },
  { code: "CN", name: "Henan Normal University", id: 1190 },
  { code: "CN", name: "Henan Univeristy", id: 1191 },
  { code: "CN", name: "Hohai University Changzhou", id: 1192 },
  { code: "CN", name: "Huaihai Institute of Technology", id: 1193 },
  { code: "CN", name: "Huaihua Medical College", id: 1194 },
  { code: "CN", name: "Huaihua Radio and Television University", id: 1195 },
  { code: "CN", name: " Huaihua University", id: 1196 },
  { code: "CN", name: "Huanghe Science & Technology University", id: 1197 },
  { code: "CN", name: "Huaqiao University Quanzhuo", id: 1198 },
  { code: "CN", name: "Huazhong Agricultural University", id: 1199 },
  { code: "CN", name: "Huazhong University of Science and Technology", id: 1200 },
  { code: "CN", name: "Hubei University", id: 1201 },
  { code: "CN", name: "Huizhou University", id: 1202 },
  { code: "CN", name: "Hunan Agricultural University", id: 1203 },
  { code: "CN", name: "Hunan Normal University", id: 1204 },
  { code: "CN", name: "Hunan University", id: 1205 },
  { code: "CN", name: "Inner Mongolia Agricultural University", id: 1206 },
  { code: "CN", name: "Inner Mongolia Normal University", id: 1207 },
  { code: "CN", name: "Inner Mongolia Polytechnic University", id: 1208 },
  { code: "CN", name: "Inner Mongolia University", id: 1209 },
  { code: "CN", name: "International Business University of Beijing", id: 1210 },
  { code: "CN", name: "Jiangsu University of Science and Technology", id: 1211 },
  { code: "CN", name: "Jiangxi Agricultural University", id: 1212 },
  { code: "CN", name: "Jiangxi Normal University", id: 1213 },
  { code: "CN", name: "Jiangxi University of Finance and Economics", id: 1214 },
  { code: "CN", name: "Jiangxi University of Traditional Chinese Medicine", id: 1215 },
  { code: "CN", name: "Jiaying University", id: 1216 },
  { code: "CN", name: "Jilin Agricultural University", id: 1217 },
  { code: "CN", name: "Jilin University", id: 1218 },
  { code: "CN", name: "Jilin University of Technology", id: 1219 },
  { code: "CN", name: "Jimei University", id: 1220 },
  { code: "CN", name: "Jinan University", id: 1221 },
  { code: "CN", name: "Jingdezhen China Institute", id: 1222 },
  { code: "CN", name: "Jishou University", id: 1223 },
  { code: "CN", name: "Kunmimg University of Science and Technology", id: 1224 },
  { code: "CN", name: "Lanzhou University", id: 1225 },
  { code: "CN", name: "Liaocheng Teachers University", id: 1226 },
  { code: "CN", name: "Liaoning Normal University", id: 1227 },
  { code: "CN", name: "Liaoning Technical University", id: 1228 },
  { code: "CN", name: "Liaoning University", id: 1229 },
  { code: "CN", name: "Logistics Engineering University of PLA", id: 1230 },
  { code: "CN", name: "Ludong University", id: 1231 },
  { code: "CN", name: "Luxun Academy of Fine Art", id: 1232 },
  { code: "CN", name: "Luzhou Medical College", id: 1233 },
  { code: "CN", name: "Nanchang University", id: 1234 },
  { code: "CN", name: "Nanjing Agricultural University", id: 1235 },
  { code: "CN", name: "Nanjing Forestry University", id: 1236 },
  { code: "CN", name: "Nanjing Institute of Meteorology", id: 1237 },
  { code: "CN", name: "Nanjing Medical University", id: 1238 },
  { code: "CN", name: "Nanjing Normal University", id: 1239 },
  { code: "CN", name: "Nanjing Union Theological Seminary", id: 1240 },
  { code: "CN", name: "Nanjing University", id: 1241 },
  { code: "CN", name: "Nanjing University of Aeronautics and Astronautics", id: 1242 },
  { code: "CN", name: "Nanjing University of Chemical Technology", id: 1243 },
  { code: "CN", name: "Nanjing University of Economics", id: 1244 },
  { code: "CN", name: "Nanjing University of Posts and Telecommunications", id: 1245 },
  { code: "CN", name: "Nanjing University of Science and Technology", id: 1246 },
  { code: "CN", name: "Nanjing University of Traditional Chinese Medicine", id: 1247 },
  { code: "CN", name: "Nankai University", id: 1248 },
  { code: "CN", name: "National University of Defense Technology", id: 1249 },
  { code: "CN", name: "Neijiang Teacher University", id: 1250 },
  { code: "CN", name: "Ningbo University", id: 1251 },
  { code: "CN", name: "Ningbo University of Technology", id: 1252 },
  { code: "CN", name: "Ningxia Medical College", id: 1253 },
  { code: "CN", name: "Ningxia University", id: 1254 },
  { code: "CN", name: "North China Electric Power University", id: 1255 },
  { code: "CN", name: "North China University of Technology", id: 1256 },
  { code: "CN", name: "Northeast Agricultural University", id: 1257 },
  { code: "CN", name: "Northeastern University", id: 1258 },
  { code: "CN", name: "Northeast Forest University", id: 1259 },
  { code: "CN", name: "Northeast Normal University", id: 1260 },
  { code: "CN", name: "Northeast University at Qinhuangdao Campus", id: 1261 },
  { code: "CN", name: "Northern Jiaotong University", id: 1262 },
  { code: "CN", name: "Northern Sichuan Medical College", id: 1263 },
  { code: "CN", name: "Northwest A&F University", id: 1264 },
  { code: "CN", name: "Northwest Normal University Lanzhou", id: 1265 },
  { code: "CN", name: "Northwest Polytechnical University Xi'an", id: 1266 },
  { code: "CN", name: "Northwest University Xi'an", id: 1267 },
  { code: "CN", name: "Ocean University of China", id: 1268 },
  { code: "CN", name: "Panzhihua University", id: 1269 },
  { code: "CN", name: "Peking University", id: 1270 },
  { code: "CN", name: "Qingdao University", id: 1271 },
  { code: "CN", name: "Qingdao University of Science and Technology", id: 1272 },
  { code: "CN", name: "Qinghai Normal University", id: 1273 },
  { code: "CN", name: "Qinghai Radio & Television University", id: 1274 },
  { code: "CN", name: "Qinghai University", id: 1275 },
  { code: "CN", name: "Qiongzhou University", id: 1276 },
  { code: "CN", name: "Qufu Normal University", id: 1277 },
  { code: "CN", name: "Renmin University of China", id: 1278 },
  { code: "CN", name: "Shaanxi Normal University", id: 1279 },
  { code: "CN", name: "Shandong Agricultural University", id: 1280 },
  { code: "CN", name: "Shandong Economic University", id: 1281 },
  { code: "CN", name: "Shandong Medical University", id: 1282 },
  { code: "CN", name: "Shandong Normal University", id: 1283 },
  { code: "CN", name: "Shandong University", id: 1284 },
  { code: "CN", name: "Shandong University of Art and Design", id: 1285 },
  { code: "CN", name: "Shandong University of Science and Technology", id: 1286 },
  { code: "CN", name: "Shandong University of Technology", id: 1287 },
  { code: "CN", name: "Shandong University of Triaditional Chinese Medicine", id: 1288 },
  { code: "CN", name: "Shanghai Business School", id: 1289 },
  { code: "CN", name: "Shanghai City College", id: 1290 },
  { code: "CN", name: "Shanghai Customs College", id: 1291 },
  { code: "CN", name: "Shanghai Dainji University", id: 1292 },
  { code: "CN", name: "Shanghai Institue of Foreign Trade", id: 1293 },
  { code: "CN", name: "Shanghai International Studies University", id: 1294 },
  { code: "CN", name: "Shanghai Jiaotong University", id: 1295 },
  { code: "CN", name: "Shanghai Lida Polytechnic Institute", id: 1296 },
  { code: "CN", name: "Shanghai Lixin University of Commerce", id: 1297 },
  { code: "CN", name: "Shanghai Maritime University", id: 1298 },
  { code: "CN", name: "Shanghai Medical University", id: 1299 },
  { code: "CN", name: "Shanghai Normal University", id: 1300 },
  { code: "CN", name: "Shanghai Ocean University", id: 1301 },
  { code: "CN", name: "Shanghai Ouhua Vocational Technical College", id: 1302 },
  { code: "CN", name: "Shanghai Sanda University", id: 1303 },
  { code: "CN", name: "Shanghai Second Medical University", id: 1304 },
  { code: "CN", name: "Shanghai Second Polytechnic University", id: 1305 },
  { code: "CN", name: "Shanghai Sipo Polytechnic", id: 1306 },
  { code: "CN", name: "Shanghai Television University", id: 1307 },
  { code: "CN", name: "Shanghai TieDao University", id: 1308 },
  { code: "CN", name: "Shanghai University", id: 1309 },
  { code: "CN", name: "Shanghai University of Engineering Science", id: 1310 },
  { code: "CN", name: "Shanghai University of Finance and Economics", id: 1311 },
  { code: "CN", name: "Shanghai University of Science and Technology", id: 1312 },
  { code: "CN", name: "Shanghai University of Traditional Chinese Medicine and Pharmacology", id: 1313 },
  { code: "CN", name: "Shantou University", id: 1314 },
  { code: "CN", name: "Shanxi Agricultural University", id: 1315 },
  { code: "CN", name: "Shanxi Normal University", id: 1316 },
  { code: "CN", name: "Shanxi University", id: 1317 },
  { code: "CN", name: "Shaoguan University", id: 1318 },
  { code: "CN", name: "Shenyang Institute of Chemical Technology", id: 1319 },
  { code: "CN", name: "Shenyang Jianzhu University", id: 1320 },
  { code: "CN", name: "Shenyang Pharmaceutical University", id: 1321 },
  { code: "CN", name: "Shenyang Polytechnic University", id: 1322 },
  { code: "CN", name: "Shenyang University", id: 1323 },
  { code: "CN", name: "Shenzhen University", id: 1324 },
  { code: "CN", name: "Shihezi University ", id: 1325 },
  { code: "CN", name: "Sichuan Agricultural University", id: 1326 },
  { code: "CN", name: "Sichuan Fine Art Institute", id: 1327 },
  { code: "CN", name: "Sichuan International Studies University", id: 1328 },
  { code: "CN", name: "Sichuan Normal University", id: 1329 },
  { code: "CN", name: "Sichuan University", id: 1330 },
  { code: "CN", name: "South China Agricultural University", id: 1331 },
  { code: "CN", name: "South China Construction University", id: 1332 },
  { code: "CN", name: "South China Normal University", id: 1333 },
  { code: "CN", name: "South China University", id: 1334 },
  { code: "CN", name: "South China University of Technology", id: 1335 },
  { code: "CN", name: "Southeast University", id: 1336 },
  { code: "CN", name: "Southern Medial University", id: 1337 },
  { code: "CN", name: "Southern Yangtze University", id: 1338 },
  { code: "CN", name: "South University of Science and Technology of China ", id: 1339 },
  { code: "CN", name: "Southwest Agricultural University", id: 1340 },
  { code: "CN", name: "Southwest Forestry University", id: 1341 },
  { code: "CN", name: "Southwest Jiaotong University", id: 1342 },
  { code: "CN", name: "Southwest Petroleum University", id: 1343 },
  { code: "CN", name: "Southwest University", id: 1344 },
  { code: "CN", name: "Southwest University of Finance and Economics", id: 1345 },
  { code: "CN", name: "Southwest University of Nationalities", id: 1346 },
  { code: "CN", name: "Southwest University of Political Science and Law", id: 1347 },
  { code: "CN", name: "Southwest University of Science and Technology", id: 1348 },
  { code: "CN", name: "South West University Yucai College", id: 1349 },
  { code: "CN", name: "Sun Yat-Sen University of Medical Sciences", id: 1350 },
  { code: "CN", name: "Suzhou University", id: 1351 },
  { code: "CN", name: "Taiyuan University of Technology", id: 1352 },
  { code: "CN", name: "Taizhou College", id: 1353 },
  { code: "CN", name: "Tarim University", id: 1354 },
  { code: "CN", name: "The University of Nottingham Ningbo China", id: 1355 },
  { code: "CN", name: "Tianjin Academy of Fine Art", id: 1356 },
  { code: "CN", name: "Tianjin Medical University", id: 1357 },
  { code: "CN", name: "Tianjin Normal University", id: 1358 },
  { code: "CN", name: "Tianjin Open University", id: 1359 },
  { code: "CN", name: "Tianjin Polytechnic University", id: 1360 },
  { code: "CN", name: "Tianjin University", id: 1361 },
  { code: "CN", name: "Tianjin University of Commerce", id: 1362 },
  { code: "CN", name: "Tianjin University of Finance & Economics", id: 1363 },
  { code: "CN", name: "Tianjin University of Technology", id: 1364 },
  { code: "CN", name: "Tibet Agricultural and Animal Husbandry College", id: 1365 },
  { code: "CN", name: "Tibet Tibetan Medical College", id: 1366 },
  { code: "CN", name: "Tibet University", id: 1367 },
  { code: "CN", name: "Tibet University of Nationalities", id: 1368 },
  { code: "CN", name: "Tongji Medical University", id: 1369 },
  { code: "CN", name: "Tongji University", id: 1370 },
  { code: "CN", name: "Tsinghua University", id: 1371 },
  { code: "CN", name: "University of Electronic Science and Technology of China", id: 1372 },
  { code: "CN", name: "University of Hydraulic Electric Engineering", id: 1373 },
  { code: "CN", name: "University of International Business and Economics", id: 1374 },
  { code: "CN", name: "University of Jinan", id: 1375 },
  { code: "CN", name: "University of Petroleum (East China)", id: 1376 },
  { code: "CN", name: "University of Science and Technology Beijing", id: 1377 },
  { code: "CN", name: "University of Science and Technology of China", id: 1378 },
  { code: "CN", name: "Urumqi Vocational University", id: 1379 },
  { code: "CN", name: "Wenzhou Medical College", id: 1380 },
  { code: "CN", name: "Wenzhou University", id: 1381 },
  { code: "CN", name: "West China University of Medical Sciences", id: 1382 },
  { code: "CN", name: "Wudanshan Taoist College ", id: 1383 },
  { code: "CN", name: "Wuhan Automobile Polytechnical University", id: 1384 },
  { code: "CN", name: "Wuhan Technical University of Surveying and Mapping", id: 1385 },
  { code: "CN", name: "Wuhan Transportation University", id: 1386 },
  { code: "CN", name: "Wuhan University", id: 1387 },
  { code: "CN", name: "Wuhan University of Hydraulic and Electric Engineering", id: 1388 },
  { code: "CN", name: "Wuhan University of Technology", id: 1389 },
  { code: "CN", name: "Wuhan University School of Medicine", id: 1390 },
  { code: "CN", name: "Wuxi University of Light Industry", id: 1391 },
  { code: "CN", name: "Wuyi University", id: 1392 },
  { code: "CN", name: "Xiamen University", id: 1393 },
  { code: "CN", name: "Xi'an Academy of Fine Art", id: 1394 },
  { code: "CN", name: "Xiangtan Normal University", id: 1395 },
  { code: "CN", name: "Xiangtan University", id: 1396 },
  { code: "CN", name: "Xi'an Highway University", id: 1397 },
  { code: "CN", name: "Xi'an International Studies University", id: 1398 },
  { code: "CN", name: "Xi'an Jiaotong-Liverpool University", id: 1399 },
  { code: "CN", name: "Xi'an Jiaotong University", id: 1400 },
  { code: "CN", name: "Xi'an University of Architecture and Technology", id: 1401 },
  { code: "CN", name: "Xi'an University of Electronic Science and Technology", id: 1402 },
  { code: "CN", name: "Xi'an University of Technology", id: 1403 },
  { code: "CN", name: "Xihua University", id: 1404 },
  { code: "CN", name: "Xijiang University", id: 1405 },
  { code: "CN", name: "Xinghai Conservatory of Music", id: 1406 },
  { code: "CN", name: "Xinjiang Agriculture University", id: 1407 },
  { code: "CN", name: "Xinjiang Normal University", id: 1408 },
  { code: "CN", name: "Xinjiang University", id: 1409 },
  { code: "CN", name: "Xinjiang University of Finance and Economics", id: 1410 },
  { code: "CN", name: "Xuzhou Normal University", id: 1411 },
  { code: "CN", name: "Yan'an University", id: 1412 },
  { code: "CN", name: "Yanbian University", id: 1413 },
  { code: "CN", name: "Yanbian University of Science and Technology", id: 1414 },
  { code: "CN", name: "Yangtze Normal University", id: 1415 },
  { code: "CN", name: "Yangtze University", id: 1416 },
  { code: "CN", name: "Yangzhou University", id: 1417 },
  { code: "CN", name: "Yan Shan University", id: 1418 },
  { code: "CN", name: "Yantai Education Institute & Yantai Television University", id: 1419 },
  { code: "CN", name: "Yantai University", id: 1420 },
  { code: "CN", name: "Yunnan Agriculture University", id: 1421 },
  { code: "CN", name: "Yunnan Normal University", id: 1422 },
  { code: "CN", name: "Yunnan University", id: 1423 },
  { code: "CN", name: "Zhanjiang Ocean University", id: 1424 },
  { code: "CN", name: "Zhejiang Forestry University", id: 1425 },
  { code: "CN", name: "Zhejiang Gongshang University", id: 1426 },
  { code: "CN", name: "Zhejiang Normal University", id: 1427 },
  { code: "CN", name: "Zhejiang Sci-Tech University ", id: 1428 },
  { code: "CN", name: "Zhejiang University", id: 1429 },
  { code: "CN", name: "Zhejiang University of Technology", id: 1430 },
  { code: "CN", name: "Zhengda Software College", id: 1431 },
  { code: "CN", name: "Zhengzhou Grain University", id: 1432 },
  { code: "CN", name: "Zhengzhou University of Technology", id: 1433 },
  { code: "CN", name: "Zhongnan University of Economics and Law", id: 1434 },
  { code: "CN", name: "Zhongnan University of Finance and Economics", id: 1435 },
  { code: "CN", name: "Zhongnan University of Technology", id: 1436 },
  { code: "CN", name: "Zhongshan University", id: 1437 },
  { code: "CO", name: "Centro de Estudios Investigación y Tecnología (CEIT)", id: 1438 },
  { code: "CO", name: "Colegio de Estudios Superiores de Administración (CESA)", id: 1439 },
  { code: "CO", name: "Conservatorio del Tolima", id: 1440 },
  { code: "CO", name: "Corporación Educativa Mayor del Desarrollo Simón Bolivar", id: 1441 },
  { code: "CO", name: "Corporación Universitaria de Ibagué (CORUNIVERSITARIA)", id: 1442 },
  { code: "CO", name: "Corporación Universitaria de la Costa (UNICOSTA)", id: 1443 },
  { code: "CO", name: "Corporación Universitaria de Santander (UDES)", id: 1444 },
  { code: "CO", name: "Corporación Universitaria Tecnológica de Bolivar", id: 1445 },
  { code: "CO", name: "Escuela Colombiana de Carreras Industriales", id: 1446 },
  { code: "CO", name: "Escuela Colombiana de Ingeniería Julio Garavito", id: 1447 },
  { code: "CO", name: "Escuela de Administración de Negocios", id: 1448 },
  { code: "CO", name: "Escuela de Ingeniería de Antioquia", id: 1449 },
  { code: "CO", name: "Escuela de Policia General Santander", id: 1450 },
  { code: "CO", name: "Escuela Superiore de Administración Pública", id: 1451 },
  { code: "CO", name: "Fundacion Escuela Colombiana de Rehabiliación", id: 1452 },
  { code: "CO", name: "Fundación Universitaria de Boyacá", id: 1453 },
  { code: "CO", name: "Fundación Universitaria del Area Andina. Sede Pereira", id: 1454 },
  { code: "CO", name: "Fundación Universitaria Luis Amigó", id: 1455 },
  { code: "CO", name: "Fundación Universitaria Manuela Beltrán", id: 1456 },
  { code: "CO", name: "Fundación Universitaria San Martín", id: 1457 },
  { code: "CO", name: "Institucion Universitaria de Envigado", id: 1458 },
  { code: "CO", name: "Institución Universitaria Iberoamericana", id: 1459 },
  { code: "CO", name: "Instituto Tecnológico Metropolitano", id: 1460 },
  { code: "CO", name: "Instituto Universitario de La Paz", id: 1461 },
  { code: "CO", name: "Politécnico Columbiano Jaime Isaza Cadavid", id: 1462 },
  { code: "CO", name: "Politécnico Grancolombiano - Institución Universitaria", id: 1463 },
  { code: "CO", name: "Pontificia Universidad Javeriana", id: 1464 },
  { code: "CO", name: "Universidad Adventista de Colombia", id: 1465 },
  { code: "CO", name: "Universidad Antonio Nariño", id: 1466 },
  { code: "CO", name: "Universidad Autónoma de Bucaramanga", id: 1467 },
  { code: "CO", name: "Universidad Autónoma de Colombia", id: 1468 },
  { code: "CO", name: "Universidad Autónoma de Las Américas", id: 1469 },
  { code: "CO", name: "Universidad Autónoma del Caribe", id: 1470 },
  { code: "CO", name: "Universidad Autónoma de Manizales", id: 1471 },
  { code: "CO", name: "Universidad Autónoma de Occidente", id: 1472 },
  { code: "CO", name: "Universidad Autónoma Latinoamericana", id: 1473 },
  { code: "CO", name: "Universidad Bellas Artes", id: 1474 },
  { code: "CO", name: "Universidad Católica de Colombia", id: 1475 },
  { code: "CO", name: "Universidad Católica del Norte", id: 1476 },
  { code: "CO", name: "Universidad Católica del Oriente", id: 1477 },
  { code: "CO", name: "Universidad Católica de Manizales", id: 1478 },
  { code: "CO", name: "Universidad Católica Popular del Risaralda", id: 1479 },
  { code: "CO", name: "Universidad Central", id: 1480 },
  { code: "CO", name: "Universidad Central del Valle del Cauca", id: 1481 },
  { code: "CO", name: "Universidad CES", id: 1482 },
  { code: "CO", name: "Universidad Cooperativa de Colombia", id: 1483 },
  { code: "CO", name: "Universidad de América Bogotá", id: 1484 },
  { code: "CO", name: "Universidad de Antioquia", id: 1485 },
  { code: "CO", name: "Universidad de Antioquía", id: 1486 },
  { code: "CO", name: "Universidad de Bogotá Jorge Tadeo Lozano", id: 1487 },
  { code: "CO", name: "Universidad de Caldas", id: 1488 },
  { code: "CO", name: "Universidad de Cartagena", id: 1489 },
  { code: "CO", name: "Universidad de Ciencias Aplicadas y Ambientales (UDCA)", id: 1490 },
  { code: "CO", name: "Universidad de Córdoba", id: 1491 },
  { code: "CO", name: "Universidad de La Amazonia", id: 1492 },
  { code: "CO", name: "Universidad de La Guajira", id: 1493 },
  { code: "CO", name: "Universidad de La Sabana", id: 1494 },
  { code: "CO", name: "Universidad de La Salle", id: 1495 },
  { code: "CO", name: "Universidad del Atlántico", id: 1496 },
  { code: "CO", name: "Universidad del Cauca", id: 1497 },
  { code: "CO", name: "Universidad del Magdalena", id: 1498 },
  { code: "CO", name: "Universidad del Norte", id: 1499 },
  { code: "CO", name: "Universidad de Los Andes", id: 1500 },
  { code: "CO", name: "Universidad de Los Llanos", id: 1501 },
  { code: "CO", name: "Universidad del Quindío", id: 1502 },
  { code: "CO", name: "Universidad del Rosario", id: 1503 },
  { code: "CO", name: "Universidad del Sinú", id: 1504 },
  { code: "CO", name: "Universidad del Tolima", id: 1505 },
  { code: "CO", name: "Universidad del Valle del Cauca", id: 1506 },
  { code: "CO", name: "Universidad de Manizales", id: 1507 },
  { code: "CO", name: "Universidad de Medellín", id: 1508 },
  { code: "CO", name: "Universidad de Nariño", id: 1509 },
  { code: "CO", name: "Universidad de Pamplona", id: 1510 },
  { code: "CO", name: "Universidad de San Buenaventura", id: 1511 },
  { code: "CO", name: "Universidad de San Buenaventura Medellin", id: 1512 },
  { code: "CO", name: "Universidad de Sucre", id: 1513 },
  { code: "CO", name: "Universidad Distral Francisco José de Caldas", id: 1514 },
  { code: "CO", name: "Universidad EAFIT", id: 1515 },
  { code: "CO", name: "Universidad El Bosque", id: 1516 },
  { code: "CO", name: "Universidad Externado de Colombia", id: 1517 },
  { code: "CO", name: "Universidad Francisco de Paula Santander", id: 1518 },
  { code: "CO", name: "Universidad ICESI", id: 1519 },
  { code: "CO", name: "Universidad INCCA de Colombia", id: 1520 },
  { code: "CO", name: "Universidad Industrial de Santander", id: 1521 },
  { code: "CO", name: "Universidad La Gran Colombia", id: 1522 },
  { code: "CO", name: "Universidad Libre de Colombia", id: 1523 },
  { code: "CO", name: "Universidad Mariana", id: 1524 },
  { code: "CO", name: "Universidad Metropolitana", id: 1525 },
  { code: "CO", name: "Universidad Militar Nueva Granada", id: 1526 },
  { code: "CO", name: "Universidad Nacional Abierta y a Distancia", id: 1527 },
  { code: "CO", name: "Universidad Nacional de Colombia", id: 1528 },
  { code: "CO", name: "Universidad Pedagogica Nacional", id: 1529 },
  { code: "CO", name: "Universidad Pedagógica y Tecnológica de Colombia", id: 1530 },
  { code: "CO", name: "Universidad Piloto de Colombia", id: 1531 },
  { code: "CO", name: "Universidad Pontificia Bolivariana", id: 1532 },
  { code: "CO", name: "Universidad Popular del Cesar", id: 1533 },
  { code: "CO", name: "Universidad Santiago de Cali", id: 1534 },
  { code: "CO", name: "Universidad Santo Tomás", id: 1535 },
  { code: "CO", name: "Universidad Sergio Arboleda", id: 1536 },
  { code: "CO", name: "Universidad Surcolombiana", id: 1537 },
  { code: "CO", name: "Universidad Tecnológica del Choco Diego Luis Cordoba", id: 1538 },
  { code: "CO", name: "Universidad Tecnológica de Pereira", id: 1539 },
  { code: "CR", name: "Instituto Tecnológico de Costa Rica", id: 1540 },
  { code: "CR", name: "Universidad Adventista de Centro América", id: 1541 },
  { code: "CR", name: "Universidad Autónoma de Centro América", id: 1542 },
  { code: "CR", name: "Universidad Autonoma Monterrey", id: 1543 },
  { code: "CR", name: "Universidad Braulio Carrillo", id: 1544 },
  { code: "CR", name: "Universidad Católica Anselmo Llorente", id: 1545 },
  { code: "CR", name: "Universidad Central", id: 1546 },
  { code: "CR", name: "Universidad de Cartago Florencio del Castillo", id: 1547 },
  { code: "CR", name: "Universidad de Costa Rica", id: 1548 },
  { code: "CR", name: "Universidad de Iberoamérica", id: 1549 },
  { code: "CR", name: "Universidad de La Salle", id: 1550 },
  { code: "CR", name: "Universidad del Turismo", id: 1551 },
  { code: "CR", name: "Universidad de San José", id: 1552 },
  { code: "CR", name: "Universidad Empresarial", id: 1553 },
  { code: "CR", name: "Universidad Estatal a Distancia", id: 1554 },
  { code: "CR", name: "Universidad Fidélitas", id: 1555 },
  { code: "CR", name: "Universidad Fundepos Alma Mater", id: 1556 },
  { code: "CR", name: "Universidad Hispanoamericana", id: 1557 },
  { code: "CR", name: "Universidad Internacional de las Américas", id: 1558 },
  { code: "CR", name: "Universidad Isaac Newton", id: 1559 },
  { code: "CR", name: "Universidad Latina de Costa Rica", id: 1560 },
  { code: "CR", name: "Universidad Latinoamericana de Ciencia y Tecnología", id: 1561 },
  { code: "CR", name: "Universidad Libre de Costa Rica", id: 1562 },
  { code: "CR", name: "Universidad Metropolitana Castro Carazo", id: 1563 },
  { code: "CR", name: "Universidad Nacional", id: 1564 },
  { code: "CR", name: "Universidad para la Cooperación Internacional", id: 1565 },
  { code: "CR", name: "Universidad San Juan de la Cruz", id: 1566 },
  { code: "CR", name: "Universidad San Marcos", id: 1567 },
  { code: "CR", name: "Universidad Santa Paula", id: 1568 },
  { code: "CR", name: "Universidad Veritas", id: 1569 },
  { code: "CR", name: "University for Peace", id: 1570 },
  { code: "CU", name: "Instituto Superior Minero Metalúrgico Dr. Antonio Núñez Jiménez", id: 1571 },
  { code: "CU", name: "Instituto Superior Politécnico José Antonio Echeverría", id: 1572 },
  { code: "CU", name: "Universidad Central de Las Villas", id: 1573 },
  { code: "CU", name: "Universidad de Camagüey", id: 1574 },
  { code: "CU", name: "Universidad de Ciego de Avila", id: 1575 },
  { code: "CU", name: "Universidad de Cienfuegos", id: 1576 },
  { code: "CU", name: "Universidad de Granma", id: 1577 },
  { code: "CU", name: "Universidad de Holguín", id: 1578 },
  { code: "CU", name: "Universidad de La Habana", id: 1579 },
  { code: "CU", name: "Universidad de Matanzas Camilo Cienfuegos", id: 1580 },
  { code: "CU", name: "Universidad de Oriente", id: 1581 },
  { code: "CU", name: "Universidad de Pinar del Río", id: 1582 },
  { code: "CU", name: "Universidad Pedagógica José Martí", id: 1583 },
  { code: "CV", name: "Universidade Jean Piaget de Cabo Verde", id: 1584 },
  { code: "CY", name: "Americanos College", id: 1585 },
  { code: "CY", name: "Cyprus College", id: 1586 },
  { code: "CY", name: "Cyprus International Institute of Management (CIIM)", id: 1587 },
  { code: "CY", name: "Cyprus International University", id: 1588 },
  { code: "CY", name: "Cyprus University of Technology", id: 1589 },
  { code: "CY", name: "Eastern Mediterranean University", id: 1590 },
  { code: "CY", name: "European University Cyprus", id: 1591 },
  { code: "CY", name: "European University of Lefke", id: 1592 },
  { code: "CY", name: "Frederick University", id: 1593 },
  { code: "CY", name: "Girne American University", id: 1594 },
  { code: "CY", name: "Intercollege", id: 1595 },
  { code: "CY", name: "Near East University", id: 1596 },
  { code: "CY", name: "Open University of Cyprus", id: 1597 },
  { code: "CY", name: "The CTL Eurocollege", id: 1598 },
  { code: "CY", name: "University of Cyprus", id: 1599 },
  { code: "CY", name: "University of Nicosia", id: 1600 },
  { code: "CZ", name: "Academy of Performing Arts", id: 1601 },
  { code: "CZ", name: "Anglo-American University", id: 1602 },
  { code: "CZ", name: "Brno University of Technology", id: 1603 },
  { code: "CZ", name: "Cevro Institut College", id: 1604 },
  { code: "CZ", name: "Charles University Prague", id: 1605 },
  { code: "CZ", name: "Czech Technical University of Prague", id: 1606 },
  { code: "CZ", name: "Czech University of Agriculture Prague", id: 1607 },
  { code: "CZ", name: "Masaryk University", id: 1608 },
  { code: "CZ", name: "Mendel University of Agriculture and Forestry", id: 1609 },
  { code: "CZ", name: "Palacky University", id: 1610 },
  { code: "CZ", name: "Prague College", id: 1611 },
  { code: "CZ", name: "Prague Institute of Chemical Technology", id: 1612 },
  { code: "CZ", name: "Prague International University", id: 1613 },
  { code: "CZ", name: "Silesian University", id: 1614 },
  { code: "CZ", name: "Technical University of Liberec", id: 1615 },
  { code: "CZ", name: "Technical University of Mining and Metallurgy Ostrava", id: 1616 },
  { code: "CZ", name: "Tiffin University Prague", id: 1617 },
  { code: "CZ", name: "Tomas Bata University in Zlin", id: 1618 },
  { code: "CZ", name: "University of Economics Prague", id: 1619 },
  { code: "CZ", name: "University of Education Hradec Kralove", id: 1620 },
  { code: "CZ", name: "University of Jan Evangelista Purkyne", id: 1621 },
  { code: "CZ", name: "University of New York in Prague", id: 1622 },
  { code: "CZ", name: "University of Northern Virginia", id: 1623 },
  { code: "CZ", name: "University of Ostrava", id: 1624 },
  { code: "CZ", name: "University of Pardubice", id: 1625 },
  { code: "CZ", name: "University of South Bohemia", id: 1626 },
  { code: "CZ", name: "University of Veterinary and Pharmaceutical Science", id: 1627 },
  { code: "CZ", name: "University of West Bohemia", id: 1628 },
  { code: "DE", name: "AKAD Hochschulen für Berufstätige", id: 1629 },
  { code: "DE", name: "Albert-Ludwigs-Universität Freiburg", id: 1630 },
  { code: "DE", name: "Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin", id: 1631 },
  { code: "DE", name: "Augustana Hochschule Neuendettelsau", id: 1632 },
  { code: "DE", name: "Bauhaus Universität Weimar", id: 1633 },
  { code: "DE", name: "Bayerische Julius-Maximilians-Universität Würzburg", id: 1634 },
  { code: "DE", name: "Bergische Universität Gesamthochschule Wuppertal", id: 1635 },
  { code: "DE", name: "BiTS - Business and Information Technology School gGmbH", id: 1636 },
  { code: "DE", name: "Brandenburgische Technische Universität Cottbus", id: 1637 },
  { code: "DE", name: "Carl von Ossietzky Universität Oldenburg", id: 1638 },
  { code: "DE", name: "Christian-Albrechts-Universität Kiel", id: 1639 },
  { code: "DE", name: "Cologne Business School", id: 1640 },
  { code: "DE", name: "Deutsche Hochschule für Verwaltungswissenschaften Speyer", id: 1641 },
  { code: "DE", name: "Deutsche Sporthochschule Köln", id: 1642 },
  { code: "DE", name: "Deutsche Telekom Fachhochschule Leipzig", id: 1643 },
  { code: "DE", name: "Deutsch-Ordens Fachhochschule Riedlingen", id: 1644 },
  { code: "DE", name: "DIPLOMA-Fachhochschule Ölsnitz/Vogtland", id: 1645 },
  { code: "DE", name: "Duale Hochschule Baden-Württemberg", id: 1646 },
  { code: "DE", name: "E.A.P. Europäische Wirtschaftshochschule Berlin", id: 1647 },
  { code: "DE", name: "Eberhard-Karls-Universität Tübingen", id: 1648 },
  { code: "DE", name: "Ernst-Moritz-Arndt Universität Greifswald", id: 1649 },
  { code: "DE", name: "Europa Fachhochschule Fresenius", id: 1650 },
  { code: "DE", name: "Europäische Betriebswirtschafts-Akademie", id: 1651 },
  { code: "DE", name: "Europäische Fachhochschule", id: 1652 },
  { code: "DE", name: "Europa-Universität Viadrina Frankfurt (Oder)", id: 1653 },
  { code: "DE", name: "European Business School Schloß Reichartshausen", id: 1654 },
  { code: "DE", name: "European College of Liberal Arts", id: 1655 },
  { code: "DE", name: "European Management School", id: 1656 },
  { code: "DE", name: "Evangelische Fachhochschule Berlin", id: 1657 },
  { code: "DE", name: "Evangelische Fachhochschule Darmstadt", id: 1658 },
  { code: "DE", name: "Evangelische Fachhochschule Freiburg", id: 1659 },
  { code: "DE", name: "Evangelische Fachhochschule für Religionspädagogik", id: 1660 },
  { code: "DE", name: "Evangelische Fachhochschule für Sozialpädagogik der Diakonenanstalt des Rauhen Hauses Hamburg", id: 1661 },
  { code: "DE", name: "Evangelische Fachhochschule Hannover", id: 1662 },
  { code: "DE", name: "Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen", id: 1663 },
  { code: "DE", name: "Evangelische Fachhochschule Nürnberg", id: 1664 },
  { code: "DE", name: "Evangelische Fachhochschule Reutlingen-Ludwigsburg", id: 1665 },
  { code: "DE", name: "Evangelische Fachhochschule Rheinland-Westfalen-Lippe", id: 1666 },
  { code: "DE", name: "Evangelische Hochschule für Soziale Arbeit Dresden (FH)", id: 1667 },
  { code: "DE", name: "Fachhochschule Aachen", id: 1668 },
  { code: "DE", name: "Fachhochschule Aschaffenburg", id: 1669 },
  { code: "DE", name: "Fachhochschule Augsburg", id: 1670 },
  { code: "DE", name: "Fachhochschule Biberach", id: 1671 },
  { code: "DE", name: "Fachhochschule Bielefeld", id: 1672 },
  { code: "DE", name: "Fachhochschule Bingen", id: 1673 },
  { code: "DE", name: "Fachhochschule Bochum", id: 1674 },
  { code: "DE", name: "Fachhochschule Bonn-Rhein-Sieg", id: 1675 },
  { code: "DE", name: "Fachhochschule Brandenburg", id: 1676 },
  { code: "DE", name: "Fachhochschule Braunschweig/Wolfenbüttel", id: 1677 },
  { code: "DE", name: "Fachhochschule Deggendorf", id: 1678 },
  { code: "DE", name: "Fachhochschule der Wirtschaft", id: 1679 },
  { code: "DE", name: "Fachhochschule des Mittelstandes (FHM)", id: 1680 },
  { code: "DE", name: "Fachhochschule Dortmund", id: 1681 },
  { code: "DE", name: "Fachhochschule Düsseldorf", id: 1682 },
  { code: "DE", name: "Fachhochschule Eberswalde", id: 1683 },
  { code: "DE", name: "Fachhochschule Erfurt", id: 1684 },
  { code: "DE", name: "Fachhochschule Flensburg", id: 1685 },
  { code: "DE", name: "Fachhochschule Frankfurt am Main", id: 1686 },
  { code: "DE", name: "Fachhochschule für das öffentliche Bibliothekswesen Bonn", id: 1687 },
  { code: "DE", name: "Fachhochschule für die Wirtschaft", id: 1688 },
  { code: "DE", name: "Fachhochschule für Oekonomie und Management (FOM)", id: 1689 },
  { code: "DE", name: "Fachhochschule für Technik und Wirtschaft Berlin", id: 1690 },
  { code: "DE", name: "Fachhochschule Furtwangen", id: 1691 },
  { code: "DE", name: "Fachhochschule für Verwaltung und Rechtspflege Berlin", id: 1692 },
  { code: "DE", name: "Fachhochschule für Wirtschaft Berlin", id: 1693 },
  { code: "DE", name: "Fachhochschule Gelsenkirchen", id: 1694 },
  { code: "DE", name: "Fachhochschule Gießen-Friedberg", id: 1695 },
  { code: "DE", name: "Fachhochschule Hamburg", id: 1696 },
  { code: "DE", name: "Fachhochschule Hannover", id: 1697 },
  { code: "DE", name: "Fachhochschule Heidelberg", id: 1698 },
  { code: "DE", name: "Fachhochschule Heilbronn", id: 1699 },
  { code: "DE", name: "Fachhochschule Hildesheim/Holzminden/Göttingen", id: 1700 },
  { code: "DE", name: "Fachhochschule Hof", id: 1701 },
  { code: "DE", name: "Fachhochschule Ingolstadt", id: 1702 },
  { code: "DE", name: "Fachhochschule Jena", id: 1703 },
  { code: "DE", name: "Fachhochschule Kaiserslautern", id: 1704 },
  { code: "DE", name: "Fachhochschule Kempten", id: 1705 },
  { code: "DE", name: "Fachhochschule Kiel", id: 1706 },
  { code: "DE", name: "Fachhochschule Koblenz", id: 1707 },
  { code: "DE", name: "Fachhochschule Köln", id: 1708 },
  { code: "DE", name: "Fachhochschule Konstanz", id: 1709 },
  { code: "DE", name: "Fachhochschule Landshut", id: 1710 },
  { code: "DE", name: "Fachhochschule Lausitz", id: 1711 },
  { code: "DE", name: "Fachhochschule Lippe", id: 1712 },
  { code: "DE", name: "Fachhochschule Lübeck", id: 1713 },
  { code: "DE", name: "Fachhochschule Ludwigshafen", id: 1714 },
  { code: "DE", name: "Fachhochschule Mainz", id: 1715 },
  { code: "DE", name: "Fachhochschule Mannheim", id: 1716 },
  { code: "DE", name: "Fachhochschule Merseburg", id: 1717 },
  { code: "DE", name: "Fachhochschule München", id: 1718 },
  { code: "DE", name: "Fachhochschule Münster", id: 1719 },
  { code: "DE", name: "Fachhochschule Neubrandenburg", id: 1720 },
  { code: "DE", name: "Fachhochschule Neu-Ulm", id: 1721 },
  { code: "DE", name: "Fachhochschule Niederrhein", id: 1722 },
  { code: "DE", name: "Fachhochschule Nordhausen", id: 1723 },
  { code: "DE", name: "Fachhochschule Nordhessen", id: 1724 },
  { code: "DE", name: "Fachhochschule Nordostniedersachsen", id: 1725 },
  { code: "DE", name: "Fachhochschule Nürtingen", id: 1726 },
  { code: "DE", name: "Fachhochschule Offenburg", id: 1727 },
  { code: "DE", name: "Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven", id: 1728 },
  { code: "DE", name: "Fachhochschule Osnabrück", id: 1729 },
  { code: "DE", name: "Fachhochschule Pforzheim", id: 1730 },
  { code: "DE", name: "Fachhochschule Potsdam", id: 1731 },
  { code: "DE", name: "Fachhochschule Ravensburg-Weingarten", id: 1732 },
  { code: "DE", name: "Fachhochschule Regensburg", id: 1733 },
  { code: "DE", name: "Fachhochschule Reutlingen", id: 1734 },
  { code: "DE", name: "Fachhochschule Rosenheim", id: 1735 },
  { code: "DE", name: "Fachhochschule Rottenburg", id: 1736 },
  { code: "DE", name: "Fachhochschule Schmalkalden", id: 1737 },
  { code: "DE", name: "Fachhochschule Schwäbisch Gmünd", id: 1738 },
  { code: "DE", name: "Fachhochschule Schwäbisch Hall", id: 1739 },
  { code: "DE", name: "Fachhochschule Stralsund", id: 1740 },
  { code: "DE", name: "Fachhochschule Stuttgart", id: 1741 },
  { code: "DE", name: "Fachhochschule Trier", id: 1742 },
  { code: "DE", name: "Fachhochschule Ulm", id: 1743 },
  { code: "DE", name: "Fachhochschule und Berufskollegs NTA", id: 1744 },
  { code: "DE", name: "Fachhochschule Wedel", id: 1745 },
  { code: "DE", name: "Fachhochschule Weihenstephan", id: 1746 },
  { code: "DE", name: "Fachhochschule Westküste", id: 1747 },
  { code: "DE", name: "Fachhochschule Wiesbaden", id: 1748 },
  { code: "DE", name: "Fachhochschule Worms", id: 1749 },
  { code: "DE", name: "Fachhochschule Würzburg - Schweinfurt", id: 1750 },
  { code: "DE", name: "Fern-Fachhochschule Hamburg", id: 1751 },
  { code: "DE", name: "Fernuniversität Gesamthochschule Hagen", id: 1752 },
  { code: "DE", name: "Freie Kunst-Studienstätte Ottersberg", id: 1753 },
  { code: "DE", name: "Freie Universität Berlin", id: 1754 },
  { code: "DE", name: "Friedrich-Alexander Universität Erlangen-Nürnberg", id: 1755 },
  { code: "DE", name: "Friedrich-Schiller Universität Jena", id: 1756 },
  { code: "DE", name: "Georg-August Universität Göttingen", id: 1757 },
  { code: "DE", name: "Georg-Simon-Ohm-Fachhochschule Nürnberg", id: 1758 },
  { code: "DE", name: "Gustav-Siewerth-Akademie", id: 1759 },
  { code: "DE", name: "Hafencity Universität Hamburg", id: 1760 },
  { code: "DE", name: "Handelshochschule Leipzig", id: 1761 },
  { code: "DE", name: "Heinrich-Heine Universität Düsseldorf", id: 1762 },
  { code: "DE", name: "Hertie School of Governance", id: 1763 },
  { code: "DE", name: "Hochschule Albstadt-Sigmaringen", id: 1764 },
  { code: "DE", name: "Hochschule Anhalt (FH)", id: 1765 },
  { code: "DE", name: "Hochschule Bremen", id: 1766 },
  { code: "DE", name: "Hochschule Bremerhaven", id: 1767 },
  { code: "DE", name: "Hochschule Coburg", id: 1768 },
  { code: "DE", name: "Hochschule Darmstadt", id: 1769 },
  { code: "DE", name: "Hochschule Esslingen", id: 1770 },
  { code: "DE", name: "Hochschule Fulda", id: 1771 },
  { code: "DE", name: "Hochschule für Bankwirtschaft (HfB)", id: 1772 },
  { code: "DE", name: "Hochschule für Berufstätige Rendsburg", id: 1773 },
  { code: "DE", name: "Hochschule für Internationales Management", id: 1774 },
  { code: "DE", name: "Hochschule für Jüdische Studien Heidelberg", id: 1775 },
  { code: "DE", name: "Hochschule für Philosophie München", id: 1776 },
  { code: "DE", name: "Hochschule für Politik (HFP)", id: 1777 },
  { code: "DE", name: "Hochschule für Technik und Wirtschaft des Saarlandes", id: 1778 },
  { code: "DE", name: "Hochschule für Technik und Wirtschaft Dresden (FH)", id: 1779 },
  { code: "DE", name: "Hochschule für Technik und Wirtschaft Karlsruhe", id: 1780 },
  { code: "DE", name: "Hochschule für Technik", id: 1781 },
  { code: "DE", name: "Hochschule Harz", id: 1782 },
  { code: "DE", name: "Hochschule Magdeburg-Stendal (FH)", id: 1783 },
  { code: "DE", name: "Hochschule Mittweida (FH)", id: 1784 },
  { code: "DE", name: "Hochschule Vechta", id: 1785 },
  { code: "DE", name: "Hochschule Wismar", id: 1786 },
  { code: "DE", name: "Hochschule Zittau/Görlitz (FH)", id: 1787 },
  { code: "DE", name: "Humboldt Universität Berlin", id: 1788 },
  { code: "DE", name: "HWP - Hamburger Universität für Wirtschaft und Politik", id: 1789 },
  { code: "DE", name: "Internationale Fachhochschule Aalen", id: 1790 },
  { code: "DE", name: "Internationale Fachhochschule Bad Honnef", id: 1791 },
  { code: "DE", name: "Internationales Hochschulinstitut Zittau", id: 1792 },
  { code: "DE", name: "International School of Management ISM Dortmund", id: 1793 },
  { code: "DE", name: "International School of New Media", id: 1794 },
  { code: "DE", name: "International University in Germany", id: 1795 },
  { code: "DE", name: "Jacobs University Bremen", id: 1796 },
  { code: "DE", name: "Johannes-Gutenberg Universität Mainz", id: 1797 },
  { code: "DE", name: "Johann Wolfgang Goethe Universität Frankfurt am Main", id: 1798 },
  { code: "DE", name: "Justus Liebig Universität Gießen", id: 1799 },
  { code: "DE", name: "Karlshochschule International University", id: 1800 },
  { code: "DE", name: "Katholische Fachhochschule Berlin (KFB)", id: 1801 },
  { code: "DE", name: "Katholische Fachhochschule Freiburg", id: 1802 },
  { code: "DE", name: "Katholische Fachhochschule Mainz", id: 1803 },
  { code: "DE", name: "Katholische Fachhochschule Norddeutschland", id: 1804 },
  { code: "DE", name: "Katholische Fachhochschule Nordrhein-Westfalen", id: 1805 },
  { code: "DE", name: "Katholische Hochschule für Soziale Arbeit Saarbrücken", id: 1806 },
  { code: "DE", name: "Katholische Stiftungsfachhochschule München", id: 1807 },
  { code: "DE", name: "Katholische Universität Eichstätt", id: 1808 },
  { code: "DE", name: "Kirchliche Hochschule Bethel", id: 1809 },
  { code: "DE", name: "Kirchliche Hochschule Wuppertal", id: 1810 },
  { code: "DE", name: "Kunstakademie Düsseldorf.", id: 1811 },
  { code: "DE", name: "Kunsthochschule Berlin-Weissensee", id: 1812 },
  { code: "DE", name: "Ludwig-Maximilians-Universität München", id: 1813 },
  { code: "DE", name: "Lutherische Theologische Hochschule Oberursel", id: 1814 },
  { code: "DE", name: "Märkische Fachhochschule Iserlohn", id: 1815 },
  { code: "DE", name: "Martin-Luther Universität Halle-Wittenberg", id: 1816 },
  { code: "DE", name: "Medizinische Hochschule Hannover", id: 1817 },
  { code: "DE", name: "Medizinische Universität Lübeck", id: 1818 },
  { code: "DE", name: "Merkur Internationale FH Karlsruhe", id: 1819 },
  { code: "DE", name: "Merz Akademie", id: 1820 },
  { code: "DE", name: "Muthesius-Hochschule", id: 1821 },
  { code: "DE", name: "Nordakademie", id: 1822 },
  { code: "DE", name: "Northern Institute of Technology Management (NIT)", id: 1823 },
  { code: "DE", name: "Otto-Friedrich Universität Bamberg", id: 1824 },
  { code: "DE", name: "Otto-von-Guericke Universität Magdeburg", id: 1825 },
  { code: "DE", name: "Pädagogische Hochschule Erfurt/Mühlhausen", id: 1826 },
  { code: "DE", name: "Pädagogische Hochschule Freiburg", id: 1827 },
  { code: "DE", name: "Pädagogische Hochschule Heidelberg", id: 1828 },
  { code: "DE", name: "Pädagogische Hochschule Karlsruhe", id: 1829 },
  { code: "DE", name: "Pädagogische Hochschule Ludwigsburg", id: 1830 },
  { code: "DE", name: "Pädagogische Hochschule Schwäbisch Gmünd", id: 1831 },
  { code: "DE", name: "Pädagogische Hochschule Weingarten", id: 1832 },
  { code: "DE", name: "Phillips-Universität Marburg", id: 1833 },
  { code: "DE", name: "Philosophisch-Theologische Hochschule der Salesianer Don Boscos", id: 1834 },
  { code: "DE", name: "Philosophisch-Theologische Hochschule Münster", id: 1835 },
  { code: "DE", name: "Philosophisch-Theologische Hochschule Sankt Georgen", id: 1836 },
  { code: "DE", name: "Philosophisch-Theologische Hochschule SVD Sankt Augustin", id: 1837 },
  { code: "DE", name: "Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)", id: 1838 },
  { code: "DE", name: "Philosophisch-Theologisches Studium Erfurt", id: 1839 },
  { code: "DE", name: "Popakademie Baden-Württemberg", id: 1840 },
  { code: "DE", name: "Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz", id: 1841 },
  { code: "DE", name: "Private Fachhochschule Göttingen", id: 1842 },
  { code: "DE", name: "Private FernFachhochschule Darmstadt", id: 1843 },
  { code: "DE", name: "Private Hanseuniversität", id: 1844 },
  { code: "DE", name: "Private Universität Witten/Herdecke", id: 1845 },
  { code: "DE", name: "Rheinische Fachhochschule Köln", id: 1846 },
  { code: "DE", name: "Rheinische Friedrich-Wilhelms Universität Bonn", id: 1847 },
  { code: "DE", name: "Rheinisch Westfälische Technische Hochschule Aachen", id: 1848 },
  { code: "DE", name: "Ruhr-Universität Bochum", id: 1849 },
  { code: "DE", name: "Ruprecht-Karls-Universität Heidelberg", id: 1850 },
  { code: "DE", name: "Schiller International University", id: 1851 },
  { code: "DE", name: "SRH University of Applied Sciences", id: 1852 },
  { code: "DE", name: "Staatlich anerkannte Fachhochschule für Kunsttherapie", id: 1853 },
  { code: "DE", name: "Staatliche Hochschule für Musik", id: 1854 },
  { code: "DE", name: "Steinbeis-Hochschule-Berlin", id: 1855 },
  { code: "DE", name: "Technische Fachhochschule Berlin", id: 1856 },
  { code: "DE", name: "Technische Fachhochschule Georg Agricola für Rohstoff", id: 1857 },
  { code: "DE", name: "Technische Fachhochschule Wildau", id: 1858 },
  { code: "DE", name: "Technische Universität Bergakademie Freiberg", id: 1859 },
  { code: "DE", name: "Technische Universität Berlin", id: 1860 },
  { code: "DE", name: "Technische Universität Carolo-Wilhelmina Braunschweig", id: 1861 },
  { code: "DE", name: "Technische Universität Chemnitz", id: 1862 },
  { code: "DE", name: "Technische Universität Clausthal", id: 1863 },
  { code: "DE", name: "Technische Universität Darmstadt", id: 1864 },
  { code: "DE", name: "Technische Universität Dresden", id: 1865 },
  { code: "DE", name: "Technische Universität Hamburg-Harburg", id: 1866 },
  { code: "DE", name: "Technische Universität Ilmenau", id: 1867 },
  { code: "DE", name: "Technische Universität München", id: 1868 },
  { code: "DE", name: "Theologische Fakultät Paderborn", id: 1869 },
  { code: "DE", name: "Theologische Fakultät Trier", id: 1870 },
  { code: "DE", name: "Theologische Hochschule Friedensau", id: 1871 },
  { code: "DE", name: "Tierärztliche Hochschule Hannover", id: 1872 },
  { code: "DE", name: "Universität Augsburg", id: 1873 },
  { code: "DE", name: "Universität Bayreuth", id: 1874 },
  { code: "DE", name: "Universität Bielefeld", id: 1875 },
  { code: "DE", name: "Universität Bremen", id: 1876 },
  { code: "DE", name: "Universität der Bundeswehr Hamburg", id: 1877 },
  { code: "DE", name: "Universität der Bundeswehr München", id: 1878 },
  { code: "DE", name: "Universität des Saarlandes", id: 1879 },
  { code: "DE", name: "Universität Dortmund", id: 1880 },
  { code: "DE", name: "Universität Duisburg-Essen", id: 1881 },
  { code: "DE", name: "Universität Erfurt", id: 1882 },
  { code: "DE", name: "Universität Flensburg", id: 1883 },
  { code: "DE", name: "Universität Fridericana Karlsruhe (Technische Hochschule)", id: 1884 },
  { code: "DE", name: "Universität Hamburg", id: 1885 },
  { code: "DE", name: "Universität Hannover", id: 1886 },
  { code: "DE", name: "Universität Hildesheim", id: 1887 },
  { code: "DE", name: "Universität Hohenheim", id: 1888 },
  { code: "DE", name: "Universität Kaiserslautern", id: 1889 },
  { code: "DE", name: "Universität Kassel", id: 1890 },
  { code: "DE", name: "Universität Koblenz-Landau", id: 1891 },
  { code: "DE", name: "Universität Köln", id: 1892 },
  { code: "DE", name: "Universität Konstanz", id: 1893 },
  { code: "DE", name: "Universität Leipzig", id: 1894 },
  { code: "DE", name: "Universität Lüneburg", id: 1895 },
  { code: "DE", name: "Universität Mannheim", id: 1896 },
  { code: "DE", name: "Universität Osnabrück", id: 1897 },
  { code: "DE", name: "Universität Paderborn", id: 1898 },
  { code: "DE", name: "Universität Passau", id: 1899 },
  { code: "DE", name: "Universität Potsdam", id: 1900 },
  { code: "DE", name: "Universität Regensburg", id: 1901 },
  { code: "DE", name: "Universität Rostock", id: 1902 },
  { code: "DE", name: "Universität Siegen", id: 1903 },
  { code: "DE", name: "Universität Stuttgart", id: 1904 },
  { code: "DE", name: "Universität Trier", id: 1905 },
  { code: "DE", name: "Universität Ulm", id: 1906 },
  { code: "DE", name: "Westfälische Wilhelms-Universität Münster", id: 1907 },
  { code: "DE", name: "Westsächsische Hochschule Zwickau (FH)", id: 1908 },
  { code: "DE", name: "Wissenschaftliche Hochschule für Unternehmensführung", id: 1909 },
  { code: "DJ", name: "Université de Djibouti", id: 1910 },
  { code: "DK", name: "Aalborg Business College", id: 1911 },
  { code: "DK", name: "Aalborg University", id: 1912 },
  { code: "DK", name: "Aarhus School of Architecture", id: 1913 },
  { code: "DK", name: "Aarhus School of Business", id: 1914 },
  { code: "DK", name: "Aarhus Technical College", id: 1915 },
  { code: "DK", name: "Aarhus University", id: 1916 },
  { code: "DK", name: "Copenhagen Business School", id: 1917 },
  { code: "DK", name: "Copenhagen University", id: 1918 },
  { code: "DK", name: "Danish Business Academy", id: 1919 },
  { code: "DK", name: "Danish University of Education", id: 1920 },
  { code: "DK", name: "Engineering College of Aarhus", id: 1921 },
  { code: "DK", name: "Engineering College of Copenhagen", id: 1922 },
  { code: "DK", name: "International Business School of Scandinavia", id: 1923 },
  { code: "DK", name: "International People's College", id: 1924 },
  { code: "DK", name: "IT University of Copenhagen", id: 1925 },
  { code: "DK", name: "Knightsbridge University", id: 1926 },
  { code: "DK", name: "Lutheran School Of Theology In Aarhus", id: 1927 },
  { code: "DK", name: "Odense University", id: 1928 },
  { code: "DK", name: "Roskilde University", id: 1929 },
  { code: "DK", name: "Royal Academy of Music", id: 1930 },
  { code: "DK", name: "Royal Danish Academy of Fine Arts", id: 1931 },
  { code: "DK", name: "Royal Danish Academy of Music", id: 1932 },
  { code: "DK", name: "Royal Danish School of Educational Sciences", id: 1933 },
  { code: "DK", name: "Royal Danish School of Pharmacy", id: 1934 },
  { code: "DK", name: "Royal School of Library and Information Science", id: 1935 },
  { code: "DK", name: "Royal Veterinary and Agricultural University", id: 1936 },
  { code: "DK", name: "Southern Denmark Business School", id: 1937 },
  { code: "DK", name: "Technical University of Denmark", id: 1938 },
  { code: "DK", name: "Tietgen Business College", id: 1939 },
  { code: "DK", name: "University of Southern Denmark", id: 1940 },
  { code: "DM", name: "Ballsbridge University ", id: 1941 },
  { code: "DM", name: "Dominica State College", id: 1942 },
  { code: "DM", name: "New World University", id: 1943 },
  { code: "DM", name: "Ross University Caribbean School of Medicine", id: 1944 },
  { code: "DO", name: "Instituto Tecnológico de Santo Domingo", id: 1945 },
  { code: "DO", name: "Pontificia Universidad Católica Madre y Maestra (PUCMM)", id: 1946 },
  { code: "DO", name: "Universidad Abierta Para Adultos", id: 1947 },
  { code: "DO", name: "Universidad Adventista Dominicana", id: 1948 },
  { code: "DO", name: "Universidad Agroforestal Fernando A.Meriño", id: 1949 },
  { code: "DO", name: "Universidad APEC", id: 1950 },
  { code: "DO", name: "Universidad Autónoma de Santo Domingo", id: 1951 },
  { code: "DO", name: "Universidad Católica de Santo Domingo", id: 1952 },
  { code: "DO", name: "Universidad Católica Madre y Maestra", id: 1953 },
  { code: "DO", name: "Universidad Católica Nordestana", id: 1954 },
  { code: "DO", name: "Universidad Católica Tecnológica del Cibao", id: 1955 },
  { code: "DO", name: "Universidad Central del Este", id: 1956 },
  { code: "DO", name: "Universidad Central Dominicana de Estudio Profesionales", id: 1957 },
  { code: "DO", name: "Universidad de la Tercera Edad", id: 1958 },
  { code: "DO", name: "Universidad del Caribe", id: 1959 },
  { code: "DO", name: "Universidad Dominicana O&M", id: 1960 },
  { code: "DO", name: "Universidad Dominico-Americana", id: 1961 },
  { code: "DO", name: "Universidad Eugenio Maria de Hostos", id: 1962 },
  { code: "DO", name: "Universidad Experimental Felix Adam", id: 1963 },
  { code: "DO", name: "Universidad Federico Henríquez y Carvajal", id: 1964 },
  { code: "DO", name: "Universidad Iberoamericana", id: 1965 },
  { code: "DO", name: "Universidad Interamericana", id: 1966 },
  { code: "DO", name: "Universidad Nacional Evangélica", id: 1967 },
  { code: "DO", name: "Universidad Nacional Pedro Henríquez Ureña", id: 1968 },
  { code: "DO", name: "Universidad Odontológica Dominicana", id: 1969 },
  { code: "DO", name: "Universidad Tecnológica del Sur", id: 1970 },
  { code: "DO", name: "Universidad Tecnológica de Santiago", id: 1971 },
  { code: "DO", name: "Universidad Ulises Francisco Espaillat", id: 1972 },
  { code: "DZ", name: "Centre Universitaire de Jijel", id: 1973 },
  { code: "DZ", name: "Centre Universitaire de Tebessa", id: 1974 },
  { code: "DZ", name: "Centre Universitaire de Tiaret", id: 1975 },
  { code: "DZ", name: "Centre Universitaire d'Oum El Bouaghi", id: 1976 },
  { code: "DZ", name: "Université Abou Bekr Belkaid", id: 1977 },
  { code: "DZ", name: "Université Amar Telidji", id: 1978 },
  { code: "DZ", name: "Université Badji Mokhtar", id: 1979 },
  { code: "DZ", name: "Université d'Alger", id: 1980 },
  { code: "DZ", name: "Université d'Alger 3", id: 1981 },
  { code: "DZ", name: "Université de Batna", id: 1982 },
  { code: "DZ", name: "Université de Béjaia", id: 1983 },
  { code: "DZ", name: "Université de Biskra", id: 1984 },
  { code: "DZ", name: "Université de Blida", id: 1985 },
  { code: "DZ", name: "Université de Chlef", id: 1986 },
  { code: "DZ", name: "Université de Guelma", id: 1987 },
  { code: "DZ", name: "Université de Mostaganem", id: 1988 },
  { code: "DZ", name: "Université de M'sila", id: 1989 },
  { code: "DZ", name: "Université de Saida", id: 1990 },
  { code: "DZ", name: "Université de Sétif", id: 1991 },
  { code: "DZ", name: "Université de Sidi-Bel-Abbès (Djillali Liabès)", id: 1992 },
  { code: "DZ", name: "Université de Skikda", id: 1993 },
  { code: "DZ", name: "Université des Sciences et de la Technologie d'Oran", id: 1994 },
  { code: "DZ", name: "Université des Sciences et de la Technologie Houari Boumediène", id: 1995 },
  { code: "DZ", name: "Universite des Sciences Islamiques de Constantine", id: 1996 },
  { code: "DZ", name: "Université d'Oran Es-Senia", id: 1997 },
  { code: "DZ", name: "Université Dr Tahar Moulay", id: 1998 },
  { code: "DZ", name: "Université Kasdi Merbah Ouargla", id: 1999 },
  { code: "DZ", name: "Université Mentouri Constantine", id: 2000 },
  { code: "DZ", name: "Université M'hamed Bouguerra de Boumerdes", id: 2001 },
  { code: "DZ", name: "Université Mouloud Mammeri de Tizi Ouzou", id: 2002 },
  { code: "EC", name: "Brookdale Community College", id: 2003 },
  { code: "EC", name: "Escuela Politécnica de Chimborazo", id: 2004 },
  { code: "EC", name: "Escuela Politécnica del Ejercito", id: 2005 },
  { code: "EC", name: "Escuela Politécnica Nacional", id: 2006 },
  { code: "EC", name: "Escuela Superior Politécnica del Litoral", id: 2007 },
  { code: "EC", name: "Pontificia Universidad Católica del Ecuador", id: 2008 },
  { code: "EC", name: "Universidad Agraria del Ecuador", id: 2009 },
  { code: "EC", name: "Universidad Andina Simón Bolívar", id: 2010 },
  { code: "EC", name: "Universidad Casa Grande", id: 2011 },
  { code: "EC", name: "Universidad Catolica de Azogues", id: 2012 },
  { code: "EC", name: "Universidad Católica de Cuenca", id: 2013 },
  { code: "EC", name: "Universidad Católica de Santiago de Guayaquil", id: 2014 },
  { code: "EC", name: "Universidad Central del Ecuador", id: 2015 },
  { code: "EC", name: "Universidad de Cuenca", id: 2016 },
  { code: "EC", name: "Universidad de Especialidades del Espíritu Santo", id: 2017 },
  { code: "EC", name: "Universidad de Especialidades Turísticas ", id: 2018 },
  { code: "EC", name: "Universidad de Guayaquil", id: 2019 },
  { code: "EC", name: "Universidad del Azuay", id: 2020 },
  { code: "EC", name: "Universidad del Pacifico", id: 2021 },
  { code: "EC", name: "Universidad Estatal de Bolívar", id: 2022 },
  { code: "EC", name: "Universidad Internacional del Ecuador", id: 2023 },
  { code: "EC", name: "Universidad Internacional SEK", id: 2024 },
  { code: "EC", name: "Universidad Laica Eloy Alfaro de Manabi", id: 2025 },
  { code: "EC", name: "Universidad Laica Vicente Rocafuerte de Guayaquil", id: 2026 },
  { code: "EC", name: "Universidad Nacional de Chimborazo", id: 2027 },
  { code: "EC", name: "Universidad Nacional de Loja", id: 2028 },
  { code: "EC", name: "Universidad Politécnica Salesiana", id: 2029 },
  { code: "EC", name: "Universidad San Francisco de Quito", id: 2030 },
  { code: "EC", name: "Universidad Santa María Campus Guayaquil", id: 2031 },
  { code: "EC", name: "Universidad Técnica de Ambato", id: 2032 },
  { code: "EC", name: "Universidad Técnica de Babahoyo", id: 2033 },
  { code: "EC", name: "Universidad Técnica de Cotopaxi", id: 2034 },
  { code: "EC", name: "Universidad Técnica de Esmeraldas Luis Vargas Torres", id: 2035 },
  { code: "EC", name: "Universidad Técnica del Norte", id: 2036 },
  { code: "EC", name: "Universidad Técnica de Machala", id: 2037 },
  { code: "EC", name: "Universidad Técnica de Manabí", id: 2038 },
  { code: "EC", name: "Universidad Técnica Estatal de Quevedo", id: 2039 },
  { code: "EC", name: "Universidad Técnica Particular de Loja", id: 2040 },
  { code: "EC", name: "Universidad Tecnológica América", id: 2041 },
  { code: "EC", name: "Universidad Tecnológica Equinoccial", id: 2042 },
  { code: "EC", name: "Universidad Tecnológica Indoamérica", id: 2043 },
  { code: "EC", name: "Universidad Tecnologica Israel", id: 2044 },
  { code: "EC", name: "Universidad Tecnológica San Antonio de Machala", id: 2045 },
  { code: "EE", name: "Estonian Academy of Arts", id: 2046 },
  { code: "EE", name: "Estonian Academy of Music and Theatre", id: 2047 },
  { code: "EE", name: "Estonian Academy of Security Sciences", id: 2048 },
  { code: "EE", name: "Estonian Business School", id: 2049 },
  { code: "EE", name: "Estonian University of Life Sciences", id: 2050 },
  { code: "EE", name: "Tallinn University", id: 2051 },
  { code: "EE", name: "Tallinn University of Technology", id: 2052 },
  { code: "EE", name: "Tartu Health Care College", id: 2053 },
  { code: "EE", name: "University of Applied Sciences", id: 2054 },
  { code: "EE", name: "University of Tartu", id: 2055 },
  { code: "EG", name: "Ain Shams University", id: 2056 },
  { code: "EG", name: "Akhbar El Yom Academy", id: 2057 },
  { code: "EG", name: "Al Azhar University", id: 2058 },
  { code: "EG", name: "Alexandria University", id: 2059 },
  { code: "EG", name: "American University in Cairo", id: 2060 },
  { code: "EG", name: "Arab Academy for Science & Technology", id: 2061 },
  { code: "EG", name: "Arab Open University", id: 2062 },
  { code: "EG", name: "Assiut University", id: 2063 },
  { code: "EG", name: "Benha University", id: 2064 },
  { code: "EG", name: "Beni Suef University", id: 2065 },
  { code: "EG", name: "Cairo University", id: 2066 },
  { code: "EG", name: "Damanhour University", id: 2067 },
  { code: "EG", name: "Damietta University", id: 2068 },
  { code: "EG", name: "Delta University", id: 2069 },
  { code: "EG", name: "El Shorouk Academy", id: 2070 },
  { code: "EG", name: "Fayoum University", id: 2071 },
  { code: "EG", name: "Future University", id: 2072 },
  { code: "EG", name: "German University in Cairo", id: 2073 },
  { code: "EG", name: "Helwan University", id: 2074 },
  { code: "EG", name: "Higher Technological Institute", id: 2075 },
  { code: "EG", name: "Kafr El-Sheikh University", id: 2076 },
  { code: "EG", name: "Mansoura University", id: 2077 },
  { code: "EG", name: "Menoufia University", id: 2078 },
  { code: "EG", name: "Military Technical College", id: 2079 },
  { code: "EG", name: "Minia University", id: 2080 },
  { code: "EG", name: "Misr International University", id: 2081 },
  { code: "EG", name: "Misr University for Sience and Technology", id: 2082 },
  { code: "EG", name: "Modern Acadmy", id: 2083 },
  { code: "EG", name: "Modern Sciences & Arts University", id: 2084 },
  { code: "EG", name: "Modern University For Technology and Information", id: 2085 },
  { code: "EG", name: "Nile University", id: 2086 },
  { code: "EG", name: "October 6 university", id: 2087 },
  { code: "EG", name: "Pharos International University", id: 2088 },
  { code: "EG", name: "Port Said University", id: 2089 },
  { code: "EG", name: "Sadat Academy for Management Sciences", id: 2090 },
  { code: "EG", name: "Sinai University", id: 2091 },
  { code: "EG", name: "Sohag University", id: 2092 },
  { code: "EG", name: "South Valley University", id: 2093 },
  { code: "EG", name: "Suez Canal University", id: 2094 },
  { code: "EG", name: "Tanta University", id: 2095 },
  { code: "EG", name: "Université Française d'Égypte", id: 2096 },
  { code: "EG", name: "Université Senghor d'Alexandrie", id: 2097 },
  { code: "EG", name: "University of Sadat City", id: 2098 },
  { code: "EG", name: "Zagazig University", id: 2099 },
  { code: "ER", name: "Eritrea Institute of Technology", id: 2100 },
  { code: "ES", name: "Barcelona Graduate School of Economics", id: 2101 },
  { code: "ES", name: "Bircham International University", id: 2102 },
  { code: "ES", name: "Centro Universitario Villanueva", id: 2103 },
  { code: "ES", name: "Escuela Superior de Gestion Comercial y Marketing (ESIC)", id: 2104 },
  { code: "ES", name: "Global Business School Barcelona", id: 2105 },
  { code: "ES", name: "IE University", id: 2106 },
  { code: "ES", name: "Iniciativa Universidad del Atlantico", id: 2107 },
  { code: "ES", name: "International University of Southern Europe", id: 2108 },
  { code: "ES", name: "La Salle Universities - International Programmes", id: 2109 },
  { code: "ES", name: "Mondragon Univertsitatea", id: 2110 },
  { code: "ES", name: "Schiller International University", id: 2111 },
  { code: "ES", name: "Universidad Abierta Interactiva", id: 2112 },
  { code: "ES", name: "Universidad Alfonso X El Sabio", id: 2113 },
  { code: "ES", name: "Universidad Antonio de Nebrija", id: 2114 },
  { code: "ES", name: "Universidad Autónoma de Madrid", id: 2115 },
  { code: "ES", name: "Universidad Cardenal Herrera-CEU", id: 2116 },
  { code: "ES", name: "Universidad Carlos III de Madrid", id: 2117 },
  { code: "ES", name: "Universidad Católica de Ávila", id: 2118 },
  { code: "ES", name: "Universidad Católica de Valencia", id: 2119 },
  { code: "ES", name: "Universidad Católica San Antonio", id: 2120 },
  { code: "ES", name: "Universidad Complutense de Madrid", id: 2121 },
  { code: "ES", name: "Universidad de Alcalá de Henares", id: 2122 },
  { code: "ES", name: "Universidad de Alicante", id: 2123 },
  { code: "ES", name: "Universidad de Almería", id: 2124 },
  { code: "ES", name: "Universidad de Burgos", id: 2125 },
  { code: "ES", name: "Universidad de Cádiz", id: 2126 },
  { code: "ES", name: "Universidad de Cantabria", id: 2127 },
  { code: "ES", name: "Universidad de Castilla La Mancha", id: 2128 },
  { code: "ES", name: "Universidad de Córdoba", id: 2129 },
  { code: "ES", name: "Universidad de Deusto", id: 2130 },
  { code: "ES", name: "Universidad de Extremadura", id: 2131 },
  { code: "ES", name: "Universidad de Gerona", id: 2132 },
  { code: "ES", name: "Universidad de Granada", id: 2133 },
  { code: "ES", name: "Universidad de Huelva", id: 2134 },
  { code: "ES", name: "Universidad de Jaén", id: 2135 },
  { code: "ES", name: "Universidad de La Coruña", id: 2136 },
  { code: "ES", name: "Universidad de La Laguna", id: 2137 },
  { code: "ES", name: "Universidad de La Rioja", id: 2138 },
  { code: "ES", name: "Universidad de Las Palmas de Gran Canaria", id: 2139 },
  { code: "ES", name: "Universidad de León", id: 2140 },
  { code: "ES", name: "Universidad del País Vasco", id: 2141 },
  { code: "ES", name: "Universidad de Málaga", id: 2142 },
  { code: "ES", name: "Universidad de Murcia", id: 2143 },
  { code: "ES", name: "Universidad de Navarra", id: 2144 },
  { code: "ES", name: "Universidad de Oviedo", id: 2145 },
  { code: "ES", name: "Universidad de Salamanca", id: 2146 },
  { code: "ES", name: "Universidad de San Pablo CEU", id: 2147 },
  { code: "ES", name: "Universidad de Santiago de Compostela", id: 2148 },
  { code: "ES", name: "Universidad de Sevilla", id: 2149 },
  { code: "ES", name: "Universidad de Valencia", id: 2150 },
  { code: "ES", name: "Universidad de Valladolid", id: 2151 },
  { code: "ES", name: "Universidad de Vigo", id: 2152 },
  { code: "ES", name: "Universidad de Zaragoza", id: 2153 },
  { code: "ES", name: "Universidad Europea de Madrid", id: 2154 },
  { code: "ES", name: "Universidad Francisco de Vitoria", id: 2155 },
  { code: "ES", name: "Universidad Internacional de Andalucía", id: 2156 },
  { code: "ES", name: "Universidad Internacional Menéndez Pelayo", id: 2157 },
  { code: "ES", name: "Universidad Miguel Hernández de Elche", id: 2158 },
  { code: "ES", name: "Universidad Nacional de Educación a Distancia", id: 2159 },
  { code: "ES", name: "Universidad Pablo de Olavide", id: 2160 },
  { code: "ES", name: "Universidad Politécnica de Cartagena", id: 2161 },
  { code: "ES", name: "Universidad Politécnica de Cataluna", id: 2162 },
  { code: "ES", name: "Universidad Politécnica de Madrid", id: 2163 },
  { code: "ES", name: "Universidad Politécnica de Valencia", id: 2164 },
  { code: "ES", name: "Universidad Pontificia Comillas", id: 2165 },
  { code: "ES", name: "Universidad Pontificia de Salamanca", id: 2166 },
  { code: "ES", name: "Universidad Pública de Navarra", id: 2167 },
  { code: "ES", name: "Universidad Rey Juan Carlos", id: 2168 },
  { code: "ES", name: "Universitat Autónoma de Barcelona", id: 2169 },
  { code: "ES", name: "Universitat de Barcelona", id: 2170 },
  { code: "ES", name: "Universitat de les Illes Balears", id: 2171 },
  { code: "ES", name: "Universitat de Lleida", id: 2172 },
  { code: "ES", name: "Universitat de Vic", id: 2173 },
  { code: "ES", name: "Universitat Internacional de Catalunya", id: 2174 },
  { code: "ES", name: "Universitat Jaume I de Castellón", id: 2175 },
  { code: "ES", name: "Universitat Oberta de Catalunya", id: 2176 },
  { code: "ES", name: "Universitat Pompeu Fabra", id: 2177 },
  { code: "ES", name: "Universitat Rámon Llull", id: 2178 },
  { code: "ES", name: "Universitat Rovira I Virgili Tarragona", id: 2179 },
  { code: "ET", name: "Adama Science and Technology University", id: 2180 },
  { code: "ET", name: "Addis Ababa Science & Technology University", id: 2181 },
  { code: "ET", name: "Addis Ababa University", id: 2182 },
  { code: "ET", name: "Adigrat University", id: 2183 },
  { code: "ET", name: "Aksum University", id: 2184 },
  { code: "ET", name: "Ambo University", id: 2185 },
  { code: "ET", name: "Arba Minch University", id: 2186 },
  { code: "ET", name: "Assosa University", id: 2187 },
  { code: "ET", name: "Bahir Dar University", id: 2188 },
  { code: "ET", name: "Bule Hora  University", id: 2189 },
  { code: "ET", name: "Debre Birhan University", id: 2190 },
  { code: "ET", name: "Debre Markos University", id: 2191 },
  { code: "ET", name: "Dilla University", id: 2192 },
  { code: "ET", name: "Dire Dawa University", id: 2193 },
  { code: "ET", name: "Ethiopian Civil Service University", id: 2194 },
  { code: "ET", name: "Gondar University", id: 2195 },
  { code: "ET", name: "Haramaya University", id: 2196 },
  { code: "ET", name: "Hawassa University", id: 2197 },
  { code: "ET", name: "Jigjiga University", id: 2198 },
  { code: "ET", name: "Jimma University", id: 2199 },
  { code: "ET", name: "Madawalabu University", id: 2200 },
  { code: "ET", name: "Mekelle Institute of Technology", id: 2201 },
  { code: "ET", name: "Mekelle University", id: 2202 },
  { code: "ET", name: "Mizan Tepi University", id: 2203 },
  { code: "ET", name: "Semera University", id: 2204 },
  { code: "ET", name: "Unity  University", id: 2205 },
  { code: "ET", name: "Wolaita Sodo University", id: 2206 },
  { code: "ET", name: "Wolkite University", id: 2207 },
  { code: "ET", name: "Wollega University", id: 2208 },
  { code: "ET", name: "Wollo University", id: 2209 },
  { code: "FI", name: "Abo Akademi University", id: 2210 },
  { code: "FI", name: "Academy of Fine Arts", id: 2211 },
  { code: "FI", name: "Central Ostrobothnia University of Applied Sciences", id: 2212 },
  { code: "FI", name: "Diaconia University of Applied Sciences", id: 2213 },
  { code: "FI", name: "EVTEK University of Applied Sciences", id: 2214 },
  { code: "FI", name: "Helsinki School of Economics and Business Administration", id: 2215 },
  { code: "FI", name: "Helsinki University of Technology", id: 2216 },
  { code: "FI", name: "Lahti Polytechnic", id: 2217 },
  { code: "FI", name: "Lappeenranta University of Technology", id: 2218 },
  { code: "FI", name: "Oulu Polytechnic", id: 2219 },
  { code: "FI", name: "Rovaniemi University of Applied Sciences", id: 2220 },
  { code: "FI", name: "Satakunta University Of Applied Scinces", id: 2221 },
  { code: "FI", name: "Scandinavian Art and Business Institute", id: 2222 },
  { code: "FI", name: "Sibelius Academy", id: 2223 },
  { code: "FI", name: "South Carelian Polytechnic", id: 2224 },
  { code: "FI", name: "Swedish School of Economics and Business Administration", id: 2225 },
  { code: "FI", name: "Tampere Polytechnic", id: 2226 },
  { code: "FI", name: "Tampere University of Technology", id: 2227 },
  { code: "FI", name: "Theatre Academy Finland", id: 2228 },
  { code: "FI", name: "Turku School of Economics and Business Administration", id: 2229 },
  { code: "FI", name: "University of Art and Design Helsinki", id: 2230 },
  { code: "FI", name: "University of Helsinki", id: 2231 },
  { code: "FI", name: "University of Joensuu", id: 2232 },
  { code: "FI", name: "University of Jyväskylä", id: 2233 },
  { code: "FI", name: "University of Kuopio", id: 2234 },
  { code: "FI", name: "University of Lapland", id: 2235 },
  { code: "FI", name: "University of Oulu", id: 2236 },
  { code: "FI", name: "University of Tampere", id: 2237 },
  { code: "FI", name: "University of Turku", id: 2238 },
  { code: "FI", name: "University of Vaasa", id: 2239 },
  { code: "FI", name: "Vaasa University of Applied Sciences", id: 2240 },
  { code: "FJ", name: "Fiji National University ", id: 2241 },
  { code: "FJ", name: "Fiji School of Medicine", id: 2242 },
  { code: "FJ", name: "University of Fiji", id: 2243 },
  { code: "FJ", name: "University of the South Pacific", id: 2244 },
  { code: "FO", name: "University of the Faroe Islands", id: 2245 },
  { code: "FR", name: "AgroParisTech", id: 2246 },
  { code: "FR", name: "American Graduate School in Paris", id: 2247 },
  { code: "FR", name: "American University of Paris", id: 2248 },
  { code: "FR", name: "British Institute in Paris", id: 2249 },
  { code: "FR", name: "Centre de Formation et de Perfectionnement des Journalistes", id: 2250 },
  { code: "FR", name: "Centre d'Etudes Supérieures des Techniques Industrielles", id: 2251 },
  { code: "FR", name: "Centre d'Etudes Supérieures Industrielles Paris", id: 2252 },
  { code: "FR", name: "Centre National d'Etudes Agronomiques des Régions Chaudes", id: 2253 },
  { code: "FR", name: "Conservatoire National des Arts et Métiers", id: 2254 },
  { code: "FR", name: "Ecole Catholique d'Arts & Metiers", id: 2255 },
  { code: "FR", name: "Ecole Centrale d'Electronique - ECE", id: 2256 },
  { code: "FR", name: "Ecole Centrale de Lille", id: 2257 },
  { code: "FR", name: "Ecole Centrale de Lyon", id: 2258 },
  { code: "FR", name: "Ecole Centrale de Nantes", id: 2259 },
  { code: "FR", name: "Ecole d'Architecture de Nancy", id: 2260 },
  { code: "FR", name: "Ecole de l'Air", id: 2261 },
  { code: "FR", name: "Ecole des Hautes Etudes Commerciales", id: 2262 },
  { code: "FR", name: "Ecole des Hautes Etudes Commerciales du Nord", id: 2263 },
  { code: "FR", name: "Ecole des Hautes Etudes Industrielles de Lille", id: 2264 },
  { code: "FR", name: "Ecole des Ingénieurs de la Ville de Paris", id: 2265 },
  { code: "FR", name: "Ecole d'Ingénieurs en Informatique pour l'Industrie", id: 2266 },
  { code: "FR", name: "Ecole Européen des Affaires", id: 2267 },
  { code: "FR", name: "Ecole Européenne de Chimie", id: 2268 },
  { code: "FR", name: "Ecole Française d'Electronique et d'Informatique", id: 2269 },
  { code: "FR", name: "Ecole Française de Papeterie et des Industries Graphiques", id: 2270 },
  { code: "FR", name: "Ecole Nationale d'Administration", id: 2271 },
  { code: "FR", name: "Ecole Nationale de la Météorologie", id: 2272 },
  { code: "FR", name: "Ecole Nationale de la Statistique et de l'Administration Economique", id: 2273 },
  { code: "FR", name: "Ecole Nationale de la Statistique et de l'Analyse de l'information", id: 2274 },
  { code: "FR", name: "Ecole Nationale de l'Aviation Civile", id: 2275 },
  { code: "FR", name: "Ecole Nationale des Ponts et Chausees", id: 2276 },
  { code: "FR", name: "Ecole Nationale des Sciences Géographiques", id: 2277 },
  { code: "FR", name: "Ecole Nationale des Travaux Publics de l'Etat", id: 2278 },
  { code: "FR", name: "Ecole Nationale d'Ingénieurs de Metz", id: 2279 },
  { code: "FR", name: "Ecole Nationale d'Ingénieurs de Saint-Etienne", id: 2280 },
  { code: "FR", name: "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires", id: 2281 },
  { code: "FR", name: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux", id: 2282 },
  { code: "FR", name: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand", id: 2283 },
  { code: "FR", name: "Ecole Nationale d'Ingénieurs de Tarbes", id: 2284 },
  { code: "FR", name: "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg", id: 2285 },
  { code: "FR", name: "Ecole Nationale Supérieur de Géologie de Nancy", id: 2286 },
  { code: "FR", name: "Ecole Nationale Supérieur de Mécanique et d'Aéronautique", id: 2287 },
  { code: "FR", name: "Ecole Nationale Supérieur de Mécaniques et des Microtechniques", id: 2288 },
  { code: "FR", name: "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement", id: 2289 },
  { code: "FR", name: "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique", id: 2290 },
  { code: "FR", name: "Ecole Nationale Supérieure Agronomique de Toulouse", id: 2291 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Agronomie de Montpellier", id: 2292 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Agronomie de Rennes", id: 2293 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires", id: 2294 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Arts et Métiers de Paris", id: 2295 },
  { code: "FR", name: "Ecole Nationale Supérieure de Biologie Appliquée à la Nutrition et à l'Alementation", id: 2296 },
  { code: "FR", name: "Ecole Nationale Supérieure de Chimie de Clermont-Ferrand", id: 2297 },
  { code: "FR", name: "Ecole Nationale Supérieure de Chimie de Lille", id: 2298 },
  { code: "FR", name: "Ecole Nationale Supérieure de Chimie de Montpellier", id: 2299 },
  { code: "FR", name: "Ecole Nationale Supérieure de Chimie de Mulhouse", id: 2300 },
  { code: "FR", name: "Ecole Nationale Supérieure de Chimie de Paris", id: 2301 },
  { code: "FR", name: "Ecole Nationale Supérieure de Chimie de Rennes", id: 2302 },
  { code: "FR", name: "Ecole Nationale Supérieure de Chimie de Toulouse", id: 2303 },
  { code: "FR", name: "Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux", id: 2304 },
  { code: "FR", name: "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace", id: 2305 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Electrochimie et d'Electrométallurgie de Gernoble", id: 2306 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Electronique", id: 2307 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux", id: 2308 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Grenoble", id: 2309 },
  { code: "FR", name: "Ecole Nationale Supérieure de l'Electronique et de ses Applications", id: 2310 },
  { code: "FR", name: "Ecole Nationale Supérieure de Physique de Grenoble", id: 2311 },
  { code: "FR", name: "Ecole Nationale Supérieure de Physique de Marseille", id: 2312 },
  { code: "FR", name: "Ecole Nationale Supérieure de Physique de Strasbourg", id: 2313 },
  { code: "FR", name: "Ecole Nationale Supérieure des Arts et Industries de Strasbourg", id: 2314 },
  { code: "FR", name: "Ecole Nationale Supérieure des Arts et Industries Textiles", id: 2315 },
  { code: "FR", name: "Ecole Nationale Supérieure des Industries Chimiques de Nancy", id: 2316 },
  { code: "FR", name: "Ecole Nationale Supérieure des Industries Textiles de Mulhouse", id: 2317 },
  { code: "FR", name: "Ecole Nationale Supérieure des Mines d'Alès", id: 2318 },
  { code: "FR", name: "Ecole Nationale Supérieure des Mines de Douai", id: 2319 },
  { code: "FR", name: "Ecole Nationale Supérieure des Mines de Nancy", id: 2320 },
  { code: "FR", name: "Ecole Nationale Supérieure des Mines de Paris", id: 2321 },
  { code: "FR", name: "Ecole Nationale Supérieure des Mines de St-Etienne", id: 2322 },
  { code: "FR", name: "Ecole Nationale Supérieure des Telecommunications de Bretagne", id: 2323 },
  { code: "FR", name: "Ecole Nationale Supérieure des Telecommunications de Paris", id: 2324 },
  { code: "FR", name: "Ecole Nationale Supérieure de Techniques Avancées", id: 2325 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Hydraulique et de Mécanique de Grenoble", id: 2326 },
  { code: "FR", name: "Ecole Nationale Superieure d'Informatique et de Mathematiques Appliquees de Grenoble", id: 2327 },
  { code: "FR", name: "École Nationale Supérieure d'Ingénieurs de Constructions Aéronautiques", id: 2328 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Ingénieurs de Génie Chimique", id: 2329 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Ingenieurs Electriciens de Grenoble", id: 2330 },
  { code: "FR", name: "Ecole Nationale Supérieure d'Ingénieurs en Mécanique et Energétique de Valenciennes", id: 2331 },
  { code: "FR", name: "Ecole Nationale Supérieure du Pétrole et des Monteurs", id: 2332 },
  { code: "FR", name: "Ecole Nationale Supérieure Electricité et Mécanique", id: 2333 },
  { code: "FR", name: "Ecole Nationale Supérieure en Electrotechnique", id: 2334 },
  { code: "FR", name: "Ecole Nationale Vétérinaire d'Alfort", id: 2335 },
  { code: "FR", name: "Ecole Nationale Vétérinaire de Lyon", id: 2336 },
  { code: "FR", name: "Ecole Nationale Vétérinaire de Nantes", id: 2337 },
  { code: "FR", name: "Ecole Nationale Vétérinaire de Toulouse", id: 2338 },
  { code: "FR", name: "Ecole Normale Supérieure de Cachan", id: 2339 },
  { code: "FR", name: "Ecole Normale Supérieure de Fontenay-Saint Cloud", id: 2340 },
  { code: "FR", name: "Ecole Normale Supérieure de Lyon", id: 2341 },
  { code: "FR", name: "Ecole Normale Supérieure de Paris", id: 2342 },
  { code: "FR", name: "Ecole Polytechnique", id: 2343 },
  { code: "FR", name: "Ecole Polytechnique Marseille", id: 2344 },
  { code: "FR", name: "Ecole Polytechnique Universitaire de Lille", id: 2345 },
  { code: "FR", name: "Ecole pour les Etudes et la Recherche en Informatique et Electronique", id: 2346 },
  { code: "FR", name: "Ecole Spéciale de Mécanique et d'Electricité", id: 2347 },
  { code: "FR", name: "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie", id: 2348 },
  { code: "FR", name: "Ecole Superieur d'Ingenieurs Leonard de Vinci", id: 2349 },
  { code: "FR", name: "Ecole Supérieure d'Agriculture d'Angers", id: 2350 },
  { code: "FR", name: "Ecole Supérieure d'Agriculture de Purpan", id: 2351 },
  { code: "FR", name: "Ecole Supérieure de Chimie Organique et Minérale", id: 2352 },
  { code: "FR", name: "Ecole Supérieure de Chimie Physique Electronique de Lyon", id: 2353 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Bordeaux", id: 2354 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Brest", id: 2355 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Clermont-Ferrand", id: 2356 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Dijon", id: 2357 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Grenoble", id: 2358 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Le Havre/Caen", id: 2359 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Lille", id: 2360 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Lyon", id: 2361 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Marseille-Provence", id: 2362 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Nantes-Atlantique", id: 2363 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Paris", id: 2364 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Pau", id: 2365 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Reims", id: 2366 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Rouen", id: 2367 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Sophia Antipolis", id: 2368 },
  { code: "FR", name: "Ecole Supérieure de Commerce de Toulouse", id: 2369 },
  { code: "FR", name: "Ecole Supérieure de Commerce et Management", id: 2370 },
  { code: "FR", name: "Ecole Supérieure d'Electricité", id: 2371 },
  { code: "FR", name: "Ecole Supérieure d'Electronique de l'Ouest", id: 2372 },
  { code: "FR", name: "Ecole Supérieure de Physique et de Chimie Industrielles", id: 2373 },
  { code: "FR", name: "Ecole Supérieure des Sciences Commerciales d'Angers", id: 2374 },
  { code: "FR", name: "Ecole Supérieure des Sciences Economiques et Commerciales", id: 2375 },
  { code: "FR", name: "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy", id: 2376 },
  { code: "FR", name: "Ecole Supérieure des Techniques Industrielles et des Textiles", id: 2377 },
  { code: "FR", name: "Ecole Supérieure d'Informatique-Electronique-Automatique", id: 2378 },
  { code: "FR", name: "Ecole Supérieure d'Ingénieurs de Marseille", id: 2379 },
  { code: "FR", name: "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique", id: 2380 },
  { code: "FR", name: "Ecole Supérieure d'Ingénieurs en Génie Electrique", id: 2381 },
  { code: "FR", name: "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture", id: 2382 },
  { code: "FR", name: "Ecole Supérieure d'Optique", id: 2383 },
  { code: "FR", name: "Ecole Supérieure Internationale d'Administration des Entreprises", id: 2384 },
  { code: "FR", name: "Ecole Superieure Robert de Sorbon", id: 2385 },
  { code: "FR", name: "Ecole Universitaire d'Ingénieurs de Lille", id: 2386 },
  { code: "FR", name: "ENIC Telecom Lille 1", id: 2387 },
  { code: "FR", name: "EPF Ecole d'Ingénieurs", id: 2388 },
  { code: "FR", name: "European Business School", id: 2389 },
  { code: "FR", name: "European Management Center Paris", id: 2390 },
  { code: "FR", name: "Grenoble Ecole de Management", id: 2391 },
  { code: "FR", name: "Groupe Sup de Co Amiens Picardie", id: 2392 },
  { code: "FR", name: "Groupe Sup de Co Montpellier", id: 2393 },
  { code: "FR", name: "IDRAC (Institut de recherche en action commerciale)", id: 2394 },
  { code: "FR", name: "Institut Catholique d'Arts et Métiers Lille", id: 2395 },
  { code: "FR", name: "Institut Catholique d'Arts et Métiers Nantes", id: 2396 },
  { code: "FR", name: "Institut Catholique de Paris", id: 2397 },
  { code: "FR", name: "Institut Catholique de Toulouse", id: 2398 },
  { code: "FR", name: "Institut Commercial de Nancy", id: 2399 },
  { code: "FR", name: "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique", id: 2400 },
  { code: "FR", name: "Institut des hautes études économiques et commerciales", id: 2401 },
  { code: "FR", name: "Institut des Sciences de la Matière et du Rayonnement", id: 2402 },
  { code: "FR", name: "Institut des Sciences de l'Ingénieur de Clermont-Ferrand", id: 2403 },
  { code: "FR", name: "Institut des Sciences de l'Ingénieur de Montpellier", id: 2404 },
  { code: "FR", name: "Institut d'Etudes Politiques de Bordeaux", id: 2405 },
  { code: "FR", name: "Institut d'Etudes Politiques de Paris (Sciences Po)", id: 2406 },
  { code: "FR", name: "Institut National des Sciences Appliquées de Lyon", id: 2407 },
  { code: "FR", name: "Institut National des Sciences Appliquées de Rennes", id: 2408 },
  { code: "FR", name: "Institut National des Sciences Appliquées de Rouen", id: 2409 },
  { code: "FR", name: "Institut National des Sciences Appliquées de Toulouse", id: 2410 },
  { code: "FR", name: "Institut National des Télécommunications", id: 2411 },
  { code: "FR", name: "Institut National Polytechnique de Grenoble", id: 2412 },
  { code: "FR", name: "Institut National Polytechnique de Lorraine", id: 2413 },
  { code: "FR", name: "Institut National Polytechnique de Toulouse", id: 2414 },
  { code: "FR", name: "Institut National Supérieur de Formation Agro-Alimentaire", id: 2415 },
  { code: "FR", name: "Institut Supérieur Agricole de Beauvais", id: 2416 },
  { code: "FR", name: "Institut Supérieur d'Agriculture Lille", id: 2417 },
  { code: "FR", name: "Institut Supérieur d'Agriculture Rhone-Alpes", id: 2418 },
  { code: "FR", name: "Institut Supérieure d'Electronique de Paris", id: 2419 },
  { code: "FR", name: "Institut Supérieure d'Electronique du Nord", id: 2420 },
  { code: "FR", name: "Institut Textile et Chimique de Lyon", id: 2421 },
  { code: "FR", name: "Schiller International University", id: 2422 },
  { code: "FR", name: "Université Blaise Pascal (Clermont-Ferrand II)", id: 2423 },
  { code: "FR", name: "Université Bordeaux I", id: 2424 },
  { code: "FR", name: "Université Catholique de Lille", id: 2425 },
  { code: "FR", name: "Université Catholique de l' Ouest", id: 2426 },
  { code: "FR", name: "Université Catholique de Lyon", id: 2427 },
  { code: "FR", name: "Université Charles-de-Gaulle (Lille III)", id: 2428 },
  { code: "FR", name: "Université Claude Bernard (Lyon I)", id: 2429 },
  { code: "FR", name: "Université d'Aix-Marseille III", id: 2430 },
  { code: "FR", name: "Université d'Angers", id: 2431 },
  { code: "FR", name: "Université d'Artois", id: 2432 },
  { code: "FR", name: "Université d'Auvergne (Clermont-Ferrand I)", id: 2433 },
  { code: "FR", name: "Université d'Avignon", id: 2434 },
  { code: "FR", name: "Université de Bourgogne", id: 2435 },
  { code: "FR", name: "Université de Bretagne Occidentale", id: 2436 },
  { code: "FR", name: "Université de Bretagne Sud", id: 2437 },
  { code: "FR", name: "Université de Caen Basse Normandie", id: 2438 },
  { code: "FR", name: "Université de Cergy-Pontoise", id: 2439 },
  { code: "FR", name: "Université de Corse Pascal Paoli", id: 2440 },
  { code: "FR", name: "Université de Franche-Comté", id: 2441 },
  { code: "FR", name: "Université de Haute-Alsace", id: 2442 },
  { code: "FR", name: "Université de la Méditerranée (Aix Marseille II)", id: 2443 },
  { code: "FR", name: "Université de La Rochelle", id: 2444 },
  { code: "FR", name: "Université de Limoges", id: 2445 },
  { code: "FR", name: "Université de Marne la Vallée", id: 2446 },
  { code: "FR", name: "Université de Metz", id: 2447 },
  { code: "FR", name: "Université de Montpellier I", id: 2448 },
  { code: "FR", name: "Université de Montpellier II", id: 2449 },
  { code: "FR", name: "Université de Nantes", id: 2450 },
  { code: "FR", name: "Université de Nice-Sophia Antipolis", id: 2451 },
  { code: "FR", name: "Université Denis Diderot (Paris VII)", id: 2452 },
  { code: "FR", name: "Université de Pau et des Pays de l'Adour", id: 2453 },
  { code: "FR", name: "Université de Perpignan", id: 2454 },
  { code: "FR", name: "Université de Picardie Jules-Verne", id: 2455 },
  { code: "FR", name: "Université de Poitiers", id: 2456 },
  { code: "FR", name: "Université de Provence (Aix-Marseille I)", id: 2457 },
  { code: "FR", name: "Université de Reims Champagne-Ardenne", id: 2458 },
  { code: "FR", name: "Université de Rouen - Haute Normandie", id: 2459 },
  { code: "FR", name: "Université de Savoie", id: 2460 },
  { code: "FR", name: "Université des Sciences et Technologies de Lille (Lille I)", id: 2461 },
  { code: "FR", name: "Université des Sciences Humaines (Strasbourg II)", id: 2462 },
  { code: "FR", name: "Université des Sciences Sociales (Toulouse I)", id: 2463 },
  { code: "FR", name: "Université de Technologie de Belfort Montbéliard", id: 2464 },
  { code: "FR", name: "Université de Technologie de Compiègne", id: 2465 },
  { code: "FR", name: "Université de Technologie de Troyes", id: 2466 },
  { code: "FR", name: "Université de Toulouse", id: 2467 },
  { code: "FR", name: "Université de Toulouse-le-Mirail (Toulouse II)", id: 2468 },
  { code: "FR", name: "Université de Valenciennes et du Hainaut-Cambrésis", id: 2469 },
  { code: "FR", name: "Université de Versailles Saint-Quentin-en-Yvelines", id: 2470 },
  { code: "FR", name: "Université d'Evry Val d'Essonne", id: 2471 },
  { code: "FR", name: "Université d'Orléans", id: 2472 },
  { code: "FR", name: "Université du Droit et de la Sante (Lille II)", id: 2473 },
  { code: "FR", name: "Université du Havre", id: 2474 },
  { code: "FR", name: "Université du Littoral Cote d'Opale", id: 2475 },
  { code: "FR", name: "Université du Maine", id: 2476 },
  { code: "FR", name: "Université du Sud", id: 2477 },
  { code: "FR", name: "Université François Rabelais de Tours", id: 2478 },
  { code: "FR", name: "Université Henri Poincaré (Nancy I)", id: 2479 },
  { code: "FR", name: "Université Jean Monnet", id: 2480 },
  { code: "FR", name: "Université Jean Moulin (Lyon III)", id: 2481 },
  { code: "FR", name: "Université Joseph Fourier (Grenoble I)", id: 2482 },
  { code: "FR", name: "Université Louis Pasteur (Strasbourg I)", id: 2483 },
  { code: "FR", name: "Université Lumiére (Lyon II)", id: 2484 },
  { code: "FR", name: "Université Michel de Montaigne (Bordeaux III )", id: 2485 },
  { code: "FR", name: "Université Montesquieu (Bordeaux IV)", id: 2486 },
  { code: "FR", name: "Université Nancy II", id: 2487 },
  { code: "FR", name: "Université Panthéon-Assas (Paris II)", id: 2488 },
  { code: "FR", name: "Université Panthéon-Sorbonne (Paris I)", id: 2489 },
  { code: "FR", name: "Université Paris-Dauphine (Paris IX)", id: 2490 },
  { code: "FR", name: "Université Paris Nanterre (Paris X)", id: 2491 },
  { code: "FR", name: "Université Paris Nord (Paris XIII)", id: 2492 },
  { code: "FR", name: "Université Paris-Sorbonne (Paris IV)", id: 2493 },
  { code: "FR", name: "Université Paris Sud (Paris XI)", id: 2494 },
  { code: "FR", name: "Université Paul Sabatier (Toulouse III)", id: 2495 },
  { code: "FR", name: "Université Paul Valéry (Montpellier III)", id: 2496 },
  { code: "FR", name: "Université Pierre et Marie Curie (Paris VI)", id: 2497 },
  { code: "FR", name: "Université Pierre Mendes-France (Grenoble II)", id: 2498 },
  { code: "FR", name: "Université René Descartes (Paris V)", id: 2499 },
  { code: "FR", name: "Université Rennes I", id: 2500 },
  { code: "FR", name: "Université Rennes II - Haute-Bretagne", id: 2501 },
  { code: "FR", name: "Université Robert Schuman (Strasbourg III)", id: 2502 },
  { code: "FR", name: "Université Sorbonne-Nouvelle (Paris III)", id: 2503 },
  { code: "FR", name: "Université Stendhal (Grenoble III)", id: 2504 },
  { code: "FR", name: "Université Val-de-Marne (Paris XII)", id: 2505 },
  { code: "FR", name: "Université Victor Segalen (Bordeaux II)", id: 2506 },
  { code: "FR", name: "Université Vincennes Saint-Denis (Paris VIII)", id: 2507 },
  { code: "GA", name: "Université Omar Bongo", id: 2508 },
  { code: "GB", name: "Aga Khan University", id: 2509 },
  { code: "GB", name: "American InterContinental University - London", id: 2510 },
  { code: "GB", name: "Anglia Ruskin University", id: 2511 },
  { code: "GB", name: "Aston University", id: 2512 },
  { code: "GB", name: "Birkbeck College", id: 2513 },
  { code: "GB", name: "Birmingham City University", id: 2514 },
  { code: "GB", name: "Bournemouth University", id: 2515 },
  { code: "GB", name: "British College of Osteopathic Medicine", id: 2516 },
  { code: "GB", name: "Brunel University Uxbridge", id: 2517 },
  { code: "GB", name: "Buckinghamshire New University", id: 2518 },
  { code: "GB", name: "Camberwell College of Arts", id: 2519 },
  { code: "GB", name: "Canterbury Christ Church University", id: 2520 },
  { code: "GB", name: "Cardiff University", id: 2521 },
  { code: "GB", name: "Central Saint Martins College of Art & Design", id: 2522 },
  { code: "GB", name: "Chelsea College of Art and Design", id: 2523 },
  { code: "GB", name: "City University", id: 2524 },
  { code: "GB", name: "Courtauld Institute of Art", id: 2525 },
  { code: "GB", name: "Coventry University", id: 2526 },
  { code: "GB", name: "Cranfield University", id: 2527 },
  { code: "GB", name: "De Montfort University Leicester", id: 2528 },
  { code: "GB", name: "European School of Economics", id: 2529 },
  { code: "GB", name: "Falmouth University", id: 2530 },
  { code: "GB", name: "Glasgow Caledonian University", id: 2531 },
  { code: "GB", name: "Glasgow School of Art", id: 2532 },
  { code: "GB", name: "Goldsmiths College", id: 2533 },
  { code: "GB", name: "Heriot-Watt University", id: 2534 },
  { code: "GB", name: "Heythrop College", id: 2535 },
  { code: "GB", name: "Huron University USA in London", id: 2536 },
  { code: "GB", name: "ifs University College", id: 2537 },
  { code: "GB", name: "Imperial College London", id: 2538 },
  { code: "GB", name: "Imperial College School of Medicine", id: 2539 },
  { code: "GB", name: "Institue of Historical Research", id: 2540 },
  { code: "GB", name: "Institute of Advanced Legal Studies", id: 2541 },
  { code: "GB", name: "Institute of Classical Studies", id: 2542 },
  { code: "GB", name: "Institute of Commonwealth Studies", id: 2543 },
  { code: "GB", name: "Institute of Education", id: 2544 },
  { code: "GB", name: "Institute of Germanic Studies", id: 2545 },
  { code: "GB", name: "Institute of Latin American Studies", id: 2546 },
  { code: "GB", name: "International Centre for Isclamic Science", id: 2547 },
  { code: "GB", name: "International Colleges of Islamic Science", id: 2548 },
  { code: "GB", name: "Keele University", id: 2549 },
  { code: "GB", name: "King's College London", id: 2550 },
  { code: "GB", name: "Kingston University", id: 2551 },
  { code: "GB", name: "Leeds Metropolitan University", id: 2552 },
  { code: "GB", name: "Liverpool Hope University College", id: 2553 },
  { code: "GB", name: "Liverpool John Moores University", id: 2554 },
  { code: "GB", name: "London Business School", id: 2555 },
  { code: "GB", name: "London College of Science & Technology", id: 2556 },
  { code: "GB", name: "London Guildhall University", id: 2557 },
  { code: "GB", name: "London Institute of Management and Technology", id: 2558 },
  { code: "GB", name: "London Metropolitan University", id: 2559 },
  { code: "GB", name: "London School of Business & Finance", id: 2560 },
  { code: "GB", name: "London School of Economics and Political Science", id: 2561 },
  { code: "GB", name: "London School of Hygiene & Tropical Medicine", id: 2562 },
  { code: "GB", name: "London School of Jewish Studies", id: 2563 },
  { code: "GB", name: "Loughborough University", id: 2564 },
  { code: "GB", name: "Middlesex University", id: 2565 },
  { code: "GB", name: "Napier University", id: 2566 },
  { code: "GB", name: "Newport International University", id: 2567 },
  { code: "GB", name: "Nottingham Trent University", id: 2568 },
  { code: "GB", name: "Open University", id: 2569 },
  { code: "GB", name: "Oxford Brookes University", id: 2570 },
  { code: "GB", name: "Queen Mary and Westfield College", id: 2571 },
  { code: "GB", name: "Richmond University - The American International University in London", id: 2572 },
  { code: "GB", name: "Roehampton University of Surrey", id: 2573 },
  { code: "GB", name: "Royal Academy of Music", id: 2574 },
  { code: "GB", name: "Royal College of Art", id: 2575 },
  { code: "GB", name: "Royal College of Music", id: 2576 },
  { code: "GB", name: "Royal Free Hospital School of Medicine", id: 2577 },
  { code: "GB", name: "Royal Holloway and Bedford New College", id: 2578 },
  { code: "GB", name: "Saint George's Hospital Medical School", id: 2579 },
  { code: "GB", name: "Schiller International University", id: 2580 },
  { code: "GB", name: "School of Oriental and African Studies", id: 2581 },
  { code: "GB", name: "School of Pharmacy", id: 2582 },
  { code: "GB", name: "School of Slavonic and East European Studies", id: 2583 },
  { code: "GB", name: "Sheffield Hallam University", id: 2584 },
  { code: "GB", name: "Sotheby´s Institute of Art - London", id: 2585 },
  { code: "GB", name: "Southampton Solent University", id: 2586 },
  { code: "GB", name: "South Bank University", id: 2587 },
  { code: "GB", name: "Staffordshire University", id: 2588 },
  { code: "GB", name: "St.Patrick's International College", id: 2589 },
  { code: "GB", name: "Stratford College London", id: 2590 },
  { code: "GB", name: "Swansea Metropolitan University", id: 2591 },
  { code: "GB", name: "Thames Valley University", id: 2592 },
  { code: "GB", name: "The American University in London", id: 2593 },
  { code: "GB", name: "The Manchester Metropolitan University", id: 2594 },
  { code: "GB", name: "The Queen's University Belfast", id: 2595 },
  { code: "GB", name: "The Robert Gordon University", id: 2596 },
  { code: "GB", name: "Trinity College Carmarthen", id: 2597 },
  { code: "GB", name: "Trinity College of Music", id: 2598 },
  { code: "GB", name: "United Medical and Dental Schools", id: 2599 },
  { code: "GB", name: "University Campus Suffolk", id: 2600 },
  { code: "GB", name: "University College London", id: 2601 },
  { code: "GB", name: "University of Aberdeen", id: 2602 },
  { code: "GB", name: "University of Abertay Dundee", id: 2603 },
  { code: "GB", name: "University of Bath", id: 2604 },
  { code: "GB", name: "University of Bedfordshire", id: 2605 },
  { code: "GB", name: "University of Birmingham", id: 2606 },
  { code: "GB", name: "University of Bolton", id: 2607 },
  { code: "GB", name: "University of Bradford", id: 2608 },
  { code: "GB", name: "University of Brighton", id: 2609 },
  { code: "GB", name: "University of Bristol", id: 2610 },
  { code: "GB", name: "University of Buckingham", id: 2611 },
  { code: "GB", name: "University of Cambridge", id: 2612 },
  { code: "GB", name: "University of Central Lancashire", id: 2613 },
  { code: "GB", name: "University of Chester", id: 2614 },
  { code: "GB", name: "University of Derby", id: 2615 },
  { code: "GB", name: "University of Dundee", id: 2616 },
  { code: "GB", name: "University of Durham", id: 2617 },
  { code: "GB", name: "University of East Anglia", id: 2618 },
  { code: "GB", name: "University of East London", id: 2619 },
  { code: "GB", name: "University of Edinburgh", id: 2620 },
  { code: "GB", name: "University of Essex", id: 2621 },
  { code: "GB", name: "University of Exeter", id: 2622 },
  { code: "GB", name: "University of Glamorgan", id: 2623 },
  { code: "GB", name: "University of Glasgow", id: 2624 },
  { code: "GB", name: "University of Gloucestershire", id: 2625 },
  { code: "GB", name: "University of Greenwich", id: 2626 },
  { code: "GB", name: "University of Halifax", id: 2627 },
  { code: "GB", name: "University of Hertfordshire", id: 2628 },
  { code: "GB", name: "University of Huddersfield", id: 2629 },
  { code: "GB", name: "University of Hull", id: 2630 },
  { code: "GB", name: "University of Kent at Canterbury", id: 2631 },
  { code: "GB", name: "University of Lancaster", id: 2632 },
  { code: "GB", name: "University of Leeds", id: 2633 },
  { code: "GB", name: "University of Leicester", id: 2634 },
  { code: "GB", name: "University of Lincoln", id: 2635 },
  { code: "GB", name: "University of Liverpool", id: 2636 },
  { code: "GB", name: "University of London", id: 2637 },
  { code: "GB", name: "University of Manchester", id: 2638 },
  { code: "GB", name: "University of Newcastle-upon-Tyne", id: 2639 },
  { code: "GB", name: "University of Northampton", id: 2640 },
  { code: "GB", name: "University of North London", id: 2641 },
  { code: "GB", name: "University of Northumbria at Newcastle", id: 2642 },
  { code: "GB", name: "University of Nottingham", id: 2643 },
  { code: "GB", name: "University of Oxford", id: 2644 },
  { code: "GB", name: "University of Paisley", id: 2645 },
  { code: "GB", name: "University of Plymouth", id: 2646 },
  { code: "GB", name: "University of Portsmouth", id: 2647 },
  { code: "GB", name: "University of Reading", id: 2648 },
  { code: "GB", name: "University of Salford", id: 2649 },
  { code: "GB", name: "University of Sheffield", id: 2650 },
  { code: "GB", name: "University of Southampton", id: 2651 },
  { code: "GB", name: "University of St. Andrews", id: 2652 },
  { code: "GB", name: "University of Stirling", id: 2653 },
  { code: "GB", name: "University of Strathclyde", id: 2654 },
  { code: "GB", name: "University of Sunderland", id: 2655 },
  { code: "GB", name: "University of Surrey", id: 2656 },
  { code: "GB", name: "University of Sussex", id: 2657 },
  { code: "GB", name: "University of Teesside", id: 2658 },
  { code: "GB", name: "University of the West of England", id: 2659 },
  { code: "GB", name: "University of Ulster", id: 2660 },
  { code: "GB", name: "University of Wales", id: 2661 },
  { code: "GB", name: "University of Wales College of Medicine", id: 2662 },
  { code: "GB", name: "University of Wales Institute", id: 2663 },
  { code: "GB", name: "University of Warwick", id: 2664 },
  { code: "GB", name: "University of Westminster", id: 2665 },
  { code: "GB", name: "University of Wolverhampton", id: 2666 },
  { code: "GB", name: "University of Worcester", id: 2667 },
  { code: "GB", name: "University of York", id: 2668 },
  { code: "GB", name: "Warburg Institute", id: 2669 },
  { code: "GB", name: "Warnborough University", id: 2670 },
  { code: "GB", name: "William Gilbert College", id: 2671 },
  { code: "GB", name: "Wimbledon School of Art", id: 2672 },
  { code: "GD", name: "St. George's University", id: 2673 },
  { code: "GE", name: "Agricultural University of Georgia", id: 2674 },
  { code: "GE", name: "Akaki Tsereteli State University", id: 2675 },
  { code: "GE", name: "Caucasus University", id: 2676 },
  { code: "GE", name: "Free University of Tbilisi", id: 2677 },
  { code: "GE", name: "Georgian Technical University", id: 2678 },
  { code: "GE", name: "Ilia Chavchavadze State University", id: 2679 },
  { code: "GE", name: "International Blacksea University", id: 2680 },
  { code: "GE", name: "St. Andrew Georgian University", id: 2681 },
  { code: "GE", name: "Tbilisi State Medical University", id: 2682 },
  { code: "GE", name: "Tbilisi State University", id: 2683 },
  { code: "GE", name: "Tbilisi Teaching University", id: 2684 },
  { code: "GE", name: "University of Grigol Robakidze", id: 2685 },
  { code: "GF", name: "Université des Antilles et de la Guyane", id: 2686 },
  { code: "GH", name: "Accra Polytechnic ", id: 2687 },
  { code: "GH", name: "Akrofi-Christaller Institute of Theeology", id: 2688 },
  { code: "GH", name: "All Nations University College", id: 2689 },
  { code: "GH", name: "Ashesi University", id: 2690 },
  { code: "GH", name: "Cape Coast Polytechnic ", id: 2691 },
  { code: "GH", name: "Central University College", id: 2692 },
  { code: "GH", name: "Ghana Christian University College", id: 2693 },
  { code: "GH", name: "Ghana Institute of Management and Public Administration (GIMPA)", id: 2694 },
  { code: "GH", name: "Ghana Telecom University College", id: 2695 },
  { code: "GH", name: "Ho Polytechnic ", id: 2696 },
  { code: "GH", name: "Islamic University College", id: 2697 },
  { code: "GH", name: "Koforidua Polytechnic ", id: 2698 },
  { code: "GH", name: "Kumasi Polytechnic ", id: 2699 },
  { code: "GH", name: "Kwame Nkrumah University of Science and Technology", id: 2700 },
  { code: "GH", name: "Lancaster University Ghana", id: 2701 },
  { code: "GH", name: "Pan African Christian University College", id: 2702 },
  { code: "GH", name: "Prempeh College", id: 2703 },
  { code: "GH", name: "Presbyterian University College", id: 2704 },
  { code: "GH", name: "Regent University College of Science and Technology  ", id: 2705 },
  { code: "GH", name: "Takoradi Polytechnic ", id: 2706 },
  { code: "GH", name: "Trinity Theological Seminary", id: 2707 },
  { code: "GH", name: "University for Development Studies", id: 2708 },
  { code: "GH", name: "University of Cape Coast", id: 2709 },
  { code: "GH", name: "University of Education", id: 2710 },
  { code: "GH", name: "University of Ghana", id: 2711 },
  { code: "GH", name: "Valley View University", id: 2712 },
  { code: "GL", name: "University of Greenland", id: 2713 },
  { code: "GM", name: "American International University West Africa", id: 2714 },
  { code: "GM", name: "EUCLID University", id: 2715 },
  { code: "GM", name: "University of the Gambia", id: 2716 },
  { code: "GN", name: "Université Gamal Abdel Nasser de Conakry", id: 2717 },
  { code: "GN", name: "Université Julius Nyerere Kankan", id: 2718 },
  { code: "GN", name: "Université Kofi Annan", id: 2719 },
  { code: "GN", name: "Université Thierno Amadou Diallo", id: 2720 },
  { code: "GP", name: "Université des Antilles et de la Guyane", id: 2721 },
  { code: "GQ", name: "Universidad Nacional de Guinea Ecuatorial", id: 2722 },
  { code: "GR", name: "Aegean University", id: 2723 },
  { code: "GR", name: "Agricultural University of Athens", id: 2724 },
  { code: "GR", name: "American College of Greece", id: 2725 },
  { code: "GR", name: "American College of Thessaloniki", id: 2726 },
  { code: "GR", name: "Aristotle University of Thessaloniki", id: 2727 },
  { code: "GR", name: "Athens Graduate School of Management (AGSM)", id: 2728 },
  { code: "GR", name: "Athens Laboratory of Business Administration (ALBA)", id: 2729 },
  { code: "GR", name: "Athens School of Fine Arts", id: 2730 },
  { code: "GR", name: "Athens University of Economics and Business", id: 2731 },
  { code: "GR", name: "City University Athens", id: 2732 },
  { code: "GR", name: "DEI Bachelor & Master Degrees", id: 2733 },
  { code: "GR", name: "Dimocritus University of Thrace", id: 2734 },
  { code: "GR", name: "European University", id: 2735 },
  { code: "GR", name: "Harokopio University", id: 2736 },
  { code: "GR", name: "Hellenic Army Academy", id: 2737 },
  { code: "GR", name: "Hellenic Open University", id: 2738 },
  { code: "GR", name: "Institute of Management Development - Ohrid", id: 2739 },
  { code: "GR", name: "Institute of Management & Enteurpreneurship of South East Europe", id: 2740 },
  { code: "GR", name: "International Hellenic University", id: 2741 },
  { code: "GR", name: "Ionian University Corfu", id: 2742 },
  { code: "GR", name: "National Technical University of Athens", id: 2743 },
  { code: "GR", name: "Panteios University of Economics and Political Sciences Athens", id: 2744 },
  { code: "GR", name: "Technical University of Crete", id: 2745 },
  { code: "GR", name: "Technological Education Institute of Athens", id: 2746 },
  { code: "GR", name: "Technological Education Institute of Epiros", id: 2747 },
  { code: "GR", name: "Technological Education Institute of Halkida", id: 2748 },
  { code: "GR", name: "Technological Education Institute of Heraklion", id: 2749 },
  { code: "GR", name: "Technological Education Institute of Kalamata", id: 2750 },
  { code: "GR", name: "Technological Education Institute of Kavala", id: 2751 },
  { code: "GR", name: "Technological Education Institute of Kozani", id: 2752 },
  { code: "GR", name: "Technological Education Institute of Lamia", id: 2753 },
  { code: "GR", name: "Technological Education Institute of Larissa", id: 2754 },
  { code: "GR", name: "Technological Education Institute of Mesologgi", id: 2755 },
  { code: "GR", name: "Technological Education Institute of Patras", id: 2756 },
  { code: "GR", name: "Technological Education Institute of Piraeus", id: 2757 },
  { code: "GR", name: "Technological Education Institute of Serres", id: 2758 },
  { code: "GR", name: "Technological Education Institute of Thessaloniki", id: 2759 },
  { code: "GR", name: "University of Athens", id: 2760 },
  { code: "GR", name: "University of Central Greece", id: 2761 },
  { code: "GR", name: "University of Crete", id: 2762 },
  { code: "GR", name: "University of Indianapolis in Athens", id: 2763 },
  { code: "GR", name: "University of Ioannina", id: 2764 },
  { code: "GR", name: "University of LaVerne in Athens", id: 2765 },
  { code: "GR", name: "University of Macedonia", id: 2766 },
  { code: "GR", name: "University of Patras", id: 2767 },
  { code: "GR", name: "University of Peloponnese", id: 2768 },
  { code: "GR", name: "University of Piraeus", id: 2769 },
  { code: "GR", name: "University of Thessaly", id: 2770 },
  { code: "GR", name: "University of Western Macedonia", id: 2771 },
  { code: "GR", name: "Yorker International University", id: 2772 },
  { code: "GT", name: "Centro Universitario Ciudad Vieja", id: 2773 },
  { code: "GT", name: "Centro Universitario de Occidente", id: 2774 },
  { code: "GT", name: "Universidad del Istmo", id: 2775 },
  { code: "GT", name: "Universidad del Valle de Guatemala", id: 2776 },
  { code: "GT", name: "Universidad de San Carlos de Guatemala", id: 2777 },
  { code: "GT", name: "Universidad Francisco Marroquín", id: 2778 },
  { code: "GT", name: "Universidad Galileo", id: 2779 },
  { code: "GT", name: "Universidad Mariano Gálvez", id: 2780 },
  { code: "GT", name: "Universidad Panamericana", id: 2781 },
  { code: "GT", name: "Universidad Rafael Landívar", id: 2782 },
  { code: "GT", name: "Universidad Rural de Guatemala", id: 2783 },
  { code: "GU", name: "University of Guam", id: 2784 },
  { code: "GY", name: "Gemsville Technical University", id: 2785 },
  { code: "GY", name: "Greenheart Medical School", id: 2786 },
  { code: "GY", name: "Texila American University", id: 2787 },
  { code: "GY", name: "University of Guyana", id: 2788 },
  { code: "HK", name: "Chinese University of Hong Kong", id: 2789 },
  { code: "HK", name: "Chu Hai College", id: 2790 },
  { code: "HK", name: "City University of Hong Kong", id: 2791 },
  { code: "HK", name: "Hong Kong Academy for Performing Arts ", id: 2792 },
  { code: "HK", name: "Hong Kong Baptist University", id: 2793 },
  { code: "HK", name: "Hong Kong Institute of Education", id: 2794 },
  { code: "HK", name: "Hong Kong Polytechnic University", id: 2795 },
  { code: "HK", name: "Hong Kong Shue Yan College", id: 2796 },
  { code: "HK", name: "Hong Kong University of Science and Technology", id: 2797 },
  { code: "HK", name: "Lingnan University", id: 2798 },
  { code: "HK", name: "Open University of Hong Kong", id: 2799 },
  { code: "HK", name: "University of Hong Kong", id: 2800 },
  { code: "HN", name: "Escuela Agricola Panamericana Zamorano", id: 2801 },
  { code: "HN", name: "Universidad Católica de Honduras", id: 2802 },
  { code: "HN", name: "Universidad de San Pedro Sula", id: 2803 },
  { code: "HN", name: "Universidad José Cecilio del Valle", id: 2804 },
  { code: "HN", name: "Universidad Metropolitana de Honduras", id: 2805 },
  { code: "HN", name: "Universidad Nacional Autónoma de Honduras", id: 2806 },
  { code: "HN", name: "Universidad Pedagógica Nacional Francisco Morazán", id: 2807 },
  { code: "HN", name: "Universidad Tecnológica Centroamericana", id: 2808 },
  { code: "HN", name: "Universidad Tecnológica de Honduras", id: 2809 },
  { code: "HR", name: "University of Dubrovnik", id: 2810 },
  { code: "HR", name: "University of Osijek", id: 2811 },
  { code: "HR", name: "University of Pula", id: 2812 },
  { code: "HR", name: "University of Rijeka", id: 2813 },
  { code: "HR", name: "University of Split", id: 2814 },
  { code: "HR", name: "University of Zadar", id: 2815 },
  { code: "HR", name: "University of Zagreb", id: 2816 },
  { code: "HR", name: "Zagreb School of Economics and Management", id: 2817 },
  { code: "HT", name: "American University of the Caribbean", id: 2818 },
  { code: "HT", name: "Université Chrétienne du Nord d'Haiti", id: 2819 },
  { code: "HT", name: "Université d'Etat d'Haiti", id: 2820 },
  { code: "HT", name: "Université Épiscopale d'Haiti", id: 2821 },
  { code: "HT", name: "Université Notre Dame d'Haïti", id: 2822 },
  { code: "HT", name: "Université Quisqueya", id: 2823 },
  { code: "HU", name: "Academy of Drama and Film", id: 2824 },
  { code: "HU", name: "Andrassy Gyula German Speaking University Budapest", id: 2825 },
  { code: "HU", name: "Avicenna International College", id: 2826 },
  { code: "HU", name: "Budapest Buddhist University", id: 2827 },
  { code: "HU", name: "Budapest University of Economic Sciences and Public Administration", id: 2828 },
  { code: "HU", name: "Central European University", id: 2829 },
  { code: "HU", name: "College of Dunaujvaros", id: 2830 },
  { code: "HU", name: "Dániel Berzsenyi Teacher Training College", id: 2831 },
  { code: "HU", name: "Debrecen University of Agricultural Sciences", id: 2832 },
  { code: "HU", name: "Eötvös Lorand University", id: 2833 },
  { code: "HU", name: "Esztergom Theological College", id: 2834 },
  { code: "HU", name: "Haynal Imre University of Health Sciences Budapest", id: 2835 },
  { code: "HU", name: "Hungarian Academy of Craft and Design", id: 2836 },
  { code: "HU", name: "Hungarian Academy of Fine Arts Budapest", id: 2837 },
  { code: "HU", name: "Hungarian University of Physical Education", id: 2838 },
  { code: "HU", name: "Janus Pannonius University", id: 2839 },
  { code: "HU", name: "Károl Gáspár University of the Reformed Church", id: 2840 },
  { code: "HU", name: "Kodolanyi Janos University College", id: 2841 },
  { code: "HU", name: "Kossuth Lajos University", id: 2842 },
  { code: "HU", name: "Liszt Ferenc Academy of Music Budapest", id: 2843 },
  { code: "HU", name: "Pázmány Péter Catholic University", id: 2844 },
  { code: "HU", name: "Reformed Theological Academy of Debrecen", id: 2845 },
  { code: "HU", name: "Séchenyi István University", id: 2846 },
  { code: "HU", name: "Semmelweis University of Medical Sciences", id: 2847 },
  { code: "HU", name: "Szent István University", id: 2848 },
  { code: "HU", name: "Technical University of Budapest", id: 2849 },
  { code: "HU", name: "University Medical School of Debrecen", id: 2850 },
  { code: "HU", name: "University Medical School of Pécs", id: 2851 },
  { code: "HU", name: "University of Debrecen ", id: 2852 },
  { code: "HU", name: "University of Horticulture and Food Industry", id: 2853 },
  { code: "HU", name: "University of Miskolc", id: 2854 },
  { code: "HU", name: "University of Pannonia", id: 2855 },
  { code: "HU", name: "University of Pecs", id: 2856 },
  { code: "HU", name: "University of Sopron", id: 2857 },
  { code: "HU", name: "University of Szeged", id: 2858 },
  { code: "HU", name: "University of Veterinary Science", id: 2859 },
  { code: "HU", name: "University of West Hungary", id: 2860 },
  { code: "ID", name: "Abfi Institute Perbanas Jakarta", id: 2861 },
  { code: "ID", name: "Binus University", id: 2862 },
  { code: "ID", name: "Bogor Agricultural University", id: 2863 },
  { code: "ID", name: "Institut Sains dan Teknologi Al Kamal", id: 2864 },
  { code: "ID", name: "Institut Sains & Teknologi Akprind", id: 2865 },
  { code: "ID", name: "Institut Teknologi Adhi Tama Surabaya", id: 2866 },
  { code: "ID", name: "Institut Teknologi Bandung", id: 2867 },
  { code: "ID", name: "Institut Teknologi Sepuluh Nopember", id: 2868 },
  { code: "ID", name: "Institut Teknologi Telkom", id: 2869 },
  { code: "ID", name: "Politeknik Negeri Bandung", id: 2870 },
  { code: "ID", name: "Politeknik Negeri Jakarta", id: 2871 },
  { code: "ID", name: "Politeknik Negeri Lhokseumawe", id: 2872 },
  { code: "ID", name: "Politeknik Negeri Malang", id: 2873 },
  { code: "ID", name: "Politeknik Negeri Padang", id: 2874 },
  { code: "ID", name: "Politeknik Negeri Pontianak", id: 2875 },
  { code: "ID", name: "Politeknik Negeri Sambas", id: 2876 },
  { code: "ID", name: "Politeknik Negeri Semarang", id: 2877 },
  { code: "ID", name: "Politeknik Pos Indonesia", id: 2878 },
  { code: "ID", name: "Prasetiya Mulya Business School", id: 2879 },
  { code: "ID", name: "Sekolah Tinggi Akuntansi Negara (STAN)", id: 2880 },
  { code: "ID", name: "Stie Perbanas Surabaya", id: 2881 },
  { code: "ID", name: "STMIK AMIKOM Yogyakarta", id: 2882 },
  { code: "ID", name: "STMIK Sinar Nusantara", id: 2883 },
  { code: "ID", name: "Swiss German University", id: 2884 },
  { code: "ID", name: "Trisakti Institute of Tourism ", id: 2885 },
  { code: "ID", name: "Unitomo Surabaya", id: 2886 },
  { code: "ID", name: "Universitas 17 Agustus 1945 Banyuwangi", id: 2887 },
  { code: "ID", name: "Universitas 17 Agustus 1945 Cirebon", id: 2888 },
  { code: "ID", name: "Universitas 17 Agustus 1945 Jakarta", id: 2889 },
  { code: "ID", name: "Universitas 17 Agustus 1945 Samarinda", id: 2890 },
  { code: "ID", name: "Universitas 17 Agustus 1945 Semarang", id: 2891 },
  { code: "ID", name: "Universitas 17 Agustus 1945 Surabaya", id: 2892 },
  { code: "ID", name: "Universitas Advent Indonesia", id: 2893 },
  { code: "ID", name: "Universitas Ahmad Dahlan", id: 2894 },
  { code: "ID", name: "Universitas Airlangga", id: 2895 },
  { code: "ID", name: "Universitas Amir Hamzah", id: 2896 },
  { code: "ID", name: "Universitas Andalas", id: 2897 },
  { code: "ID", name: "Universitas Atma Jaya Yogyakarta", id: 2898 },
  { code: "ID", name: "Universitas Bakrie", id: 2899 },
  { code: "ID", name: "Universitas Bengkulu", id: 2900 },
  { code: "ID", name: "Universitas Bhayangkara Jakarta Raya", id: 2901 },
  { code: "ID", name: "Universitas Bhayangkara Surabaya", id: 2902 },
  { code: "ID", name: "Universitas Bojonegoro", id: 2903 },
  { code: "ID", name: "Universitas Brawijaya", id: 2904 },
  { code: "ID", name: "Universitas Bunda Mulia Jakarta", id: 2905 },
  { code: "ID", name: "Universitas Bung Hatta", id: 2906 },
  { code: "ID", name: "Universitas Cenderawasih", id: 2907 },
  { code: "ID", name: "Universitas Darma Agung", id: 2908 },
  { code: "ID", name: "Universitas Darma Persada", id: 2909 },
  { code: "ID", name: "Universitas Dian Nuswantoro", id: 2910 },
  { code: "ID", name: "Universitas Diponegoro", id: 2911 },
  { code: "ID", name: "Universitas Dr. R. Moestopo", id: 2912 },
  { code: "ID", name: "Universitas Gadjah Mada", id: 2913 },
  { code: "ID", name: "Universitas Gajayana Malang", id: 2914 },
  { code: "ID", name: "Universitas Gunadarma", id: 2915 },
  { code: "ID", name: "Universitas Haluoleo", id: 2916 },
  { code: "ID", name: "Universitas Hasanuddin", id: 2917 },
  { code: "ID", name: "Universitas HKBP Nommensen", id: 2918 },
  { code: "ID", name: "Universitas Ibn Chaldun Jakarta", id: 2919 },
  { code: "ID", name: "Universitas Ibn Khaldun Bogor", id: 2920 },
  { code: "ID", name: "Universitas Indonesia", id: 2921 },
  { code: "ID", name: "Universitas Indonusa Esa Unggul", id: 2922 },
  { code: "ID", name: "Universitas Islam Bandung", id: 2923 },
  { code: "ID", name: "Universitas Islam Indonesia", id: 2924 },
  { code: "ID", name: "Universitas Islam Jakarta", id: 2925 },
  { code: "ID", name: "Universitas Islam Malang", id: 2926 },
  { code: "ID", name: "Universitas Islam Nusantara", id: 2927 },
  { code: "ID", name: "Universitas Islam Riau", id: 2928 },
  { code: "ID", name: "Universitas Islam Sultan Agung", id: 2929 },
  { code: "ID", name: "Universitas Islam Sumatera Utara", id: 2930 },
  { code: "ID", name: "Universitas Islam Syekh-Yusuf ", id: 2931 },
  { code: "ID", name: "Universitas Jambi", id: 2932 },
  { code: "ID", name: "Universitas Janabadra", id: 2933 },
  { code: "ID", name: "Universitas Jayabaya", id: 2934 },
  { code: "ID", name: "Universitas Jember", id: 2935 },
  { code: "ID", name: "Universitas Jenderal Achmad Yani", id: 2936 },
  { code: "ID", name: "Universitas Jenderal Soedirman", id: 2937 },
  { code: "ID", name: "Universitas Katolik Indonesia Atma Jaya", id: 2938 },
  { code: "ID", name: "Universitas Katolik Parahyangan", id: 2939 },
  { code: "ID", name: "Universitas Katolik Soegijapranoto", id: 2940 },
  { code: "ID", name: "Universitas Katolik Widya Karya", id: 2941 },
  { code: "ID", name: "Universitas Katolik Widya Manadala", id: 2942 },
  { code: "ID", name: "Universitas Kediri", id: 2943 },
  { code: "ID", name: "Universitas Khairun", id: 2944 },
  { code: "ID", name: "Universitas Klabat", id: 2945 },
  { code: "ID", name: "Universitas Komputer Indonesia", id: 2946 },
  { code: "ID", name: "Universitas Krisnadwipayana", id: 2947 },
  { code: "ID", name: "Universitas Kristen Duta Wacana", id: 2948 },
  { code: "ID", name: "Universitas Kristen Indonesia", id: 2949 },
  { code: "ID", name: "Universitas Kristen Indonesia Tomohon", id: 2950 },
  { code: "ID", name: "Universitas Kristen Krida Wacana", id: 2951 },
  { code: "ID", name: "Universitas Kristen Maranatha", id: 2952 },
  { code: "ID", name: "Universitas Kristen Petra", id: 2953 },
  { code: "ID", name: "Universitas Kristen Satya Wacana", id: 2954 },
  { code: "ID", name: "Universitas Lambung Mangkurat", id: 2955 },
  { code: "ID", name: "Universitas Lampung", id: 2956 },
  { code: "ID", name: "Universitas Lancang Kuning", id: 2957 },
  { code: "ID", name: "Universitas Ma Chung", id: 2958 },
  { code: "ID", name: "Universitas Madura", id: 2959 },
  { code: "ID", name: "Universitas Mahasaraswati Denpasar", id: 2960 },
  { code: "ID", name: "Universitas Mahendradatta", id: 2961 },
  { code: "ID", name: "Universitas Mataram", id: 2962 },
  { code: "ID", name: "Universitas Mercu Buana", id: 2963 },
  { code: "ID", name: "Universitas Merdeka Madiun", id: 2964 },
  { code: "ID", name: "Universitas Merdeka Malang", id: 2965 },
  { code: "ID", name: "Universitas Methodist Indonesia", id: 2966 },
  { code: "ID", name: "Universitas Muhammadiyah Jakarta", id: 2967 },
  { code: "ID", name: "Universitas Muhammadiyah Jember", id: 2968 },
  { code: "ID", name: "Universitas Muhammadiyah Magelang", id: 2969 },
  { code: "ID", name: "Universitas Muhammadiyah Makassar", id: 2970 },
  { code: "ID", name: "Universitas Muhammadiyah Malang", id: 2971 },
  { code: "ID", name: "Universitas Muhammadiyah Mataram", id: 2972 },
  { code: "ID", name: "Universitas Muhammadiyah Sumatra Utara", id: 2973 },
  { code: "ID", name: "Universitas Muhammadiyah Surakarta", id: 2974 },
  { code: "ID", name: "Universitas Muhammadiyah Yogyakarta", id: 2975 },
  { code: "ID", name: "Universitas Mulawarman", id: 2976 },
  { code: "ID", name: "Universitas Muria Kudus", id: 2977 },
  { code: "ID", name: "Universitas Muslim Indonesia", id: 2978 },
  { code: "ID", name: "Universitas Narotama", id: 2979 },
  { code: "ID", name: "Universitas Nasional Jakarta", id: 2980 },
  { code: "ID", name: "Universitas Nasional Pasim", id: 2981 },
  { code: "ID", name: "Universitas Negeri Gorontalo", id: 2982 },
  { code: "ID", name: "Universitas Negeri Jakarta", id: 2983 },
  { code: "ID", name: "Universitas Negeri Makassar", id: 2984 },
  { code: "ID", name: "Universitas Negeri Malang", id: 2985 },
  { code: "ID", name: "Universitas Negeri Manado", id: 2986 },
  { code: "ID", name: "Universitas Negeri Medan", id: 2987 },
  { code: "ID", name: "Universitas Negeri Padang", id: 2988 },
  { code: "ID", name: "Universitas Negeri Semarang", id: 2989 },
  { code: "ID", name: "Universitas Negeri Surabaya", id: 2990 },
  { code: "ID", name: "Universitas Negeri Yogyakarta", id: 2991 },
  { code: "ID", name: "Universitas Ngurah Rai", id: 2992 },
  { code: "ID", name: "Universitas Nusa Cendana", id: 2993 },
  { code: "ID", name: "Universitas Padjadjaran", id: 2994 },
  { code: "ID", name: "Universitas Pakuan", id: 2995 },
  { code: "ID", name: "Universitas Palangka Raya", id: 2996 },
  { code: "ID", name: "Universitas Pancasakti Tegal", id: 2997 },
  { code: "ID", name: "Universitas Pancasila", id: 2998 },
  { code: "ID", name: "Universitas Paramadina Mulya", id: 2999 },
  { code: "ID", name: "Universitas Pasundan", id: 3000 },
  { code: "ID", name: "Universitas Pattimura", id: 3001 },
  { code: "ID", name: "Universitas Pekalongan", id: 3002 },
  { code: "ID", name: "Universitas Pelita Harapan", id: 3003 },
  { code: "ID", name: "Universitas Pembangunan Nasional Veteran East Java", id: 3004 },
  { code: "ID", name: "Universitas Pembangunan Panca Budi", id: 3005 },
  { code: "ID", name: "Universitas Pendidikan Indonesia", id: 3006 },
  { code: "ID", name: "Universitas Persada Indonesia Y.A.I", id: 3007 },
  { code: "ID", name: "Universitas Pesantren Darul Ulum Jombang", id: 3008 },
  { code: "ID", name: "Universitas Proklamasi 45", id: 3009 },
  { code: "ID", name: "Universitas Putera Batam", id: 3010 },
  { code: "ID", name: "Universitas Riau", id: 3011 },
  { code: "ID", name: "Universitas Sam Ratulangi", id: 3012 },
  { code: "ID", name: "Universitas Sanata Dharma", id: 3013 },
  { code: "ID", name: "Universitas Sarjanawiyata Tamansiswa", id: 3014 },
  { code: "ID", name: "Universitas Sebelas Maret", id: 3015 },
  { code: "ID", name: "Universitas Semarang", id: 3016 },
  { code: "ID", name: "Universitas Siliwangi", id: 3017 },
  { code: "ID", name: "Universitas Simalungun", id: 3018 },
  { code: "ID", name: "Universitas Slamet Riyadi Surakarta", id: 3019 },
  { code: "ID", name: "Universitas Sriwijaya", id: 3020 },
  { code: "ID", name: "Universitas Sultan Ageng Tirtayasa", id: 3021 },
  { code: "ID", name: "Universitas Sumatera Utara", id: 3022 },
  { code: "ID", name: "Universitas Surabaya", id: 3023 },
  { code: "ID", name: "Universitas Swadaya Gunung Djati", id: 3024 },
  { code: "ID", name: "Universitas Syiah Kuala", id: 3025 },
  { code: "ID", name: "Universitas Tadulako", id: 3026 },
  { code: "ID", name: "Universitas Tanjungpura", id: 3027 },
  { code: "ID", name: "Universitas Tarumanagara", id: 3028 },
  { code: "ID", name: "Universitas Terbuka Indonesia", id: 3029 },
  { code: "ID", name: "Universitas Tidar Magelang", id: 3030 },
  { code: "ID", name: "Universitas Trilogi", id: 3031 },
  { code: "ID", name: "Universitas Trisakti", id: 3032 },
  { code: "ID", name: "Universitas Tunas Pembangunan", id: 3033 },
  { code: "ID", name: "Universitas Udayana", id: 3034 },
  { code: "ID", name: "Universitas Widya Gama Malang", id: 3035 },
  { code: "ID", name: "Universitas Widyatama", id: 3036 },
  { code: "ID", name: "Universitas Wijaya Kusuma Purwokerto", id: 3037 },
  { code: "ID", name: "Universitas Wijaya Kusuma Surabaya", id: 3038 },
  { code: "ID", name: "Universitas Wisnuwardhana", id: 3039 },
  { code: "ID", name: "Universitas Yarsi", id: 3040 },
  { code: "IE", name: "Athlone Institute of Technology", id: 3041 },
  { code: "IE", name: "Carlow Institute of Technology", id: 3042 },
  { code: "IE", name: "Cork Institute of Technology", id: 3043 },
  { code: "IE", name: "Dublin City University", id: 3044 },
  { code: "IE", name: "Dublin Institute for Advanced Studies", id: 3045 },
  { code: "IE", name: "Dublin Institute of Technology", id: 3046 },
  { code: "IE", name: "Galway Mayo Institute of Technology", id: 3047 },
  { code: "IE", name: "Griffith College", id: 3048 },
  { code: "IE", name: "Institue of Technology", id: 3049 },
  { code: "IE", name: "Irish International University (Distance Education)", id: 3050 },
  { code: "IE", name: "LSB College", id: 3051 },
  { code: "IE", name: "National College of Ireland", id: 3052 },
  { code: "IE", name: "National University of Ireland", id: 3053 },
  { code: "IE", name: "Royal College of Physicians of Ireland", id: 3054 },
  { code: "IE", name: "Royal College of Surgeons", id: 3055 },
  { code: "IE", name: "Shannon College of Hotel Management", id: 3056 },
  { code: "IE", name: "University College Cork", id: 3057 },
  { code: "IE", name: "University College Dublin", id: 3058 },
  { code: "IE", name: "University of Dublin", id: 3059 },
  { code: "IE", name: "University of Limerick", id: 3060 },
  { code: "IE", name: "Warnborough University", id: 3061 },
  { code: "IE", name: "Waterford Institute Of Technology", id: 3062 },
  { code: "IL", name: "Acdemic Center for Law and Business", id: 3063 },
  { code: "IL", name: "Afeka Tel Aviv Academic College of Engineering", id: 3064 },
  { code: "IL", name: "Ariel University Center of Samaria", id: 3065 },
  { code: "IL", name: "Ashkelon Academic College", id: 3066 },
  { code: "IL", name: "Bar-Ilan University", id: 3067 },
  { code: "IL", name: "Ben-Gurion University of the Negev", id: 3068 },
  { code: "IL", name: "Bezalel Academy of Art and Design", id: 3069 },
  { code: "IL", name: "College of Management", id: 3070 },
  { code: "IL", name: "Emeq Yizrael College", id: 3071 },
  { code: "IL", name: "Galillee College", id: 3072 },
  { code: "IL", name: "Hebrew University of Jerusalem", id: 3073 },
  { code: "IL", name: "Jerusalem University College", id: 3074 },
  { code: "IL", name: "Open University of Israel", id: 3075 },
  { code: "IL", name: "Russell Berrie Nanotechnology Institute", id: 3076 },
  { code: "IL", name: "Sami Shamoon College of Engineering", id: 3077 },
  { code: "IL", name: "Shenkar School of Engineering & Design", id: 3078 },
  { code: "IL", name: "Technion - Israel Institute of Technology", id: 3079 },
  { code: "IL", name: "Tel Aviv University", id: 3080 },
  { code: "IL", name: "The Interdisciplinary Center Herzliya", id: 3081 },
  { code: "IL", name: "University of Haifa", id: 3082 },
  { code: "IL", name: "Weizmann Institute of Science", id: 3083 },
  { code: "IL", name: "Western Galilee College", id: 3084 },
  { code: "IN", name: "Acharya Nagarjuna University", id: 3085 },
  { code: "IN", name: "Acharya Ranga Agricultural University", id: 3086 },
  { code: "IN", name: "Ahmedabad University", id: 3087 },
  { code: "IN", name: "AISECT University", id: 3088 },
  { code: "IN", name: "Alagappa University", id: 3089 },
  { code: "IN", name: "Aligarh Muslim University", id: 3090 },
  { code: "IN", name: "Allahabad Agricultural Institute", id: 3091 },
  { code: "IN", name: "Allahabad University", id: 3092 },
  { code: "IN", name: "All India Institute of Medical Sciences", id: 3093 },
  { code: "IN", name: "Amity University", id: 3094 },
  { code: "IN", name: "Amravati University", id: 3095 },
  { code: "IN", name: "Amrita Vishwa Vidyapeetham (Deemed University)", id: 3096 },
  { code: "IN", name: "Andhra University", id: 3097 },
  { code: "IN", name: "Annamalai University", id: 3098 },
  { code: "IN", name: "Anna University", id: 3099 },
  { code: "IN", name: "Anna University of Technology", id: 3100 },
  { code: "IN", name: "ARYA Institute of Engineering & Technology", id: 3101 },
  { code: "IN", name: "Asian Business School", id: 3102 },
  { code: "IN", name: "Assam Agricultural University", id: 3103 },
  { code: "IN", name: "Assam University", id: 3104 },
  { code: "IN", name: "Avinashilingam Institute for Home Science and Higher Education for Women", id: 3105 },
  { code: "IN", name: "Awadhesh Pratap Singh University", id: 3106 },
  { code: "IN", name: "Babasaheb Bhimrao Ambedkar University", id: 3107 },
  { code: "IN", name: "Banaras Hindu University", id: 3108 },
  { code: "IN", name: "Banasthali University", id: 3109 },
  { code: "IN", name: "Bangalore University", id: 3110 },
  { code: "IN", name: "Bankura University", id: 3111 },
  { code: "IN", name: "Barkatullah University", id: 3112 },
  { code: "IN", name: "Bengal Engineering College", id: 3113 },
  { code: "IN", name: "Berhampur University", id: 3114 },
  { code: "IN", name: "Bhagwant University", id: 3115 },
  { code: "IN", name: "Bharathiar University", id: 3116 },
  { code: "IN", name: "Bharathidasan University", id: 3117 },
  { code: "IN", name: "Bharath Institue Of Higher Education & Research", id: 3118 },
  { code: "IN", name: "Bharati Vidyapeeth University", id: 3119 },
  { code: "IN", name: "Bhavnagar University", id: 3120 },
  { code: "IN", name: "Bhupendra Narayan Mandal University", id: 3121 },
  { code: "IN", name: "Bidhan Chandra Agricultural University", id: 3122 },
  { code: "IN", name: "Biju Pattnaik University of Technology", id: 3123 },
  { code: "IN", name: "Birla Institute of Technology and Science", id: 3124 },
  { code: "IN", name: "Birla Institute of Technology", id: 3125 },
  { code: "IN", name: "Birsa Agricultural University", id: 3126 },
  { code: "IN", name: "B. R. Ambedkar Bihar University", id: 3127 },
  { code: "IN", name: "B. S. Abdur Rahman University", id: 3128 },
  { code: "IN", name: "Bundelkhand University", id: 3129 },
  { code: "IN", name: "Burdwan University", id: 3130 },
  { code: "IN", name: "CCS Haryana Agricultural University", id: 3131 },
  { code: "IN", name: "Center for Entrepreneurship and Small Business Management", id: 3132 },
  { code: "IN", name: "Central Agricultural University", id: 3133 },
  { code: "IN", name: "Central Institute of English and Foreign Languages", id: 3134 },
  { code: "IN", name: "Central Institute of Fisheries Education", id: 3135 },
  { code: "IN", name: "Central Institute of Higher Tibetan Studies", id: 3136 },
  { code: "IN", name: "Chandra Shekhar Azad University of Agriculture and Technology", id: 3137 },
  { code: "IN", name: "Chaudhary Charan Singh Haryana Agricultural University", id: 3138 },
  { code: "IN", name: "Chaudhary Charan Singh University", id: 3139 },
  { code: "IN", name: "Ch. Charan Singh University", id: 3140 },
  { code: "IN", name: "Chhatrapati Shahu Ji Maharaj University", id: 3141 },
  { code: "IN", name: "Christ University", id: 3142 },
  { code: "IN", name: "CMJ University", id: 3143 },
  { code: "IN", name: "Cochin University of Science and Technology", id: 3144 },
  { code: "IN", name: "Darul Huda Islamic University", id: 3145 },
  { code: "IN", name: "Dav University", id: 3146 },
  { code: "IN", name: "Dayalbagh Educational Institute", id: 3147 },
  { code: "IN", name: "Deccan College Postgraduate and Research Institute", id: 3148 },
  { code: "IN", name: "Deen Dayal Upadhyay Gorakhpur University", id: 3149 },
  { code: "IN", name: "Delhi College of Engineering (DCE)", id: 3150 },
  { code: "IN", name: "Devi Ahilya University of Indore", id: 3151 },
  { code: "IN", name: "Dhirubhai Ambani Institute of Information and Communication Technology", id: 3152 },
  { code: "IN", name: "Dibrugarh University", id: 3153 },
  { code: "IN", name: "Dravidian University", id: 3154 },
  { code: "IN", name: "Dr. Babasaheb Ambedkar Marathwada Universtiy", id: 3155 },
  { code: "IN", name: "Dr. Babasaheb Ambedkar Technological University", id: 3156 },
  { code: "IN", name: "Dr. Bhim Rao Abdekar University", id: 3157 },
  { code: "IN", name: "Dr. B.R. Ambedkar Open University", id: 3158 },
  { code: "IN", name: "Dr. C.V. Raman University", id: 3159 },
  { code: "IN", name: "Dr. D.Y. Patil University", id: 3160 },
  { code: "IN", name: "Dr. Hari Singh Gour University", id: 3161 },
  { code: "IN", name: "Dr. Panjabrao Deshmukh Krishi Vidyapeeth", id: 3162 },
  { code: "IN", name: "Dr. Ram Manohar Lohia Avadh University", id: 3163 },
  { code: "IN", name: "Dr. YS Parmar University of Horticulture and Forestry", id: 3164 },
  { code: "IN", name: "Eiilm University", id: 3165 },
  { code: "IN", name: "Engineering Colleges in Tamil Nadu", id: 3166 },
  { code: "IN", name: "Fakir Mohan University", id: 3167 },
  { code: "IN", name: "Fatima mata national college kollam kerala", id: 3168 },
  { code: "IN", name: "First Global University to teaching Jainism", id: 3169 },
  { code: "IN", name: "Forest Research Institute Dehradun", id: 3170 },
  { code: "IN", name: "Gandhigram Rural Institute", id: 3171 },
  { code: "IN", name: "Gandhi Institute of Technology and Managment", id: 3172 },
  { code: "IN", name: "Gauhati University", id: 3173 },
  { code: "IN", name: "Global Open University ", id: 3174 },
  { code: "IN", name: "Goa University", id: 3175 },
  { code: "IN", name: "Gokhale Institute of Politics and Economics", id: 3176 },
  { code: "IN", name: "Govind Ballabh Pant University of Agriculture and Technology", id: 3177 },
  { code: "IN", name: "Gujarat Ayurved University", id: 3178 },
  { code: "IN", name: "Gujarat Technological University Ahmedabad", id: 3179 },
  { code: "IN", name: "Gujarat University Ahmedabad", id: 3180 },
  { code: "IN", name: "Gulbarga University", id: 3181 },
  { code: "IN", name: "Guru Ghasidas University", id: 3182 },
  { code: "IN", name: "Guru Gobind Singh Indraprastha University", id: 3183 },
  { code: "IN", name: "Guru Jambeshwar University", id: 3184 },
  { code: "IN", name: "Gurukula Kangri University", id: 3185 },
  { code: "IN", name: "Gurukul University", id: 3186 },
  { code: "IN", name: "Guru Nanak Dev University", id: 3187 },
  { code: "IN", name: "Hemchandracharay North Gujarat University", id: 3188 },
  { code: "IN", name: "Hemwati Nandan Bahuguna Garhwal University", id: 3189 },
  { code: "IN", name: "Hidayatullah National Law University", id: 3190 },
  { code: "IN", name: "Himachal Pradesh Agricultural University", id: 3191 },
  { code: "IN", name: "Himachal Pradesh University", id: 3192 },
  { code: "IN", name: "IASE (Institute of Advanced Studies) Deemed University", id: 3193 },
  { code: "IN", name: "ICFAI University", id: 3194 },
  { code: "IN", name: "Indian Agricultural Research Institute", id: 3195 },
  { code: "IN", name: "Indian Board of Alternative Medicine", id: 3196 },
  { code: "IN", name: "Indian Institute of Information Technology", id: 3197 },
  { code: "IN", name: "Indian Institute of Information Technology and Management - Kerala", id: 3198 },
  { code: "IN", name: "Indian Institute of Management", id: 3199 },
  { code: "IN", name: "Indian Institute of Science", id: 3200 },
  { code: "IN", name: "Indian Institute of Technology", id: 3201 },
  { code: "IN", name: "Indian Law Institue", id: 3202 },
  { code: "IN", name: "Indian School of Business Management and Administration", id: 3203 },
  { code: "IN", name: "Indian School of Mines (ISM)", id: 3204 },
  { code: "IN", name: "Indian Statistical Institute", id: 3205 },
  { code: "IN", name: "Indian University", id: 3206 },
  { code: "IN", name: "Indian Veterinary Research Institute", id: 3207 },
  { code: "IN", name: "Indira Gandhi Agricultural University", id: 3208 },
  { code: "IN", name: "Indira Gandhi Institute of Development and Research", id: 3209 },
  { code: "IN", name: "Indira Gandhi Institute of Medical Sciences", id: 3210 },
  { code: "IN", name: "Indira Gandhi National Open University", id: 3211 },
  { code: "IN", name: "Institute of Management and Technical Studies ", id: 3212 },
  { code: "IN", name: "Institute of Technology and Management", id: 3213 },
  { code: "IN", name: "Integral University", id: 3214 },
  { code: "IN", name: "International Institute for Population Sciences", id: 3215 },
  { code: "IN", name: "Jadavpur University", id: 3216 },
  { code: "IN", name: "Jagran Lakecity University", id: 3217 },
  { code: "IN", name: "Jai Narayan Vyas University", id: 3218 },
  { code: "IN", name: "Jain University", id: 3219 },
  { code: "IN", name: "Jain Vishva Bharati Institute", id: 3220 },
  { code: "IN", name: "Jai Prakash University", id: 3221 },
  { code: "IN", name: "Jaipur National University", id: 3222 },
  { code: "IN", name: "Jamia Hamdard University", id: 3223 },
  { code: "IN", name: "Jamia Millia Islamia University", id: 3224 },
  { code: "IN", name: "Jawaharlal Nehru Agricultural University", id: 3225 },
  { code: "IN", name: "Jawaharlal Nehru Centre for Advanced  Scientific Research", id: 3226 },
  { code: "IN", name: "Jawaharlal Nehru Technological University", id: 3227 },
  { code: "IN", name: "Jawaharlal Nehru University", id: 3228 },
  { code: "IN", name: "Jaypee Institute of Information Technology", id: 3229 },
  { code: "IN", name: "Jiwaji University", id: 3230 },
  { code: "IN", name: "Jodhpur National University", id: 3231 },
  { code: "IN", name: "Kakatiya University", id: 3232 },
  { code: "IN", name: "Kalinga University", id: 3233 },
  { code: "IN", name: "Kameshwar Singh Darbhanga Sanskrit University", id: 3234 },
  { code: "IN", name: "Kamrah International Institute of Technology (KIIT)", id: 3235 },
  { code: "IN", name: "Kannada University", id: 3236 },
  { code: "IN", name: "Kannur University", id: 3237 },
  { code: "IN", name: "Kanpur University", id: 3238 },
  { code: "IN", name: "Karnataka State Law University", id: 3239 },
  { code: "IN", name: "Karnataka State Open University", id: 3240 },
  { code: "IN", name: "Karnatak University", id: 3241 },
  { code: "IN", name: "Karpagam University", id: 3242 },
  { code: "IN", name: "Karunya Institute Of Technology ( Deemed University)", id: 3243 },
  { code: "IN", name: "Kerala Agricultural University", id: 3244 },
  { code: "IN", name: "Kerala University of Fisheries and Ocean Sciences", id: 3245 },
  { code: "IN", name: "Kohinoor School of Hospitality Management", id: 3246 },
  { code: "IN", name: "Konkan Agricultural University", id: 3247 },
  { code: "IN", name: "Krishna University", id: 3248 },
  { code: "IN", name: "Kumaun University", id: 3249 },
  { code: "IN", name: "Kurukshetra University", id: 3250 },
  { code: "IN", name: "Kuvempu University", id: 3251 },
  { code: "IN", name: "Lakshmibai National Institute of Physical Education", id: 3252 },
  { code: "IN", name: "Lalit Narayan Mithila University", id: 3253 },
  { code: "IN", name: " L.D.College of Engineering", id: 3254 },
  { code: "IN", name: "Madhya Pradesh Bhoj (Open) University", id: 3255 },
  { code: "IN", name: "Madurai Kamaraj University", id: 3256 },
  { code: "IN", name: "Magadh University", id: 3257 },
  { code: "IN", name: "Maharaja Ganga Singh University", id: 3258 },
  { code: "IN", name: "Maharaja Sayajirao University of Baroda", id: 3259 },
  { code: "IN", name: "Maharishi Dayanand University", id: 3260 },
  { code: "IN", name: "Maharshi Dayanand Sarswati University Ajmer", id: 3261 },
  { code: "IN", name: "Mahatma Gandhi Chitrakut Gramoday University", id: 3262 },
  { code: "IN", name: "Mahatma Gandhi Kashi Vidyapith ", id: 3263 },
  { code: "IN", name: "Mahatma Gandhi University", id: 3264 },
  { code: "IN", name: "Mahatma Gandhi University Meghalaya ", id: 3265 },
  { code: "IN", name: "Mahatma Jyotiba Phule Rohilkhand University Bareilly ", id: 3266 },
  { code: "IN", name: "Mahatma Phule Agricultural University", id: 3267 },
  { code: "IN", name: "Makanlal Chutur Vedi University", id: 3268 },
  { code: "IN", name: "Makhanlal Chaturvedi National University of Journalism and Communication", id: 3269 },
  { code: "IN", name: "Mangalore University", id: 3270 },
  { code: "IN", name: "Manipal University", id: 3271 },
  { code: "IN", name: "Manipur University", id: 3272 },
  { code: "IN", name: "Manonmaniam Sundaranar University", id: 3273 },
  { code: "IN", name: "Mar Athanasios College for Advanced Studies", id: 3274 },
  { code: "IN", name: "Marathwada Agricultural University", id: 3275 },
  { code: "IN", name: "Mohan Lal Sukhadia University", id: 3276 },
  { code: "IN", name: "Mother Teresa Women's University", id: 3277 },
  { code: "IN", name: "Motilal Nehru National Institute Of Technology", id: 3278 },
  { code: "IN", name: "Moulana Azad national Urdu University", id: 3279 },
  { code: "IN", name: "Nagaland University", id: 3280 },
  { code: "IN", name: "Nagpur University", id: 3281 },
  { code: "IN", name: "Nalanda Open University", id: 3282 },
  { code: "IN", name: "Narendra Deva University of Agriculture and Technology", id: 3283 },
  { code: "IN", name: "Narsee Monjee Institute of Management Studies", id: 3284 },
  { code: "IN", name: "National Dairy Research Institute", id: 3285 },
  { code: "IN", name: "National Institute of Industrial Engineering", id: 3286 },
  { code: "IN", name: "National Institute of Mental Health and Neuro Sciences", id: 3287 },
  { code: "IN", name: "National Institute of Technology", id: 3288 },
  { code: "IN", name: "National Institute of Technology Kurukshetra", id: 3289 },
  { code: "IN", name: "National Law School of India University", id: 3290 },
  { code: "IN", name: "National Museum Institute of History of Art", id: 3291 },
  { code: "IN", name: "National Sanskrit University", id: 3292 },
  { code: "IN", name: "Netaji Subhas Inst of Technology", id: 3293 },
  { code: "IN", name: "Nirma University", id: 3294 },
  { code: "IN", name: "Nizam's Institute of Medical Sciences", id: 3295 },
  { code: "IN", name: "North Bengal University", id: 3296 },
  { code: "IN", name: "North Eastern Hill University", id: 3297 },
  { code: "IN", name: "North Maharashtra University", id: 3298 },
  { code: "IN", name: "Open International University for Alternative Medicines", id: 3299 },
  { code: "IN", name: "Orissa University of Agriculture and Technology", id: 3300 },
  { code: "IN", name: "Osmania University", id: 3301 },
  { code: "IN", name: "Pandit Ravi Shankar Shukla University", id: 3302 },
  { code: "IN", name: "Panjab University", id: 3303 },
  { code: "IN", name: "Patna University", id: 3304 },
  { code: "IN", name: "Periyar University", id: 3305 },
  { code: "IN", name: "Pes University", id: 3306 },
  { code: "IN", name: "Pondicherry University", id: 3307 },
  { code: "IN", name: "Postgraduate lnstitute of Medical Education and Research", id: 3308 },
  { code: "IN", name: "Potti Sreeramulu Telugu University", id: 3309 },
  { code: "IN", name: "PSG College of Technology", id: 3310 },
  { code: "IN", name: "Punjab Agricultural University", id: 3311 },
  { code: "IN", name: "Punjab Engineering College Deemed University", id: 3312 },
  { code: "IN", name: "Punjabi University Patiala", id: 3313 },
  { code: "IN", name: "Punjab Technical University", id: 3314 },
  { code: "IN", name: "Rabindra Bharati University", id: 3315 },
  { code: "IN", name: "Rajasthan Agricultural University", id: 3316 },
  { code: "IN", name: "Rajasthan Technical University", id: 3317 },
  { code: "IN", name: "Rajasthan Vidyapeeth University", id: 3318 },
  { code: "IN", name: "Rajendra Agricultural University", id: 3319 },
  { code: "IN", name: "Rajitlal Institute of Technology & Health Sciences (RITHS)", id: 3320 },
  { code: "IN", name: "Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)", id: 3321 },
  { code: "IN", name: "Rajiv Gandhi University", id: 3322 },
  { code: "IN", name: "Ranchi University", id: 3323 },
  { code: "IN", name: "Rani Durgavati University", id: 3324 },
  { code: "IN", name: "RKDF University", id: 3325 },
  { code: "IN", name: "Sambalpur University", id: 3326 },
  { code: "IN", name: "Sampurnanand Sanskrit University", id: 3327 },
  { code: "IN", name: "Sanjay Gandhi Postgraduate lnstitute of Medical Sciences", id: 3328 },
  { code: "IN", name: "Sardarkrushinagar Dantiwada Agricultural University", id: 3329 },
  { code: "IN", name: "Sardar Patel University", id: 3330 },
  { code: "IN", name: "Sardar Vallabhai Patel University of Agriculture Amd Technology", id: 3331 },
  { code: "IN", name: "SASTRA Deemed University", id: 3332 },
  { code: "IN", name: "Sathyabama University", id: 3333 },
  { code: "IN", name: "Saurashtra University", id: 3334 },
  { code: "IN", name: "School of Planning and Architecture", id: 3335 },
  { code: "IN", name: "Shanmugha Arts Science Technology and Research Academy", id: 3336 },
  { code: "IN", name: "Sher-E-Kashmir University of Agricultural Sciences and Technology", id: 3337 },
  { code: "IN", name: "Shivaji University", id: 3338 },
  { code: "IN", name: "Shobhit University", id: 3339 },
  { code: "IN", name: "Shreemati Nathibai Damodar Thackersey Women's University", id: 3340 },
  { code: "IN", name: "Shridhar University", id: 3341 },
  { code: "IN", name: "Shri Jagannath Sanskrit University", id: 3342 },
  { code: "IN", name: "Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha", id: 3343 },
  { code: "IN", name: "Shri Mata Vaishno Devi University", id: 3344 },
  { code: "IN", name: "Sido Kanhu Murmu University", id: 3345 },
  { code: "IN", name: "Sikha 'O' Anushandhan University", id: 3346 },
  { code: "IN", name: "Sikkim Manipal University of Health", id: 3347 },
  { code: "IN", name: "Singhania University Rajasthan", id: 3348 },
  { code: "IN", name: "SMK Fomra Institute of Technology", id: 3349 },
  { code: "IN", name: "Solapur University", id: 3350 },
  { code: "IN", name: "South Asian University", id: 3351 },
  { code: "IN", name: "South Gujarat University", id: 3352 },
  { code: "IN", name: "Sree Chitra Tirunal Institute for Medical Sciences and Technology", id: 3353 },
  { code: "IN", name: "Sree Rama Krishna Degree College", id: 3354 },
  { code: "IN", name: "Sree Sankaracharya University of Sanskrit", id: 3355 },
  { code: "IN", name: "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya", id: 3356 },
  { code: "IN", name: "Sri Krishnadevaraya University", id: 3357 },
  { code: "IN", name: "Sri Padmavati Women's University", id: 3358 },
  { code: "IN", name: "Sri Ramachardra Medical College and Research Institute", id: 3359 },
  { code: "IN", name: "Sri Sant Gajanan Maharaj College of Engineering", id: 3360 },
  { code: "IN", name: "Sri Sathya Sai Institute of Higher Learning", id: 3361 },
  { code: "IN", name: "Sri Venkateswara Institute of Medical Sciences", id: 3362 },
  { code: "IN", name: "Sri Venkateswara University", id: 3363 },
  { code: "IN", name: "SRM Institute Of Science & Technology ( Deemed University)", id: 3364 },
  { code: "IN", name: "Sunrise University Alwar", id: 3365 },
  { code: "IN", name: "Swami Ramanand Teerth Marathwada University", id: 3366 },
  { code: "IN", name: "Symbiosis International University", id: 3367 },
  { code: "IN", name: "Takhatmal Shrivallabh Homoeopathic Medical College", id: 3368 },
  { code: "IN", name: "Tamil Nadu Agricultural University", id: 3369 },
  { code: "IN", name: "Tamil Nadu Dr. Ambedkar Law University", id: 3370 },
  { code: "IN", name: "Tamil Nadu Dr. M.G.R. Medical University", id: 3371 },
  { code: "IN", name: "Tamil Nadu Veterinary and Animal Sciences University", id: 3372 },
  { code: "IN", name: "Tamil University", id: 3373 },
  { code: "IN", name: "Tata Institute of Social Sciences", id: 3374 },
  { code: "IN", name: "Telugu University", id: 3375 },
  { code: "IN", name: "Tezpur University", id: 3376 },
  { code: "IN", name: "Thapar Institute of Engineering and Technology", id: 3377 },
  { code: "IN", name: "The New College", id: 3378 },
  { code: "IN", name: "Thiagarajar College of Engineering", id: 3379 },
  { code: "IN", name: "Thiruvalluvar University", id: 3380 },
  { code: "IN", name: "Tilak Maharashtra Vidyapeeth", id: 3381 },
  { code: "IN", name: "Tilka Manjhi Bhagalpur University", id: 3382 },
  { code: "IN", name: "Tripura University", id: 3383 },
  { code: "IN", name: "Tumkur University", id: 3384 },
  { code: "IN", name: "University of Agricultural Sciences", id: 3385 },
  { code: "IN", name: "University of Burdwan", id: 3386 },
  { code: "IN", name: "University of Calcutta", id: 3387 },
  { code: "IN", name: "University of Calicut", id: 3388 },
  { code: "IN", name: "University of Delhi", id: 3389 },
  { code: "IN", name: "University of Health Sciences Andhra Pradesh", id: 3390 },
  { code: "IN", name: "University of Hyderabad", id: 3391 },
  { code: "IN", name: "University of Jammu", id: 3392 },
  { code: "IN", name: "University of Kalyani", id: 3393 },
  { code: "IN", name: "University of Kashmir", id: 3394 },
  { code: "IN", name: "University of Kerala", id: 3395 },
  { code: "IN", name: "University of Kota", id: 3396 },
  { code: "IN", name: "University of Lucknow", id: 3397 },
  { code: "IN", name: "University of Madras", id: 3398 },
  { code: "IN", name: "University of Media Arts", id: 3399 },
  { code: "IN", name: "University of Mumbai", id: 3400 },
  { code: "IN", name: "University of Music and Fine Arts", id: 3401 },
  { code: "IN", name: "University of Mysore", id: 3402 },
  { code: "IN", name: "University of North Bengal", id: 3403 },
  { code: "IN", name: "University of Pune", id: 3404 },
  { code: "IN", name: "University of Rajasthan", id: 3405 },
  { code: "IN", name: "University of Roorkee", id: 3406 },
  { code: "IN", name: "Utkal University", id: 3407 },
  { code: "IN", name: "Uttarakhand Open University ", id: 3408 },
  { code: "IN", name: "Uttarakhand Technical University", id: 3409 },
  { code: "IN", name: "Uttar Pradesh Technical University", id: 3410 },
  { code: "IN", name: "Vardhaman Mahaveer Open University", id: 3411 },
  { code: "IN", name: "VBS Purvanchal University", id: 3412 },
  { code: "IN", name: "Veer Kunwar Singh University", id: 3413 },
  { code: "IN", name: "Veer Surendra Sai University of Technology", id: 3414 },
  { code: "IN", name: "Vellore Institute of Technology", id: 3415 },
  { code: "IN", name: "Vidyasagar University", id: 3416 },
  { code: "IN", name: "Vignan University", id: 3417 },
  { code: "IN", name: "Vikrama Simhapuri University", id: 3418 },
  { code: "IN", name: "Vikram University", id: 3419 },
  { code: "IN", name: "Vinayaka Mission's Research Foundation-Deemed University", id: 3420 },
  { code: "IN", name: "Vinoba Bhave University", id: 3421 },
  { code: "IN", name: "Visva-Bharati University", id: 3422 },
  { code: "IN", name: "Visvesvaraya National Institute of Technology", id: 3423 },
  { code: "IN", name: "Visvesvaraya Technological University", id: 3424 },
  { code: "IN", name: "West Bengal University of Animal and Fishery Sciences", id: 3425 },
  { code: "IN", name: "West Bengal University of Technology", id: 3426 },
  { code: "IN", name: "Yashawantrao Chavan Maharashtra Open University", id: 3427 },
  { code: "IQ", name: "Ahlulbait International University", id: 3428 },
  { code: "IQ", name: "Al Maarif University College", id: 3429 },
  { code: "IQ", name: "Al Mamon University College", id: 3430 },
  { code: "IQ", name: "Al Mansour University College", id: 3431 },
  { code: "IQ", name: "Al Muthanna University", id: 3432 },
  { code: "IQ", name: "Al Nahrain University", id: 3433 },
  { code: "IQ", name: "Al Rafidain University College", id: 3434 },
  { code: "IQ", name: "Al Rasheed University College", id: 3435 },
  { code: "IQ", name: "Alsadrain University", id: 3436 },
  { code: "IQ", name: "Al Turath University College", id: 3437 },
  { code: "IQ", name: "Al Yarmouk University College", id: 3438 },
  { code: "IQ", name: "American University of Iraq", id: 3439 },
  { code: "IQ", name: "Baghdad College of Economic Sciences University", id: 3440 },
  { code: "IQ", name: "Baghdad College of Pharmacy", id: 3441 },
  { code: "IQ", name: "British Royal University", id: 3442 },
  { code: "IQ", name: "Cihan University", id: 3443 },
  { code: "IQ", name: "College of Science", id: 3444 },
  { code: "IQ", name: "Dijla University College", id: 3445 },
  { code: "IQ", name: "Diyala University", id: 3446 },
  { code: "IQ", name: "Hawler Medical University", id: 3447 },
  { code: "IQ", name: "Iraq University College", id: 3448 },
  { code: "IQ", name: "Ishik University", id: 3449 },
  { code: "IQ", name: "Kerbala University", id: 3450 },
  { code: "IQ", name: "Komar University of Science and Technology", id: 3451 },
  { code: "IQ", name: "Koya University (Kurdistan Region)", id: 3452 },
  { code: "IQ", name: "Madenat Alelem University College", id: 3453 },
  { code: "IQ", name: "Misan University", id: 3454 },
  { code: "IQ", name: "Nawroz University", id: 3455 },
  { code: "IQ", name: "Salahddin University (Kurdistan Region)", id: 3456 },
  { code: "IQ", name: "Soran University", id: 3457 },
  { code: "IQ", name: "Sulaimani Polytechnic University", id: 3458 },
  { code: "IQ", name: "Thi Qar University", id: 3459 },
  { code: "IQ", name: "Tikrit University", id: 3460 },
  { code: "IQ", name: "Uinversity of Babylon", id: 3461 },
  { code: "IQ", name: "University of Al-Qadisiyah", id: 3462 },
  { code: "IQ", name: "University of Anbar", id: 3463 },
  { code: "IQ", name: "University of Baghdad", id: 3464 },
  { code: "IQ", name: "University of Basrah", id: 3465 },
  { code: "IQ", name: "University of Dohuk (Kurdistan Region)", id: 3466 },
  { code: "IQ", name: "University of Human Development (Kurdistan Region)", id: 3467 },
  { code: "IQ", name: "University of Kirkuk", id: 3468 },
  { code: "IQ", name: "University of Kufa", id: 3469 },
  { code: "IQ", name: "University of Kurdistan - Hawler", id: 3470 },
  { code: "IQ", name: "University of Mosul", id: 3471 },
  { code: "IQ", name: "University of Mustansiriyah", id: 3472 },
  { code: "IQ", name: "University of Sulaimania (Kurdistan Region)", id: 3473 },
  { code: "IQ", name: "University of Technology - Iraq", id: 3474 },
  { code: "IQ", name: "University of Wassit", id: 3475 },
  { code: "IR", name: "Adiban Higher Education Institue", id: 3476 },
  { code: "IR", name: "Ahvaz University of Medical Sciences", id: 3477 },
  { code: "IR", name: "Ahwaz Jondishapour University of Medical Sciences", id: 3478 },
  { code: "IR", name: "Allameh Tabatabaie University", id: 3479 },
  { code: "IR", name: "Almustafa Open University", id: 3480 },
  { code: "IR", name: "Amirkabir College of Managmant & Technology", id: 3481 },
  { code: "IR", name: "Amirkabir University of Technology", id: 3482 },
  { code: "IR", name: "Arak University", id: 3483 },
  { code: "IR", name: "Arak University of Medical Sciences", id: 3484 },
  { code: "IR", name: "Arak University of Technology", id: 3485 },
  { code: "IR", name: "Ardabil University of Medical Sciences", id: 3486 },
  { code: "IR", name: "Art University", id: 3487 },
  { code: "IR", name: "Azzahra University", id: 3488 },
  { code: "IR", name: "Babol Noshirvani University of Technology", id: 3489 },
  { code: "IR", name: "Babol University of Medical Sciences", id: 3490 },
  { code: "IR", name: "Baghyatoolah Medical Sciences University", id: 3491 },
  { code: "IR", name: "Baha'i Institute for Higher Education", id: 3492 },
  { code: "IR", name: "Birjand University", id: 3493 },
  { code: "IR", name: "Birjand University of Medical Sciences", id: 3494 },
  { code: "IR", name: "Bu Ali Sina University", id: 3495 },
  { code: "IR", name: "Bushehr University of Medical Sciences", id: 3496 },
  { code: "IR", name: "Damghan University of Basic Sciences", id: 3497 },
  { code: "IR", name: "Daneshestan Institute of Higher Education", id: 3498 },
  { code: "IR", name: "Delijan Payame Noor University", id: 3499 },
  { code: "IR", name: "Deylaman Institute of Higher Education", id: 3500 },
  { code: "IR", name: "Fasa Faculty of Medical Sciences", id: 3501 },
  { code: "IR", name: "Ferdowsi University of Mashhad", id: 3502 },
  { code: "IR", name: "Golestan University of Medical Sciences", id: 3503 },
  { code: "IR", name: "Gonabad University of Medical Sciences", id: 3504 },
  { code: "IR", name: "Gorgan University of Agricultural Sciences and Natural Resources", id: 3505 },
  { code: "IR", name: "Guilan University", id: 3506 },
  { code: "IR", name: "Guilan University of Medical Sciences", id: 3507 },
  { code: "IR", name: "Hamadan University of Medical Sciences", id: 3508 },
  { code: "IR", name: "Hormozgan University of Medical Sciences", id: 3509 },
  { code: "IR", name: "Hyrcania Institute of Higher Education", id: 3510 },
  { code: "IR", name: "Ilam University", id: 3511 },
  { code: "IR", name: "Ilam University of Medical Sciences", id: 3512 },
  { code: "IR", name: "Imam Khomeini International University", id: 3513 },
  { code: "IR", name: "Imam Reza University", id: 3514 },
  { code: "IR", name: "Imam Sadiq University", id: 3515 },
  { code: "IR", name: "Iranian Academy of Persian Language and Literature", id: 3516 },
  { code: "IR", name: "Iran University of Medical Sciences", id: 3517 },
  { code: "IR", name: "Iran University of Science and Technology Tehran", id: 3518 },
  { code: "IR", name: "Isfahan University", id: 3519 },
  { code: "IR", name: "Isfahan University of Medical Sciences", id: 3520 },
  { code: "IR", name: "Isfahan University of Technology", id: 3521 },
  { code: "IR", name: "Islamic Azad University", id: 3522 },
  { code: "IR", name: "Jahrom University of Medical Sciences", id: 3523 },
  { code: "IR", name: "Jami Institute of Technology", id: 3524 },
  { code: "IR", name: "Kamalolmolk Art & Architecture Higher Education Institute of Noshahr", id: 3525 },
  { code: "IR", name: "Kar University", id: 3526 },
  { code: "IR", name: "Kashan University", id: 3527 },
  { code: "IR", name: "Kashan University of Medical Sciences", id: 3528 },
  { code: "IR", name: "Kerman Graduate University of Technology", id: 3529 },
  { code: "IR", name: "Kermanshah University of Medical Sciences", id: 3530 },
  { code: "IR", name: "Kermanshah University of Technology", id: 3531 },
  { code: "IR", name: "Kerman University of Medical Sciences", id: 3532 },
  { code: "IR", name: "Khomeinishahr Payame Noor University", id: 3533 },
  { code: "IR", name: "K.N.Toosi University of Technology", id: 3534 },
  { code: "IR", name: "Kurdistan University of Medical Sciences", id: 3535 },
  { code: "IR", name: "Lorestan University", id: 3536 },
  { code: "IR", name: "Lorestan University of Medical Sciences", id: 3537 },
  { code: "IR", name: "Malayer University", id: 3538 },
  { code: "IR", name: "Malekan Payame Nour University", id: 3539 },
  { code: "IR", name: "Mamasani University of Technology", id: 3540 },
  { code: "IR", name: "Mashhad University of Medical Sciences", id: 3541 },
  { code: "IR", name: "Mazandaran University of Iran", id: 3542 },
  { code: "IR", name: "Mazandaran University of Medical Science", id: 3543 },
  { code: "IR", name: "Mazandaran University of Science & Technology", id: 3544 },
  { code: "IR", name: "Mofid University", id: 3545 },
  { code: "IR", name: "Mustafa International University", id: 3546 },
  { code: "IR", name: "Najaf Abad University of Applied Science and Technology", id: 3547 },
  { code: "IR", name: "Payame Noor University", id: 3548 },
  { code: "IR", name: "Persian Gulf University", id: 3549 },
  { code: "IR", name: "Petroleum University of Technology", id: 3550 },
  { code: "IR", name: "Power and Water Institute of Technology", id: 3551 },
  { code: "IR", name: "Qazvin University of Medical Sciences", id: 3552 },
  { code: "IR", name: "Qom University", id: 3553 },
  { code: "IR", name: "Qom University of Medical Sciences", id: 3554 },
  { code: "IR", name: "Qom University of Technology", id: 3555 },
  { code: "IR", name: "Rafsanjan University of Medical Sciences", id: 3556 },
  { code: "IR", name: "Raghebe Esfahani University", id: 3557 },
  { code: "IR", name: "Razi University", id: 3558 },
  { code: "IR", name: "Sabzevar School of Medical Sciences", id: 3559 },
  { code: "IR", name: "Sabzevar Teacher Training University", id: 3560 },
  { code: "IR", name: "Sadjad Institute of Technology", id: 3561 },
  { code: "IR", name: "Sadra University", id: 3562 },
  { code: "IR", name: "Sahand University of Technology", id: 3563 },
  { code: "IR", name: "School of Economic Sciences", id: 3564 },
  { code: "IR", name: "Semnan University", id: 3565 },
  { code: "IR", name: "Semnan University of Medical Sciences", id: 3566 },
  { code: "IR", name: "Shahed University", id: 3567 },
  { code: "IR", name: "Shaheed Beheshti University", id: 3568 },
  { code: "IR", name: "Shaheed Beheshti University of Medical Sciences", id: 3569 },
  { code: "IR", name: "Shaheed Chamran University", id: 3570 },
  { code: "IR", name: "Shaheed Rajaei Teacher Training University", id: 3571 },
  { code: "IR", name: "Shahid Bahonar University of Kerman", id: 3572 },
  { code: "IR", name: "Shahrekord University", id: 3573 },
  { code: "IR", name: "Shahrekord University of Medical Sciences", id: 3574 },
  { code: "IR", name: "Shahrood University of Medical Sciences", id: 3575 },
  { code: "IR", name: "Shahrood University of Technology", id: 3576 },
  { code: "IR", name: "Sharif University of Technology", id: 3577 },
  { code: "IR", name: "Sheikhbahaee University", id: 3578 },
  { code: "IR", name: "Shiraz Payamnoor University", id: 3579 },
  { code: "IR", name: "Shiraz University", id: 3580 },
  { code: "IR", name: "Shiraz University of Medical Sciences", id: 3581 },
  { code: "IR", name: "Shiraz University of Technology", id: 3582 },
  { code: "IR", name: "Shomal University", id: 3583 },
  { code: "IR", name: "Sistan & Balouchestan University", id: 3584 },
  { code: "IR", name: "Tabari Institute of Higher Education", id: 3585 },
  { code: "IR", name: "Tabriz College of Technology", id: 3586 },
  { code: "IR", name: "Tabriz Islamic Art University", id: 3587 },
  { code: "IR", name: "Tabriz University", id: 3588 },
  { code: "IR", name: "Tabriz University of Medical Sciences", id: 3589 },
  { code: "IR", name: "Takestan Islamic Azad University", id: 3590 },
  { code: "IR", name: "Tarbiat Modares University", id: 3591 },
  { code: "IR", name: "Tehran University of Medical Sciences", id: 3592 },
  { code: "IR", name: "Tehran University of Teacher Training", id: 3593 },
  { code: "IR", name: "The Bahá'í Institute For Higher Education (BIHE)", id: 3594 },
  { code: "IR", name: "Torbat Heydarieh University of Medical Sciences", id: 3595 },
  { code: "IR", name: "University College of Nabi Akram", id: 3596 },
  { code: "IR", name: "University of Applied Science and Technology", id: 3597 },
  { code: "IR", name: "University of Hormozgan", id: 3598 },
  { code: "IR", name: "University of Kurdistan", id: 3599 },
  { code: "IR", name: "University of Lavasanat Pnu", id: 3600 },
  { code: "IR", name: "University of Mashhad Pnu", id: 3601 },
  { code: "IR", name: "University of Mohaghegh", id: 3602 },
  { code: "IR", name: "University of Science and Culture", id: 3603 },
  { code: "IR", name: "University of Social Welfare and Rehabilitation Scinences", id: 3604 },
  { code: "IR", name: "University of Tehran", id: 3605 },
  { code: "IR", name: "Urmia University", id: 3606 },
  { code: "IR", name: "Urmia University of Medical Sciences", id: 3607 },
  { code: "IR", name: "Urmia University of Technology", id: 3608 },
  { code: "IR", name: "Vali-e-Asr University", id: 3609 },
  { code: "IR", name: "Yasuj University", id: 3610 },
  { code: "IR", name: "Yasuj University of Medical Sciences", id: 3611 },
  { code: "IR", name: "Yazd Medical University", id: 3612 },
  { code: "IR", name: "Yazd University", id: 3613 },
  { code: "IR", name: "Zabol University", id: 3614 },
  { code: "IR", name: "Zahedan University of Medical Sciences", id: 3615 },
  { code: "IR", name: "Zanjan Institute for Advanced Studies in Basic Sciences", id: 3616 },
  { code: "IR", name: "Zanjan University", id: 3617 },
  { code: "IR", name: "Zanjan University of Medical Sciences", id: 3618 },
  { code: "IS", name: "Bifrost School of Business", id: 3619 },
  { code: "IS", name: "Holar University College", id: 3620 },
  { code: "IS", name: "Hvanneyri Agricultural University", id: 3621 },
  { code: "IS", name: "Iceland Academy of the Arts", id: 3622 },
  { code: "IS", name: "Iceland University of Education", id: 3623 },
  { code: "IS", name: "Reykjavík University", id: 3624 },
  { code: "IS", name: "Technological University of Iceland", id: 3625 },
  { code: "IS", name: "University Centre of the Westfjords", id: 3626 },
  { code: "IS", name: "University of Akureyri", id: 3627 },
  { code: "IS", name: "University of Iceland", id: 3628 },
  { code: "IT", name: "Abdus Salam international centre for theoretical physics", id: 3629 },
  { code: "IT", name: "Basilicata University Potenza", id: 3630 },
  { code: "IT", name: "Campus Bio-Medico University of Rome", id: 3631 },
  { code: "IT", name: "Catholic University of the Sacred Heart", id: 3632 },
  { code: "IT", name: "Dutch University Institute for Art History (DUIA)", id: 3633 },
  { code: "IT", name: "European School of Economics", id: 3634 },
  { code: "IT", name: "European University Institute", id: 3635 },
  { code: "IT", name: "Fondazione Sacro Cuore", id: 3636 },
  { code: "IT", name: "Free International University of Social Studies", id: 3637 },
  { code: "IT", name: "Free University Institute Carlo Cattaneo", id: 3638 },
  { code: "IT", name: "Free University Maria Santissima Assunta", id: 3639 },
  { code: "IT", name: "Free University of Bozen", id: 3640 },
  { code: "IT", name: "Higher School of University and Advanced Studies Pisa", id: 3641 },
  { code: "IT", name: "Institute for Advanced Studies Lucca", id: 3642 },
  { code: "IT", name: "International Higher School for Advanced Studies Trieste", id: 3643 },
  { code: "IT", name: "Istituto Universitario di Studi Superiori", id: 3644 },
  { code: "IT", name: "John Cabot University", id: 3645 },
  { code: "IT", name: "Johns Hopkins University", id: 3646 },
  { code: "IT", name: "Link Campus University of Malta", id: 3647 },
  { code: "IT", name: "NABA - Nuova Accademia di Belle Arti", id: 3648 },
  { code: "IT", name: "Polytechnic Institute of Bari", id: 3649 },
  { code: "IT", name: "Polytechnic Institute of Milan", id: 3650 },
  { code: "IT", name: "Polytechnic Institute of Turin", id: 3651 },
  { code: "IT", name: "Pontifica Università Gregoriana", id: 3652 },
  { code: "IT", name: "Pontificia Università della Santa Croce", id: 3653 },
  { code: "IT", name: "Pontificia Università Lateranense", id: 3654 },
  { code: "IT", name: "Pontificia Università S. Tommaso", id: 3655 },
  { code: "IT", name: "Pontificio Ateneo Antonianum", id: 3656 },
  { code: "IT", name: "Pontificio Istituto Orientale", id: 3657 },
  { code: "IT", name: "School of Education Pisa", id: 3658 },
  { code: "IT", name: "Second University of Naples", id: 3659 },
  { code: "IT", name: "Third University of Rome", id: 3660 },
  { code: "IT", name: "Università Bocconi", id: 3661 },
  { code: "IT", name: "Università Pontificia Salesiana", id: 3662 },
  { code: "IT", name: "University Institute of Architecture Venice", id: 3663 },
  { code: "IT", name: "University Institute of Modern Languages", id: 3664 },
  { code: "IT", name: "University Institute of Naval Studies", id: 3665 },
  { code: "IT", name: "University Institute of Oriental Studies", id: 3666 },
  { code: "IT", name: "University Institute of Teacher Training Suor Orsola Benincasa", id: 3667 },
  { code: "IT", name: "University of Ancona", id: 3668 },
  { code: "IT", name: "University of Aquila", id: 3669 },
  { code: "IT", name: "University of Bari", id: 3670 },
  { code: "IT", name: "University of Bergamo", id: 3671 },
  { code: "IT", name: "University of Bologna", id: 3672 },
  { code: "IT", name: "University of Brescia", id: 3673 },
  { code: "IT", name: "University of Cagliari", id: 3674 },
  { code: "IT", name: "University of Calabria", id: 3675 },
  { code: "IT", name: "University of Camerino", id: 3676 },
  { code: "IT", name: "University of Cassino", id: 3677 },
  { code: "IT", name: "University of Catania", id: 3678 },
  { code: "IT", name: "University of Chieti", id: 3679 },
  { code: "IT", name: "University of Commerce Luigi Bocconi", id: 3680 },
  { code: "IT", name: "University of Ferrara", id: 3681 },
  { code: "IT", name: "University of Florence", id: 3682 },
  { code: "IT", name: "University of Foggia", id: 3683 },
  { code: "IT", name: "University of Gastronomic Sciences", id: 3684 },
  { code: "IT", name: "University of Genoa", id: 3685 },
  { code: "IT", name: "University of Insubria", id: 3686 },
  { code: "IT", name: "University of Italian Studies for Foreigners of Perugia", id: 3687 },
  { code: "IT", name: "University of Italian Studies for Foreigners of Siena", id: 3688 },
  { code: "IT", name: "University of Lecce", id: 3689 },
  { code: "IT", name: "University of Macerata", id: 3690 },
  { code: "IT", name: "University of Messina", id: 3691 },
  { code: "IT", name: "University of Milan", id: 3692 },
  { code: "IT", name: "University of Milan - Bicocca", id: 3693 },
  { code: "IT", name: "University of Modena", id: 3694 },
  { code: "IT", name: "University of Molise", id: 3695 },
  { code: "IT", name: "University of Naples Federico II", id: 3696 },
  { code: "IT", name: "University of Padua", id: 3697 },
  { code: "IT", name: "University of Palermo", id: 3698 },
  { code: "IT", name: "University of Parma", id: 3699 },
  { code: "IT", name: "University of Pavia", id: 3700 },
  { code: "IT", name: "University of Perugia", id: 3701 },
  { code: "IT", name: "University of Pisa", id: 3702 },
  { code: "IT", name: "University of Reggio Calabria", id: 3703 },
  { code: "IT", name: "University of Roma La Sapienza", id: 3704 },
  { code: "IT", name: "University of Roma Tor Vergata", id: 3705 },
  { code: "IT", name: "University of Salerno", id: 3706 },
  { code: "IT", name: "University of Sannio", id: 3707 },
  { code: "IT", name: "University of Sassari", id: 3708 },
  { code: "IT", name: "University of Siena", id: 3709 },
  { code: "IT", name: "University of Teramo", id: 3710 },
  { code: "IT", name: "University of Trento", id: 3711 },
  { code: "IT", name: "University of Trieste", id: 3712 },
  { code: "IT", name: "University of Turin", id: 3713 },
  { code: "IT", name: "University of Udine", id: 3714 },
  { code: "IT", name: "University of Urbino", id: 3715 },
  { code: "IT", name: "University of Venice", id: 3716 },
  { code: "IT", name: "University of Verona", id: 3717 },
  { code: "IT", name: "Viterbo State University", id: 3718 },
  { code: "IT", name: "Yorker International University", id: 3719 },
  { code: "JM", name: "Northern Caribbean University", id: 3720 },
  { code: "JM", name: "University of Technology Jamaica", id: 3721 },
  { code: "JM", name: "University of the West Indies", id: 3722 },
  { code: "JO", name: "Al-Ahliyya Amman University", id: 3723 },
  { code: "JO", name: "Al al-Bayt University", id: 3724 },
  { code: "JO", name: "Al-Balqa Applied University", id: 3725 },
  { code: "JO", name: "Al Hussein Bin Talal University", id: 3726 },
  { code: "JO", name: "Al-Isra University", id: 3727 },
  { code: "JO", name: "Al-Zaytoonah University", id: 3728 },
  { code: "JO", name: "Amman Arab University for Higher Studies", id: 3729 },
  { code: "JO", name: "Amman University", id: 3730 },
  { code: "JO", name: "Applied Science University", id: 3731 },
  { code: "JO", name: "Arab Academy for Banking and Financial Sciences", id: 3732 },
  { code: "JO", name: "Arab Open University", id: 3733 },
  { code: "JO", name: "Balqa Applied University", id: 3734 },
  { code: "JO", name: "German Jordanian University", id: 3735 },
  { code: "JO", name: "Hashemite University", id: 3736 },
  { code: "JO", name: "Irbid National University", id: 3737 },
  { code: "JO", name: "Jadara University", id: 3738 },
  { code: "JO", name: "Jerash Private University", id: 3739 },
  { code: "JO", name: "Jordan Academy of Music / Higher Institute of Music", id: 3740 },
  { code: "JO", name: "Jordan University of Science and Technology", id: 3741 },
  { code: "JO", name: "Middle East University", id: 3742 },
  { code: "JO", name: "Mu' tah University", id: 3743 },
  { code: "JO", name: "New York Institute of Technology", id: 3744 },
  { code: "JO", name: "Philadelphia University", id: 3745 },
  { code: "JO", name: "Princess Sumaya University for Technology", id: 3746 },
  { code: "JO", name: "Tafila Technical University ", id: 3747 },
  { code: "JO", name: "The World Islamic Sciences & Education University", id: 3748 },
  { code: "JO", name: "University of Jordan", id: 3749 },
  { code: "JO", name: "University of Petra", id: 3750 },
  { code: "JO", name: "Yarmouk University", id: 3751 },
  { code: "JO", name: "Zarka Private University", id: 3752 },
  { code: "JP", name: "Aichi Bunkyo University", id: 3753 },
  { code: "JP", name: "Aichi Gakuin University", id: 3754 },
  { code: "JP", name: "Aichi Gakusen University", id: 3755 },
  { code: "JP", name: "Aichi Institute of Technology", id: 3756 },
  { code: "JP", name: "Aichi Medical University", id: 3757 },
  { code: "JP", name: "Aichi Prefectural University", id: 3758 },
  { code: "JP", name: "Aichi Prefectural University of Fine Arts & Music", id: 3759 },
  { code: "JP", name: "Aichi Sangyo University", id: 3760 },
  { code: "JP", name: "Aichi Shukutoku University", id: 3761 },
  { code: "JP", name: "Aichi University", id: 3762 },
  { code: "JP", name: "Aichi University of Education", id: 3763 },
  { code: "JP", name: "Aikoku Gakuen University", id: 3764 },
  { code: "JP", name: "Akita University", id: 3765 },
  { code: "JP", name: "Akita University of Economics and Law", id: 3766 },
  { code: "JP", name: "American University Extension", id: 3767 },
  { code: "JP", name: "Aomori Chuoh Gakuin University", id: 3768 },
  { code: "JP", name: "Aomori Public College", id: 3769 },
  { code: "JP", name: "Aomori University", id: 3770 },
  { code: "JP", name: "Aomori University of Health and Welfare", id: 3771 },
  { code: "JP", name: "Aoyama Gakuin University", id: 3772 },
  { code: "JP", name: "Asahikawa Medical College", id: 3773 },
  { code: "JP", name: "Asahikawa University", id: 3774 },
  { code: "JP", name: "Asahi University", id: 3775 },
  { code: "JP", name: "Ashikaga Institute of Technology", id: 3776 },
  { code: "JP", name: "Ashiya University", id: 3777 },
  { code: "JP", name: "Asia University", id: 3778 },
  { code: "JP", name: "Atomi College", id: 3779 },
  { code: "JP", name: "Azabu University", id: 3780 },
  { code: "JP", name: "Baika Women's College", id: 3781 },
  { code: "JP", name: "Baiko Women's College", id: 3782 },
  { code: "JP", name: "Beppu University", id: 3783 },
  { code: "JP", name: "Bukkyo University", id: 3784 },
  { code: "JP", name: "Bunka Women's University", id: 3785 },
  { code: "JP", name: "Bunkyo University", id: 3786 },
  { code: "JP", name: "Chiba Institute of Technology", id: 3787 },
  { code: "JP", name: "Chiba Keizai University", id: 3788 },
  { code: "JP", name: "Chiba University", id: 3789 },
  { code: "JP", name: "Chiba University of Commerce", id: 3790 },
  { code: "JP", name: "Chikushi Jogakuen University", id: 3791 },
  { code: "JP", name: "Chubu Gakuin University & Chubu Women's College", id: 3792 },
  { code: "JP", name: "Chubu University", id: 3793 },
  { code: "JP", name: "Chukyo Gakuin University", id: 3794 },
  { code: "JP", name: "Chukyo University", id: 3795 },
  { code: "JP", name: "Chukyo Women's University", id: 3796 },
  { code: "JP", name: "Chuo Gakuin University", id: 3797 },
  { code: "JP", name: "Chuo University", id: 3798 },
  { code: "JP", name: "Daido Institute of Technology", id: 3799 },
  { code: "JP", name: "Daiichi College of Pharmaceutical Sciences", id: 3800 },
  { code: "JP", name: "Dai Ichi University", id: 3801 },
  { code: "JP", name: "Daiichi University of Economics", id: 3802 },
  { code: "JP", name: "Daito Bunka University", id: 3803 },
  { code: "JP", name: "Doho University", id: 3804 },
  { code: "JP", name: "Dohto University", id: 3805 },
  { code: "JP", name: "Dokkyo University", id: 3806 },
  { code: "JP", name: "Dokkyo University School of Medicine", id: 3807 },
  { code: "JP", name: "Doshisha University", id: 3808 },
  { code: "JP", name: "Doshisha Women's College of Liberal Arts", id: 3809 },
  { code: "JP", name: "Edogawa University", id: 3810 },
  { code: "JP", name: "Ehime University", id: 3811 },
  { code: "JP", name: "Eichi University", id: 3812 },
  { code: "JP", name: "Elisabeth University of Music", id: 3813 },
  { code: "JP", name: "Ferris University", id: 3814 },
  { code: "JP", name: "Fujita Health University", id: 3815 },
  { code: "JP", name: "Fuji University", id: 3816 },
  { code: "JP", name: "Fuji Women's College", id: 3817 },
  { code: "JP", name: "Fukui Medical School", id: 3818 },
  { code: "JP", name: "Fukui Prefectural University", id: 3819 },
  { code: "JP", name: "Fukui University", id: 3820 },
  { code: "JP", name: "Fukui University of Technology", id: 3821 },
  { code: "JP", name: "Fukuoka Dental College", id: 3822 },
  { code: "JP", name: "Fukuoka Institute of Technology", id: 3823 },
  { code: "JP", name: "Fukuoka International University", id: 3824 },
  { code: "JP", name: "Fukuoka Prefectural University", id: 3825 },
  { code: "JP", name: "Fukuoka University", id: 3826 },
  { code: "JP", name: "Fukuoka University of Education", id: 3827 },
  { code: "JP", name: "Fukuoka Women's University", id: 3828 },
  { code: "JP", name: "Fukushima Medical College", id: 3829 },
  { code: "JP", name: "Fukushima University", id: 3830 },
  { code: "JP", name: "Fukuyama Heisei University", id: 3831 },
  { code: "JP", name: "Fukuyama University", id: 3832 },
  { code: "JP", name: "Gakushuin University", id: 3833 },
  { code: "JP", name: "Gifu Keizai University", id: 3834 },
  { code: "JP", name: "Gifu Pharmaceutical University", id: 3835 },
  { code: "JP", name: "Gifu Shotoku Gakuen University", id: 3836 },
  { code: "JP", name: "Gifu University", id: 3837 },
  { code: "JP", name: "Gifu University for Education and Languages", id: 3838 },
  { code: "JP", name: "Gifu Women's University", id: 3839 },
  { code: "JP", name: "Graduate University for Advanced Studies", id: 3840 },
  { code: "JP", name: "Gunma Prefectural Women's University", id: 3841 },
  { code: "JP", name: "Gunma University", id: 3842 },
  { code: "JP", name: "Hachinohe Institute of Technology", id: 3843 },
  { code: "JP", name: "Hachinohe University", id: 3844 },
  { code: "JP", name: "Hakodate University", id: 3845 },
  { code: "JP", name: "Hakuoh University", id: 3846 },
  { code: "JP", name: "Hamamatsu University", id: 3847 },
  { code: "JP", name: "Hamamatsu University School of Medicine", id: 3848 },
  { code: "JP", name: "Hanazono University", id: 3849 },
  { code: "JP", name: "Hannan University", id: 3850 },
  { code: "JP", name: "Heisei International University", id: 3851 },
  { code: "JP", name: "Higashi Nippon International University", id: 3852 },
  { code: "JP", name: "Hijiyama University", id: 3853 },
  { code: "JP", name: "Himeji Dokkyo University", id: 3854 },
  { code: "JP", name: "Himeji Institute of Technology", id: 3855 },
  { code: "JP", name: "Hirosaki Gakuin University", id: 3856 },
  { code: "JP", name: "Hirosaki University", id: 3857 },
  { code: "JP", name: "Hiroshima Bunkyo Women's University", id: 3858 },
  { code: "JP", name: "Hiroshima City University", id: 3859 },
  { code: "JP", name: "Hiroshima Institute of Technology", id: 3860 },
  { code: "JP", name: "Hiroshima International University", id: 3861 },
  { code: "JP", name: "Hiroshima Jogakuin University", id: 3862 },
  { code: "JP", name: "Hiroshima Kokusai Gakuin University", id: 3863 },
  { code: "JP", name: "Hiroshima Prefectural University", id: 3864 },
  { code: "JP", name: "Hiroshima Shudo University", id: 3865 },
  { code: "JP", name: "Hiroshima University", id: 3866 },
  { code: "JP", name: "Hiroshima University of Economics", id: 3867 },
  { code: "JP", name: "Hiroshima Women's University", id: 3868 },
  { code: "JP", name: "Hitotsubashi University", id: 3869 },
  { code: "JP", name: "Hokkaido Information University", id: 3870 },
  { code: "JP", name: "Hokkaido Institute of Pharmaceutical Sciences", id: 3871 },
  { code: "JP", name: "Hokkaido Institute of Technology", id: 3872 },
  { code: "JP", name: "Hokkaido Tokai University", id: 3873 },
  { code: "JP", name: "Hokkaido University", id: 3874 },
  { code: "JP", name: "Hokkaido University of Education", id: 3875 },
  { code: "JP", name: "Hokkaido University of Health Sciences", id: 3876 },
  { code: "JP", name: "Hokkaigakuen University", id: 3877 },
  { code: "JP", name: "Hokkaigakuen University of Kitami", id: 3878 },
  { code: "JP", name: "Hokuriku University", id: 3879 },
  { code: "JP", name: "Hokusei Gakuen University", id: 3880 },
  { code: "JP", name: "Hosei University", id: 3881 },
  { code: "JP", name: "Hoshi University", id: 3882 },
  { code: "JP", name: "Hyogo College of Medicine", id: 3883 },
  { code: "JP", name: "Hyogo University", id: 3884 },
  { code: "JP", name: "Hyogo University of Education", id: 3885 },
  { code: "JP", name: "Ibaraki Christian College", id: 3886 },
  { code: "JP", name: "Ibaraki Prefectural University of Health Sciences", id: 3887 },
  { code: "JP", name: "Ibaraki University", id: 3888 },
  { code: "JP", name: "International Buddhist University", id: 3889 },
  { code: "JP", name: "International Budo University", id: 3890 },
  { code: "JP", name: "International Christian University", id: 3891 },
  { code: "JP", name: "International University of Health and Welfare", id: 3892 },
  { code: "JP", name: "International University of Japan", id: 3893 },
  { code: "JP", name: "International University of Kagoshima", id: 3894 },
  { code: "JP", name: "Ishinomaki Senshu University", id: 3895 },
  { code: "JP", name: "Iwaki Meisei University", id: 3896 },
  { code: "JP", name: "Iwate Medical University", id: 3897 },
  { code: "JP", name: "Iwate Prefectural University", id: 3898 },
  { code: "JP", name: "Iwate University", id: 3899 },
  { code: "JP", name: "Japan Advanced Institute of Science and Technology", id: 3900 },
  { code: "JP", name: "Japan College of Social Work", id: 3901 },
  { code: "JP", name: "Japanese Red Cross College of Nursing", id: 3902 },
  { code: "JP", name: "Japan Women's University", id: 3903 },
  { code: "JP", name: "Jichi Medical School", id: 3904 },
  { code: "JP", name: "Jikei University School of Medicine", id: 3905 },
  { code: "JP", name: "Jissen Women's University", id: 3906 },
  { code: "JP", name: "Jobu University", id: 3907 },
  { code: "JP", name: "Joetsu University of Education", id: 3908 },
  { code: "JP", name: "Josai International University", id: 3909 },
  { code: "JP", name: "Josai University", id: 3910 },
  { code: "JP", name: "Juntendo University", id: 3911 },
  { code: "JP", name: "Kagawa Institute of Nutrition", id: 3912 },
  { code: "JP", name: "Kagawa Medical School", id: 3913 },
  { code: "JP", name: "Kagawa University", id: 3914 },
  { code: "JP", name: "Kagoshima Immaculate Heart University", id: 3915 },
  { code: "JP", name: "Kagoshima University", id: 3916 },
  { code: "JP", name: "Kagoshima Women's College", id: 3917 },
  { code: "JP", name: "Kamakura Women's College", id: 3918 },
  { code: "JP", name: "Kanagawa Dental College", id: 3919 },
  { code: "JP", name: "Kanagawa Institute of Technology", id: 3920 },
  { code: "JP", name: "Kanagawa University", id: 3921 },
  { code: "JP", name: "Kanazawa College of Art", id: 3922 },
  { code: "JP", name: "Kanazawa College of Economics", id: 3923 },
  { code: "JP", name: "Kanazawa Gakuin University", id: 3924 },
  { code: "JP", name: "Kanazawa Institute of Technology", id: 3925 },
  { code: "JP", name: "Kanazawa Medical University", id: 3926 },
  { code: "JP", name: "Kanazawa University", id: 3927 },
  { code: "JP", name: "Kanda University of International Studies", id: 3928 },
  { code: "JP", name: "Kansai Gaidai University", id: 3929 },
  { code: "JP", name: "Kansai Medical University", id: 3930 },
  { code: "JP", name: "Kansai University", id: 3931 },
  { code: "JP", name: "Kansai University of International Studies", id: 3932 },
  { code: "JP", name: "Kansai University of Social Welfare", id: 3933 },
  { code: "JP", name: "Kanto Gakuen University", id: 3934 },
  { code: "JP", name: "Kanto Gakuin University", id: 3935 },
  { code: "JP", name: "Kawamura Gakuen Woman's University", id: 3936 },
  { code: "JP", name: "Kawasaki Medical School", id: 3937 },
  { code: "JP", name: "Kawasaki University of Medical Care", id: 3938 },
  { code: "JP", name: "Keiai University", id: 3939 },
  { code: "JP", name: "Keio University", id: 3940 },
  { code: "JP", name: "Keisen Jogaku-en College", id: 3941 },
  { code: "JP", name: "Keiwa College", id: 3942 },
  { code: "JP", name: "Kibi International University", id: 3943 },
  { code: "JP", name: "Kinjo Gakuin University", id: 3944 },
  { code: "JP", name: "Kinki University", id: 3945 },
  { code: "JP", name: "Kitakyushu University", id: 3946 },
  { code: "JP", name: "Kitami Institute of Technology", id: 3947 },
  { code: "JP", name: "Kitasato University", id: 3948 },
  { code: "JP", name: "Kobe City University of ForeignStudies", id: 3949 },
  { code: "JP", name: "Kobe Design University", id: 3950 },
  { code: "JP", name: "Kobe Gakuin University", id: 3951 },
  { code: "JP", name: "Kobe International University", id: 3952 },
  { code: "JP", name: "Kobe Jogakuin University", id: 3953 },
  { code: "JP", name: "Kobe Pharmaceutical University", id: 3954 },
  { code: "JP", name: "Kobe Shinwa Women's University", id: 3955 },
  { code: "JP", name: "Kobe Shoin Women's University", id: 3956 },
  { code: "JP", name: "Kobe University", id: 3957 },
  { code: "JP", name: "Kobe University of Mercantile Marine", id: 3958 },
  { code: "JP", name: "Kobe Women's University", id: 3959 },
  { code: "JP", name: "Kochi Medical School", id: 3960 },
  { code: "JP", name: "Kochi University", id: 3961 },
  { code: "JP", name: "Kochi University of Technology", id: 3962 },
  { code: "JP", name: "Kochi Women's University", id: 3963 },
  { code: "JP", name: "Kogakkan University", id: 3964 },
  { code: "JP", name: "Kogakuin University", id: 3965 },
  { code: "JP", name: "Koka Women's College", id: 3966 },
  { code: "JP", name: "Kokugakuin University", id: 3967 },
  { code: "JP", name: "Kokushikan University", id: 3968 },
  { code: "JP", name: "Komazawa University", id: 3969 },
  { code: "JP", name: "Konan University", id: 3970 },
  { code: "JP", name: "Konan Women's University", id: 3971 },
  { code: "JP", name: "Korea University", id: 3972 },
  { code: "JP", name: "Koriyama Women's University and College", id: 3973 },
  { code: "JP", name: "Koshien University", id: 3974 },
  { code: "JP", name: "Koyasan University", id: 3975 },
  { code: "JP", name: "Kumamoto Gakuen University", id: 3976 },
  { code: "JP", name: "Kumamoto Institute of Technology", id: 3977 },
  { code: "JP", name: "Kumamoto Prefectural University", id: 3978 },
  { code: "JP", name: "Kumamoto University", id: 3979 },
  { code: "JP", name: "Kunitachi College of Music", id: 3980 },
  { code: "JP", name: "Kurashiki Sakuyo University", id: 3981 },
  { code: "JP", name: "Kurashiki University of Science and the Arts", id: 3982 },
  { code: "JP", name: "Kure University", id: 3983 },
  { code: "JP", name: "Kurume Institute of Technology", id: 3984 },
  { code: "JP", name: "Kurume University", id: 3985 },
  { code: "JP", name: "Kushiro Public University of Economics", id: 3986 },
  { code: "JP", name: "Kwansei Gakuin University", id: 3987 },
  { code: "JP", name: "Kwassui Women's College", id: 3988 },
  { code: "JP", name: "Kyorin University", id: 3989 },
  { code: "JP", name: "Kyoritsu Pharmaceutical University", id: 3990 },
  { code: "JP", name: "Kyoritsu Woman's University", id: 3991 },
  { code: "JP", name: "Kyoto Bunkyo University", id: 3992 },
  { code: "JP", name: "Kyoto City University of Arts", id: 3993 },
  { code: "JP", name: "Kyoto Gakuen University", id: 3994 },
  { code: "JP", name: "Kyoto Institute of Technology", id: 3995 },
  { code: "JP", name: "Kyoto Pharmaceutical University", id: 3996 },
  { code: "JP", name: "Kyoto Prefectural University", id: 3997 },
  { code: "JP", name: "Kyoto Prefectural University of Medicine", id: 3998 },
  { code: "JP", name: "Kyoto Sangyo University", id: 3999 },
  { code: "JP", name: "Kyoto Seika University", id: 4000 },
  { code: "JP", name: "Kyoto Tachibana Women's University", id: 4001 },
  { code: "JP", name: "Kyoto University", id: 4002 },
  { code: "JP", name: "Kyoto University of Art and Design", id: 4003 },
  { code: "JP", name: "Kyoto University of Education", id: 4004 },
  { code: "JP", name: "Kyoto University of Foreign Studies", id: 4005 },
  { code: "JP", name: "Kyoto Women's University", id: 4006 },
  { code: "JP", name: "Kyushu Dental College", id: 4007 },
  { code: "JP", name: "Kyushu Institute of Design", id: 4008 },
  { code: "JP", name: "Kyushu Institute of Technology", id: 4009 },
  { code: "JP", name: "Kyushu International University", id: 4010 },
  { code: "JP", name: "Kyushu Kyoritsu University", id: 4011 },
  { code: "JP", name: "Kyushu Sangyo University", id: 4012 },
  { code: "JP", name: "Kyushu Tokai University", id: 4013 },
  { code: "JP", name: "Kyushu University", id: 4014 },
  { code: "JP", name: "Kyushu University of Nursing and SocialWelfare", id: 4015 },
  { code: "JP", name: "Kyushu Women's University", id: 4016 },
  { code: "JP", name: "Matsumoto Dental University", id: 4017 },
  { code: "JP", name: "Matsusaka University", id: 4018 },
  { code: "JP", name: "Matsuyama University", id: 4019 },
  { code: "JP", name: "Meiji College of Pharmacy", id: 4020 },
  { code: "JP", name: "Meiji Gakuin University", id: 4021 },
  { code: "JP", name: "Meiji University", id: 4022 },
  { code: "JP", name: "Meiji University of Oriental Medicine", id: 4023 },
  { code: "JP", name: "Meijo University", id: 4024 },
  { code: "JP", name: "Meikai University", id: 4025 },
  { code: "JP", name: "Meio University", id: 4026 },
  { code: "JP", name: "Meisei University", id: 4027 },
  { code: "JP", name: "Mejiro University", id: 4028 },
  { code: "JP", name: "Mie University", id: 4029 },
  { code: "JP", name: "Mimasaka Women's College", id: 4030 },
  { code: "JP", name: "Minamikyushu University", id: 4031 },
  { code: "JP", name: "Miyagi Gakuin Women's College", id: 4032 },
  { code: "JP", name: "Miyagi University", id: 4033 },
  { code: "JP", name: "Miyagi University of Education", id: 4034 },
  { code: "JP", name: "Miyazaki Medical College", id: 4035 },
  { code: "JP", name: "Miyazaki Municipal University", id: 4036 },
  { code: "JP", name: "Miyazaki Prefectural Nursing University", id: 4037 },
  { code: "JP", name: "Miyazaki University", id: 4038 },
  { code: "JP", name: "Morioka College", id: 4039 },
  { code: "JP", name: "Mukogawa Women's University", id: 4040 },
  { code: "JP", name: "Muroran Institute of Technology", id: 4041 },
  { code: "JP", name: "Musashi Institute of Technology", id: 4042 },
  { code: "JP", name: "Musashino Academy of Music", id: 4043 },
  { code: "JP", name: "Musashino Art University", id: 4044 },
  { code: "JP", name: "Musashino Women's University", id: 4045 },
  { code: "JP", name: "Musashi University", id: 4046 },
  { code: "JP", name: "Nagano University", id: 4047 },
  { code: "JP", name: "Nagaoka University of Technology", id: 4048 },
  { code: "JP", name: "Nagasaki Institute of Applied Science", id: 4049 },
  { code: "JP", name: "Nagasaki Prefectural University", id: 4050 },
  { code: "JP", name: "Nagasaki University", id: 4051 },
  { code: "JP", name: "Nagoya City University", id: 4052 },
  { code: "JP", name: "Nagoya College of Music", id: 4053 },
  { code: "JP", name: "Nagoya Economics University", id: 4054 },
  { code: "JP", name: "Nagoya Gakuin University", id: 4055 },
  { code: "JP", name: "Nagoya Institute of Technology", id: 4056 },
  { code: "JP", name: "Nagoya University", id: 4057 },
  { code: "JP", name: "Nagoya University of Arts", id: 4058 },
  { code: "JP", name: "Nagoya University of Commerce and Business Administration", id: 4059 },
  { code: "JP", name: "Nagoya University of Foreign Studies", id: 4060 },
  { code: "JP", name: "Nagoya Women's University", id: 4061 },
  { code: "JP", name: "Nakamura Gakuen University", id: 4062 },
  { code: "JP", name: "Nanzan University", id: 4063 },
  { code: "JP", name: "Nara Medical University", id: 4064 },
  { code: "JP", name: "Nara Sangyo University", id: 4065 },
  { code: "JP", name: "Nara University", id: 4066 },
  { code: "JP", name: "Nara University of Commerce", id: 4067 },
  { code: "JP", name: "Nara University of Education", id: 4068 },
  { code: "JP", name: "Nara Women's University", id: 4069 },
  { code: "JP", name: "Naruto University of Education", id: 4070 },
  { code: "JP", name: "National Defence Medical College", id: 4071 },
  { code: "JP", name: "National Fisheries University", id: 4072 },
  { code: "JP", name: "National Institute of Fitness and Sports Kanoya", id: 4073 },
  { code: "JP", name: "Nihon Fukushi University", id: 4074 },
  { code: "JP", name: "Nihon University", id: 4075 },
  { code: "JP", name: "Niigata College of Pharmacy", id: 4076 },
  { code: "JP", name: "Niigata Sangyo University", id: 4077 },
  { code: "JP", name: "Niigata University", id: 4078 },
  { code: "JP", name: "Niigata University of International and Information Studies", id: 4079 },
  { code: "JP", name: "Niigata University of Management", id: 4080 },
  { code: "JP", name: "Nippon Bunri University", id: 4081 },
  { code: "JP", name: "Nippon Dental University", id: 4082 },
  { code: "JP", name: "Nippon Institute of Technology", id: 4083 },
  { code: "JP", name: "Nippon Medical School", id: 4084 },
  { code: "JP", name: "Nippon Sport Science University", id: 4085 },
  { code: "JP", name: "Nippon Veterinary and Animalscience University", id: 4086 },
  { code: "JP", name: "Nishikyushu University", id: 4087 },
  { code: "JP", name: "Nishinippon Institute of Technology", id: 4088 },
  { code: "JP", name: "Nisho Gakusha University", id: 4089 },
  { code: "JP", name: "Nortre Dame Seishin University", id: 4090 },
  { code: "JP", name: "Notre Dame Women's College", id: 4091 },
  { code: "JP", name: "Obihiro University of Agriculture and Veterinary Medicine", id: 4092 },
  { code: "JP", name: "Obirin University", id: 4093 },
  { code: "JP", name: "Ochanomizu Women's University", id: 4094 },
  { code: "JP", name: "Ohka Gakuen University", id: 4095 },
  { code: "JP", name: "Ohtani Women's University", id: 4096 },
  { code: "JP", name: "Ohu University", id: 4097 },
  { code: "JP", name: "Oita Medical University", id: 4098 },
  { code: "JP", name: "Oita University", id: 4099 },
  { code: "JP", name: "Oita University of Nursing and Health Sciences", id: 4100 },
  { code: "JP", name: "Okayama Prefectural University", id: 4101 },
  { code: "JP", name: "Okayama Shoka University", id: 4102 },
  { code: "JP", name: "Okayama University", id: 4103 },
  { code: "JP", name: "Okayama University of Science", id: 4104 },
  { code: "JP", name: "Okinawa International University", id: 4105 },
  { code: "JP", name: "Okinawa Prefectural University of Fine Arts", id: 4106 },
  { code: "JP", name: "Okinawa University", id: 4107 },
  { code: "JP", name: "Osaka City University", id: 4108 },
  { code: "JP", name: "Osaka College of Music", id: 4109 },
  { code: "JP", name: "Osaka Dental University", id: 4110 },
  { code: "JP", name: "Osaka Electro-Communication University", id: 4111 },
  { code: "JP", name: "Osaka Gakuin University", id: 4112 },
  { code: "JP", name: "Osaka Institute of Technology", id: 4113 },
  { code: "JP", name: "Osaka International University", id: 4114 },
  { code: "JP", name: "Osaka International University for Women", id: 4115 },
  { code: "JP", name: "Osaka Medical College", id: 4116 },
  { code: "JP", name: "Osaka Prefectural University", id: 4117 },
  { code: "JP", name: "Osaka Sangyo University", id: 4118 },
  { code: "JP", name: "Osaka Shoin Women's College", id: 4119 },
  { code: "JP", name: "Osaka University", id: 4120 },
  { code: "JP", name: "Osaka University of Arts", id: 4121 },
  { code: "JP", name: "Osaka University of Commerce", id: 4122 },
  { code: "JP", name: "Osaka University of Economics", id: 4123 },
  { code: "JP", name: "Osaka University of Economics & Law", id: 4124 },
  { code: "JP", name: "Osaka University of Education", id: 4125 },
  { code: "JP", name: "Osaka University of Foreign Studies", id: 4126 },
  { code: "JP", name: "Osaka University of Health and Sport Sciences", id: 4127 },
  { code: "JP", name: "Osaka University of Pharmaceutical Sciences", id: 4128 },
  { code: "JP", name: "Osaka Women's University", id: 4129 },
  { code: "JP", name: "Otani University", id: 4130 },
  { code: "JP", name: "Otaru University of Commerce", id: 4131 },
  { code: "JP", name: "Otemae University", id: 4132 },
  { code: "JP", name: "Otemon Gakuin University", id: 4133 },
  { code: "JP", name: "Otsuma Women's University", id: 4134 },
  { code: "JP", name: "Polytechnic University", id: 4135 },
  { code: "JP", name: "Poole Gakuin University", id: 4136 },
  { code: "JP", name: "Rakuno Gakuen University", id: 4137 },
  { code: "JP", name: "Reitaku University", id: 4138 },
  { code: "JP", name: "Rikkyo University (St. Paul's University)", id: 4139 },
  { code: "JP", name: "Rissho University", id: 4140 },
  { code: "JP", name: "Ritsumeikan Asia Pacific University", id: 4141 },
  { code: "JP", name: "Ritsumeikan University", id: 4142 },
  { code: "JP", name: "Ryukoku University", id: 4143 },
  { code: "JP", name: "Ryutsu Keizai University", id: 4144 },
  { code: "JP", name: "Saga Medical School", id: 4145 },
  { code: "JP", name: "Sagami Women's University", id: 4146 },
  { code: "JP", name: "Saga University", id: 4147 },
  { code: "JP", name: "Saitama Institute of Technology", id: 4148 },
  { code: "JP", name: "Saitama Medical School", id: 4149 },
  { code: "JP", name: "Saitama Prefectural University", id: 4150 },
  { code: "JP", name: "Saitama University", id: 4151 },
  { code: "JP", name: "Sakushin Gakuin University", id: 4152 },
  { code: "JP", name: "Sankei University", id: 4153 },
  { code: "JP", name: "Sanno University", id: 4154 },
  { code: "JP", name: "Sanyo Gakuen University", id: 4155 },
  { code: "JP", name: "Sapporo Gakuin University", id: 4156 },
  { code: "JP", name: "Sapporo International University", id: 4157 },
  { code: "JP", name: "Sapporo Medical University", id: 4158 },
  { code: "JP", name: "Sapporo University", id: 4159 },
  { code: "JP", name: "Science University of Tokyo", id: 4160 },
  { code: "JP", name: "Science University of Tokyo in Yamaguchi", id: 4161 },
  { code: "JP", name: "Seian University of Art & Design", id: 4162 },
  { code: "JP", name: "Seigakuin University", id: 4163 },
  { code: "JP", name: "Seijo University", id: 4164 },
  { code: "JP", name: "Seikei University", id: 4165 },
  { code: "JP", name: "Seinan Gakuin University", id: 4166 },
  { code: "JP", name: "Seisen University", id: 4167 },
  { code: "JP", name: "Seiwa College", id: 4168 },
  { code: "JP", name: "Sendai University", id: 4169 },
  { code: "JP", name: "Senshu University", id: 4170 },
  { code: "JP", name: "Senzoku Gakuen College", id: 4171 },
  { code: "JP", name: "Setsunan University", id: 4172 },
  { code: "JP", name: "Shibaura Institute of Technology", id: 4173 },
  { code: "JP", name: "Shiga University", id: 4174 },
  { code: "JP", name: "Shiga University of Medical Science", id: 4175 },
  { code: "JP", name: "Shikoku Christian College", id: 4176 },
  { code: "JP", name: "Shikoku University", id: 4177 },
  { code: "JP", name: "Shimane University", id: 4178 },
  { code: "JP", name: "Shimonoseki City University", id: 4179 },
  { code: "JP", name: "Shinshu University", id: 4180 },
  { code: "JP", name: "Shirayuri Women's College", id: 4181 },
  { code: "JP", name: "Shizuoka Prefectural University", id: 4182 },
  { code: "JP", name: "Shizuoka Sangyo University", id: 4183 },
  { code: "JP", name: "Shizuoka University", id: 4184 },
  { code: "JP", name: "Shokei College", id: 4185 },
  { code: "JP", name: "Shonan Institute of Technology", id: 4186 },
  { code: "JP", name: "Showa College of Pharmaceutical Sciences", id: 4187 },
  { code: "JP", name: "Showa University", id: 4188 },
  { code: "JP", name: "Showa Women's University", id: 4189 },
  { code: "JP", name: "Shuchiin College", id: 4190 },
  { code: "JP", name: "Shujitsu Women's University", id: 4191 },
  { code: "JP", name: "Shukutoku University", id: 4192 },
  { code: "JP", name: "Shumei University", id: 4193 },
  { code: "JP", name: "Siebold University of Nagasaki", id: 4194 },
  { code: "JP", name: "Soai University", id: 4195 },
  { code: "JP", name: "Soka University", id: 4196 },
  { code: "JP", name: "Sonoda Women's University", id: 4197 },
  { code: "JP", name: "Sophia University", id: 4198 },
  { code: "JP", name: "St. Andrew's University", id: 4199 },
  { code: "JP", name: "St. Luke' s College of Nursing", id: 4200 },
  { code: "JP", name: "St. Marianna University School of Medicine", id: 4201 },
  { code: "JP", name: "Sugino Women's College", id: 4202 },
  { code: "JP", name: "Sugiyama Jogakuen University", id: 4203 },
  { code: "JP", name: "Surugadai University", id: 4204 },
  { code: "JP", name: "Suzuka International University", id: 4205 },
  { code: "JP", name: "Suzuka University of Medical Science", id: 4206 },
  { code: "JP", name: "Taisho University", id: 4207 },
  { code: "JP", name: "Takachiho University", id: 4208 },
  { code: "JP", name: "Takamatsu University", id: 4209 },
  { code: "JP", name: "Takarazuka University of Art and Design", id: 4210 },
  { code: "JP", name: "Takasaki City University of Economics", id: 4211 },
  { code: "JP", name: "Takushoku University", id: 4212 },
  { code: "JP", name: "Tama Art University", id: 4213 },
  { code: "JP", name: "Tamagawa University", id: 4214 },
  { code: "JP", name: "Tama Institute of Management and Information Sciences", id: 4215 },
  { code: "JP", name: "Teikyo Heisei University", id: 4216 },
  { code: "JP", name: "Teikyo University of Science and Technology", id: 4217 },
  { code: "JP", name: "Temple University Japan", id: 4218 },
  { code: "JP", name: "Tenri University", id: 4219 },
  { code: "JP", name: "Tezukayama Gakuin University", id: 4220 },
  { code: "JP", name: "Tezukayama University", id: 4221 },
  { code: "JP", name: "Toho College of Music", id: 4222 },
  { code: "JP", name: "Toho Gakuen School of Music", id: 4223 },
  { code: "JP", name: "Tohoku Bunka Gakuen University", id: 4224 },
  { code: "JP", name: "Tohoku College of Pharmacy", id: 4225 },
  { code: "JP", name: "Tohoku Fukushi University", id: 4226 },
  { code: "JP", name: "Tohoku Gakuin University", id: 4227 },
  { code: "JP", name: "Tohoku Institute of Technology", id: 4228 },
  { code: "JP", name: "Tohoku University", id: 4229 },
  { code: "JP", name: "Tohoku University of Art and Design", id: 4230 },
  { code: "JP", name: "Tohoku Women's College", id: 4231 },
  { code: "JP", name: "Toho University", id: 4232 },
  { code: "JP", name: "Tohwa University", id: 4233 },
  { code: "JP", name: "Toin University of Yokohama", id: 4234 },
  { code: "JP", name: "Tokai Gakuen University", id: 4235 },
  { code: "JP", name: "Tokai University", id: 4236 },
  { code: "JP", name: "Tokai University Educational System", id: 4237 },
  { code: "JP", name: "Tokai Women's College", id: 4238 },
  { code: "JP", name: "Tokiwa University", id: 4239 },
  { code: "JP", name: "Tokoha Gakuen University", id: 4240 },
  { code: "JP", name: "Tokushima Bunri University", id: 4241 },
  { code: "JP", name: "Tokushima University", id: 4242 },
  { code: "JP", name: "Tokuyama University", id: 4243 },
  { code: "JP", name: "Tokyo College of Music", id: 4244 },
  { code: "JP", name: "Tokyo Denki University", id: 4245 },
  { code: "JP", name: "Tokyo Dental College", id: 4246 },
  { code: "JP", name: "Tokyo Engineering University", id: 4247 },
  { code: "JP", name: "Tokyo Gakugei University", id: 4248 },
  { code: "JP", name: "Tokyo Institute of Polytechnics", id: 4249 },
  { code: "JP", name: "Tokyo Institute of Technology", id: 4250 },
  { code: "JP", name: "Tokyo International University", id: 4251 },
  { code: "JP", name: "Tokyo Kasei University", id: 4252 },
  { code: "JP", name: "Tokyo Keizai University", id: 4253 },
  { code: "JP", name: "Tokyo Medical and Dental University", id: 4254 },
  { code: "JP", name: "Tokyo Medical College", id: 4255 },
  { code: "JP", name: "Tokyo Metropolitan Institute of Technology", id: 4256 },
  { code: "JP", name: "Tokyo Metropolitan University", id: 4257 },
  { code: "JP", name: "Tokyo Metropolitan University of Health Sciences", id: 4258 },
  { code: "JP", name: "Tokyo National University of Fine Arts and Music", id: 4259 },
  { code: "JP", name: "Tokyo University", id: 4260 },
  { code: "JP", name: "Tokyo University of Agriculture", id: 4261 },
  { code: "JP", name: "Tokyo University of Agriculture and Technology", id: 4262 },
  { code: "JP", name: "Tokyo University of Art and Design", id: 4263 },
  { code: "JP", name: "Tokyo University of Fisheries", id: 4264 },
  { code: "JP", name: "Tokyo University of Foreign Studies", id: 4265 },
  { code: "JP", name: "Tokyo University of Information Sciences", id: 4266 },
  { code: "JP", name: "Tokyo University of Mercantile Marine", id: 4267 },
  { code: "JP", name: "Tokyo University of Pharmacy and Life Science", id: 4268 },
  { code: "JP", name: "Tokyo Woman's Christian University", id: 4269 },
  { code: "JP", name: "Tokyo Women's College of Physical Education", id: 4270 },
  { code: "JP", name: "Tokyo Women's Medial College", id: 4271 },
  { code: "JP", name: "Tomakomai Komazawa University", id: 4272 },
  { code: "JP", name: "Tottori University", id: 4273 },
  { code: "JP", name: "Tottori University of Environmental Studies", id: 4274 },
  { code: "JP", name: "Toyama Medical and Pharmaceutical University", id: 4275 },
  { code: "JP", name: "Toyama Prefectural University", id: 4276 },
  { code: "JP", name: "Toyama University", id: 4277 },
  { code: "JP", name: "Toyama University of International Studies", id: 4278 },
  { code: "JP", name: "Toyo Eiwa Women's University", id: 4279 },
  { code: "JP", name: "Toyo Gakuen University", id: 4280 },
  { code: "JP", name: "Toyohashi University of Technology", id: 4281 },
  { code: "JP", name: "Toyota Technological Institute", id: 4282 },
  { code: "JP", name: "Toyo University", id: 4283 },
  { code: "JP", name: "Tsuda College", id: 4284 },
  { code: "JP", name: "Tsukuba University", id: 4285 },
  { code: "JP", name: "Tsurumi University", id: 4286 },
  { code: "JP", name: "Tsuru University", id: 4287 },
  { code: "JP", name: "Ueno Gakuen College", id: 4288 },
  { code: "JP", name: "United Nations University", id: 4289 },
  { code: "JP", name: "University of Aizu", id: 4290 },
  { code: "JP", name: "University of Bunkyo", id: 4291 },
  { code: "JP", name: "University of East Asia", id: 4292 },
  { code: "JP", name: "University of Electro-Communications", id: 4293 },
  { code: "JP", name: "University of Marketing and Distribution Sciences", id: 4294 },
  { code: "JP", name: "University of Occupational and Environmental Health", id: 4295 },
  { code: "JP", name: "University of Shiga Prefecture", id: 4296 },
  { code: "JP", name: "University of the Air", id: 4297 },
  { code: "JP", name: "University of the Ryukyus", id: 4298 },
  { code: "JP", name: "University of the Sacred Heart Tokyo", id: 4299 },
  { code: "JP", name: "Utsunomiya University", id: 4300 },
  { code: "JP", name: "Wakayama Medical College", id: 4301 },
  { code: "JP", name: "Wakayama University", id: 4302 },
  { code: "JP", name: "Wakkanai Hokusei Gakuen University", id: 4303 },
  { code: "JP", name: "Wako University", id: 4304 },
  { code: "JP", name: "Waseda University", id: 4305 },
  { code: "JP", name: "Wayo Women's University", id: 4306 },
  { code: "JP", name: "Women's College of Fine Arts", id: 4307 },
  { code: "JP", name: "Yamagata University", id: 4308 },
  { code: "JP", name: "Yamaguchi Prefectural University", id: 4309 },
  { code: "JP", name: "Yamaguchi University", id: 4310 },
  { code: "JP", name: "Yamanashi Gakuin University", id: 4311 },
  { code: "JP", name: "Yamanashi Medical University", id: 4312 },
  { code: "JP", name: "Yamanashi University", id: 4313 },
  { code: "JP", name: "Yasuda Women's University", id: 4314 },
  { code: "JP", name: "Yokkaichi University", id: 4315 },
  { code: "JP", name: "Yokohama City University", id: 4316 },
  { code: "JP", name: "Yokohama College of Commerce", id: 4317 },
  { code: "JP", name: "Yokohama National University", id: 4318 },
  { code: "KE", name: "Adventist University of Africa", id: 4319 },
  { code: "KE", name: "Africa International University", id: 4320 },
  { code: "KE", name: "Africa Nazarene University", id: 4321 },
  { code: "KE", name: "Aga Khan University", id: 4322 },
  { code: "KE", name: "Catholic University of Eastern Africa", id: 4323 },
  { code: "KE", name: "Chuka University", id: 4324 },
  { code: "KE", name: "Daystar University", id: 4325 },
  { code: "KE", name: " Dedan Kimathi University of Technology", id: 4326 },
  { code: "KE", name: "Egerton University", id: 4327 },
  { code: "KE", name: "Great Lakes University of Kisumu", id: 4328 },
  { code: "KE", name: "Gretsa Universtiy", id: 4329 },
  { code: "KE", name: "Inoorero University", id: 4330 },
  { code: "KE", name: "Jaramogi Oginga Odinga University of Science and Technology", id: 4331 },
  { code: "KE", name: "Jomo Kenyatta University of Agriculture and Technology", id: 4332 },
  { code: "KE", name: "Kabarak University", id: 4333 },
  { code: "KE", name: "Karatina University", id: 4334 },
  { code: "KE", name: "Kenya College of Accountancy", id: 4335 },
  { code: "KE", name: "Kenya Medical Training College", id: 4336 },
  { code: "KE", name: "Kenya Methodist University", id: 4337 },
  { code: "KE", name: "Kenyatta University", id: 4338 },
  { code: "KE", name: "Kiriri Womens University of Science and Technology", id: 4339 },
  { code: "KE", name: "Kisii University", id: 4340 },
  { code: "KE", name: "Laikipia University", id: 4341 },
  { code: "KE", name: "Maasai Mara University", id: 4342 },
  { code: "KE", name: "Maseno University", id: 4343 },
  { code: "KE", name: "Masinde Muliro University of Science and Technology", id: 4344 },
  { code: "KE", name: "Meru University of Science and Technology", id: 4345 },
  { code: "KE", name: "Moi University", id: 4346 },
  { code: "KE", name: "Mount Kenya University", id: 4347 },
  { code: "KE", name: "Multimedia University of Kenya", id: 4348 },
  { code: "KE", name: "Nairobi Institute of Business Studies ", id: 4349 },
  { code: "KE", name: "Nairobi Technical Training Institute ", id: 4350 },
  { code: "KE", name: "Pan Africa Christian University", id: 4351 },
  { code: "KE", name: "Pioneer International University", id: 4352 },
  { code: "KE", name: "Presbyterian University of East Africa", id: 4353 },
  { code: "KE", name: "Pwani University", id: 4354 },
  { code: "KE", name: "Riara University School of Business and Law", id: 4355 },
  { code: "KE", name: "Scott Christian University", id: 4356 },
  { code: "KE", name: "South Eastern Kenya University", id: 4357 },
  { code: "KE", name: "St. Pauls University", id: 4358 },
  { code: "KE", name: "Strathmore University", id: 4359 },
  { code: "KE", name: "Technical University of Kenya", id: 4360 },
  { code: "KE", name: "Technical University of Mombasa", id: 4361 },
  { code: "KE", name: "United States International University", id: 4362 },
  { code: "KE", name: "University of Eastern Africa", id: 4363 },
  { code: "KE", name: "University of Eldoret", id: 4364 },
  { code: "KE", name: "University of Kabianga", id: 4365 },
  { code: "KE", name: "University of Nairobi", id: 4366 },
  { code: "KE", name: "Uzima University College ", id: 4367 },
  { code: "KE", name: "Zetech College", id: 4368 },
  { code: "KG", name: "American University of Central Asia", id: 4369 },
  { code: "KG", name: "Asian Medical Institute", id: 4370 },
  { code: "KG", name: "Bishkek Humanities University", id: 4371 },
  { code: "KG", name: "International Ataturk Alatoo University", id: 4372 },
  { code: "KG", name: "International University of Kyrgyzstan", id: 4373 },
  { code: "KG", name: "Jalalabad State University Kyrgyzstan", id: 4374 },
  { code: "KG", name: "Kyrgyz National Agrarian University", id: 4375 },
  { code: "KG", name: "Kyrgyz National University", id: 4376 },
  { code: "KG", name: "Kyrgyz Russian Slavic University", id: 4377 },
  { code: "KG", name: "Kyrgyz State Technical University", id: 4378 },
  { code: "KG", name: "Kyrgyz State University of Construction", id: 4379 },
  { code: "KG", name: "Kyrgyz Turkish  Manas University", id: 4380 },
  { code: "KG", name: "Medical Faculty", id: 4381 },
  { code: "KH", name: "Angkor University", id: 4382 },
  { code: "KH", name: "Asia Europe University", id: 4383 },
  { code: "KH", name: "Build Bright University", id: 4384 },
  { code: "KH", name: "Cambodian Mekong University", id: 4385 },
  { code: "KH", name: "Cambodia University of Specialties", id: 4386 },
  { code: "KH", name: "Chamreun University of Poly Technology", id: 4387 },
  { code: "KH", name: "International University", id: 4388 },
  { code: "KH", name: "National Institute of Education", id: 4389 },
  { code: "KH", name: "National University of Management", id: 4390 },
  { code: "KH", name: "Norton University", id: 4391 },
  { code: "KH", name: "Pannnasatra University of Cambodia", id: 4392 },
  { code: "KH", name: "Royal University of Agriculture", id: 4393 },
  { code: "KH", name: "Royal University of Fine Arts", id: 4394 },
  { code: "KH", name: "Royal University of Law and Economics", id: 4395 },
  { code: "KH", name: "Royal University of Phnom Penh", id: 4396 },
  { code: "KH", name: "Svey Reong University", id: 4397 },
  { code: "KH", name: "University of Cambodia", id: 4398 },
  { code: "KH", name: "University of Health and Science", id: 4399 },
  { code: "KH", name: "University of Technology Phnom Penh", id: 4400 },
  { code: "KH", name: "Western University", id: 4401 },
  { code: "KH", name: "Zaman University", id: 4402 },
  { code: "KN", name: "International University for Graduate Studies", id: 4403 },
  { code: "KN", name: "Ross University Caribbean School of Veterinary Medicine", id: 4404 },
  { code: "KN", name: "St Theresa's Medical University", id: 4405 },
  { code: "KN", name: "University of Silvaner", id: 4406 },
  { code: "KN", name: "Windsor University School of Medicine", id: 4407 },
  { code: "KP", name: "Pyongyang University of Science and Technology", id: 4408 },
  { code: "KR", name: "Ajou University", id: 4409 },
  { code: "KR", name: "Andong National University", id: 4410 },
  { code: "KR", name: "Catholic University of Korea", id: 4411 },
  { code: "KR", name: "Catholic University of Pusan", id: 4412 },
  { code: "KR", name: "Catholic University of Taegu-Hyosung", id: 4413 },
  { code: "KR", name: "Changwon National University", id: 4414 },
  { code: "KR", name: "Cheju National University", id: 4415 },
  { code: "KR", name: "Cheju National University of Education", id: 4416 },
  { code: "KR", name: "Chinju National University", id: 4417 },
  { code: "KR", name: "Chinju National University of Education", id: 4418 },
  { code: "KR", name: "Chodang University", id: 4419 },
  { code: "KR", name: "Chonbuk National University", id: 4420 },
  { code: "KR", name: "Chonbuk Sanup University of Technology (Howon University)", id: 4421 },
  { code: "KR", name: "Chongju National University of Education", id: 4422 },
  { code: "KR", name: "Chongju University", id: 4423 },
  { code: "KR", name: "Chonju National University of Education", id: 4424 },
  { code: "KR", name: "Chonnam National University", id: 4425 },
  { code: "KR", name: "Chosun University", id: 4426 },
  { code: "KR", name: "Chunchon National University of Education", id: 4427 },
  { code: "KR", name: "Chung-Ang University", id: 4428 },
  { code: "KR", name: "Chungbuk National University", id: 4429 },
  { code: "KR", name: "Chung-Ju National University", id: 4430 },
  { code: "KR", name: "Chungnam National University", id: 4431 },
  { code: "KR", name: "Daebul University", id: 4432 },
  { code: "KR", name: "Dankook University", id: 4433 },
  { code: "KR", name: "Dong-A University", id: 4434 },
  { code: "KR", name: "Dongduk Women's University", id: 4435 },
  { code: "KR", name: "Dong Eui University", id: 4436 },
  { code: "KR", name: "Dongguk University", id: 4437 },
  { code: "KR", name: "Dongseo University", id: 4438 },
  { code: "KR", name: "Dongshin University", id: 4439 },
  { code: "KR", name: "Dong Yang University of Technology", id: 4440 },
  { code: "KR", name: "Duksung Women's University", id: 4441 },
  { code: "KR", name: "Ewha Women's University", id: 4442 },
  { code: "KR", name: "Gwangju Catholic College", id: 4443 },
  { code: "KR", name: "Gyeongju University", id: 4444 },
  { code: "KR", name: "Gyeongsang National University", id: 4445 },
  { code: "KR", name: "Halla University", id: 4446 },
  { code: "KR", name: "Hallym University", id: 4447 },
  { code: "KR", name: "Hankuk Aviation University", id: 4448 },
  { code: "KR", name: "Hankuk University of Foreign Studies", id: 4449 },
  { code: "KR", name: "Hankyong National University", id: 4450 },
  { code: "KR", name: "Hannam University", id: 4451 },
  { code: "KR", name: "Hansei University", id: 4452 },
  { code: "KR", name: "Hanseo University", id: 4453 },
  { code: "KR", name: "Hanshin University", id: 4454 },
  { code: "KR", name: "Hansung University Seoul", id: 4455 },
  { code: "KR", name: "Hanyang University", id: 4456 },
  { code: "KR", name: "Honam University", id: 4457 },
  { code: "KR", name: "Hongik University", id: 4458 },
  { code: "KR", name: "Hoseo University", id: 4459 },
  { code: "KR", name: "Hyupsung University", id: 4460 },
  { code: "KR", name: "Inchon National University of Education", id: 4461 },
  { code: "KR", name: "Inchon University", id: 4462 },
  { code: "KR", name: "Information and Communications University", id: 4463 },
  { code: "KR", name: "Inha University", id: 4464 },
  { code: "KR", name: "Inje University", id: 4465 },
  { code: "KR", name: "Jeonju University", id: 4466 },
  { code: "KR", name: "Kangnam University", id: 4467 },
  { code: "KR", name: "Kangnung National University", id: 4468 },
  { code: "KR", name: "Kangwon National University", id: 4469 },
  { code: "KR", name: "Kaya University", id: 4470 },
  { code: "KR", name: "KDI School of Public Policy and Management", id: 4471 },
  { code: "KR", name: "Keimyung University", id: 4472 },
  { code: "KR", name: "Kongju National University", id: 4473 },
  { code: "KR", name: "Kongju National University of Education", id: 4474 },
  { code: "KR", name: "Konkuk University", id: 4475 },
  { code: "KR", name: "Konyang University", id: 4476 },
  { code: "KR", name: "Kookmin University", id: 4477 },
  { code: "KR", name: "Korea Advanced Institute of Science & Technology", id: 4478 },
  { code: "KR", name: "Korea Maritime University", id: 4479 },
  { code: "KR", name: "Korea National Open University", id: 4480 },
  { code: "KR", name: "Korea National University of Arts", id: 4481 },
  { code: "KR", name: "Korea National University of Education", id: 4482 },
  { code: "KR", name: "Korea National University of Physical Education", id: 4483 },
  { code: "KR", name: "Korea University", id: 4484 },
  { code: "KR", name: "Kosin University", id: 4485 },
  { code: "KR", name: "Kumoh National University of Technology", id: 4486 },
  { code: "KR", name: "Kunsan National University", id: 4487 },
  { code: "KR", name: "Kwan Dong University", id: 4488 },
  { code: "KR", name: "Kwangju National University of Education", id: 4489 },
  { code: "KR", name: "Kwangju University", id: 4490 },
  { code: "KR", name: "Kwangwoon University", id: 4491 },
  { code: "KR", name: "Kyonggi University", id: 4492 },
  { code: "KR", name: "Kyongju University", id: 4493 },
  { code: "KR", name: "Kyunghee University", id: 4494 },
  { code: "KR", name: "Kyungil University", id: 4495 },
  { code: "KR", name: "Kyungnam University", id: 4496 },
  { code: "KR", name: "Kyungpook National University", id: 4497 },
  { code: "KR", name: "Kyungsung University", id: 4498 },
  { code: "KR", name: "Kyungwon University", id: 4499 },
  { code: "KR", name: "Miryang National University", id: 4500 },
  { code: "KR", name: "Mokpo National Maritime University", id: 4501 },
  { code: "KR", name: "Mokpo National University", id: 4502 },
  { code: "KR", name: "Mokwon University Taejon", id: 4503 },
  { code: "KR", name: "Myongji University", id: 4504 },
  { code: "KR", name: "Paichai University", id: 4505 },
  { code: "KR", name: "Pohang University of Science and Technology", id: 4506 },
  { code: "KR", name: "Pukyong National University", id: 4507 },
  { code: "KR", name: "Pusan National University", id: 4508 },
  { code: "KR", name: "Pusan National University of Education", id: 4509 },
  { code: "KR", name: "Pusan University of Foreign Studies", id: 4510 },
  { code: "KR", name: "Pusan Women's University", id: 4511 },
  { code: "KR", name: "Pyongtaek University ", id: 4512 },
  { code: "KR", name: "Sahmyook University", id: 4513 },
  { code: "KR", name: "Samchok National University", id: 4514 },
  { code: "KR", name: "Sang Ji University", id: 4515 },
  { code: "KR", name: "Sang Myung University", id: 4516 },
  { code: "KR", name: "Sejong University", id: 4517 },
  { code: "KR", name: "Semyung University", id: 4518 },
  { code: "KR", name: "Seokyeong University", id: 4519 },
  { code: "KR", name: "Seonam University", id: 4520 },
  { code: "KR", name: "Seoul City University", id: 4521 },
  { code: "KR", name: "Seoul National University", id: 4522 },
  { code: "KR", name: "Seoul National University of Education", id: 4523 },
  { code: "KR", name: "Seoul National University of Technology", id: 4524 },
  { code: "KR", name: "Seoul Teological University", id: 4525 },
  { code: "KR", name: "Seoul Women's University", id: 4526 },
  { code: "KR", name: "Seowon University", id: 4527 },
  { code: "KR", name: "Silla University", id: 4528 },
  { code: "KR", name: "Sogang University", id: 4529 },
  { code: "KR", name: "Sookmyung Women's University", id: 4530 },
  { code: "KR", name: "Soonchunhyang University", id: 4531 },
  { code: "KR", name: "Soongsil University", id: 4532 },
  { code: "KR", name: "Sunchon National University", id: 4533 },
  { code: "KR", name: "Sungkyul University", id: 4534 },
  { code: "KR", name: "Sung Kyun Kwan University", id: 4535 },
  { code: "KR", name: "Sungshin Women's University", id: 4536 },
  { code: "KR", name: "Sunmoon University", id: 4537 },
  { code: "KR", name: "Suwon Catholic University", id: 4538 },
  { code: "KR", name: "Suwon University", id: 4539 },
  { code: "KR", name: "Taegu National University of Education", id: 4540 },
  { code: "KR", name: "Taegu University", id: 4541 },
  { code: "KR", name: "Taejon National University of Technology", id: 4542 },
  { code: "KR", name: "Taejon University", id: 4543 },
  { code: "KR", name: "Taeshin Christian University", id: 4544 },
  { code: "KR", name: "Tongmyung University of Information Technology", id: 4545 },
  { code: "KR", name: "Ulsan University", id: 4546 },
  { code: "KR", name: "University of Science and Technology", id: 4547 },
  { code: "KR", name: "Wonkwang University", id: 4548 },
  { code: "KR", name: "Woosuk University", id: 4549 },
  { code: "KR", name: "Yeungnam University", id: 4550 },
  { code: "KR", name: "Yonsei University", id: 4551 },
  { code: "KR", name: "Yosu National University", id: 4552 },
  { code: "KW", name: "American University of Kuwait ", id: 4553 },
  { code: "KW", name: "American University of Middle East", id: 4554 },
  { code: "KW", name: "Arab Open University", id: 4555 },
  { code: "KW", name: "Australlian College of Kuwait ", id: 4556 },
  { code: "KW", name: "Gulf University for Science and Technology", id: 4557 },
  { code: "KW", name: "Kuwait International Law School", id: 4558 },
  { code: "KW", name: "Kuwait Maastricht Business School", id: 4559 },
  { code: "KW", name: "Kuwait University", id: 4560 },
  { code: "KY", name: "International College of the Cayman Islands", id: 4561 },
  { code: "KY", name: "St. Matthews University", id: 4562 },
  { code: "KZ", name: "Abai State University", id: 4563 },
  { code: "KZ", name: "Abylai Khan University", id: 4564 },
  { code: "KZ", name: "Aktau State University", id: 4565 },
  { code: "KZ", name: "Al-Farabi Kazakh National University", id: 4566 },
  { code: "KZ", name: "Atyrau State University", id: 4567 },
  { code: "KZ", name: "East Kazakhstan State University", id: 4568 },
  { code: "KZ", name: "East Kazakstan State Technical University", id: 4569 },
  { code: "KZ", name: "Eurasian Institute of market", id: 4570 },
  { code: "KZ", name: "International Kazakh-Turkish University", id: 4571 },
  { code: "KZ", name: "Karaganda State Buketov University", id: 4572 },
  { code: "KZ", name: "Karaganda State Technical University", id: 4573 },
  { code: "KZ", name: "Kazak American University", id: 4574 },
  { code: "KZ", name: "Kazakh British Technical University", id: 4575 },
  { code: "KZ", name: "Kazakhstan Institute of Management", id: 4576 },
  { code: "KZ", name: "Kazak National Technical University", id: 4577 },
  { code: "KZ", name: "Kazak State University of Agriculture", id: 4578 },
  { code: "KZ", name: "Kostanai State University", id: 4579 },
  { code: "KZ", name: "L.N. Gumilyov Eurasian National University", id: 4580 },
  { code: "KZ", name: "North Kazakhstan State University", id: 4581 },
  { code: "KZ", name: "Pavlodar University", id: 4582 },
  { code: "KZ", name: "Semey State Medical University", id: 4583 },
  { code: "KZ", name: "Semey State University", id: 4584 },
  { code: "KZ", name: "Suleyman Demirel University", id: 4585 },
  { code: "KZ", name: "Turar Ryskulov Kazakh Economic University", id: 4586 },
  { code: "KZ", name: "University of International Business", id: 4587 },
  { code: "KZ", name: "Western Kazakhstan Agricultural University", id: 4588 },
  { code: "KZ", name: "Zhetysu State University ", id: 4589 },
  { code: "KZ", name: "Zhezkazgan Baikonurov University", id: 4590 },
  { code: "LA", name: "National University of Laos", id: 4591 },
  { code: "LA", name: "Soutsaka Institute of management and Technology", id: 4592 },
  { code: "LB", name: "Al Imam Al-Ouzai University", id: 4593 },
  { code: "LB", name: "Al-Islah University", id: 4594 },
  { code: "LB", name: "Al-Manar University", id: 4595 },
  { code: "LB", name: "American University College of Technology", id: 4596 },
  { code: "LB", name: "American University of Beirut", id: 4597 },
  { code: "LB", name: "American University of Science and Technology", id: 4598 },
  { code: "LB", name: "Arab Open University", id: 4599 },
  { code: "LB", name: "Arts", id: 4600 },
  { code: "LB", name: "Balamand University", id: 4601 },
  { code: "LB", name: "Beirut Arab University", id: 4602 },
  { code: "LB", name: "Business & Computer University College", id: 4603 },
  { code: "LB", name: "Centre Universitaire de Technologie", id: 4604 },
  { code: "LB", name: "Global University", id: 4605 },
  { code: "LB", name: "Haigazian University", id: 4606 },
  { code: "LB", name: "Hariri Canadian University", id: 4607 },
  { code: "LB", name: "Holy Spirit University of Kaslik", id: 4608 },
  { code: "LB", name: "Jinan University", id: 4609 },
  { code: "LB", name: "Lebanese American University", id: 4610 },
  { code: "LB", name: "Lebanese International University", id: 4611 },
  { code: "LB", name: "Lebanese University", id: 4612 },
  { code: "LB", name: "Matn University", id: 4613 },
  { code: "LB", name: "Near East School of Theology", id: 4614 },
  { code: "LB", name: "Notre Dame University", id: 4615 },
  { code: "LB", name: "St. Joseph University Beirut", id: 4616 },
  { code: "LC", name: "Spartan University of Health Sciences", id: 4617 },
  { code: "LI", name: "Internationle Akademie für Philosophie", id: 4618 },
  { code: "LI", name: "Universität Liechtenstein", id: 4619 },
  { code: "LK", name: "Arthur C Clarke Institute of Modern Technologies", id: 4620 },
  { code: "LK", name: "Buddhasravaka Bhikshu University", id: 4621 },
  { code: "LK", name: "Buddhist and Pali University of Sri Lanka", id: 4622 },
  { code: "LK", name: "Eastern University of Sri Lanka", id: 4623 },
  { code: "LK", name: "Horizon College of Business and Technology", id: 4624 },
  { code: "LK", name: "Kotelawala Defence University", id: 4625 },
  { code: "LK", name: "Open University of Sri Lanka", id: 4626 },
  { code: "LK", name: "Postgraduate Institute of Agriculture (PGIA)", id: 4627 },
  { code: "LK", name: "Postgraduate Institute of Pali & Buddhist Studies (University of Kelaniya)", id: 4628 },
  { code: "LK", name: "Rajarata University of Sri Lanka", id: 4629 },
  { code: "LK", name: "Sabaragamuwa University of Sri Lanka", id: 4630 },
  { code: "LK", name: "South Asian Institute of Technology And Medicine (SAITM) ", id: 4631 },
  { code: "LK", name: "South Eastern University of Sri Lanka", id: 4632 },
  { code: "LK", name: "Sri Lanka Institute of Advanced Technical Education", id: 4633 },
  { code: "LK", name: "Sri Lanka Institute of Information Technology (SLIT)", id: 4634 },
  { code: "LK", name: "University of Colombo", id: 4635 },
  { code: "LK", name: "University of Jaffna", id: 4636 },
  { code: "LK", name: "University of Kelaniya", id: 4637 },
  { code: "LK", name: "University of Moratuwa", id: 4638 },
  { code: "LK", name: "University of Peradeniya", id: 4639 },
  { code: "LK", name: "University of Ruhuna", id: 4640 },
  { code: "LK", name: "University of Sri Jayawardenapura", id: 4641 },
  { code: "LK", name: "University of the Visual & Performing Arts", id: 4642 },
  { code: "LK", name: "Uva Wellassa University", id: 4643 },
  { code: "LK", name: "Wayamba University of Sri Lanka ", id: 4644 },
  { code: "LR", name: "University of Liberia", id: 4645 },
  { code: "LS", name: "National University of Lesotho", id: 4646 },
  { code: "LT", name: "International School of Management", id: 4647 },
  { code: "LT", name: "Kaunas Medical Academy", id: 4648 },
  { code: "LT", name: "Kaunas University of Technology", id: 4649 },
  { code: "LT", name: "Klaipeda University", id: 4650 },
  { code: "LT", name: "Lithuania Christian College", id: 4651 },
  { code: "LT", name: "Lithunian Academy of Music and Theatre", id: 4652 },
  { code: "LT", name: "Lithunian Institute of Physical Education", id: 4653 },
  { code: "LT", name: "Lithunian University of Agriculture", id: 4654 },
  { code: "LT", name: "Lithunian Veterinary Academy", id: 4655 },
  { code: "LT", name: "Military academy of Lithuania", id: 4656 },
  { code: "LT", name: "Mykolo Romerio university", id: 4657 },
  { code: "LT", name: "Siauliai University", id: 4658 },
  { code: "LT", name: "Vilnius Academy of Arts", id: 4659 },
  { code: "LT", name: "Vilnius Gediminas Technical University", id: 4660 },
  { code: "LT", name: "Vilnius Pedagogical University", id: 4661 },
  { code: "LT", name: "Vilnius University", id: 4662 },
  { code: "LT", name: "Vytautas Magnus University", id: 4663 },
  { code: "LU", name: "International University Institute of Luxembourg", id: 4664 },
  { code: "LU", name: "University of Luxemburg", id: 4665 },
  { code: "LV", name: "Baltic International Academy", id: 4666 },
  { code: "LV", name: "Daugavpils University", id: 4667 },
  { code: "LV", name: "Higher School of Psychology", id: 4668 },
  { code: "LV", name: "Latvian Academy of Arts", id: 4669 },
  { code: "LV", name: "Latvian Academy of Culture", id: 4670 },
  { code: "LV", name: "Latvian Academy of Music", id: 4671 },
  { code: "LV", name: "Latvian Academy of Sports Education", id: 4672 },
  { code: "LV", name: "Latvian Maritime Academy", id: 4673 },
  { code: "LV", name: "Latvian University of Agriculture", id: 4674 },
  { code: "LV", name: "Liepaja Pedagogical Higher School", id: 4675 },
  { code: "LV", name: "Medical Academy of Latvia", id: 4676 },
  { code: "LV", name: "National Defence Academy of Latvia", id: 4677 },
  { code: "LV", name: "Police Academy of Latvia", id: 4678 },
  { code: "LV", name: "Rezekne Higher School", id: 4679 },
  { code: "LV", name: "Riga Aviation University", id: 4680 },
  { code: "LV", name: "Riga Teacher Training and Educational Management Academy", id: 4681 },
  { code: "LV", name: "Riga Technical University", id: 4682 },
  { code: "LV", name: "School of Business and Finance", id: 4683 },
  { code: "LV", name: "Stockholm School of Economics in Riga", id: 4684 },
  { code: "LV", name: "Transport and Telecommunication Institute", id: 4685 },
  { code: "LV", name: "University of Latvia", id: 4686 },
  { code: "LV", name: "Vidzeme University College", id: 4687 },
  { code: "LY", name: "Al-Asmarya University for Islamic Studies", id: 4688 },
  { code: "LY", name: "Al-Jabal Al-Gharbi University", id: 4689 },
  { code: "LY", name: "Elmergib University", id: 4690 },
  { code: "LY", name: "Libyan International Medical University (LIMU)", id: 4691 },
  { code: "LY", name: "Libya Open University", id: 4692 },
  { code: "LY", name: "Omar Al-Mukhtar University", id: 4693 },
  { code: "LY", name: "Sebha University", id: 4694 },
  { code: "LY", name: "Sirte University", id: 4695 },
  { code: "LY", name: "University of Benghazi", id: 4696 },
  { code: "LY", name: "University of Tripoli", id: 4697 },
  { code: "LY", name: "Zawiya University", id: 4698 },
  { code: "MA", name: "Al Akhawayn University", id: 4699 },
  { code: "MA", name: "Ecole des Hautes Etudes Commerciales MAROC", id: 4700 },
  { code: "MA", name: "Ecole des Hautes Etudes en Gestion Informatique et Communication", id: 4701 },
  { code: "MA", name: "Ecole Mohammadia d'Ingénieurs", id: 4702 },
  { code: "MA", name: "Ecole National d'Agriculture de Meknes", id: 4703 },
  { code: "MA", name: "Ecole Nationale d'Architecture", id: 4704 },
  { code: "MA", name: "Ecole Supérieure de Commerce et des Affaires", id: 4705 },
  { code: "MA", name: "Ecole Superieure des Télécommunications", id: 4706 },
  { code: "MA", name: "Ecole Supérieure d'Informatique et de Management", id: 4707 },
  { code: "MA", name: "Institut des Hautes Etudes de Management", id: 4708 },
  { code: "MA", name: "Institute of Management and Business Technology", id: 4709 },
  { code: "MA", name: "Institut National des Postes et Télécommunications", id: 4710 },
  { code: "MA", name: "Institut Supérieur de Commerce et d'Administration des Entreprises", id: 4711 },
  { code: "MA", name: "Institut Supérieur de Management et de Technologie (MATCI)", id: 4712 },
  { code: "MA", name: "International Institute for Higher Education", id: 4713 },
  { code: "MA", name: "ISFORT - Institut de Formation en Technologie Alimentaire", id: 4714 },
  { code: "MA", name: "Université Abdelmalek Essadi", id: 4715 },
  { code: "MA", name: "Université Cadi Ayyad", id: 4716 },
  { code: "MA", name: "Université Chouaib Doukkali", id: 4717 },
  { code: "MA", name: "Université Hassan Ier - Settat", id: 4718 },
  { code: "MA", name: "Université Hassan II - Aïn Chock", id: 4719 },
  { code: "MA", name: "Université Hassan II - Mohammadia", id: 4720 },
  { code: "MA", name: "Université Ibn Toufail", id: 4721 },
  { code: "MA", name: "Université Ibn Zohr Agadir", id: 4722 },
  { code: "MA", name: "Université Mohammed Ier", id: 4723 },
  { code: "MA", name: "Université Mohammed V - Agdal", id: 4724 },
  { code: "MA", name: "Université Mohammed V - Souissi", id: 4725 },
  { code: "MA", name: "Université Moulay Ismail Meknès", id: 4726 },
  { code: "MA", name: "Université Sidi Mohammed Ben Abdellah", id: 4727 },
  { code: "MC", name: "University of Southern Europe (Monaco Business School)", id: 4728 },
  { code: "MD", name: "Academy of Economic Studies of Moldova", id: 4729 },
  { code: "MD", name: "Balti State University Alecu Russo", id: 4730 },
  { code: "MD", name: "Comrat State University", id: 4731 },
  { code: "MD", name: "Free International University of Moldova", id: 4732 },
  { code: "MD", name: "Moldova Cooperative Trade University", id: 4733 },
  { code: "MD", name: "Moldova State Agricultural University", id: 4734 },
  { code: "MD", name: "Moldova State University", id: 4735 },
  { code: "MD", name: "Moldova State University of Medicine and Pharmacy N. Testemitsanu", id: 4736 },
  { code: "MD", name: "Moldova Technical University", id: 4737 },
  { code: "MD", name: "Pedagogical State University Ion Creanga", id: 4738 },
  { code: "MD", name: "Tiraspol State University", id: 4739 },
  { code: "MD", name: "University of Applied Sciences of Moldova", id: 4740 },
  { code: "ME", name: "University of Montenegro", id: 4741 },
  { code: "MG", name: "Université d'Antananarivo", id: 4742 },
  { code: "MG", name: "Université d'Antsiranana", id: 4743 },
  { code: "MG", name: "Université de Fianarantsoa", id: 4744 },
  { code: "MG", name: "Université de Mahajanga", id: 4745 },
  { code: "MG", name: "Université de Toamasina", id: 4746 },
  { code: "MG", name: "Université de Toliary", id: 4747 },
  { code: "MK", name: "FON University", id: 4748 },
  { code: "MK", name: "South East European University", id: 4749 },
  { code: "MK", name: "State University of Tetova", id: 4750 },
  { code: "MK", name: "St.Cyril and Methodius University", id: 4751 },
  { code: "MK", name: "St.Kliment Ohridski University", id: 4752 },
  { code: "MK", name: "University for Information Science and Technology  St. Paul The Apostle ", id: 4753 },
  { code: "MK", name: "University Goce Delcev", id: 4754 },
  { code: "ML", name: "Université de Bamako", id: 4755 },
  { code: "MM", name: "International Theravada Buddhist Missionary University", id: 4756 },
  { code: "MM", name: "Mandalay Technological University", id: 4757 },
  { code: "MM", name: "Myanmar Aerospace Engineering University", id: 4758 },
  { code: "MM", name: "Technological University (Banmaw)", id: 4759 },
  { code: "MM", name: "Technological University (Dawei)", id: 4760 },
  { code: "MM", name: "Technological University (Hinthada)", id: 4761 },
  { code: "MM", name: "Technological University (Hmawbi)", id: 4762 },
  { code: "MM", name: "Technological University (Hpaan)", id: 4763 },
  { code: "MM", name: "Technological University (Kalay)", id: 4764 },
  { code: "MM", name: "Technological University (Kyaing Tong)", id: 4765 },
  { code: "MM", name: "Technological University (Kyaukse)", id: 4766 },
  { code: "MM", name: "Technological University (Lashio)", id: 4767 },
  { code: "MM", name: "Technological University (Loikaw)", id: 4768 },
  { code: "MM", name: "Technological University (Magway)", id: 4769 },
  { code: "MM", name: "Technological University (Mandalay)", id: 4770 },
  { code: "MM", name: "Technological University (Maubin)", id: 4771 },
  { code: "MM", name: "Technological University (Mawlamyaing)", id: 4772 },
  { code: "MM", name: "Technological University (Meikhtila)", id: 4773 },
  { code: "MM", name: "Technological University (Monywa)", id: 4774 },
  { code: "MM", name: "Technological University (Myeik)", id: 4775 },
  { code: "MM", name: "Technological University (Myitkyina) ", id: 4776 },
  { code: "MM", name: "Technological University (Pakokku)", id: 4777 },
  { code: "MM", name: "Technological University (Pathein)", id: 4778 },
  { code: "MM", name: "Technological University (Pinlon)", id: 4779 },
  { code: "MM", name: "Technological University (Pyay)", id: 4780 },
  { code: "MM", name: "Technological University (Sittwe)", id: 4781 },
  { code: "MM", name: "Technological University (Taunggyi)", id: 4782 },
  { code: "MM", name: "Technological University (Taungoo)", id: 4783 },
  { code: "MM", name: "Technological University (Thanlyin)", id: 4784 },
  { code: "MM", name: "Technological University (West Yangon)", id: 4785 },
  { code: "MM", name: " Technological University (Yangon)", id: 4786 },
  { code: "MM", name: "University of Computer Studies", id: 4787 },
  { code: "MM", name: "University of Medicine 1", id: 4788 },
  { code: "MM", name: "University of Medicine Magway", id: 4789 },
  { code: "MN", name: "Health sciences University of Mongolia", id: 4790 },
  { code: "MN", name: "Institute of Commerce and Business", id: 4791 },
  { code: "MN", name: "Mongolian National University", id: 4792 },
  { code: "MN", name: "Mongolian State University of Agriculture", id: 4793 },
  { code: "MN", name: "Mongolian State University of Arts and Culture", id: 4794 },
  { code: "MN", name: "Mongolian State University of Education", id: 4795 },
  { code: "MN", name: "Mongolian University of Science and Technology", id: 4796 },
  { code: "MN", name: "Orkhon University", id: 4797 },
  { code: "MN", name: "Ulaanbaatar University", id: 4798 },
  { code: "MN", name: "University of the Humanities", id: 4799 },
  { code: "MO", name: "Macau Polytechnic Institute", id: 4800 },
  { code: "MO", name: "University of Macau", id: 4801 },
  { code: "MO", name: "University of Saint Joseph", id: 4802 },
  { code: "MQ", name: "Université des Antilles et de la Guyane", id: 4803 },
  { code: "MR", name: " Université de Nouakchott", id: 4804 },
  { code: "MS", name: "University of Science", id: 4805 },
  { code: "MT", name: "European Institute of Education", id: 4806 },
  { code: "MT", name: "International Tourism Institute", id: 4807 },
  { code: "MT", name: "University of Malta", id: 4808 },
  { code: "MU", name: "University of Mauritius", id: 4809 },
  { code: "MU", name: "University of Technology", id: 4810 },
  { code: "MV", name: "Cyryx College", id: 4811 },
  { code: "MV", name: "Maldives National University", id: 4812 },
  { code: "MV", name: "Mandhu College", id: 4813 },
  { code: "MW", name: "Catholic University of Malawi", id: 4814 },
  { code: "MW", name: "Mzuzu University", id: 4815 },
  { code: "MW", name: "University of Livingstonia", id: 4816 },
  { code: "MW", name: "University of Malawi", id: 4817 },
  { code: "MX", name: "Benemerita Universidad Autónoma de Puebla", id: 4818 },
  { code: "MX", name: "Centro de Enseñanza Técnica Industrial", id: 4819 },
  { code: "MX", name: "Centro de Estudios Universitarios Monterrey", id: 4820 },
  { code: "MX", name: "Centro de Estudios Universitarios Xochicalco", id: 4821 },
  { code: "MX", name: "Centro Universitario Ixtlahuaca", id: 4822 },
  { code: "MX", name: "CETYS Universidad", id: 4823 },
  { code: "MX", name: "Dirección General de Institutos Tecnológicos", id: 4824 },
  { code: "MX", name: "El Colegio de México", id: 4825 },
  { code: "MX", name: "Escuela Bancaria y Comercial", id: 4826 },
  { code: "MX", name: "Escuela Nacional de Estudios Superiores Unidad León", id: 4827 },
  { code: "MX", name: "Instituto de Estudios Superiores de la Sierra", id: 4828 },
  { code: "MX", name: "Instituto Politécnico Nacional", id: 4829 },
  { code: "MX", name: "Instituto Tecnológico Autonómo de México", id: 4830 },
  { code: "MX", name: "Instituto Tecnológico de Aguascalientes", id: 4831 },
  { code: "MX", name: "Instituto Tecnológico de Celaya", id: 4832 },
  { code: "MX", name: "Instituto Tecnologico de Durango", id: 4833 },
  { code: "MX", name: "Instituto Tecnológico de León", id: 4834 },
  { code: "MX", name: "Instituto Tecnologico de Minatitlan", id: 4835 },
  { code: "MX", name: "Instituto Tecnológico de Morelia", id: 4836 },
  { code: "MX", name: "Instituto Tecnológico de Querétaro", id: 4837 },
  { code: "MX", name: "Instituto Tecnológico de San Luis Potosí", id: 4838 },
  { code: "MX", name: "Instituto Tecnológico de Sonora", id: 4839 },
  { code: "MX", name: "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)", id: 4840 },
  { code: "MX", name: "Instituto Tecnológico y de Estudios Superiores de Occidente", id: 4841 },
  { code: "MX", name: "Politécnico de Guanajuato", id: 4842 },
  { code: "MX", name: "Tecnologico de Baja California", id: 4843 },
  { code: "MX", name: "Universidad Anáhuac", id: 4844 },
  { code: "MX", name: "Universidad Anáhuac del Sur", id: 4845 },
  { code: "MX", name: "Universidad Autónoma Agraria Antonio Narro", id: 4846 },
  { code: "MX", name: "Universidad Autónoma Benito Juárez de Oaxaca", id: 4847 },
  { code: "MX", name: "Universidad Autónoma de Aguascalientes", id: 4848 },
  { code: "MX", name: "Universidad Autónoma de Baja California", id: 4849 },
  { code: "MX", name: "Universidad Autónoma de Baja California Sur", id: 4850 },
  { code: "MX", name: "Universidad Autónoma de Campeche", id: 4851 },
  { code: "MX", name: "Universidad Autónoma de Chapingo", id: 4852 },
  { code: "MX", name: "Universidad Autónoma de Chiapas", id: 4853 },
  { code: "MX", name: "Universidad Autónoma de Chihuahua", id: 4854 },
  { code: "MX", name: "Universidad Autónoma de Ciudad Juárez", id: 4855 },
  { code: "MX", name: "Universidad Autónoma de Coahuila", id: 4856 },
  { code: "MX", name: "Universidad Autonoma  de Durango", id: 4857 },
  { code: "MX", name: "Universidad Autónoma de Fresnillo", id: 4858 },
  { code: "MX", name: "Universidad Autónoma de Guadalajara", id: 4859 },
  { code: "MX", name: "Universidad Autónoma de Guerrero", id: 4860 },
  { code: "MX", name: "Universidad Autónoma de la Ciudad de México", id: 4861 },
  { code: "MX", name: "Universidad Autónoma de la Laguna", id: 4862 },
  { code: "MX", name: "Universidad Autónoma del Carmen", id: 4863 },
  { code: "MX", name: "Universidad Autónoma del Estado de Hidalgo", id: 4864 },
  { code: "MX", name: "Universidad Autónoma del Estado de México", id: 4865 },
  { code: "MX", name: "Universidad Autónoma del Estado de Morelos", id: 4866 },
  { code: "MX", name: "Universidad Autónoma del Noreste", id: 4867 },
  { code: "MX", name: "Universidad Autónoma de Nayarit", id: 4868 },
  { code: "MX", name: "Universidad Autónoma de Nuevo León", id: 4869 },
  { code: "MX", name: "Universidad Autónoma de Querétaro", id: 4870 },
  { code: "MX", name: "Universidad Autónoma de San Luis Potosí", id: 4871 },
  { code: "MX", name: "Universidad Autónoma de Sinaloa", id: 4872 },
  { code: "MX", name: "Universidad Autónoma de Tamaulipas", id: 4873 },
  { code: "MX", name: "Universidad Autónoma de Tlaxcala", id: 4874 },
  { code: "MX", name: "Universidad Autónoma de Veracruz Villa Rica", id: 4875 },
  { code: "MX", name: "Universidad Autónoma de Yucatán", id: 4876 },
  { code: "MX", name: "Universidad Autónoma de Zacatecas", id: 4877 },
  { code: "MX", name: "Universidad Autonoma España de Durango", id: 4878 },
  { code: "MX", name: "Universidad Autónoma Metropolitana", id: 4879 },
  { code: "MX", name: "Universidad Autónoma Metropolitana - Azcapotzalco", id: 4880 },
  { code: "MX", name: "Universidad Autónoma Metropolitana - Iztapalapa", id: 4881 },
  { code: "MX", name: "Universidad Autónoma Metropolitana - Xochimilco", id: 4882 },
  { code: "MX", name: "Universidad Champagnat", id: 4883 },
  { code: "MX", name: "Universidad Chapultepéc", id: 4884 },
  { code: "MX", name: "Universidad Cientifica Latinoamericana de Hidalgo", id: 4885 },
  { code: "MX", name: "Universidad Cristóbal Colón", id: 4886 },
  { code: "MX", name: "Universidad Cuauhtémoc", id: 4887 },
  { code: "MX", name: "Universidad de Celaya", id: 4888 },
  { code: "MX", name: "Universidad de Ciencias y Artes de Chiapas", id: 4889 },
  { code: "MX", name: "Universidad de Colima", id: 4890 },
  { code: "MX", name: "Universidad de Comunicación Avanzada", id: 4891 },
  { code: "MX", name: "Universidad de Cuautitlan Izcalli", id: 4892 },
  { code: "MX", name: "Universidad de Guadalajara", id: 4893 },
  { code: "MX", name: "Universidad de Guanajuato", id: 4894 },
  { code: "MX", name: "Universidad de Hermosillo", id: 4895 },
  { code: "MX", name: "Universidad de La Salle", id: 4896 },
  { code: "MX", name: "Universidad de las Américas", id: 4897 },
  { code: "MX", name: "Universidad de las Américas - Puebla", id: 4898 },
  { code: "MX", name: "Universidad de la Sierra A.C.", id: 4899 },
  { code: "MX", name: "Universidad del Bajío", id: 4900 },
  { code: "MX", name: "Universidad del Centro de México", id: 4901 },
  { code: "MX", name: "Universidad de León", id: 4902 },
  { code: "MX", name: "Universidad del Mar", id: 4903 },
  { code: "MX", name: "Universidad del Mayab", id: 4904 },
  { code: "MX", name: "Universidad del Noreste", id: 4905 },
  { code: "MX", name: "Universidad del Noroeste", id: 4906 },
  { code: "MX", name: "Universidad del Norte", id: 4907 },
  { code: "MX", name: "Universidad del Tepeyac", id: 4908 },
  { code: "MX", name: "Universidad del Valle de Atemajac", id: 4909 },
  { code: "MX", name: "Universidad del Valle del Bravo", id: 4910 },
  { code: "MX", name: "Universidad del Valle de México", id: 4911 },
  { code: "MX", name: "Universidad del Valle de Puebla", id: 4912 },
  { code: "MX", name: "Universidad del Valle de Toluca", id: 4913 },
  { code: "MX", name: "Universidad de Montemorelos", id: 4914 },
  { code: "MX", name: "Universidad de Monterrey", id: 4915 },
  { code: "MX", name: "Universidad de Norteamerica", id: 4916 },
  { code: "MX", name: "Universidad de Occidente", id: 4917 },
  { code: "MX", name: "Universidad de Quintana Roo", id: 4918 },
  { code: "MX", name: "Universidad de San Miguel", id: 4919 },
  { code: "MX", name: "Universidad de Sonora", id: 4920 },
  { code: "MX", name: "Universidad Don Vasco", id: 4921 },
  { code: "MX", name: "Universidad España de Durango", id: 4922 },
  { code: "MX", name: "Universidad Franciscana de México", id: 4923 },
  { code: "MX", name: "Universidad Franco-Mexicana", id: 4924 },
  { code: "MX", name: "Universidad Fray Luca Paccioli", id: 4925 },
  { code: "MX", name: "Universidad Hispanoamericana", id: 4926 },
  { code: "MX", name: "Universidad Iberoamericana", id: 4927 },
  { code: "MX", name: "Universidad Insurgentes Plantel León", id: 4928 },
  { code: "MX", name: "Universidad Intercontinental", id: 4929 },
  { code: "MX", name: "Universidad Itaca", id: 4930 },
  { code: "MX", name: "Universidad Jóse Vasconcelos", id: 4931 },
  { code: "MX", name: "Universidad Juárez Autónoma de Tabasco", id: 4932 },
  { code: "MX", name: "Universidad Juárez del Estado de Durango", id: 4933 },
  { code: "MX", name: "Universidad Justo Sierra", id: 4934 },
  { code: "MX", name: "Universidad Kino", id: 4935 },
  { code: "MX", name: "Universidad la Concordia", id: 4936 },
  { code: "MX", name: "Universidad La Salle", id: 4937 },
  { code: "MX", name: "Universidad La Salle Cuernavaca", id: 4938 },
  { code: "MX", name: "Universidad Lasallista Benavente", id: 4939 },
  { code: "MX", name: "Universidad Latina", id: 4940 },
  { code: "MX", name: "Universidad Latinoamericana", id: 4941 },
  { code: "MX", name: "Universidad Madero", id: 4942 },
  { code: "MX", name: "Universidad Mesoamericana de San Agustín", id: 4943 },
  { code: "MX", name: "Universidad Metropolitana de Monterrey", id: 4944 },
  { code: "MX", name: "Universidad Metropolitana Latin Campus", id: 4945 },
  { code: "MX", name: "Universidad Mexicana", id: 4946 },
  { code: "MX", name: "Universidad Mexicana del Noreste", id: 4947 },
  { code: "MX", name: "Universidad México Americana del Norte", id: 4948 },
  { code: "MX", name: "Universidad México Internacional", id: 4949 },
  { code: "MX", name: "Universidad Michoacana de San Nicolás de Hidalgo", id: 4950 },
  { code: "MX", name: "Universidad Motolinía del Pedegral", id: 4951 },
  { code: "MX", name: "Universidad Motolinía del Pedregal", id: 4952 },
  { code: "MX", name: "Universidad Nacional Autónoma de México", id: 4953 },
  { code: "MX", name: "Universidad Nuevo Mundo", id: 4954 },
  { code: "MX", name: "Universidad Obrera de Mexico", id: 4955 },
  { code: "MX", name: "Universidad Olmeca", id: 4956 },
  { code: "MX", name: "Universidad Panamericana", id: 4957 },
  { code: "MX", name: "Universidad Pedagógica Nacional", id: 4958 },
  { code: "MX", name: "Universidad Pedagógica Veracruzana", id: 4959 },
  { code: "MX", name: "Universidad Popular Autonóma del Estado de Puebla", id: 4960 },
  { code: "MX", name: "Universidad Regiomontana", id: 4961 },
  { code: "MX", name: "Universidad Regional del Sureste", id: 4962 },
  { code: "MX", name: "Universidad Regional Miguel Hidalgo", id: 4963 },
  { code: "MX", name: "Universidad Salesiana", id: 4964 },
  { code: "MX", name: "Universidad Santa Fe", id: 4965 },
  { code: "MX", name: "Universidad Simón Bolivar", id: 4966 },
  { code: "MX", name: "Universidad Tecnologica de la Mixteca", id: 4967 },
  { code: "MX", name: "Universidad Tecnológica de México", id: 4968 },
  { code: "MX", name: "Universidad Tecnologica de Nezahualcoyotl", id: 4969 },
  { code: "MX", name: "Universidad Valle del Grijalva", id: 4970 },
  { code: "MX", name: "Universidad Veracruzana", id: 4971 },
  { code: "MX", name: "Universidad Xicotepetl", id: 4972 },
  { code: "MX", name: "Westhill University", id: 4973 },
  { code: "MY", name: "Advance Tertiary College", id: 4974 },
  { code: "MY", name: "Aimst University", id: 4975 },
  { code: "MY", name: "Al-Bukhari International University", id: 4976 },
  { code: "MY", name: "Allianze College of Medical Sciences (ACMS)", id: 4977 },
  { code: "MY", name: "Al Madinah International University", id: 4978 },
  { code: "MY", name: "Asia E University", id: 4979 },
  { code: "MY", name: "Asia Pacific Institute of Information Technology (APIIT)", id: 4980 },
  { code: "MY", name: "Baitulmal Management Institute (IPB)", id: 4981 },
  { code: "MY", name: "Binary University College of Managemant & Entrepreneurship", id: 4982 },
  { code: "MY", name: "Brickfields Asia College", id: 4983 },
  { code: "MY", name: "British Malaysian Institute", id: 4984 },
  { code: "MY", name: "City University College of Science and Technology", id: 4985 },
  { code: "MY", name: "Curtin University of Technology", id: 4986 },
  { code: "MY", name: "Cyberjaya University College of Medical Science", id: 4987 },
  { code: "MY", name: "Darul Hikmah Islamic College", id: 4988 },
  { code: "MY", name: "Darul Naim College of Technology", id: 4989 },
  { code: "MY", name: "Darul Quran Islamic College University", id: 4990 },
  { code: "MY", name: "Darul Takzim Institute of Technology", id: 4991 },
  { code: "MY", name: "Darul Ulum Islamic College", id: 4992 },
  { code: "MY", name: "FTMS Global Academy", id: 4993 },
  { code: "MY", name: "Help University College", id: 4994 },
  { code: "MY", name: "Iact College", id: 4995 },
  { code: "MY", name: "Institute of Teachers Education", id: 4996 },
  { code: "MY", name: "Institut Prima Bestari - Pine Academy ", id: 4997 },
  { code: "MY", name: "International Islamic College", id: 4998 },
  { code: "MY", name: "International Islamic College of Penang", id: 4999 },
  { code: "MY", name: "International Islamic University", id: 5000 },
  { code: "MY", name: "International Medical University", id: 5001 },
  { code: "MY", name: "International University College of Nursing (IUCN)", id: 5002 },
  { code: "MY", name: "International University College of Technology Twintech (IUCTT)", id: 5003 },
  { code: "MY", name: "Islamic College for Sciences and Technologies", id: 5004 },
  { code: "MY", name: "Johore Bharu Primeir Polytechnic", id: 5005 },
  { code: "MY", name: "KBU International College", id: 5006 },
  { code: "MY", name: "KDU College Sdn Bhd", id: 5007 },
  { code: "MY", name: "Kolej Universiti Insaniah", id: 5008 },
  { code: "MY", name: "Kota Bharu Polytechnic", id: 5009 },
  { code: "MY", name: "Kota Kinabalu Polytechnic", id: 5010 },
  { code: "MY", name: "Kuala Lumpur Infrastructure University College", id: 5011 },
  { code: "MY", name: "Kuala Lumpur Metropolitan University", id: 5012 },
  { code: "MY", name: "Kuala Terengganu City Polytechnic", id: 5013 },
  { code: "MY", name: "Kuching Polytechnic", id: 5014 },
  { code: "MY", name: "Limkokwing University College of Creative Technology", id: 5015 },
  { code: "MY", name: "Linton University College", id: 5016 },
  { code: "MY", name: "Mahsa University College for Health and Medical Science", id: 5017 },
  { code: "MY", name: "Malaysia University of Science and Technology (MUST)", id: 5018 },
  { code: "MY", name: "Management and Science University", id: 5019 },
  { code: "MY", name: "Mara Poly-Tech College", id: 5020 },
  { code: "MY", name: "Melaka City Polytechnic", id: 5021 },
  { code: "MY", name: "Melaka Islamic University College", id: 5022 },
  { code: "MY", name: "Merlimau Polytechnic", id: 5023 },
  { code: "MY", name: "Monash University", id: 5024 },
  { code: "MY", name: "Muadzam Shah Polytechnic", id: 5025 },
  { code: "MY", name: "Multimedia University", id: 5026 },
  { code: "MY", name: "Murni Nursing College", id: 5027 },
  { code: "MY", name: "Newcastle University", id: 5028 },
  { code: "MY", name: "Nilai University College", id: 5029 },
  { code: "MY", name: "Olympia College", id: 5030 },
  { code: "MY", name: "Open University Malaysia", id: 5031 },
  { code: "MY", name: "Penang International Dental College", id: 5032 },
  { code: "MY", name: "Perak Islamic College", id: 5033 },
  { code: "MY", name: "Perdana University", id: 5034 },
  { code: "MY", name: "Perlis Islamic Higher Learning Institute", id: 5035 },
  { code: "MY", name: "Petronas Technology University", id: 5036 },
  { code: "MY", name: "Port Dickson Polytechnic", id: 5037 },
  { code: "MY", name: "Primier International University Perak", id: 5038 },
  { code: "MY", name: "PTPL College", id: 5039 },
  { code: "MY", name: "Raffles University", id: 5040 },
  { code: "MY", name: "Saito College", id: 5041 },
  { code: "MY", name: "Seberang Perai Polytechnic", id: 5042 },
  { code: "MY", name: "Segi University College", id: 5043 },
  { code: "MY", name: "Selangor Islamic University College", id: 5044 },
  { code: "MY", name: "Shahputra College", id: 5045 },
  { code: "MY", name: "Sultan Abdul Halim Muadzam Shah Polytechnic", id: 5046 },
  { code: "MY", name: "Sultanah Bahiyah Polytechnic", id: 5047 },
  { code: "MY", name: "Sultan Ahmad Shah Islamic College", id: 5048 },
  { code: "MY", name: "Sultan Azlan Shah Polytechnic ", id: 5049 },
  { code: "MY", name: "Sultan Haji Ahmad Shah Polytechnic", id: 5050 },
  { code: "MY", name: "Sultan Idris Shah Polytechnic", id: 5051 },
  { code: "MY", name: "Sultan Ismail Petra International Islamic College", id: 5052 },
  { code: "MY", name: "Sultan Mizan Zainal Abidin Polytechnic", id: 5053 },
  { code: "MY", name: "Sultan Salahuddin Abdul Aziz Shah Polytechnic", id: 5054 },
  { code: "MY", name: "Sunway University College", id: 5055 },
  { code: "MY", name: "Swinburne University of Technology", id: 5056 },
  { code: "MY", name: "Taj International College", id: 5057 },
  { code: "MY", name: "Taylor's University College", id: 5058 },
  { code: "MY", name: "TPM College", id: 5059 },
  { code: "MY", name: "Tunku Abdul Rahman Chinese College", id: 5060 },
  { code: "MY", name: "Tunku Abdul Rahman University (Chinese University)", id: 5061 },
  { code: "MY", name: "Tunku Syed Sirajuddin Polytechnic", id: 5062 },
  { code: "MY", name: "UCSI University", id: 5063 },
  { code: "MY", name: "Ungku Omar Premier Polytechnic", id: 5064 },
  { code: "MY", name: "Universiti Darul Iman", id: 5065 },
  { code: "MY", name: "Universiti Industri Selangor", id: 5066 },
  { code: "MY", name: "Universiti Kebangsaan Malaysia", id: 5067 },
  { code: "MY", name: "Universiti Kuala Lumpur", id: 5068 },
  { code: "MY", name: "Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)", id: 5069 },
  { code: "MY", name: "Universiti Malaya", id: 5070 },
  { code: "MY", name: "Universiti Malaysia Kelantan", id: 5071 },
  { code: "MY", name: "Universiti Malaysia Perlis", id: 5072 },
  { code: "MY", name: "Universiti Malaysia Sabah", id: 5073 },
  { code: "MY", name: "Universiti Malaysia Sarawak", id: 5074 },
  { code: "MY", name: "Universiti Malaysia Terengganu", id: 5075 },
  { code: "MY", name: "Universiti Pendidikan Sultan Idris", id: 5076 },
  { code: "MY", name: "Universiti Putra Malaysia", id: 5077 },
  { code: "MY", name: "Universiti Sains Malaysia", id: 5078 },
  { code: "MY", name: "Universiti Sultan Zainal Abidin", id: 5079 },
  { code: "MY", name: "Universiti Teknikal Malaysia Melaka", id: 5080 },
  { code: "MY", name: "Universiti Teknologi Malaysia", id: 5081 },
  { code: "MY", name: "Universiti Teknologi Mara", id: 5082 },
  { code: "MY", name: "Universiti Teknologi Petronas", id: 5083 },
  { code: "MY", name: "Universiti Tenaga Nasional", id: 5084 },
  { code: "MY", name: "Universiti Tun Abdul Razak", id: 5085 },
  { code: "MY", name: "Universiti Tun Hussein Onn Malaysia", id: 5086 },
  { code: "MY", name: "Universiti Tunku Abdul Rahman", id: 5087 },
  { code: "MY", name: "Universiti Utara Malaysia", id: 5088 },
  { code: "MY", name: "University College of Technology & Innovation (UCTI)", id: 5089 },
  { code: "MY", name: "University Malaysia Pahang", id: 5090 },
  { code: "MY", name: "University of Management and Technology ", id: 5091 },
  { code: "MY", name: "University of Nottingham", id: 5092 },
  { code: "MY", name: "University Tun Abdul Razak", id: 5093 },
  { code: "MY", name: "Wawasan Open University", id: 5094 },
  { code: "MY", name: "West Minster International College", id: 5095 },
  { code: "MY", name: "YPC-iTWEB College", id: 5096 },
  { code: "MZ", name: "Instituto Superior de Ciênicas e Tecnologia de Moçambique", id: 5097 },
  { code: "MZ", name: "Instituto Superior de Relações Internacionais (ISRI) ", id: 5098 },
  { code: "MZ", name: "Instituto Superior Politécnico e Universitário", id: 5099 },
  { code: "MZ", name: "Universidade Católica de Moçambique", id: 5100 },
  { code: "MZ", name: "Universidade Eduardo Mondlane", id: 5101 },
  { code: "NA", name: "Ilsa Independent College", id: 5102 },
  { code: "NA", name: "Institute of Information Technology", id: 5103 },
  { code: "NA", name: "International University of Management", id: 5104 },
  { code: "NA", name: "Polytechnic of Namibia", id: 5105 },
  { code: "NA", name: "University of Namibia", id: 5106 },
  { code: "NC", name: "University of New Caledonia", id: 5107 },
  { code: "NE", name: "Université Abdou Moumouni de Niamey", id: 5108 },
  { code: "NG", name: "Abia State Polytechnic", id: 5109 },
  { code: "NG", name: "Abia State University", id: 5110 },
  { code: "NG", name: "ABTI American University of Nigeria", id: 5111 },
  { code: "NG", name: "Abubakar Tafawa Balewa University", id: 5112 },
  { code: "NG", name: "Adamawa State University", id: 5113 },
  { code: "NG", name: "Adekunle Ajasin University", id: 5114 },
  { code: "NG", name: "Adeleke University", id: 5115 },
  { code: "NG", name: "Adeyemi College of Education", id: 5116 },
  { code: "NG", name: "Afe Babalola University", id: 5117 },
  { code: "NG", name: "African University of Science and Technology", id: 5118 },
  { code: "NG", name: "Ahmadu Bello University", id: 5119 },
  { code: "NG", name: "Ajayi Crowther University", id: 5120 },
  { code: "NG", name: " Akanu Ibiam Federal Polytechnic", id: 5121 },
  { code: "NG", name: "Akwa Ibom State University of Technology", id: 5122 },
  { code: "NG", name: "Al-hikmah University", id: 5123 },
  { code: "NG", name: "Ambrose Alli University", id: 5124 },
  { code: "NG", name: "Anambra State University of Science and Technology", id: 5125 },
  { code: "NG", name: "Auchi Polytechnic", id: 5126 },
  { code: "NG", name: "Babcock University", id: 5127 },
  { code: "NG", name: "Bauchi State University", id: 5128 },
  { code: "NG", name: "Bayero University Kano", id: 5129 },
  { code: "NG", name: "Baze University Abuja", id: 5130 },
  { code: "NG", name: "Bells University of Technology", id: 5131 },
  { code: "NG", name: "Benson Idahosa University", id: 5132 },
  { code: "NG", name: "Benue State University", id: 5133 },
  { code: "NG", name: "Bingham University", id: 5134 },
  { code: "NG", name: "Bowen University", id: 5135 },
  { code: "NG", name: "Caleb University", id: 5136 },
  { code: "NG", name: "Caritas University", id: 5137 },
  { code: "NG", name: "College of Education Ikere", id: 5138 },
  { code: "NG", name: "College of Education Oju", id: 5139 },
  { code: "NG", name: "Covenant University", id: 5140 },
  { code: "NG", name: "Crawford University ", id: 5141 },
  { code: "NG", name: "Crescent University", id: 5142 },
  { code: "NG", name: "Cross River University of Science and Technology", id: 5143 },
  { code: "NG", name: "Ebonyi State University", id: 5144 },
  { code: "NG", name: "Ekiti State University", id: 5145 },
  { code: "NG", name: "Enugu State University of Science and Technology", id: 5146 },
  { code: "NG", name: "Federal College Of Education (Technical)", id: 5147 },
  { code: "NG", name: "Federal Polytechnic Bauchi", id: 5148 },
  { code: "NG", name: "Federal University of Petroleum Resources", id: 5149 },
  { code: "NG", name: "Federal University of Technology", id: 5150 },
  { code: "NG", name: "Fountain University", id: 5151 },
  { code: "NG", name: "Godfrey Okoye University", id: 5152 },
  { code: "NG", name: "Godfrey Okoye University ", id: 5153 },
  { code: "NG", name: "Gombe State University", id: 5154 },
  { code: "NG", name: "Ibrahim Babangida University", id: 5155 },
  { code: "NG", name: "Igbinedion University", id: 5156 },
  { code: "NG", name: "Imo State University", id: 5157 },
  { code: "NG", name: "Joseph Ayo Babalola University", id: 5158 },
  { code: "NG", name: "Kaduna State University", id: 5159 },
  { code: "NG", name: "Kano State University of Technology Wudil", id: 5160 },
  { code: "NG", name: "Katsina University", id: 5161 },
  { code: "NG", name: "Kebbi State University of Science and Technology", id: 5162 },
  { code: "NG", name: "Kogi State University", id: 5163 },
  { code: "NG", name: "Kwararafa University", id: 5164 },
  { code: "NG", name: "Kwara State Polytecnic", id: 5165 },
  { code: "NG", name: "Kwara State University ", id: 5166 },
  { code: "NG", name: "Ladoke Akintola University of Technology", id: 5167 },
  { code: "NG", name: "Lagos City Polytechnic", id: 5168 },
  { code: "NG", name: "Lagos State Polytechnic", id: 5169 },
  { code: "NG", name: "Lagos State University", id: 5170 },
  { code: "NG", name: "Landmark University", id: 5171 },
  { code: "NG", name: "Lead City University of Ibadan", id: 5172 },
  { code: "NG", name: "Madonna University", id: 5173 },
  { code: "NG", name: "Michael Okpara University of Agriculture", id: 5174 },
  { code: "NG", name: "Nasarawa State University Keffi", id: 5175 },
  { code: "NG", name: "National Open University of Nigeria", id: 5176 },
  { code: "NG", name: "Niger Delta University", id: 5177 },
  { code: "NG", name: "Nigerian Defence Academy", id: 5178 },
  { code: "NG", name: "Nigerian Turkish Nile University", id: 5179 },
  { code: "NG", name: "Nnamdi Azikiwe University", id: 5180 },
  { code: "NG", name: "Novena University", id: 5181 },
  { code: "NG", name: "Obafemi Awolowo University Ile-Ife", id: 5182 },
  { code: "NG", name: "Obong University", id: 5183 },
  { code: "NG", name: "Oduduwa University", id: 5184 },
  { code: "NG", name: "Olabisi Onabanjo University ", id: 5185 },
  { code: "NG", name: "Ondo State University of Science and Technology", id: 5186 },
  { code: "NG", name: "Osun State University", id: 5187 },
  { code: "NG", name: "Pan-African University", id: 5188 },
  { code: "NG", name: "Paul University", id: 5189 },
  { code: "NG", name: "Polytechnic Ibadan", id: 5190 },
  { code: "NG", name: "Redeemers University", id: 5191 },
  { code: "NG", name: "Renaissance University", id: 5192 },
  { code: "NG", name: "Rivers State University of Science and Technology", id: 5193 },
  { code: "NG", name: "Salem University", id: 5194 },
  { code: "NG", name: "Sokoto State University ", id: 5195 },
  { code: "NG", name: "Tai Solarin University of Education", id: 5196 },
  { code: "NG", name: "Tansian University", id: 5197 },
  { code: "NG", name: "Taraba State University", id: 5198 },
  { code: "NG", name: "The Achievers University", id: 5199 },
  { code: "NG", name: "The Federal Polytechnic Offa", id: 5200 },
  { code: "NG", name: "Umaru Musa Yar'Adua University ", id: 5201 },
  { code: "NG", name: "University of Abuja", id: 5202 },
  { code: "NG", name: "University of Agriculture Abeokuta", id: 5203 },
  { code: "NG", name: "University of Agriculture Makurdi", id: 5204 },
  { code: "NG", name: "University of Benin", id: 5205 },
  { code: "NG", name: "University of Calabar", id: 5206 },
  { code: "NG", name: "University of Ibadan", id: 5207 },
  { code: "NG", name: "University of Ilorin", id: 5208 },
  { code: "NG", name: "University of Jos", id: 5209 },
  { code: "NG", name: "University of Lagos", id: 5210 },
  { code: "NG", name: "University of Maiduguri", id: 5211 },
  { code: "NG", name: "University of Mkar", id: 5212 },
  { code: "NG", name: "University of Nigeria", id: 5213 },
  { code: "NG", name: "University of Portharcourt", id: 5214 },
  { code: "NG", name: "University of Uyo", id: 5215 },
  { code: "NG", name: "Usmanu Danfodiyo University Sokoto", id: 5216 },
  { code: "NG", name: "Veritas University", id: 5217 },
  { code: "NG", name: "Wellspring University", id: 5218 },
  { code: "NG", name: "Wesley University of Science and Technology", id: 5219 },
  { code: "NG", name: "Western Delta University", id: 5220 },
  { code: "NG", name: "Yaba College of Technology", id: 5221 },
  { code: "NI", name: "Universidad Americana", id: 5222 },
  { code: "NI", name: "Universidad Catolica Redemptoris Mater", id: 5223 },
  { code: "NI", name: "Universidad Central de Nicaragua", id: 5224 },
  { code: "NI", name: "Universidad Centroamericana", id: 5225 },
  { code: "NI", name: "Universidad Centroamericana de Ciencias Empresariales (UCEM)", id: 5226 },
  { code: "NI", name: "Universidad Ciencias Comerciales", id: 5227 },
  { code: "NI", name: "Universidad de las Américas (ULAM)", id: 5228 },
  { code: "NI", name: "Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense", id: 5229 },
  { code: "NI", name: "Universidad de Managua (U de M)", id: 5230 },
  { code: "NI", name: "Universidad Hispanoamericana", id: 5231 },
  { code: "NI", name: "Universidad Iberoamericana de Ciencia y Tecnologia", id: 5232 },
  { code: "NI", name: "Universidad Internacional de Integración de América Latina", id: 5233 },
  { code: "NI", name: "Universidad Nacional Agraria", id: 5234 },
  { code: "NI", name: "Universidad Nacional Autonoma de Nicaragua", id: 5235 },
  { code: "NI", name: "Universidad Nacional de Ingenieria", id: 5236 },
  { code: "NI", name: "Universidad Nicaragüense de Ciencia y Tecnológica", id: 5237 },
  { code: "NI", name: "Universidad Politecnica de Nicaragua", id: 5238 },
  { code: "NI", name: "Universidad Popular de Nicaragua (UPONIC)", id: 5239 },
  { code: "NL", name: "Christelijke Hogeschool Windesheim", id: 5240 },
  { code: "NL", name: "De Haagse Hogeschool", id: 5241 },
  { code: "NL", name: "Delft University of Technology", id: 5242 },
  { code: "NL", name: "Dutch Delta University", id: 5243 },
  { code: "NL", name: "Eindhoven University of Technology", id: 5244 },
  { code: "NL", name: "Erasmus University Rotterdam", id: 5245 },
  { code: "NL", name: "European Open University", id: 5246 },
  { code: "NL", name: "Fontys University of Applied Sciences", id: 5247 },
  { code: "NL", name: "Foundation University", id: 5248 },
  { code: "NL", name: "Free University Amsterdam", id: 5249 },
  { code: "NL", name: "Hanzehogeschool Groningen", id: 5250 },
  { code: "NL", name: "Hogeschool Inholland", id: 5251 },
  { code: "NL", name: "Hogeschool Leiden", id: 5252 },
  { code: "NL", name: "Hogeschool Rotterdam", id: 5253 },
  { code: "NL", name: "Hogeschool Utrecht", id: 5254 },
  { code: "NL", name: "Hogeschool van Amsterdam", id: 5255 },
  { code: "NL", name: "Hogeschool voor de Kunsten Utrecht (HKU)", id: 5256 },
  { code: "NL", name: "Hotelschool The Hague", id: 5257 },
  { code: "NL", name: "Institute of Social Studies", id: 5258 },
  { code: "NL", name: "International Institute for Geo - Information Science and Earth Observation", id: 5259 },
  { code: "NL", name: "Islamic University of Rotterdam", id: 5260 },
  { code: "NL", name: "Leiden University", id: 5261 },
  { code: "NL", name: "NHL University of Applied Sciences", id: 5262 },
  { code: "NL", name: "NHTV Breda University of Professional Education", id: 5263 },
  { code: "NL", name: "NTI University", id: 5264 },
  { code: "NL", name: "Open University of the Netherlands", id: 5265 },
  { code: "NL", name: "Radboud University of Nijmegen", id: 5266 },
  { code: "NL", name: "Roosevelt Academy University College", id: 5267 },
  { code: "NL", name: "Saxion Universities ", id: 5268 },
  { code: "NL", name: "Stenden University", id: 5269 },
  { code: "NL", name: "Tilburg University", id: 5270 },
  { code: "NL", name: "UNESCO-IHE Institute for Water Education", id: 5271 },
  { code: "NL", name: "Universiteit Doesburg (UNDO)", id: 5272 },
  { code: "NL", name: "University for Humanistics (UH)", id: 5273 },
  { code: "NL", name: "University of Amsterdam", id: 5274 },
  { code: "NL", name: "University of Groningen", id: 5275 },
  { code: "NL", name: "University of Maastricht", id: 5276 },
  { code: "NL", name: "University of Nijenrode", id: 5277 },
  { code: "NL", name: "University of Twente", id: 5278 },
  { code: "NL", name: "Utrecht University", id: 5279 },
  { code: "NL", name: "Wageningen University", id: 5280 },
  { code: "NL", name: "Wittenborg University", id: 5281 },
  { code: "NL", name: "Zuyd University", id: 5282 },
  { code: "NO", name: "Agder University College", id: 5283 },
  { code: "NO", name: "Agricultural University of Norway", id: 5284 },
  { code: "NO", name: "Bergen University College", id: 5285 },
  { code: "NO", name: "Bodo Regional University", id: 5286 },
  { code: "NO", name: "Finnmark University College", id: 5287 },
  { code: "NO", name: "Lillehammer University College", id: 5288 },
  { code: "NO", name: "Molde University College", id: 5289 },
  { code: "NO", name: "National Academy of Fine Arts", id: 5290 },
  { code: "NO", name: "National College of Art and Design", id: 5291 },
  { code: "NO", name: "Norway's Information Technology University College", id: 5292 },
  { code: "NO", name: "Norwegian College of Veterinary Medicine", id: 5293 },
  { code: "NO", name: "Norwegian School of Economics and Business Administration", id: 5294 },
  { code: "NO", name: "Norwegian School of Management BI", id: 5295 },
  { code: "NO", name: "Norwegian State Academy of Music", id: 5296 },
  { code: "NO", name: "Norwegian Teacher Academy for Studies in Religion and Education", id: 5297 },
  { code: "NO", name: "Norwegian University of Life Sciences", id: 5298 },
  { code: "NO", name: "Norwegian University of Science and Technology", id: 5299 },
  { code: "NO", name: "Norwegian University of Sport and Physical Education", id: 5300 },
  { code: "NO", name: "Oslo School of Architecture", id: 5301 },
  { code: "NO", name: "University of Bergen", id: 5302 },
  { code: "NO", name: "University of Oslo", id: 5303 },
  { code: "NO", name: "University of Stavanger", id: 5304 },
  { code: "NO", name: "University of Tromsø", id: 5305 },
  { code: "NP", name: "Agriculture and Forestry University", id: 5306 },
  { code: "NP", name: "B.P.Koirala Institute of Health Sciences", id: 5307 },
  { code: "NP", name: "Kathmandu University", id: 5308 },
  { code: "NP", name: "Lumbini Buddhist University", id: 5309 },
  { code: "NP", name: "Nepal Sanskrit University", id: 5310 },
  { code: "NP", name: "Pokhara University", id: 5311 },
  { code: "NP", name: "Purbanchal University", id: 5312 },
  { code: "NP", name: "Tribhuvan University Kathmandu", id: 5313 },
  { code: "NU", name: "St Clements University - Higher Education School", id: 5314 },
  { code: "NZ", name: "Auckland University of Technology", id: 5315 },
  { code: "NZ", name: "Christchurch Polytechnic Institute of Technology", id: 5316 },
  { code: "NZ", name: "Lincoln University", id: 5317 },
  { code: "NZ", name: "Massey University", id: 5318 },
  { code: "NZ", name: "Open Polytechnic ", id: 5319 },
  { code: "NZ", name: "University of Auckland", id: 5320 },
  { code: "NZ", name: "University of Canterbury", id: 5321 },
  { code: "NZ", name: "University of Otago", id: 5322 },
  { code: "NZ", name: "University of Waikato", id: 5323 },
  { code: "NZ", name: "Victoria University of Wellington", id: 5324 },
  { code: "OM", name: "Al-Buraimi University College", id: 5325 },
  { code: "OM", name: "Arab Open University", id: 5326 },
  { code: "OM", name: "Dhofar University", id: 5327 },
  { code: "OM", name: "Ibra College of Technology", id: 5328 },
  { code: "OM", name: "Majan University College", id: 5329 },
  { code: "OM", name: "Muscat College", id: 5330 },
  { code: "OM", name: "Oman College Of Management & Technology", id: 5331 },
  { code: "OM", name: "Sohar University", id: 5332 },
  { code: "OM", name: "Sultan Qaboos University", id: 5333 },
  { code: "OM", name: "Sur University College", id: 5334 },
  { code: "OM", name: "University of Nizwa", id: 5335 },
  { code: "PA", name: "Columbus University", id: 5336 },
  { code: "PA", name: "Floret Global University", id: 5337 },
  { code: "PA", name: "Instituto de Enseñanza Superior Oteima", id: 5338 },
  { code: "PA", name: "Universidad Abierta y a Distancia de Panama", id: 5339 },
  { code: "PA", name: "Universidad Autonoma de Chiriqui", id: 5340 },
  { code: "PA", name: "Universidad Católica Santa María La Antigua", id: 5341 },
  { code: "PA", name: "Universidad de Cartago", id: 5342 },
  { code: "PA", name: "Universidad del Istmo", id: 5343 },
  { code: "PA", name: "Universidad de Panamá", id: 5344 },
  { code: "PA", name: "Universidad Especializada de Las Americas", id: 5345 },
  { code: "PA", name: "Universidad Interamericana de Educacion a Distancia de Panama", id: 5346 },
  { code: "PA", name: "Universidad Latina de Panamá", id: 5347 },
  { code: "PA", name: "Universidad Latinoamericana de Ciencia y Tecnologia", id: 5348 },
  { code: "PA", name: "Universidad Tecnológica de Panamá", id: 5349 },
  { code: "PA", name: "Universidad Tecnológica Oteima", id: 5350 },
  { code: "PA", name: "Victoria International University", id: 5351 },
  { code: "PA", name: "West Coast University (WCU)", id: 5352 },
  { code: "PE", name: "Pontificia Universidad Católica del Perú", id: 5353 },
  { code: "PE", name: "Universidad Alas Peruanas", id: 5354 },
  { code: "PE", name: "Universidad Andina del Cusco", id: 5355 },
  { code: "PE", name: "Universidad Andina Nestor Caceares Velasquez", id: 5356 },
  { code: "PE", name: "Universidad Católica de Santa María", id: 5357 },
  { code: "PE", name: "Universidad Católica Santo Toribio de Mogrovejo", id: 5358 },
  { code: "PE", name: "Universidad César Vallejo", id: 5359 },
  { code: "PE", name: "Universidad Cientifica del Sur", id: 5360 },
  { code: "PE", name: "Universidad de Chiclayo", id: 5361 },
  { code: "PE", name: "Universidad de Ciencias y Humanidades", id: 5362 },
  { code: "PE", name: "Universidad de Huánuco", id: 5363 },
  { code: "PE", name: "Universidad de Lambayeque", id: 5364 },
  { code: "PE", name: "Universidad de Lima", id: 5365 },
  { code: "PE", name: "Universidad del Pacifico", id: 5366 },
  { code: "PE", name: "Universidad de Piura", id: 5367 },
  { code: "PE", name: "Universidad Femenina del Sagrado Corazón", id: 5368 },
  { code: "PE", name: "Universidad Los Angeles de Chimbote", id: 5369 },
  { code: "PE", name: "Universidad Marcelino Champagnat", id: 5370 },
  { code: "PE", name: "Universidad Nacional Agraria de la Selva", id: 5371 },
  { code: "PE", name: "Universidad Nacional Agraria La Molina", id: 5372 },
  { code: "PE", name: "Universidad Nacional Agraria La Selva", id: 5373 },
  { code: "PE", name: "Universidad Nacional Daniel Alcides Carrion", id: 5374 },
  { code: "PE", name: "Universidad Nacional de Cajamarca", id: 5375 },
  { code: "PE", name: "Universidad Nacional de Educación Enrique Guzmán y Valle", id: 5376 },
  { code: "PE", name: "Universidad Nacional de Ingeniería", id: 5377 },
  { code: "PE", name: "Universidad Nacional de la Amazonía Peruana", id: 5378 },
  { code: "PE", name: "Universidad Nacional de La Libertad", id: 5379 },
  { code: "PE", name: "Universidad Nacional del Altiplano", id: 5380 },
  { code: "PE", name: "Universidad Nacional del Callao", id: 5381 },
  { code: "PE", name: "Universidad Nacional del Centro del Perú", id: 5382 },
  { code: "PE", name: "Universidad Nacional de Piura", id: 5383 },
  { code: "PE", name: "Universidad Nacional de San Agustin", id: 5384 },
  { code: "PE", name: "Universidad Nacional de San Antonio Abad", id: 5385 },
  { code: "PE", name: "Universidad Nacional de San Cristóbal de Huamanga", id: 5386 },
  { code: "PE", name: "Universidad Nacional de San Martín", id: 5387 },
  { code: "PE", name: "Universidad Nacional de Santa - Chimbote", id: 5388 },
  { code: "PE", name: "Universidad Nacional de Tumbes", id: 5389 },
  { code: "PE", name: "Universidad Nacional de Ucayali", id: 5390 },
  { code: "PE", name: "Universidad Nacional Federico Villarreal", id: 5391 },
  { code: "PE", name: "Universidad Nacional Hermilio Valdizan", id: 5392 },
  { code: "PE", name: "Universidad Nacional Jorge Basadre Grohmann", id: 5393 },
  { code: "PE", name: "Universidad Nacional José Faustino Sánchez Carrión", id: 5394 },
  { code: "PE", name: "Universidad Nacional Mayor de San Marcos", id: 5395 },
  { code: "PE", name: "Universidad Nacional Pedro Ruíz Gallo", id: 5396 },
  { code: "PE", name: "Universidad Nacional San Luis Gonzaga", id: 5397 },
  { code: "PE", name: "Universidad Nacional Santiago Antúnez de Mayolo", id: 5398 },
  { code: "PE", name: "Universidad Norbert Wiener", id: 5399 },
  { code: "PE", name: "Universidad Particular de Chiclayo", id: 5400 },
  { code: "PE", name: "Universidad Particular Inca Garcilaso de la Vega", id: 5401 },
  { code: "PE", name: "Universidad Peruana Cayetano Heredia", id: 5402 },
  { code: "PE", name: "Universidad Peruana de Ciencias Aplicadas", id: 5403 },
  { code: "PE", name: "Universidad Peruana de Ciencias e Informática", id: 5404 },
  { code: "PE", name: "Universidad Peruana Union", id: 5405 },
  { code: "PE", name: "Universidad Privada Antenor Orrego", id: 5406 },
  { code: "PE", name: "Universidad Privada del Norte", id: 5407 },
  { code: "PE", name: "Universidad Privada de Tacna", id: 5408 },
  { code: "PE", name: "Universidad Privada Juan Mejía Baca", id: 5409 },
  { code: "PE", name: "Universidad Privada Los Andes", id: 5410 },
  { code: "PE", name: "Universidad Privada San Juan Bautista", id: 5411 },
  { code: "PE", name: "Universidad Privada San Pedro", id: 5412 },
  { code: "PE", name: "Universidad Privada Sergio Bernales", id: 5413 },
  { code: "PE", name: "Universidad Ricardo Palma", id: 5414 },
  { code: "PE", name: "Universidad San Ignacio de Loyola", id: 5415 },
  { code: "PE", name: "Universidad San Martin de Porres", id: 5416 },
  { code: "PE", name: "Universidad San Pablo", id: 5417 },
  { code: "PE", name: "Universidad Señor de Sipán", id: 5418 },
  { code: "PE", name: "Universidad Tecnológica de los Andes de Apurímac", id: 5419 },
  { code: "PE", name: "Universidad Tecnológica del Peru", id: 5420 },
  { code: "PF", name: "Université de la Polynésie Française", id: 5421 },
  { code: "PG", name: "Divine Word University", id: 5422 },
  { code: "PG", name: "Pacific Adventist University", id: 5423 },
  { code: "PG", name: "Papua New Guinea University of Technology", id: 5424 },
  { code: "PG", name: "University of Goroka", id: 5425 },
  { code: "PG", name: "University of Papua New Guinea", id: 5426 },
  { code: "PH", name: "Adamson University", id: 5427 },
  { code: "PH", name: "Adventist University of the Philippines", id: 5428 },
  { code: "PH", name: "Angeles University", id: 5429 },
  { code: "PH", name: "Aquinas University", id: 5430 },
  { code: "PH", name: "Araullo University", id: 5431 },
  { code: "PH", name: "Arellano University", id: 5432 },
  { code: "PH", name: "Ateneo de Davao University", id: 5433 },
  { code: "PH", name: "Ateneo de Manila University", id: 5434 },
  { code: "PH", name: "Ateneo de Naga University", id: 5435 },
  { code: "PH", name: "Ateneo de Zamboanga University", id: 5436 },
  { code: "PH", name: "Benguet State University", id: 5437 },
  { code: "PH", name: "Bicol University", id: 5438 },
  { code: "PH", name: "Bulacan State University", id: 5439 },
  { code: "PH", name: "Cagayan State University", id: 5440 },
  { code: "PH", name: "Capitol University", id: 5441 },
  { code: "PH", name: "Central Luzon State University", id: 5442 },
  { code: "PH", name: "Central Mindanao University", id: 5443 },
  { code: "PH", name: "Central Philippine University", id: 5444 },
  { code: "PH", name: "Centro Escolar University", id: 5445 },
  { code: "PH", name: "Colegio de San Juan de Letran", id: 5446 },
  { code: "PH", name: "College of the Holy Spirit", id: 5447 },
  { code: "PH", name: "Davao Doctors College", id: 5448 },
  { code: "PH", name: "De La Salle University", id: 5449 },
  { code: "PH", name: "Divine Word College of Legazpi", id: 5450 },
  { code: "PH", name: "Don Mariano Marcos Memorial State University", id: 5451 },
  { code: "PH", name: "Far Eastern University", id: 5452 },
  { code: "PH", name: "Feati University", id: 5453 },
  { code: "PH", name: "Foundation University", id: 5454 },
  { code: "PH", name: "Holy Angel University", id: 5455 },
  { code: "PH", name: "Information and Communications Technology Academy", id: 5456 },
  { code: "PH", name: "Irish University Business School", id: 5457 },
  { code: "PH", name: "Isabela State University", id: 5458 },
  { code: "PH", name: "Leyte Normal University", id: 5459 },
  { code: "PH", name: "Leyte State University ", id: 5460 },
  { code: "PH", name: "Liceo de Cagayan University", id: 5461 },
  { code: "PH", name: "Lyceum of the Philippines University", id: 5462 },
  { code: "PH", name: "Manila Central University", id: 5463 },
  { code: "PH", name: "Manuel L. Quezon University", id: 5464 },
  { code: "PH", name: "Manuel S. Enverga University Foundation", id: 5465 },
  { code: "PH", name: "Mariano Marcos State University", id: 5466 },
  { code: "PH", name: "Mindanao State University", id: 5467 },
  { code: "PH", name: "Mindanao State University - Iligan Institute of Technology", id: 5468 },
  { code: "PH", name: "Misamis University", id: 5469 },
  { code: "PH", name: "Mountain View College", id: 5470 },
  { code: "PH", name: "National University", id: 5471 },
  { code: "PH", name: "New Era University", id: 5472 },
  { code: "PH", name: "Northwestern University of the Philippines", id: 5473 },
  { code: "PH", name: "Notre Dame of Marbel University", id: 5474 },
  { code: "PH", name: "Notre Dame University", id: 5475 },
  { code: "PH", name: "Our Lady of Fatima University", id: 5476 },
  { code: "PH", name: "Palawan State University", id: 5477 },
  { code: "PH", name: "Pangasinan State University", id: 5478 },
  { code: "PH", name: "Philippine Christian University", id: 5479 },
  { code: "PH", name: "Philippine Military Academy", id: 5480 },
  { code: "PH", name: "Philippine Normal University", id: 5481 },
  { code: "PH", name: "Philippine Women's University", id: 5482 },
  { code: "PH", name: "Polytechnic University of the Philippines", id: 5483 },
  { code: "PH", name: "Rizal Technological University", id: 5484 },
  { code: "PH", name: "Saint Ferdinand College", id: 5485 },
  { code: "PH", name: "Saint Louis College", id: 5486 },
  { code: "PH", name: "Saint Louis University", id: 5487 },
  { code: "PH", name: "Saint Mary's University", id: 5488 },
  { code: "PH", name: "Saint Paul University", id: 5489 },
  { code: "PH", name: "Samar State University", id: 5490 },
  { code: "PH", name: "San Beda College", id: 5491 },
  { code: "PH", name: "Silliman University", id: 5492 },
  { code: "PH", name: "Southwestern University", id: 5493 },
  { code: "PH", name: "Tarlac State University", id: 5494 },
  { code: "PH", name: "Technological University of the Philippines", id: 5495 },
  { code: "PH", name: "University of Asia and the Pacific", id: 5496 },
  { code: "PH", name: "University of Baguio", id: 5497 },
  { code: "PH", name: "University of Batangas", id: 5498 },
  { code: "PH", name: "University of Bohol", id: 5499 },
  { code: "PH", name: "University of Cagayan Valley", id: 5500 },
  { code: "PH", name: "University of Cebu", id: 5501 },
  { code: "PH", name: "University of Eastern Philippines", id: 5502 },
  { code: "PH", name: "University of lloilo", id: 5503 },
  { code: "PH", name: "University of Manila", id: 5504 },
  { code: "PH", name: "University of Mindanao", id: 5505 },
  { code: "PH", name: "University of Negros Occidental-Recoletos", id: 5506 },
  { code: "PH", name: "University of Northeastern Philippines", id: 5507 },
  { code: "PH", name: "University of Northern Philippines", id: 5508 },
  { code: "PH", name: "University of Nueva Caceres", id: 5509 },
  { code: "PH", name: "University of Pangasinan", id: 5510 },
  { code: "PH", name: "University of Perpetual Help", id: 5511 },
  { code: "PH", name: "University of Regina Carmeli", id: 5512 },
  { code: "PH", name: "University of Saint La Salle", id: 5513 },
  { code: "PH", name: "University of San Agustin", id: 5514 },
  { code: "PH", name: "University of San Carlos", id: 5515 },
  { code: "PH", name: "University of San Jose Recoletos", id: 5516 },
  { code: "PH", name: "University of Santo Tomas", id: 5517 },
  { code: "PH", name: "University of Southeastern Philippines", id: 5518 },
  { code: "PH", name: "University of Southern Mindanao", id: 5519 },
  { code: "PH", name: "University of Southern Philippines Foundation", id: 5520 },
  { code: "PH", name: "University of the Assumption", id: 5521 },
  { code: "PH", name: "University of the City of Manila", id: 5522 },
  { code: "PH", name: "University of the Cordilleras", id: 5523 },
  { code: "PH", name: "University of the East", id: 5524 },
  { code: "PH", name: "University of the Immaculate Conception", id: 5525 },
  { code: "PH", name: "University of the Philippines Baguio", id: 5526 },
  { code: "PH", name: "University of the Philippines Diliman", id: 5527 },
  { code: "PH", name: "University of the Philippines Los Banos", id: 5528 },
  { code: "PH", name: "University of the Philippines Manila", id: 5529 },
  { code: "PH", name: "University of the Philippines Mindanao", id: 5530 },
  { code: "PH", name: "University of the Philippines Open University", id: 5531 },
  { code: "PH", name: "University of the Philippines Visayas", id: 5532 },
  { code: "PH", name: "University of the Philippines Visayas - Cebu High School", id: 5533 },
  { code: "PH", name: "University of the Visayas", id: 5534 },
  { code: "PH", name: "Virgen Milagrosa University Foundation", id: 5535 },
  { code: "PH", name: "Wesleyan University Philippines", id: 5536 },
  { code: "PH", name: "Western Mindanao State University", id: 5537 },
  { code: "PH", name: "West Visayas State University", id: 5538 },
  { code: "PH", name: "Xavier University", id: 5539 },
  { code: "PK", name: "Abasyn University Peshawar", id: 5540 },
  { code: "PK", name: "Aga Khan University", id: 5541 },
  { code: "PK", name: "Air University", id: 5542 },
  { code: "PK", name: "Alhamd Islamic University", id: 5543 },
  { code: "PK", name: "Al-Khair University", id: 5544 },
  { code: "PK", name: "Allama Iqbal Open University", id: 5545 },
  { code: "PK", name: "Alpha Omega University", id: 5546 },
  { code: "PK", name: "American University of Hawaii", id: 5547 },
  { code: "PK", name: "Arid Agriculture University", id: 5548 },
  { code: "PK", name: "Asian Management Institute", id: 5549 },
  { code: "PK", name: "Asia Pacific Institute of Information Technology Pakistan (APIIT PAK)", id: 5550 },
  { code: "PK", name: "Azad Jammu and Kashmir University", id: 5551 },
  { code: "PK", name: "Bahauddin Zakariya University", id: 5552 },
  { code: "PK", name: "Bahria University", id: 5553 },
  { code: "PK", name: "Balochistan University of Engineering and Technology Khuzdar", id: 5554 },
  { code: "PK", name: "Balochistan University of Information Technology & Management Sciences ", id: 5555 },
  { code: "PK", name: "Baqai Medical University", id: 5556 },
  { code: "PK", name: "Cecos Univeristy of Information Technology", id: 5557 },
  { code: "PK", name: "College of Business Management ( CBM )", id: 5558 },
  { code: "PK", name: "COMSATS Institute of Information Technology", id: 5559 },
  { code: "PK", name: "COSMIQ Institute of Technology", id: 5560 },
  { code: "PK", name: "Dadabhoy Institute of Higher Education", id: 5561 },
  { code: "PK", name: "Dawood College of Engineering and Technology", id: 5562 },
  { code: "PK", name: "Dow University of Health Sciences", id: 5563 },
  { code: "PK", name: "Faisalabad Institute of Textile and Fashion Design", id: 5564 },
  { code: "PK", name: "FAST - National University of Computer and Emerging Sciences (NUCES)", id: 5565 },
  { code: "PK", name: "Federal Urdu University of Arts", id: 5566 },
  { code: "PK", name: "Gandhara Institute of Medical Sciences", id: 5567 },
  { code: "PK", name: "Gangdara Institute Of Science & Technology", id: 5568 },
  { code: "PK", name: "GC University", id: 5569 },
  { code: "PK", name: "Ghulam Ishaq Khan Institute of Science & Technology", id: 5570 },
  { code: "PK", name: "Gift University", id: 5571 },
  { code: "PK", name: "Gomal University", id: 5572 },
  { code: "PK", name: "Government College University Faisalabad", id: 5573 },
  { code: "PK", name: "Government College University Lahore", id: 5574 },
  { code: "PK", name: "Greenwich University", id: 5575 },
  { code: "PK", name: "Hahnamann Honoeopathic Medical College", id: 5576 },
  { code: "PK", name: "Hajvery University Lahore for Women", id: 5577 },
  { code: "PK", name: "Hamdard University", id: 5578 },
  { code: "PK", name: "Hazara University", id: 5579 },
  { code: "PK", name: "Hitec University", id: 5580 },
  { code: "PK", name: "Indus Institute of Higher Education", id: 5581 },
  { code: "PK", name: "Institute of Business Administration (IBA)", id: 5582 },
  { code: "PK", name: "Institute of Business Administration Sukkur", id: 5583 },
  { code: "PK", name: "Institute of Business Management ", id: 5584 },
  { code: "PK", name: "Institute of Business & Technology", id: 5585 },
  { code: "PK", name: "Institute of Industrial Electronics Engineering", id: 5586 },
  { code: "PK", name: "Institute of Management Sciences", id: 5587 },
  { code: "PK", name: "Institute of Space Technology", id: 5588 },
  { code: "PK", name: "International Islamic University", id: 5589 },
  { code: "PK", name: "Iqra University", id: 5590 },
  { code: "PK", name: "Islamia University of Bahawalpur", id: 5591 },
  { code: "PK", name: "Isra University", id: 5592 },
  { code: "PK", name: "Jinnah University for Women", id: 5593 },
  { code: "PK", name: "Karachi Institute Of Economics And Technology", id: 5594 },
  { code: "PK", name: "Karachi Institute of Information Technology", id: 5595 },
  { code: "PK", name: "Karachi Medical and Dental College", id: 5596 },
  { code: "PK", name: "Karachi School of Art", id: 5597 },
  { code: "PK", name: "Karakoram International University", id: 5598 },
  { code: "PK", name: "KASB Institute of Technology", id: 5599 },
  { code: "PK", name: "Kohat University of Science and Technology  (KUST)", id: 5600 },
  { code: "PK", name: "Lahore College for Women University", id: 5601 },
  { code: "PK", name: "Lahore University of Management Sciences", id: 5602 },
  { code: "PK", name: "Liaquat University of Medical & Health Sciences Jamshoro", id: 5603 },
  { code: "PK", name: "Mehran University of Engineering & Technology", id: 5604 },
  { code: "PK", name: "Minhaj University Lahore", id: 5605 },
  { code: "PK", name: "Mohammad Ali Jinnah University", id: 5606 },
  { code: "PK", name: "Mohi-ud-Din Islamic University", id: 5607 },
  { code: "PK", name: "Mooreland University", id: 5608 },
  { code: "PK", name: "National College of Arts", id: 5609 },
  { code: "PK", name: "National University of Computer & Emerging Sciences", id: 5610 },
  { code: "PK", name: "National University of Modern Languages", id: 5611 },
  { code: "PK", name: "National University of Science and Technology", id: 5612 },
  { code: "PK", name: "NED University of Engineering and Technology Karachi", id: 5613 },
  { code: "PK", name: "Newports Institute of Communication & Economics", id: 5614 },
  { code: "PK", name: "North West Frontier Province Agricultural University", id: 5615 },
  { code: "PK", name: "Nuclear Institute for Agriculture and Biology (NIAB)", id: 5616 },
  { code: "PK", name: "Pak-AIMS (American Institute of Management Sciences)", id: 5617 },
  { code: "PK", name: "Pakistan Institute of Engineering and Applied Sciences (PIEAS)", id: 5618 },
  { code: "PK", name: "Pakistan Virtual University", id: 5619 },
  { code: "PK", name: "Planwel University", id: 5620 },
  { code: "PK", name: "Preston Institute of Management Sciences and Technology (PIMSAT)", id: 5621 },
  { code: "PK", name: "Preston University", id: 5622 },
  { code: "PK", name: "Punjab Institute of Computer science", id: 5623 },
  { code: "PK", name: "Punjab University College of Information Tecnology", id: 5624 },
  { code: "PK", name: "Pyramid Education Center", id: 5625 },
  { code: "PK", name: "Qauid-e-Awam University of Engineering Sciences & Technology", id: 5626 },
  { code: "PK", name: "Quaid-i-Azam University", id: 5627 },
  { code: "PK", name: "Qurtuba University of Science and Infromation Technology", id: 5628 },
  { code: "PK", name: "Riphah International Univeristy", id: 5629 },
  { code: "PK", name: "Sarhad University of Science & Information Technology", id: 5630 },
  { code: "PK", name: "Shah Abdul Latif University Khairpur", id: 5631 },
  { code: "PK", name: "Shaheed Benazir Bhutto Women University ", id: 5632 },
  { code: "PK", name: "Shaheed Zulifkar Ali Bhutto Institute of Science and Technology", id: 5633 },
  { code: "PK", name: "Sindh Agricultural University", id: 5634 },
  { code: "PK", name: "Sindh Madressatul Islam University", id: 5635 },
  { code: "PK", name: "Sir Syed Institute Of Technology Islamabad", id: 5636 },
  { code: "PK", name: "Sir Syed University of Engineering and Technology", id: 5637 },
  { code: "PK", name: "Textile Institute of Pakistan", id: 5638 },
  { code: "PK", name: "The Global College Lahore", id: 5639 },
  { code: "PK", name: "The Government Sadiq College Women University Bahawalpur", id: 5640 },
  { code: "PK", name: "The Superior College ", id: 5641 },
  { code: "PK", name: "University of Agriculture Faisalabad", id: 5642 },
  { code: "PK", name: "University of Balochistan", id: 5643 },
  { code: "PK", name: "University of Central Punjab", id: 5644 },
  { code: "PK", name: "University of East", id: 5645 },
  { code: "PK", name: "University of Education", id: 5646 },
  { code: "PK", name: "University of Engineering and Technology Lahore", id: 5647 },
  { code: "PK", name: "University of Engineering and Technology Peshawar", id: 5648 },
  { code: "PK", name: "University of Engineering and Technology Taxila", id: 5649 },
  { code: "PK", name: "University of Faisalabad", id: 5650 },
  { code: "PK", name: "University of Gujrat", id: 5651 },
  { code: "PK", name: "University of Islamic Studies", id: 5652 },
  { code: "PK", name: "University of Karachi", id: 5653 },
  { code: "PK", name: "University of Lahore", id: 5654 },
  { code: "PK", name: "University of Malakand", id: 5655 },
  { code: "PK", name: "University of Management and Technology", id: 5656 },
  { code: "PK", name: "University of Munawwar-ul-Islam", id: 5657 },
  { code: "PK", name: "University of NorthWest", id: 5658 },
  { code: "PK", name: "University of Peshawar", id: 5659 },
  { code: "PK", name: "University of Sargodha", id: 5660 },
  { code: "PK", name: "University of Science & Technology Bannu", id: 5661 },
  { code: "PK", name: "University of Sindh", id: 5662 },
  { code: "PK", name: "University of South Asia", id: 5663 },
  { code: "PK", name: "University of the Punjab", id: 5664 },
  { code: "PK", name: "University of Veterinary & Animal Science", id: 5665 },
  { code: "PK", name: "University of Wah", id: 5666 },
  { code: "PK", name: "Virtual University of Pakistan", id: 5667 },
  { code: "PK", name: "Wah Medical College", id: 5668 },
  { code: "PK", name: "Zawiya Academy", id: 5669 },
  { code: "PK", name: "Zia-ud-Din Medical University", id: 5670 },
  { code: "PL", name: "Academy of Economics in Cracow", id: 5671 },
  { code: "PL", name: "Academy of Economics in Katowice", id: 5672 },
  { code: "PL", name: "Academy of Economics in Poznan", id: 5673 },
  { code: "PL", name: "Academy of Economics in Wroclaw", id: 5674 },
  { code: "PL", name: "Academy of Humanities and Economics in Lodz", id: 5675 },
  { code: "PL", name: "Academy of International Economic and Political Relations", id: 5676 },
  { code: "PL", name: "Adam Mickiewicz University of Poznan", id: 5677 },
  { code: "PL", name: "AGH University of Science and Technology", id: 5678 },
  { code: "PL", name: "Agricultural-Technical Academy in Olsztyn", id: 5679 },
  { code: "PL", name: "Agricultural University of Cracow", id: 5680 },
  { code: "PL", name: "Agricultural University of Lublin", id: 5681 },
  { code: "PL", name: "Agricultural University of Poznan", id: 5682 },
  { code: "PL", name: "Agricultural University of Szczecin", id: 5683 },
  { code: "PL", name: "Agricultural University of Warsaw", id: 5684 },
  { code: "PL", name: "Agricultural University of Wroclaw", id: 5685 },
  { code: "PL", name: "Akademia Podlaska", id: 5686 },
  { code: "PL", name: "Aleksander Gieysztor School of Humanities in Pultusk", id: 5687 },
  { code: "PL", name: "Aleksander Zelwerowicz State Theatre Academy", id: 5688 },
  { code: "PL", name: "Andrzej Frycz Modrzewski Cracow College", id: 5689 },
  { code: "PL", name: "Cardinal Stefan Wyszynski University in Warsaw", id: 5690 },
  { code: "PL", name: "Catholic University of Lublin", id: 5691 },
  { code: "PL", name: "Christian Theological Academy in Warszaw", id: 5692 },
  { code: "PL", name: "College of Computer Science in Lodz", id: 5693 },
  { code: "PL", name: "Collegium Civitas", id: 5694 },
  { code: "PL", name: "European Academy of Arts in Warsaw", id: 5695 },
  { code: "PL", name: "Fine Arts Academy in Gdansk", id: 5696 },
  { code: "PL", name: "Fine Arts Academy in Katowice", id: 5697 },
  { code: "PL", name: "Fine Arts Academy in Poznan", id: 5698 },
  { code: "PL", name: "Fine Arts Academy in Warsaw", id: 5699 },
  { code: "PL", name: "Fine Arts Academy in Wroclaw", id: 5700 },
  { code: "PL", name: "Fine Arts Academy Jan Matejko in Cracow", id: 5701 },
  { code: "PL", name: "Fine Arts Academy Wladyslaw Strzeminski in Lodz", id: 5702 },
  { code: "PL", name: "French Institute of Management", id: 5703 },
  { code: "PL", name: "Gdansk Management College", id: 5704 },
  { code: "PL", name: "Gdynia Maritime Academy", id: 5705 },
  { code: "PL", name: "Higher School o Business in Tarnow", id: 5706 },
  { code: "PL", name: "Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz", id: 5707 },
  { code: "PL", name: "Jagiellonian University Cracow", id: 5708 },
  { code: "PL", name: "Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala", id: 5709 },
  { code: "PL", name: "Katowice School of Economics", id: 5710 },
  { code: "PL", name: "Lazarski School of Commerce and Law", id: 5711 },
  { code: "PL", name: "Leon Kozminski Academy of Entrepreneurship and Mangement", id: 5712 },
  { code: "PL", name: "Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw", id: 5713 },
  { code: "PL", name: "Ludwik Solski State Academy of Theatre in Cracow", id: 5714 },
  { code: "PL", name: "Maria Curie-Sklodowska University Lublin", id: 5715 },
  { code: "PL", name: "Maria Grzegorzewska College for Special Education", id: 5716 },
  { code: "PL", name: "Maritime University in Szczecin", id: 5717 },
  { code: "PL", name: "Medical Academy in Bialystok", id: 5718 },
  { code: "PL", name: "Medical Academy in Gdansk", id: 5719 },
  { code: "PL", name: "Medical Academy in Lodz", id: 5720 },
  { code: "PL", name: "Medical Academy in Lublin", id: 5721 },
  { code: "PL", name: "Medical Academy in Warszawa", id: 5722 },
  { code: "PL", name: "Medical Academy in Wroclaw", id: 5723 },
  { code: "PL", name: "Medical Academy Karol Marcinkowski in Poznan", id: 5724 },
  { code: "PL", name: "Medical Academy Ludwik Rydygier in Bydgoszcz", id: 5725 },
  { code: "PL", name: "Music Academy Felix Nowowiejski in Bydgoszcz", id: 5726 },
  { code: "PL", name: "Music Academy Fryderyk Chopin in Warszaw", id: 5727 },
  { code: "PL", name: "Music Academy Ignacy Jana Paderewski in Poznan", id: 5728 },
  { code: "PL", name: "Music Academy in Cracow", id: 5729 },
  { code: "PL", name: "Music Academy in Lodz", id: 5730 },
  { code: "PL", name: "Music Academy Karol Lipinski in Wroclaw", id: 5731 },
  { code: "PL", name: "Music Academy Karol Szymanowski in Katowice", id: 5732 },
  { code: "PL", name: "Music Academy Stanislaw Moniuszko in Gdansk", id: 5733 },
  { code: "PL", name: "Nicolaus Copernicus University of Torun", id: 5734 },
  { code: "PL", name: "Pawel Wlodkowic University College in Plock", id: 5735 },
  { code: "PL", name: "Pedagogical University of Bydgoszcz", id: 5736 },
  { code: "PL", name: "Pedagogical University of Czestochowa", id: 5737 },
  { code: "PL", name: "Pedagogical University of Kielce", id: 5738 },
  { code: "PL", name: "Pedagogical University of Krakow", id: 5739 },
  { code: "PL", name: "Pedagogical University of Rzeszow", id: 5740 },
  { code: "PL", name: "Pedagogical University of Slupsk", id: 5741 },
  { code: "PL", name: "Pedagogical University of the Polish Association for Adult Education in Warsaw", id: 5742 },
  { code: "PL", name: "Pedagogical University of Zielona Gora", id: 5743 },
  { code: "PL", name: "Physical Education Academy Eugeniusz Piasecki in Poznan", id: 5744 },
  { code: "PL", name: "Physical Education Academy in Katowice", id: 5745 },
  { code: "PL", name: "Physical Education Academy in Wroclaw", id: 5746 },
  { code: "PL", name: "Physical Education Academy Jedrzej Sniadecki in Gdansk", id: 5747 },
  { code: "PL", name: "Physical Education Academy Jozef Pilsudski in Warsaw", id: 5748 },
  { code: "PL", name: "Polish-Japanese Institute of Information Technology in Warsaw", id: 5749 },
  { code: "PL", name: "Polish Open University in Warsaw (WSZ/POU)", id: 5750 },
  { code: "PL", name: "Polonia University in Czêstochowa", id: 5751 },
  { code: "PL", name: "Pomeranian Academy of Medicine in Szczecin", id: 5752 },
  { code: "PL", name: "Poznan School of Banking", id: 5753 },
  { code: "PL", name: "Poznan University of Life Sciences", id: 5754 },
  { code: "PL", name: "Ryszard Lazarski University of Commerce and Law in Warsaw", id: 5755 },
  { code: "PL", name: "School of Banking and Management in Cracow", id: 5756 },
  { code: "PL", name: "Silesian Academy of Medicine in Katowice", id: 5757 },
  { code: "PL", name: "Silesian School of Economics and Languages", id: 5758 },
  { code: "PL", name: "Silesian Technical University of Gliwice", id: 5759 },
  { code: "PL", name: "Staszic Pila College", id: 5760 },
  { code: "PL", name: "State Film", id: 5761 },
  { code: "PL", name: "Teacher Training College of Revalidation", id: 5762 },
  { code: "PL", name: "Technical University of Bialystok", id: 5763 },
  { code: "PL", name: "Technical University of Cracow", id: 5764 },
  { code: "PL", name: "Technical University of Czestochowa", id: 5765 },
  { code: "PL", name: "Technical University of Gdansk", id: 5766 },
  { code: "PL", name: "Technical University of Kielce", id: 5767 },
  { code: "PL", name: "Technical University of Koszalin", id: 5768 },
  { code: "PL", name: "Technical University of Lodz", id: 5769 },
  { code: "PL", name: "Technical University of Lublin", id: 5770 },
  { code: "PL", name: "Technical University of Opole", id: 5771 },
  { code: "PL", name: "Technical University of Poznan", id: 5772 },
  { code: "PL", name: "Technical University of Radom", id: 5773 },
  { code: "PL", name: "Technical University of Rzeszow", id: 5774 },
  { code: "PL", name: "Technical University of Szczecin", id: 5775 },
  { code: "PL", name: "Technical University of Warsaw", id: 5776 },
  { code: "PL", name: "Technical University of Wroclaw", id: 5777 },
  { code: "PL", name: "Technical University of Zielona Gora", id: 5778 },
  { code: "PL", name: "University of Bialystok", id: 5779 },
  { code: "PL", name: "University of Finance and Management in Bialystok", id: 5780 },
  { code: "PL", name: "University of Gdansk", id: 5781 },
  { code: "PL", name: "University of Information Technology and Management in Rzeszow", id: 5782 },
  { code: "PL", name: "University of Insurance and Banking in Warsaw", id: 5783 },
  { code: "PL", name: "University of Lodz", id: 5784 },
  { code: "PL", name: "University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.", id: 5785 },
  { code: "PL", name: "University of Management and Marketing", id: 5786 },
  { code: "PL", name: "University of Opole", id: 5787 },
  { code: "PL", name: "University of Silesia", id: 5788 },
  { code: "PL", name: "University of Szczecin", id: 5789 },
  { code: "PL", name: "University of Technology and Life Sciences", id: 5790 },
  { code: "PL", name: "University of Wamia and Masuria in Olsztyn", id: 5791 },
  { code: "PL", name: "University of Warsaw", id: 5792 },
  { code: "PL", name: "University of Wroclaw", id: 5793 },
  { code: "PL", name: "University School of Physical Education", id: 5794 },
  { code: "PL", name: "Walcz College", id: 5795 },
  { code: "PL", name: "Warsaw School of Economics", id: 5796 },
  { code: "PL", name: "Warsaw School of Information Technology", id: 5797 },
  { code: "PL", name: "Warsaw School of Social Psychology", id: 5798 },
  { code: "PL", name: "West Pomeranian Business School in Szczecin", id: 5799 },
  { code: "PL", name: "Zachodniopomorska School of Science and Engineering", id: 5800 },
  { code: "PR", name: "Caribbean University", id: 5801 },
  { code: "PR", name: "Centro de Estudios Avanzados de Puerto Rico y el Caribe", id: 5802 },
  { code: "PR", name: "Pontificia Universidad Catolica de Puerto Rico", id: 5803 },
  { code: "PR", name: "San Juan Bautista School of Medicine", id: 5804 },
  { code: "PR", name: "Sistema Universitario Ana G. Méndez", id: 5805 },
  { code: "PR", name: "Universidad Central de Bayamón", id: 5806 },
  { code: "PR", name: "Universidad Central del Caribe", id: 5807 },
  { code: "PR", name: "Universidad del Sagrado Corazon", id: 5808 },
  { code: "PR", name: "Universidad del Turabo", id: 5809 },
  { code: "PR", name: "Universidad de Puerto Rico", id: 5810 },
  { code: "PR", name: "Universidad Interamericana de Puerto Rico", id: 5811 },
  { code: "PR", name: "Universidad Metropolitana", id: 5812 },
  { code: "PR", name: "Universidad Politecnica de Puerto Rico", id: 5813 },
  { code: "PS", name: "Al-Aqsa University", id: 5814 },
  { code: "PS", name: "Al-Azhar University of Gaza", id: 5815 },
  { code: "PS", name: "Al-Quds Open University", id: 5816 },
  { code: "PS", name: "Al-Quds University - The Arab University in Jerusalem", id: 5817 },
  { code: "PS", name: "An-Najah National University", id: 5818 },
  { code: "PS", name: "Arab American University - Jenin", id: 5819 },
  { code: "PS", name: "Bethlehem University", id: 5820 },
  { code: "PS", name: "Birzeit University", id: 5821 },
  { code: "PS", name: "Hebron University", id: 5822 },
  { code: "PS", name: "Islamic University of Gaza", id: 5823 },
  { code: "PS", name: "Palestine Polytechnic University", id: 5824 },
  { code: "PS", name: "Palestine Technical College-Dier Elbalah", id: 5825 },
  { code: "PS", name: "Palestine Technical University - Kadoorie", id: 5826 },
  { code: "PS", name: "Ummah University", id: 5827 },
  { code: "PS", name: "University College of Applied Sciences", id: 5828 },
  { code: "PS", name: "University of Palestine", id: 5829 },
  { code: "PT", name: "Academia Nacional Superior de Orquesta", id: 5830 },
  { code: "PT", name: "Escola Nautica Infante D. Henrique", id: 5831 },
  { code: "PT", name: "Escola Superior de Artes e Design", id: 5832 },
  { code: "PT", name: "Escola Superior de Hotelaria e Turismo do Estoril", id: 5833 },
  { code: "PT", name: "European University Portugal", id: 5834 },
  { code: "PT", name: "Instituto de Artes Visuais", id: 5835 },
  { code: "PT", name: "Instituto Piaget", id: 5836 },
  { code: "PT", name: "Instituto Politécnico da Guarda", id: 5837 },
  { code: "PT", name: "Instituto Politécnico de Beja", id: 5838 },
  { code: "PT", name: "Instituto Politécnico de Bragança", id: 5839 },
  { code: "PT", name: "Instituto Politécnico de Castelo Branco", id: 5840 },
  { code: "PT", name: "Instituto Politécnico de Coimbra", id: 5841 },
  { code: "PT", name: "Instituto Politécnico de Leiria", id: 5842 },
  { code: "PT", name: "Instituto Politécnico de Lisboa", id: 5843 },
  { code: "PT", name: "Instituto Politécnico de Portalegre", id: 5844 },
  { code: "PT", name: "Instituto Politécnico de Santarém", id: 5845 },
  { code: "PT", name: "Instituto Politécnico de Setúbal", id: 5846 },
  { code: "PT", name: "Instituto Politécnico de Tomar", id: 5847 },
  { code: "PT", name: "Instituto Politécnico de Viana do Castelo", id: 5848 },
  { code: "PT", name: "Instituto Politécnico do Cávado e do Ave", id: 5849 },
  { code: "PT", name: "Instituto Politecnico do Porto", id: 5850 },
  { code: "PT", name: "Instituto Superior Bissaya Barreto", id: 5851 },
  { code: "PT", name: "Instituto Superior D. Afonso III - INUAF", id: 5852 },
  { code: "PT", name: "Instituto Superior de Assistentes e Intérpretes", id: 5853 },
  { code: "PT", name: "Instituto Superior de Ciências do Trabalho e da Empresa", id: 5854 },
  { code: "PT", name: "Instituto Superior de Ciências Educativas", id: 5855 },
  { code: "PT", name: "Instituto Superior de Ciências Empresariais e de Turismo", id: 5856 },
  { code: "PT", name: "Instituto Superior de Entre Douro e Vouga", id: 5857 },
  { code: "PT", name: "Instituto Superior de Gestão - ISG", id: 5858 },
  { code: "PT", name: "Instituto Superior de Informática e Gestão", id: 5859 },
  { code: "PT", name: "Instituto Superior de Línguas e Administração", id: 5860 },
  { code: "PT", name: "Instituto Superior de Paços de Brandão", id: 5861 },
  { code: "PT", name: "Instituto Superior de Psicologia Aplicada", id: 5862 },
  { code: "PT", name: "Instituto Superior de Saúde do Alto Ave", id: 5863 },
  { code: "PT", name: "Instituto Superior de Tecnologias Avançadas - ISTEC", id: 5864 },
  { code: "PT", name: "Instituto Superior de Transportes e Comunicações", id: 5865 },
  { code: "PT", name: "Instituto Superior Novas Profissões - INP", id: 5866 },
  { code: "PT", name: "Instituto Superior Politécnico Gaya", id: 5867 },
  { code: "PT", name: "Universidade Aberta Lisboa", id: 5868 },
  { code: "PT", name: "Universidade Atlântica", id: 5869 },
  { code: "PT", name: "Universidade Autónoma de Lisboa Luís de Camoes", id: 5870 },
  { code: "PT", name: "Universidade Catolica Portuguesa", id: 5871 },
  { code: "PT", name: "Universidade da Beira Interior", id: 5872 },
  { code: "PT", name: "Universidade da Madeira", id: 5873 },
  { code: "PT", name: "Universidade de Aveiro", id: 5874 },
  { code: "PT", name: "Universidade de Coimbra", id: 5875 },
  { code: "PT", name: "Universidade de Evora", id: 5876 },
  { code: "PT", name: "Universidade de Lisboa", id: 5877 },
  { code: "PT", name: "Universidade de Tras-os-Montes e Alto Douro", id: 5878 },
  { code: "PT", name: "Universidade do Algarve", id: 5879 },
  { code: "PT", name: "Universidade do Minho", id: 5880 },
  { code: "PT", name: "Universidade do Porto", id: 5881 },
  { code: "PT", name: "Universidade dos Acores", id: 5882 },
  { code: "PT", name: "Universidade Fernando Pessoa", id: 5883 },
  { code: "PT", name: "Universidade Independente", id: 5884 },
  { code: "PT", name: "Universidade Internacional Lisboa", id: 5885 },
  { code: "PT", name: "Universidade Lusiada", id: 5886 },
  { code: "PT", name: "Universidade Lusófona de Humanidades e Technologias", id: 5887 },
  { code: "PT", name: "Universidade Moderna de Lisboa", id: 5888 },
  { code: "PT", name: "Universidade Nova de Lisboa", id: 5889 },
  { code: "PT", name: "Universidade Portucalense Infante D. Henrique", id: 5890 },
  { code: "PT", name: "Universidade Tecnica de Lisboa", id: 5891 },
  { code: "PY", name: "Universidad Americana", id: 5892 },
  { code: "PY", name: "Universidad Autónoma de Asunción", id: 5893 },
  { code: "PY", name: "Universidad Autónoma del Paraguay", id: 5894 },
  { code: "PY", name: "Universidad Católica Nuestra Senora de la Asunción", id: 5895 },
  { code: "PY", name: "Universidad Columbia del Paraguay", id: 5896 },
  { code: "PY", name: "Universidad Comunera", id: 5897 },
  { code: "PY", name: "Universidad de la Integración de las Americas (UNIDAD)", id: 5898 },
  { code: "PY", name: "Universidad del Cono Sur de las Américas", id: 5899 },
  { code: "PY", name: "Universidad del Norte", id: 5900 },
  { code: "PY", name: "Universidad Metropolitana de Asunción", id: 5901 },
  { code: "PY", name: "Universidad Nacional de Asunción", id: 5902 },
  { code: "PY", name: "Universidad Nacional del Este", id: 5903 },
  { code: "PY", name: "Universidad Privada del Este", id: 5904 },
  { code: "QA", name: "Northwestern University In Qatar", id: 5905 },
  { code: "QA", name: "University of Qatar", id: 5906 },
  { code: "RE", name: "Université de la Reunion", id: 5907 },
  { code: "RO", name: "1 December University of Alba Iulia", id: 5908 },
  { code: "RO", name: "Academia de Studii Economice din Bucuresti", id: 5909 },
  { code: "RO", name: "Academia Tehnica Militara", id: 5910 },
  { code: "RO", name: "Academy of Arts George Enescu Iasi", id: 5911 },
  { code: "RO", name: "Academy of Music Georghe Dima Cluj-Napoca", id: 5912 },
  { code: "RO", name: "AISTEDA", id: 5913 },
  { code: "RO", name: "Babes-Bolyai University of Cluj-Napoca", id: 5914 },
  { code: "RO", name: "Banat's University of Agricultural Sciences", id: 5915 },
  { code: "RO", name: "Constantin Brancoveanu University Pitesti", id: 5916 },
  { code: "RO", name: "Emanuel University", id: 5917 },
  { code: "RO", name: "Institute of Architecture Ion Mincu Bucharest", id: 5918 },
  { code: "RO", name: "Maritime University Constanta", id: 5919 },
  { code: "RO", name: "National Academy for Physical Education and Sports Bucharest", id: 5920 },
  { code: "RO", name: "National School of Political and Administrative Studies Bucharest", id: 5921 },
  { code: "RO", name: "National University of Arts", id: 5922 },
  { code: "RO", name: "National University of Music", id: 5923 },
  { code: "RO", name: "National University of Theater and Film Arts", id: 5924 },
  { code: "RO", name: "North University of Baia Mare", id: 5925 },
  { code: "RO", name: "Oradea University", id: 5926 },
  { code: "RO", name: "Petru Maior University of Targu Mures", id: 5927 },
  { code: "RO", name: "Romanian-American University", id: 5928 },
  { code: "RO", name: "Spiru Haret University", id: 5929 },
  { code: "RO", name: "Targu-Mures University of Theatre", id: 5930 },
  { code: "RO", name: "Technical University of Civil Engineering Bucharest", id: 5931 },
  { code: "RO", name: "Technical University of Cluj-Napoca", id: 5932 },
  { code: "RO", name: "Technical University of Iasi", id: 5933 },
  { code: "RO", name: "Technical University of Timisoara", id: 5934 },
  { code: "RO", name: "Universitatea de Vest Vasile Goldi&#351; ", id: 5935 },
  { code: "RO", name: "University Aurel Vlaicu Arad", id: 5936 },
  { code: "RO", name: "University Lucian Blaga of Sibiu", id: 5937 },
  { code: "RO", name: "University of Agriculture and Veterinary Medicine Bucharest", id: 5938 },
  { code: "RO", name: "University of Agriculture and Veterinary Medicine Cluj-Napoca", id: 5939 },
  { code: "RO", name: "University of Agriculture and Veterinary Medicine Iasi", id: 5940 },
  { code: "RO", name: "University of Agriculture and Veterinary Medicine Timisoara", id: 5941 },
  { code: "RO", name: "University of Art and Design Cluj-Napoca", id: 5942 },
  { code: "RO", name: "University of Bacau", id: 5943 },
  { code: "RO", name: "University of Bucharest", id: 5944 },
  { code: "RO", name: "University of Constanta", id: 5945 },
  { code: "RO", name: "University of Constanta Medical School", id: 5946 },
  { code: "RO", name: "University of Craiova", id: 5947 },
  { code: "RO", name: "University of Galatzi", id: 5948 },
  { code: "RO", name: "University of Iasi", id: 5949 },
  { code: "RO", name: "University of Medicine and Pharmacology of Oradea", id: 5950 },
  { code: "RO", name: "University of Medicine and Pharmacy of Bucharest", id: 5951 },
  { code: "RO", name: "University of Medicine and Pharmacy of Cluj-Napoca", id: 5952 },
  { code: "RO", name: "University of Medicine and Pharmacy of Iasi", id: 5953 },
  { code: "RO", name: "University of Medicine and Pharmacy of Targu Mures", id: 5954 },
  { code: "RO", name: "University of Medicine and Pharmacy of Timisoara", id: 5955 },
  { code: "RO", name: "University of Oradea", id: 5956 },
  { code: "RO", name: "University of Petrosani", id: 5957 },
  { code: "RO", name: "University of Pitesti", id: 5958 },
  { code: "RO", name: "University of Resita", id: 5959 },
  { code: "RO", name: "University of Sibiu", id: 5960 },
  { code: "RO", name: "University of Suceava", id: 5961 },
  { code: "RO", name: "University of Targu Jiu", id: 5962 },
  { code: "RO", name: "University Oil- Gas Ploiesti", id: 5963 },
  { code: "RO", name: "University Petre Andrei Iasi", id: 5964 },
  { code: "RO", name: "University Politehnica of Bucharest", id: 5965 },
  { code: "RO", name: "University Titu Maiorescu", id: 5966 },
  { code: "RO", name: "University Transilvany of Brasov", id: 5967 },
  { code: "RO", name: "Valahia University of Targoviste", id: 5968 },
  { code: "RO", name: "West University of Timisoara", id: 5969 },
  { code: "RS", name: "Drzavni Univerzitet u Novom Pazaru", id: 5970 },
  { code: "RS", name: "European University", id: 5971 },
  { code: "RS", name: "International University of Novi Pazar", id: 5972 },
  { code: "RS", name: "Megatrend University of Applied Sciences", id: 5973 },
  { code: "RS", name: "Metropolitan University", id: 5974 },
  { code: "RS", name: "Privredna Akademija (Business Academy)", id: 5975 },
  { code: "RS", name: "Singidunum University", id: 5976 },
  { code: "RS", name: "University of Belgrade", id: 5977 },
  { code: "RS", name: "University of Fine Arts Belgrade", id: 5978 },
  { code: "RS", name: "University of Kragujevac", id: 5979 },
  { code: "RS", name: "University of Nis", id: 5980 },
  { code: "RS", name: "University of Novi Sad", id: 5981 },
  { code: "RS", name: "University of Priština", id: 5982 },
  { code: "RU", name: "Academy of Management and Entrepreneurship", id: 5983 },
  { code: "RU", name: "Adygeja State University", id: 5984 },
  { code: "RU", name: "Altai State Medical University", id: 5985 },
  { code: "RU", name: "Altai State Technical University", id: 5986 },
  { code: "RU", name: "Altai State University", id: 5987 },
  { code: "RU", name: "Amur State University", id: 5988 },
  { code: "RU", name: "Arhangelsk State Technical University", id: 5989 },
  { code: "RU", name: "Astrahan State Medical Academy", id: 5990 },
  { code: "RU", name: "Astrahan State Technical University", id: 5991 },
  { code: "RU", name: "Astrakhan State University", id: 5992 },
  { code: "RU", name: "Baikal National University of Economics and Law", id: 5993 },
  { code: "RU", name: "Baltic State Technical University", id: 5994 },
  { code: "RU", name: "Bashkir State Agrarian University", id: 5995 },
  { code: "RU", name: "Bashkir State Medical University", id: 5996 },
  { code: "RU", name: "Bashkir State Pedagogical University named After M. Akmullah", id: 5997 },
  { code: "RU", name: "Bashkir State University", id: 5998 },
  { code: "RU", name: "Bauman Moscow State Technical University", id: 5999 },
  { code: "RU", name: "Belgorod State Agricultural Academy", id: 6000 },
  { code: "RU", name: "Belgorod State Technical University", id: 6001 },
  { code: "RU", name: "Belgorod State University", id: 6002 },
  { code: "RU", name: "Birsk State Pedagogical Institute", id: 6003 },
  { code: "RU", name: "Biysk State Padagogical University after V.M. Shukshin ", id: 6004 },
  { code: "RU", name: "Bratsk State Technical University", id: 6005 },
  { code: "RU", name: "Brjansk State Technical University", id: 6006 },
  { code: "RU", name: "Burjat State University", id: 6007 },
  { code: "RU", name: "Cheljabinsk State Institute of Teacher Training", id: 6008 },
  { code: "RU", name: "Cheljabinsk State University", id: 6009 },
  { code: "RU", name: "Cheljabinsk University of Agricultural Engineering", id: 6010 },
  { code: "RU", name: "Chuvash State University", id: 6011 },
  { code: "RU", name: "Dagestan State University", id: 6012 },
  { code: "RU", name: "Don State Agrarian University", id: 6013 },
  { code: "RU", name: "Don State Technical University", id: 6014 },
  { code: "RU", name: "Dubna International University for Nature", id: 6015 },
  { code: "RU", name: "East-Siberian State Institute of Culture", id: 6016 },
  { code: "RU", name: "East-Siberian State University", id: 6017 },
  { code: "RU", name: "East-Siberian State University of Technology", id: 6018 },
  { code: "RU", name: "European University at St.Petersburg", id: 6019 },
  { code: "RU", name: "Far Easten State University of Humanities", id: 6020 },
  { code: "RU", name: "Far Eastern State Technical Fisheries University", id: 6021 },
  { code: "RU", name: "Far Eastern State Technical University", id: 6022 },
  { code: "RU", name: "Far Eastern State University", id: 6023 },
  { code: "RU", name: "Far East State Transport University", id: 6024 },
  { code: "RU", name: "Finance Academy", id: 6025 },
  { code: "RU", name: "Gnesins Russian Academy of Music", id: 6026 },
  { code: "RU", name: "Gorno-Altaisk State University", id: 6027 },
  { code: "RU", name: "Gubkin Russian State University of Oil and Gas", id: 6028 },
  { code: "RU", name: "Herzen State Pedagogical University of Russia", id: 6029 },
  { code: "RU", name: "Higher School of Economics", id: 6030 },
  { code: "RU", name: "Immanuel Kant State University of Russia", id: 6031 },
  { code: "RU", name: "I.M. Sechenov Moscow Medical Academy", id: 6032 },
  { code: "RU", name: "Institute for the Economy in Transition", id: 6033 },
  { code: "RU", name: "Institute of Business and Politics", id: 6034 },
  { code: "RU", name: "Institute of Management and Economy", id: 6035 },
  { code: "RU", name: "International Banking Institute", id: 6036 },
  { code: "RU", name: "International East-European University", id: 6037 },
  { code: "RU", name: "International University of Engineering", id: 6038 },
  { code: "RU", name: "International University of Fundamental Studies", id: 6039 },
  { code: "RU", name: "Irkutsk State Academy of Economics", id: 6040 },
  { code: "RU", name: "Irkutsk State Technical University", id: 6041 },
  { code: "RU", name: "Irkutsk State University", id: 6042 },
  { code: "RU", name: "Ivanovo State Academy of Medicine", id: 6043 },
  { code: "RU", name: "Ivanovo State Power University", id: 6044 },
  { code: "RU", name: "Ivanovo State Textile Academy", id: 6045 },
  { code: "RU", name: "Ivanovo State University", id: 6046 },
  { code: "RU", name: "Ivanovo State University of Chemistry and Technology", id: 6047 },
  { code: "RU", name: "Izhevsk State Technical University", id: 6048 },
  { code: "RU", name: "Jewish University in Moscow", id: 6049 },
  { code: "RU", name: "Kabardino-Balkarian State University", id: 6050 },
  { code: "RU", name: "Kaliningrad State Technical University", id: 6051 },
  { code: "RU", name: "Kalmyk State University", id: 6052 },
  { code: "RU", name: "Kaluga State Pedagogical University", id: 6053 },
  { code: "RU", name: "Kamchatka State University Vitus Bering", id: 6054 },
  { code: "RU", name: "Karelian State Pedagogical University", id: 6055 },
  { code: "RU", name: "Kazan State Academy of Architecture and Civil Engineering", id: 6056 },
  { code: "RU", name: "Kazan State Finance and Economics Institute ", id: 6057 },
  { code: "RU", name: "Kazan State Music Conservatory", id: 6058 },
  { code: "RU", name: "Kazan State Pedagogical University", id: 6059 },
  { code: "RU", name: "Kazan State University", id: 6060 },
  { code: "RU", name: "Kazan State University of Medicine", id: 6061 },
  { code: "RU", name: "Kazan State University of Technology", id: 6062 },
  { code: "RU", name: "Kemerovo State University", id: 6063 },
  { code: "RU", name: "Khabarovsk State Academy of Economics and Law", id: 6064 },
  { code: "RU", name: "Khabarovsk State University of Technology", id: 6065 },
  { code: "RU", name: "Komsomolsk-on-Amur State Technical University", id: 6066 },
  { code: "RU", name: "Kostroma State Technological University", id: 6067 },
  { code: "RU", name: "Krasnoyarsk State Technical University", id: 6068 },
  { code: "RU", name: "Kuban State University", id: 6069 },
  { code: "RU", name: "Kuban State University of Agriculture", id: 6070 },
  { code: "RU", name: "Kuban State University of Technology", id: 6071 },
  { code: "RU", name: "Kurgan International University", id: 6072 },
  { code: "RU", name: "Kursk State Medical University", id: 6073 },
  { code: "RU", name: "Kursk State Technical University", id: 6074 },
  { code: "RU", name: "Kursk State University", id: 6075 },
  { code: "RU", name: "Kuzbass State Technical University", id: 6076 },
  { code: "RU", name: "Linguistic University of Nizhny Novgorod", id: 6077 },
  { code: "RU", name: "Lipeck State Technical University", id: 6078 },
  { code: "RU", name: "Magnitogorsk State University", id: 6079 },
  { code: "RU", name: "Marij State Technical University", id: 6080 },
  { code: "RU", name: "Marij State University", id: 6081 },
  { code: "RU", name: "Maxim Gorky Institute of Literature", id: 6082 },
  { code: "RU", name: "Mendeleev Russian University of Chemical Technology", id: 6083 },
  { code: "RU", name: "Modern Institute of Managament", id: 6084 },
  { code: "RU", name: "Modern University for the Humanities", id: 6085 },
  { code: "RU", name: "Mordovian State University", id: 6086 },
  { code: "RU", name: "Moscow External University of the Humanities", id: 6087 },
  { code: "RU", name: "Moscow Institute of Physics and Technology", id: 6088 },
  { code: "RU", name: "Moscow International Higher Business School (MIRBIS)", id: 6089 },
  { code: "RU", name: "Moscow P. I. Tchaikovsky Conservatory", id: 6090 },
  { code: "RU", name: "Moscow Power Engineering Institute (Technical University)", id: 6091 },
  { code: "RU", name: "Moscow State Academy of Applied Biotechnology", id: 6092 },
  { code: "RU", name: "Moscow State Automobile and Road Technical University", id: 6093 },
  { code: "RU", name: "Moscow State Industrial University", id: 6094 },
  { code: "RU", name: "Moscow State Institute of International Relations MFA Russia (MGIMO-University)", id: 6095 },
  { code: "RU", name: "Moscow State Institute of Radiotechnics", id: 6096 },
  { code: "RU", name: "Moscow State Institute of Steel and Alloys", id: 6097 },
  { code: "RU", name: "Moscow State Technical University of Civil Aviation", id: 6098 },
  { code: "RU", name: "Moscow State Textile University A.N. Kosygin", id: 6099 },
  { code: "RU", name: "Moscow State University", id: 6100 },
  { code: "RU", name: "Moscow State University M.V. Lomonosov", id: 6101 },
  { code: "RU", name: "Moscow State University of Civil Engineering", id: 6102 },
  { code: "RU", name: "Moscow State University of Commerce", id: 6103 },
  { code: "RU", name: "Moscow State University of Culture", id: 6104 },
  { code: "RU", name: "Moscow State University of Ecological Engineering", id: 6105 },
  { code: "RU", name: "Moscow State University of Economics", id: 6106 },
  { code: "RU", name: "Moscow State University of Environmental Engineering", id: 6107 },
  { code: "RU", name: "Moscow State University of Forestry Engineering", id: 6108 },
  { code: "RU", name: "Moscow State University of Geodesy and Cartography", id: 6109 },
  { code: "RU", name: "Moscow State University of Railway Transport", id: 6110 },
  { code: "RU", name: "Moscow State University of Technology (Stankin)", id: 6111 },
  { code: "RU", name: "Moscow Technical University of Informatics and Communication", id: 6112 },
  { code: "RU", name: "Moscow University Touro", id: 6113 },
  { code: "RU", name: "Murmansk State Technical University", id: 6114 },
  { code: "RU", name: "National Research University of Electronic Technology", id: 6115 },
  { code: "RU", name: "Nayanova University", id: 6116 },
  { code: "RU", name: "Nevsky Institute of Language and Culture", id: 6117 },
  { code: "RU", name: "New Economic School", id: 6118 },
  { code: "RU", name: "NGO Vladikavkaz Institute of Economics", id: 6119 },
  { code: "RU", name: "Nizhny Novgorod State Academy of Medicine", id: 6120 },
  { code: "RU", name: "Nizhny Novgorod State Architectural - Building University", id: 6121 },
  { code: "RU", name: "Nizhny Novgorod State Technical University", id: 6122 },
  { code: "RU", name: "Nizhny Novgorod State University", id: 6123 },
  { code: "RU", name: "Northern State Medical University", id: 6124 },
  { code: "RU", name: "North Ossetian State University", id: 6125 },
  { code: "RU", name: "North-West State Technical University", id: 6126 },
  { code: "RU", name: "Novgorod State University", id: 6127 },
  { code: "RU", name: "Novosibirsk State Academy of Economics and Mangement", id: 6128 },
  { code: "RU", name: "Novosibirsk State Agricultural University", id: 6129 },
  { code: "RU", name: "Novosibirsk State Medical Academy", id: 6130 },
  { code: "RU", name: "Novosibirsk State Music Academy M. Glinka", id: 6131 },
  { code: "RU", name: "Novosibirsk State Technical University", id: 6132 },
  { code: "RU", name: "Novosibirsk State University", id: 6133 },
  { code: "RU", name: "Obninsk State Technical University for Nuclear Power Engineering", id: 6134 },
  { code: "RU", name: "Omsk State Agrarian University", id: 6135 },
  { code: "RU", name: "Omsk State Medicical Academy", id: 6136 },
  { code: "RU", name: "Omsk State Pedagogical University", id: 6137 },
  { code: "RU", name: "Omsk State Technical University", id: 6138 },
  { code: "RU", name: "Omsk State Transport University", id: 6139 },
  { code: "RU", name: "Omsk State University", id: 6140 },
  { code: "RU", name: "Orenburg State University", id: 6141 },
  { code: "RU", name: "Oriental University", id: 6142 },
  { code: "RU", name: "Penza State University", id: 6143 },
  { code: "RU", name: "People' s Friendship University of Russia", id: 6144 },
  { code: "RU", name: "Perm State Academy of Agriculture", id: 6145 },
  { code: "RU", name: "Perm State Medical Academy", id: 6146 },
  { code: "RU", name: "Perm State Pedagogical University", id: 6147 },
  { code: "RU", name: "Perm State Pharmaceutical Academy", id: 6148 },
  { code: "RU", name: "Perm State Technical University", id: 6149 },
  { code: "RU", name: "Perm State University", id: 6150 },
  { code: "RU", name: "Petrozavodsk State University", id: 6151 },
  { code: "RU", name: "Pjatigorsk State Linguistic University", id: 6152 },
  { code: "RU", name: "Plekhanov Russian Academy of Economics", id: 6153 },
  { code: "RU", name: "Pomorsk State University", id: 6154 },
  { code: "RU", name: "Pomor State University", id: 6155 },
  { code: "RU", name: "Rostov State Medical University", id: 6156 },
  { code: "RU", name: "Rostov State University", id: 6157 },
  { code: "RU", name: "Russian Academy of Arts", id: 6158 },
  { code: "RU", name: "Russian Academy of Theatre Arts", id: 6159 },
  { code: "RU", name: "Russian Customs Academy", id: 6160 },
  { code: "RU", name: "Russian State Geological Prospecting University ", id: 6161 },
  { code: "RU", name: "Russian State Hydrometeorological University", id: 6162 },
  { code: "RU", name: "Russian State Medical University", id: 6163 },
  { code: "RU", name: "Russian State University for the Humanities", id: 6164 },
  { code: "RU", name: "Ryazan State Pedagogical University", id: 6165 },
  { code: "RU", name: "Rybinsk State Academy of Aviational Technology", id: 6166 },
  { code: "RU", name: "Sakhalin State University", id: 6167 },
  { code: "RU", name: "Samara State Academy for Railway Transportation", id: 6168 },
  { code: "RU", name: "Samara State Academy of Architecture and Civil Engineering", id: 6169 },
  { code: "RU", name: "Samara State Aerospace University", id: 6170 },
  { code: "RU", name: "Samara State Agricultural Academy", id: 6171 },
  { code: "RU", name: "Samara State Medical University", id: 6172 },
  { code: "RU", name: "Samara State Technical University", id: 6173 },
  { code: "RU", name: "Samara State University", id: 6174 },
  { code: "RU", name: "Samara State University of Economics", id: 6175 },
  { code: "RU", name: "Samara State University of Teacher Training", id: 6176 },
  { code: "RU", name: "Saratov State Academy of Law", id: 6177 },
  { code: "RU", name: "Saratov State Agrarian University", id: 6178 },
  { code: "RU", name: "Saratov State Medical University", id: 6179 },
  { code: "RU", name: "Saratov State Socio-Economic University", id: 6180 },
  { code: "RU", name: "Saratov State Technical University", id: 6181 },
  { code: "RU", name: "Saratov State University", id: 6182 },
  { code: "RU", name: "Siberian Academy of Public Services", id: 6183 },
  { code: "RU", name: "Siberian State Industrial University", id: 6184 },
  { code: "RU", name: "Siberian State Medical University", id: 6185 },
  { code: "RU", name: "Siberian State Transport University", id: 6186 },
  { code: "RU", name: "Siberian State University of Telecommunications and Informatics", id: 6187 },
  { code: "RU", name: "Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology", id: 6188 },
  { code: "RU", name: "Smolensk Humanitarian University", id: 6189 },
  { code: "RU", name: "Smolensk State Medical Academy", id: 6190 },
  { code: "RU", name: "Smolny University", id: 6191 },
  { code: "RU", name: "Southern Ural State University", id: 6192 },
  { code: "RU", name: "State Maritine Academy", id: 6193 },
  { code: "RU", name: "State University of Aerospace Technologies", id: 6194 },
  { code: "RU", name: "State University of Management", id: 6195 },
  { code: "RU", name: "Stavropol State Technical University", id: 6196 },
  { code: "RU", name: "Stavropol State University", id: 6197 },
  { code: "RU", name: "Sterlitamak State Pedagogical Institute", id: 6198 },
  { code: "RU", name: "St. Petersburg Institute of Arts and Restoration", id: 6199 },
  { code: "RU", name: "St. Petersburg Institute of Sales Management and Economics", id: 6200 },
  { code: "RU", name: "St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture", id: 6201 },
  { code: "RU", name: "St. Petersburg State Academy for Engineering Economics (ENGECON)", id: 6202 },
  { code: "RU", name: "St. Petersburg State Agrarian University", id: 6203 },
  { code: "RU", name: "St. Petersburg State Chemical Pharmaceutical Academy", id: 6204 },
  { code: "RU", name: "St. Petersburg State Cinema and TV University", id: 6205 },
  { code: "RU", name: "St. Petersburg State Conservatory", id: 6206 },
  { code: "RU", name: "St. Petersburg State Electrotechnical University", id: 6207 },
  { code: "RU", name: "St. Petersburg State Forest-Technical Academy", id: 6208 },
  { code: "RU", name: "St. Petersburg State Institute of Technology (Technological University)", id: 6209 },
  { code: "RU", name: "St. Petersburg State Marine Technical University", id: 6210 },
  { code: "RU", name: "St. Petersburg StateMechnikov  Medical Academy", id: 6211 },
  { code: "RU", name: "St. Petersburg State Medical Academy", id: 6212 },
  { code: "RU", name: "St. Petersburg State Mining Institute (Technical University)", id: 6213 },
  { code: "RU", name: "St. Petersburg State Mountain Institut", id: 6214 },
  { code: "RU", name: "St. Petersburg State Pediatric Medical Academy", id: 6215 },
  { code: "RU", name: "St. Petersburg State Polytechnical University", id: 6216 },
  { code: "RU", name: "St. Petersburg State Technical University", id: 6217 },
  { code: "RU", name: "St. Petersburg State Technological University of Plant Polymers", id: 6218 },
  { code: "RU", name: "St. Petersburg State Theatre Arts Academy", id: 6219 },
  { code: "RU", name: "St. Petersburg State University", id: 6220 },
  { code: "RU", name: "St. Petersburg State University of Aerospace Instrumentation", id: 6221 },
  { code: "RU", name: "St. Petersburg State University of Architecture and Civil Engineering", id: 6222 },
  { code: "RU", name: "St. Petersburg State University of Civil Aviation", id: 6223 },
  { code: "RU", name: "St. Petersburg State University of Culture and Arts", id: 6224 },
  { code: "RU", name: "St. Petersburg State University of Economics and Finance", id: 6225 },
  { code: "RU", name: "St. Petersburg State University of Engineering and Economics", id: 6226 },
  { code: "RU", name: "St. Petersburg State University of Information Technologies", id: 6227 },
  { code: "RU", name: "St. Petersburg State University of Technology and Design", id: 6228 },
  { code: "RU", name: "St. Petersburg State University of Telecommunication", id: 6229 },
  { code: "RU", name: "St. Petersburg State University of Waterway Communications", id: 6230 },
  { code: "RU", name: "Surgut State University", id: 6231 },
  { code: "RU", name: "Syktyvkar State University", id: 6232 },
  { code: "RU", name: "Taganrog State Pedagogical Institute", id: 6233 },
  { code: "RU", name: "Taganrog State University of Radioengineering", id: 6234 },
  { code: "RU", name: "Tambov State Technical University", id: 6235 },
  { code: "RU", name: "Timirjazev Moscow Academy of Agricultutre", id: 6236 },
  { code: "RU", name: "Togliatti State University", id: 6237 },
  { code: "RU", name: "Tomsk Polytechnic University", id: 6238 },
  { code: "RU", name: "Tomsk State Pedagogical University", id: 6239 },
  { code: "RU", name: "Tomsk State University", id: 6240 },
  { code: "RU", name: "Tomsk State University of Architecture and Building", id: 6241 },
  { code: "RU", name: "Tomsk State University of Control Systems and Radioelectronics", id: 6242 },
  { code: "RU", name: "Tula State University", id: 6243 },
  { code: "RU", name: "Tuva State University", id: 6244 },
  { code: "RU", name: "Tver State Medical Academy", id: 6245 },
  { code: "RU", name: "Tver State Technical University", id: 6246 },
  { code: "RU", name: "Tver State University", id: 6247 },
  { code: "RU", name: "Tyumen State Agricultural Academy", id: 6248 },
  { code: "RU", name: "Tyumen State University", id: 6249 },
  { code: "RU", name: "Tyumen State University of Gas and Oil", id: 6250 },
  { code: "RU", name: "Udmurt State University", id: 6251 },
  { code: "RU", name: "Ufa State Aviation Technical University", id: 6252 },
  { code: "RU", name: "Ufa State Institute of Arts", id: 6253 },
  { code: "RU", name: "Ufa State Petroleum Technological University", id: 6254 },
  { code: "RU", name: "Ukhta State Technical University", id: 6255 },
  { code: "RU", name: "Ulyanovsk Higher School of Civil Aviation", id: 6256 },
  { code: "RU", name: "Ulyanovsk State Agricultural Academy", id: 6257 },
  { code: "RU", name: "Ulyanovsk State Pedagogical University", id: 6258 },
  { code: "RU", name: "Ulyanovsk State Technical University", id: 6259 },
  { code: "RU", name: "Ulyanovsk State University", id: 6260 },
  { code: "RU", name: "Ural Academy of Public Administration", id: 6261 },
  { code: "RU", name: "Ural Gorkij State University", id: 6262 },
  { code: "RU", name: "Ural State Academy of Architecture and Arts", id: 6263 },
  { code: "RU", name: "Ural State Academy of Law", id: 6264 },
  { code: "RU", name: "Ural State Academy of Mining and Geology", id: 6265 },
  { code: "RU", name: "Ural State Academy of Railway Transport", id: 6266 },
  { code: "RU", name: "Ural State Conservatory", id: 6267 },
  { code: "RU", name: "Ural State Forestry Technical Academy", id: 6268 },
  { code: "RU", name: "Ural State Technical University", id: 6269 },
  { code: "RU", name: "Ural State University of Economics", id: 6270 },
  { code: "RU", name: "Vaganova Academy of Russian Ballet", id: 6271 },
  { code: "RU", name: "Vladimir State University", id: 6272 },
  { code: "RU", name: "Vladivostock State University of Economics", id: 6273 },
  { code: "RU", name: "Volga State Academy of Water Transport", id: 6274 },
  { code: "RU", name: "Volgograd Medical Academy", id: 6275 },
  { code: "RU", name: "Volgograd State Academy of Physical Education", id: 6276 },
  { code: "RU", name: "Volgograd State Pedagogical University", id: 6277 },
  { code: "RU", name: "Volgograd State Technical University", id: 6278 },
  { code: "RU", name: "Volgograd State University", id: 6279 },
  { code: "RU", name: "Volgograd State University of Architecture and Civil Engineering (VolgGASU)", id: 6280 },
  { code: "RU", name: "Vologda State Pedagogical University", id: 6281 },
  { code: "RU", name: "Voronezh State Academy of Technology", id: 6282 },
  { code: "RU", name: "Voronezh State Agricultural University", id: 6283 },
  { code: "RU", name: "Voronezh State Medical Academy", id: 6284 },
  { code: "RU", name: "Voronezh State Pedagogical University", id: 6285 },
  { code: "RU", name: "Voronezh State Technical University", id: 6286 },
  { code: "RU", name: "Voronezh State University", id: 6287 },
  { code: "RU", name: "Vyatka State Pedagogical University", id: 6288 },
  { code: "RU", name: "Yakutsk State University", id: 6289 },
  { code: "RU", name: "Yaroslavl International University of Business and New Technologies", id: 6290 },
  { code: "RU", name: "Yaroslavl State Medical Academy", id: 6291 },
  { code: "RU", name: "Yaroslavl State Pedagogical University", id: 6292 },
  { code: "RU", name: "Yaroslavl State Technical University", id: 6293 },
  { code: "RU", name: "Yaroslavl State Theatre Institute", id: 6294 },
  { code: "RU", name: "Yaroslavl State University", id: 6295 },
  { code: "RU", name: "Yelets State University", id: 6296 },
  { code: "RU", name: "Yugra State University", id: 6297 },
  { code: "RW", name: "Adventist University of Central Africa", id: 6298 },
  { code: "RW", name: "Higher Institute of Agriculture and Animal Husbandry", id: 6299 },
  { code: "RW", name: "Institut d'Agriculture", id: 6300 },
  { code: "RW", name: "Kigali Health Institute", id: 6301 },
  { code: "RW", name: "Kigali Independent University", id: 6302 },
  { code: "RW", name: "Kigali Institute of Education", id: 6303 },
  { code: "RW", name: "Kigali Institute of Science & Technology", id: 6304 },
  { code: "RW", name: "National University of Rwanda", id: 6305 },
  { code: "RW", name: "School of Finance and Banking", id: 6306 },
  { code: "RW", name: "Umutara Polytechnic", id: 6307 },
  { code: "RW", name: "Université Catholique de Kabgayi", id: 6308 },
  { code: "SA", name: "Al-baha University", id: 6309 },
  { code: "SA", name: "Al-Batterjee Medical College", id: 6310 },
  { code: "SA", name: "Alfaisal University", id: 6311 },
  { code: "SA", name: "Al-Imam Mohamed Ibn Saud Islamic University", id: 6312 },
  { code: "SA", name: "Aljouf University", id: 6313 },
  { code: "SA", name: "Alkharj University", id: 6314 },
  { code: "SA", name: "Al-Yamamah College", id: 6315 },
  { code: "SA", name: "Arab Open University", id: 6316 },
  { code: "SA", name: "Batterjee Medical College", id: 6317 },
  { code: "SA", name: "Buraydah College for Applied Medical Sciences", id: 6318 },
  { code: "SA", name: "College of Busniess Admnistration", id: 6319 },
  { code: "SA", name: "College of Nursing and Allied Health Scinces", id: 6320 },
  { code: "SA", name: "College of Technology at Abha", id: 6321 },
  { code: "SA", name: "College of Technology at Dammam", id: 6322 },
  { code: "SA", name: "College of Technology at Jazan", id: 6323 },
  { code: "SA", name: "College of Technology at Jeddah", id: 6324 },
  { code: "SA", name: "College of Technology at Kharj", id: 6325 },
  { code: "SA", name: "College of Technology at Riyadh", id: 6326 },
  { code: "SA", name: "College of Telecommunication & Information ", id: 6327 },
  { code: "SA", name: "Dammam Community College", id: 6328 },
  { code: "SA", name: "Dar al Hekma College", id: 6329 },
  { code: "SA", name: "Dar Al-Uloom Colleges", id: 6330 },
  { code: "SA", name: "Effat College", id: 6331 },
  { code: "SA", name: "Fahad Bin Sultan University", id: 6332 },
  { code: "SA", name: "Ibn Sina National College for Medical Studies ", id: 6333 },
  { code: "SA", name: "Institute of Public Administration", id: 6334 },
  { code: "SA", name: "Islamic University of Medinah", id: 6335 },
  { code: "SA", name: "Jazan University", id: 6336 },
  { code: "SA", name: "Jubail Industrial College", id: 6337 },
  { code: "SA", name: "Jubail University College", id: 6338 },
  { code: "SA", name: "King Abdul Aziz University", id: 6339 },
  { code: "SA", name: "King Abdullah University of Science and Technology ", id: 6340 },
  { code: "SA", name: "King Fahad University of Petroleum and Minerals", id: 6341 },
  { code: "SA", name: "King Fahd Security College", id: 6342 },
  { code: "SA", name: "King Faisal University", id: 6343 },
  { code: "SA", name: "King Khaled University", id: 6344 },
  { code: "SA", name: "King Saud bin Abdulaziz University for Health Sciences ", id: 6345 },
  { code: "SA", name: "King Saud University", id: 6346 },
  { code: "SA", name: "Knowledge College for Science and Technology", id: 6347 },
  { code: "SA", name: "Knowledge International University", id: 6348 },
  { code: "SA", name: "Majmaah University", id: 6349 },
  { code: "SA", name: "Naif Arab University for Security Sciences", id: 6350 },
  { code: "SA", name: "Najran University", id: 6351 },
  { code: "SA", name: "Northern Border University", id: 6352 },
  { code: "SA", name: "Prince Mohammad Bin Fahd University", id: 6353 },
  { code: "SA", name: "Princess Nora Bint Abdulrahman University", id: 6354 },
  { code: "SA", name: "Prince Sultan College for Tourism and Hotel Scinces", id: 6355 },
  { code: "SA", name: "Prince Sultan University", id: 6356 },
  { code: "SA", name: "Private College of Dentistry and Pharmacy", id: 6357 },
  { code: "SA", name: "Qassim Private College", id: 6358 },
  { code: "SA", name: "Qassim University", id: 6359 },
  { code: "SA", name: "Riyadh College of Dentistry and Pharmacy", id: 6360 },
  { code: "SA", name: "Saad College of Nursing and Allied Health Sciences", id: 6361 },
  { code: "SA", name: "Saudi Electronic University", id: 6362 },
  { code: "SA", name: "Shaqra University", id: 6363 },
  { code: "SA", name: "Taibah University", id: 6364 },
  { code: "SA", name: "Taif University", id: 6365 },
  { code: "SA", name: "Umm Al-Qura University", id: 6366 },
  { code: "SA", name: "University of Dammam", id: 6367 },
  { code: "SA", name: "University of Hail", id: 6368 },
  { code: "SA", name: "University of Tabuk", id: 6369 },
  { code: "SA", name: "Yanbu Industrial College", id: 6370 },
  { code: "SA", name: "Yanbu Technical Institute", id: 6371 },
  { code: "SA", name: "Yanbu University College", id: 6372 },
  { code: "SB", name: "University of the South Pacific", id: 6373 },
  { code: "SC", name: "University of Seychelles", id: 6374 },
  { code: "SC", name: "University of Seychelles - American Institute of Medicine", id: 6375 },
  { code: "SD", name: "Ahfad University for Women", id: 6376 },
  { code: "SD", name: "Al Fashir University", id: 6377 },
  { code: "SD", name: "Al-Zaiem Al-Azhari University", id: 6378 },
  { code: "SD", name: "Bakht Er-Ruda University", id: 6379 },
  { code: "SD", name: "Bayan College for Science & Technology", id: 6380 },
  { code: "SD", name: "Blue Nile University", id: 6381 },
  { code: "SD", name: "Canadian Sudanese College", id: 6382 },
  { code: "SD", name: "Dalanj University", id: 6383 },
  { code: "SD", name: "Elrazi College Of Medical & Technological Sciences", id: 6384 },
  { code: "SD", name: "Gezira College of Technology", id: 6385 },
  { code: "SD", name: "High Institute for Banking & Financial Studies", id: 6386 },
  { code: "SD", name: "International University of Africa (IUA)", id: 6387 },
  { code: "SD", name: "Kassala University", id: 6388 },
  { code: "SD", name: "National College for Medical and Technical Studies", id: 6389 },
  { code: "SD", name: "National Ribat University", id: 6390 },
  { code: "SD", name: "Neelain University", id: 6391 },
  { code: "SD", name: "Nile Valley University", id: 6392 },
  { code: "SD", name: "Omdurman Ahlia University", id: 6393 },
  { code: "SD", name: "Omdurman Islamic University", id: 6394 },
  { code: "SD", name: "Open University of Sudan (OUS)", id: 6395 },
  { code: "SD", name: "Red Sea University", id: 6396 },
  { code: "SD", name: "Shendi University", id: 6397 },
  { code: "SD", name: "Sinnar University", id: 6398 },
  { code: "SD", name: "Sudan Academy of Sciences", id: 6399 },
  { code: "SD", name: "Sudan University for Science and Technology", id: 6400 },
  { code: "SD", name: "University of Dongola", id: 6401 },
  { code: "SD", name: "University of El Imam El Mahdi University", id: 6402 },
  { code: "SD", name: "University of Gadarif", id: 6403 },
  { code: "SD", name: "University of Gezira", id: 6404 },
  { code: "SD", name: "University of Holy Quran and Islamic Siences", id: 6405 },
  { code: "SD", name: "University of Khartoum", id: 6406 },
  { code: "SD", name: "University of Kordofan", id: 6407 },
  { code: "SD", name: "University Of Medical Sciences & Technology (UMST)", id: 6408 },
  { code: "SD", name: "University of Science and Technology", id: 6409 },
  { code: "SD", name: "Upper Nile University", id: 6410 },
  { code: "SE", name: "Blekinge Institute of Technology", id: 6411 },
  { code: "SE", name: "Chalmers University of Technology", id: 6412 },
  { code: "SE", name: "Dalarna University College", id: 6413 },
  { code: "SE", name: "Free University Stockholm", id: 6414 },
  { code: "SE", name: "Göteborg University", id: 6415 },
  { code: "SE", name: "Gotland University College", id: 6416 },
  { code: "SE", name: "Halmstad University College", id: 6417 },
  { code: "SE", name: "Jönköping University College", id: 6418 },
  { code: "SE", name: "Jönköping University College of Health Sciences", id: 6419 },
  { code: "SE", name: "Kalmar University College", id: 6420 },
  { code: "SE", name: "Karlstad University", id: 6421 },
  { code: "SE", name: "Karolinska Institute Stockholm", id: 6422 },
  { code: "SE", name: "Linköping University", id: 6423 },
  { code: "SE", name: "Lulea University of Technology", id: 6424 },
  { code: "SE", name: "Lund University", id: 6425 },
  { code: "SE", name: "Mälardalen University", id: 6426 },
  { code: "SE", name: "Malmö University College", id: 6427 },
  { code: "SE", name: "Mid-Sweden University", id: 6428 },
  { code: "SE", name: "Nordic School of Public Health", id: 6429 },
  { code: "SE", name: "Örebro University", id: 6430 },
  { code: "SE", name: "Royal lnstitute of Technology", id: 6431 },
  { code: "SE", name: "School of Design and Crafts", id: 6432 },
  { code: "SE", name: "Södertörn University College", id: 6433 },
  { code: "SE", name: "Stockholm Institute of Education", id: 6434 },
  { code: "SE", name: "Stockholm School of Economics", id: 6435 },
  { code: "SE", name: "Stockholm University", id: 6436 },
  { code: "SE", name: "Swedish University of Agricultural Sciences", id: 6437 },
  { code: "SE", name: "Umea University", id: 6438 },
  { code: "SE", name: "University College of Arts", id: 6439 },
  { code: "SE", name: "University College of Borås", id: 6440 },
  { code: "SE", name: "University College of Gävle", id: 6441 },
  { code: "SE", name: "University College of Kristianstad", id: 6442 },
  { code: "SE", name: "University College of Skövde", id: 6443 },
  { code: "SE", name: "University College of Trollhättan/Uddevalla", id: 6444 },
  { code: "SE", name: "Uppsala University", id: 6445 },
  { code: "SE", name: "Växjö University", id: 6446 },
  { code: "SE", name: "World Maritime University", id: 6447 },
  { code: "SG", name: "Aventis School of Management", id: 6448 },
  { code: "SG", name: "Nanyang Technological University", id: 6449 },
  { code: "SG", name: "National University of Singapore", id: 6450 },
  { code: "SG", name: "Singapore Institute of Management (SIM)", id: 6451 },
  { code: "SG", name: "Singapore Management University", id: 6452 },
  { code: "SG", name: "Taoist College Singapore", id: 6453 },
  { code: "SI", name: "University of Ljubljana", id: 6454 },
  { code: "SI", name: "University of Maribor", id: 6455 },
  { code: "SI", name: "University of Nova Gorica", id: 6456 },
  { code: "SI", name: "University of Primorska", id: 6457 },
  { code: "SK", name: "Academy of Arts in Banská Bystrica", id: 6458 },
  { code: "SK", name: "Academy of Fine Arts and Design in Bratislava", id: 6459 },
  { code: "SK", name: "Armed Forces Academy of General Milan Rastislav Štefánik", id: 6460 },
  { code: "SK", name: "Bratislava International School of Liberal Arts", id: 6461 },
  { code: "SK", name: "Catholic University in Ruzomberok", id: 6462 },
  { code: "SK", name: "Catholic University in Ružomberok", id: 6463 },
  { code: "SK", name: "College in Sládkovičovo", id: 6464 },
  { code: "SK", name: "Comenius University in Bratislava", id: 6465 },
  { code: "SK", name: "International School of Management in Prešov", id: 6466 },
  { code: "SK", name: "Janos Selye University", id: 6467 },
  { code: "SK", name: "Law University of Bratislava", id: 6468 },
  { code: "SK", name: "Matej Bel University in Banská Bystrica", id: 6469 },
  { code: "SK", name: "Pavol Jozef Safarik University in Kosice", id: 6470 },
  { code: "SK", name: "Police Academy in Bratislava", id: 6471 },
  { code: "SK", name: "School of Management", id: 6472 },
  { code: "SK", name: "Slovak Medical University", id: 6473 },
  { code: "SK", name: "Slovak University of Agriculture in Nitra", id: 6474 },
  { code: "SK", name: "Slovak University of Technology in Bratislava", id: 6475 },
  { code: "SK", name: "St. Elizabeth’s College of Health and Social Sciences in Bratislava", id: 6476 },
  { code: "SK", name: "Technical Institute of Dubnica in Dubnica nad Váhom", id: 6477 },
  { code: "SK", name: "Technical University in Kosice", id: 6478 },
  { code: "SK", name: "Technical University in Zvolen", id: 6479 },
  { code: "SK", name: "University Konstantina Filozov in Nitra", id: 6480 },
  { code: "SK", name: "University of Alexandra Dubček in Trenčín", id: 6481 },
  { code: "SK", name: "University of Central Europe in Skalica", id: 6482 },
  { code: "SK", name: "University of Economics in Bratislava", id: 6483 },
  { code: "SK", name: "University of Performing Arts in Bratislava", id: 6484 },
  { code: "SK", name: "University of Presov", id: 6485 },
  { code: "SK", name: "University of St. Cyril and Methodius in Trnava", id: 6486 },
  { code: "SK", name: "University of SV Cyril and Methodius in Trnava", id: 6487 },
  { code: "SK", name: "University of Trnava", id: 6488 },
  { code: "SK", name: "University of Veterinary Medicine in Kosice", id: 6489 },
  { code: "SK", name: "University of Zilinska", id: 6490 },
  { code: "SL", name: "Fourah Bay College", id: 6491 },
  { code: "SL", name: "LICCSAL Business College", id: 6492 },
  { code: "SL", name: "Njala University", id: 6493 },
  { code: "SL", name: "University of Sierra Leone", id: 6494 },
  { code: "SM", name: "University of San Marino", id: 6495 },
  { code: "SN", name: "L'Université de l'Entreprise", id: 6496 },
  { code: "SN", name: "Université Alioune Diop de Bambey", id: 6497 },
  { code: "SN", name: "Universite Amadou Hampaté Ba", id: 6498 },
  { code: "SN", name: "Université Cheikh Anta Diop", id: 6499 },
  { code: "SN", name: "Université Dakar Bourguiba", id: 6500 },
  { code: "SN", name: "Université de Thiès", id: 6501 },
  { code: "SN", name: "Université de Ziguinchor", id: 6502 },
  { code: "SN", name: "Université du Sahel", id: 6503 },
  { code: "SN", name: "Université Euro-Afrique", id: 6504 },
  { code: "SN", name: "Université Gaston Berger Saint-Louis", id: 6505 },
  { code: "SO", name: "Amoud University", id: 6506 },
  { code: "SO", name: "Benadir University", id: 6507 },
  { code: "SO", name: "Bosaso College", id: 6508 },
  { code: "SO", name: "East Africa University Bosaso", id: 6509 },
  { code: "SO", name: "East Somalia University", id: 6510 },
  { code: "SO", name: "Eelo American University", id: 6511 },
  { code: "SO", name: "Gollis University", id: 6512 },
  { code: "SO", name: "Hiiraan University", id: 6513 },
  { code: "SO", name: "Maakhir University", id: 6514 },
  { code: "SO", name: "Mogadishu University", id: 6515 },
  { code: "SO", name: "Nugaal University", id: 6516 },
  { code: "SO", name: "Puntland State University", id: 6517 },
  { code: "SO", name: "Sanaag University of Science and Technology", id: 6518 },
  { code: "SO", name: "Somaliland University of Technology", id: 6519 },
  { code: "SO", name: "University of Burao", id: 6520 },
  { code: "SO", name: "University of Hargeisa", id: 6521 },
  { code: "SO", name: "University of Somalia", id: 6522 },
  { code: "SR", name: "Anton de Kom University", id: 6523 },
  { code: "SS", name: "University of Juba", id: 6524 },
  { code: "SS", name: "University of Northern Bahr El-Ghazal", id: 6525 },
  { code: "SV", name: "Universidad Albert Einstein", id: 6526 },
  { code: "SV", name: "Universidad Autónoma de Santa Ana", id: 6527 },
  { code: "SV", name: "Universidad Capitain General Gerardo Barrios", id: 6528 },
  { code: "SV", name: "Universidad Católica de Occidente", id: 6529 },
  { code: "SV", name: "Universidad Centroamericana José Simeón Canas", id: 6530 },
  { code: "SV", name: "Universidad Cristiana de las Asambleas de Dios", id: 6531 },
  { code: "SV", name: "Universidad de El Salvador", id: 6532 },
  { code: "SV", name: "Universidad de Oriente", id: 6533 },
  { code: "SV", name: "Universidad de Sonsonate", id: 6534 },
  { code: "SV", name: "Universidad Don Bosco", id: 6535 },
  { code: "SV", name: "Universidad Dr. Andreas Bello", id: 6536 },
  { code: "SV", name: "Universidad Dr. Jose Matias Delgado", id: 6537 },
  { code: "SV", name: "Universidad Evangélica de El Salvador", id: 6538 },
  { code: "SV", name: "Universidad Francisco Gavidia", id: 6539 },
  { code: "SV", name: "Universidad Isaac Newton", id: 6540 },
  { code: "SV", name: "Universidad Luterana Salvadorena", id: 6541 },
  { code: "SV", name: "Universidad Modular Abierta", id: 6542 },
  { code: "SV", name: "Universidad Monsenor Oscar Arnulfo Romeo", id: 6543 },
  { code: "SV", name: "Universidad Nueva San Salvador", id: 6544 },
  { code: "SV", name: "Universidad Panamericana de San Salvador", id: 6545 },
  { code: "SV", name: "Universidad Pedagógica de El Salvador", id: 6546 },
  { code: "SV", name: "Universidad Politécnica de El Salvador", id: 6547 },
  { code: "SV", name: "Universidad Salvadorena Alberto Masferrer", id: 6548 },
  { code: "SV", name: "Universidad Técnica Latinoamericana", id: 6549 },
  { code: "SV", name: "Universidad Tecnológica de El Salvador", id: 6550 },
  { code: "SY", name: "Al-Baath University", id: 6551 },
  { code: "SY", name: "Al-Wataniya Private University", id: 6552 },
  { code: "SY", name: "Arab European University", id: 6553 },
  { code: "SY", name: "Damascus University", id: 6554 },
  { code: "SY", name: "Higher Institute of Business Administration", id: 6555 },
  { code: "SY", name: "International University for Science & Technology ", id: 6556 },
  { code: "SY", name: "Ittihad Private University", id: 6557 },
  { code: "SY", name: "Mamoun Private University for Science and Technology", id: 6558 },
  { code: "SY", name: "Syrian International Private University for Science & Technology", id: 6559 },
  { code: "SY", name: "Syrian Virtual University", id: 6560 },
  { code: "SY", name: "Tishreen University", id: 6561 },
  { code: "SY", name: "University of Aleppo", id: 6562 },
  { code: "SY", name: "University of Kalamoon", id: 6563 },
  { code: "SY", name: "Wadi International University", id: 6564 },
  { code: "SY", name: "Yarmouk Private University ", id: 6565 },
  { code: "SZ", name: "University of Swaziland", id: 6566 },
  { code: "TC", name: "Charisma University", id: 6567 },
  { code: "TD", name: "Université de N'Djamena", id: 6568 },
  { code: "TG", name: "Université de Lomé", id: 6569 },
  { code: "TH", name: "Asian Institute of Technology", id: 6570 },
  { code: "TH", name: "Asian University of Science and Technology", id: 6571 },
  { code: "TH", name: "Assumption University of Thailand", id: 6572 },
  { code: "TH", name: "Bangkok University", id: 6573 },
  { code: "TH", name: "Burapha University", id: 6574 },
  { code: "TH", name: "Chaopraya University ", id: 6575 },
  { code: "TH", name: "Chiang Mai Rajabhat University", id: 6576 },
  { code: "TH", name: "Chiang Mai University", id: 6577 },
  { code: "TH", name: "Chiang Mai Vocational College", id: 6578 },
  { code: "TH", name: "Chulalongkorn University", id: 6579 },
  { code: "TH", name: "Dhurakijpundit University", id: 6580 },
  { code: "TH", name: "Eastern Asia University", id: 6581 },
  { code: "TH", name: "Engineering Faculty of Asian University", id: 6582 },
  { code: "TH", name: "Hatyai University", id: 6583 },
  { code: "TH", name: "Huachiew Chalermprakiet University", id: 6584 },
  { code: "TH", name: "International Buddhist College", id: 6585 },
  { code: "TH", name: "Kasem Bundit University", id: 6586 },
  { code: "TH", name: "Kasetsart University", id: 6587 },
  { code: "TH", name: "Khon Kaen University", id: 6588 },
  { code: "TH", name: "King Mongkut's University of Technology Ladkrabang", id: 6589 },
  { code: "TH", name: "King Mongkut's University of Technology North Bangkok", id: 6590 },
  { code: "TH", name: "King Mongkut's University of Technology Thonburi", id: 6591 },
  { code: "TH", name: "Krirk University", id: 6592 },
  { code: "TH", name: "Lampang College of Commerce and Technology", id: 6593 },
  { code: "TH", name: "Lampang Rajabhat University", id: 6594 },
  { code: "TH", name: "Lampang Vocational College   ", id: 6595 },
  { code: "TH", name: "Lamphun College of Agriculture and Technology", id: 6596 },
  { code: "TH", name: "Mae Fah Luang University ", id: 6597 },
  { code: "TH", name: "Maejo University", id: 6598 },
  { code: "TH", name: "Mahachulalongkorn Buddhist University", id: 6599 },
  { code: "TH", name: "Mahamakut Buddhist University", id: 6600 },
  { code: "TH", name: "Mahanakorn University of Technology", id: 6601 },
  { code: "TH", name: "Mahasarakham University", id: 6602 },
  { code: "TH", name: "Mahidol University", id: 6603 },
  { code: "TH", name: "Mahidol University International College", id: 6604 },
  { code: "TH", name: "Naresuan University", id: 6605 },
  { code: "TH", name: "National Institute of Development Administration", id: 6606 },
  { code: "TH", name: "Nivadhana University", id: 6607 },
  { code: "TH", name: "Pathumthani University", id: 6608 },
  { code: "TH", name: "Payap University Chaiang Mai", id: 6609 },
  { code: "TH", name: "Phranakhon Si Ayutthaya Rajabhat University", id: 6610 },
  { code: "TH", name: "Prince of Songkla University", id: 6611 },
  { code: "TH", name: "Rajamangala University of Technology", id: 6612 },
  { code: "TH", name: "Ramkhamhaeng University", id: 6613 },
  { code: "TH", name: "Rangsit University", id: 6614 },
  { code: "TH", name: "Shinawatra University", id: 6615 },
  { code: "TH", name: "Siam Technology College", id: 6616 },
  { code: "TH", name: "Siam University", id: 6617 },
  { code: "TH", name: "Silpakorn University", id: 6618 },
  { code: "TH", name: "South-East Asia University", id: 6619 },
  { code: "TH", name: "Srinakharinwirot University", id: 6620 },
  { code: "TH", name: "Sripatum University", id: 6621 },
  { code: "TH", name: "Stamford International University", id: 6622 },
  { code: "TH", name: "St. John's University", id: 6623 },
  { code: "TH", name: "Suan Dusit Rajabhat University", id: 6624 },
  { code: "TH", name: "Sukhothai Thammathirat Open University", id: 6625 },
  { code: "TH", name: "Suranaree University of Technology", id: 6626 },
  { code: "TH", name: "Thaksin University", id: 6627 },
  { code: "TH", name: "Thammasat University", id: 6628 },
  { code: "TH", name: "Ubonratchathani University", id: 6629 },
  { code: "TH", name: "University of the Thai Chamber of Commerce", id: 6630 },
  { code: "TH", name: "Vongchavalitkul University", id: 6631 },
  { code: "TH", name: "Walailak University", id: 6632 },
  { code: "TH", name: "Webster University", id: 6633 },
  { code: "TH", name: "Yonok University", id: 6634 },
  { code: "TJ", name: "Tajik Agrarian University", id: 6635 },
  { code: "TJ", name: "Tajikistan International University", id: 6636 },
  { code: "TJ", name: "Tajik National University", id: 6637 },
  { code: "TM", name: "International Turkmen Turkish University", id: 6638 },
  { code: "TN", name: "Instituts Supérieurs de Formation des Maîtres", id: 6639 },
  { code: "TN", name: "Instituts Supérieurs des Etudes Technologiques", id: 6640 },
  { code: "TN", name: "Institut Supérieur de Gestion de Tunis", id: 6641 },
  { code: "TN", name: "Université Centrale", id: 6642 },
  { code: "TN", name: "Université de 7 Novembre à Carthage", id: 6643 },
  { code: "TN", name: "Université de Gabès", id: 6644 },
  { code: "TN", name: "Université de Gafsa", id: 6645 },
  { code: "TN", name: "Université de Jendouba", id: 6646 },
  { code: "TN", name: "Université de Kairouan", id: 6647 },
  { code: "TN", name: "Université de la Manouba", id: 6648 },
  { code: "TN", name: "Université de Monastir", id: 6649 },
  { code: "TN", name: "Université de Sfax", id: 6650 },
  { code: "TN", name: "Université de Tunis", id: 6651 },
  { code: "TN", name: "Université de Tunis El Manar", id: 6652 },
  { code: "TN", name: "Université du Centre", id: 6653 },
  { code: "TN", name: "Université Ez-Zitouna", id: 6654 },
  { code: "TN", name: "Université Libre de Tunis", id: 6655 },
  { code: "TN", name: "Université Virtuelle de Tunis", id: 6656 },
  { code: "TN", name: "University of Aviation and Technology", id: 6657 },
  { code: "TO", name: "University of the South Pacific Centre", id: 6658 },
  { code: "TR", name: "Abant Izzet Baysal University", id: 6659 },
  { code: "TR", name: "Adnan Menderes University", id: 6660 },
  { code: "TR", name: "Afyon Kocatepe University", id: 6661 },
  { code: "TR", name: "Ahi Evran University", id: 6662 },
  { code: "TR", name: "Akdeniz University", id: 6663 },
  { code: "TR", name: "Anadolu University", id: 6664 },
  { code: "TR", name: "Ankara University", id: 6665 },
  { code: "TR", name: "Atatürk University", id: 6666 },
  { code: "TR", name: "Atilim University", id: 6667 },
  { code: "TR", name: "Bahcesehir University", id: 6668 },
  { code: "TR", name: "Balikesir University", id: 6669 },
  { code: "TR", name: "Baskent University", id: 6670 },
  { code: "TR", name: "Beykent University", id: 6671 },
  { code: "TR", name: "Bilecik University", id: 6672 },
  { code: "TR", name: "Bilkent University", id: 6673 },
  { code: "TR", name: "Bogazici University", id: 6674 },
  { code: "TR", name: "Cag University", id: 6675 },
  { code: "TR", name: "Canakkale (18th March) University", id: 6676 },
  { code: "TR", name: "Cankaya University", id: 6677 },
  { code: "TR", name: "Celal Bayar University", id: 6678 },
  { code: "TR", name: "Cukurova University", id: 6679 },
  { code: "TR", name: "Cumhuriyet (Republik) University", id: 6680 },
  { code: "TR", name: "Dicle (Tirgris) University", id: 6681 },
  { code: "TR", name: "Dogus University", id: 6682 },
  { code: "TR", name: "Dokuz Eylül University", id: 6683 },
  { code: "TR", name: "Dumlupinar University", id: 6684 },
  { code: "TR", name: "Ege University", id: 6685 },
  { code: "TR", name: "Erciyes University", id: 6686 },
  { code: "TR", name: "Fatih University", id: 6687 },
  { code: "TR", name: "Firat (Euphrates) University", id: 6688 },
  { code: "TR", name: "Galatasaray University", id: 6689 },
  { code: "TR", name: "Gaziantep University", id: 6690 },
  { code: "TR", name: "Gaziosmanpasa University", id: 6691 },
  { code: "TR", name: "Gazi University Ankara", id: 6692 },
  { code: "TR", name: "Gebze Institute of Technology", id: 6693 },
  { code: "TR", name: "Gediz University", id: 6694 },
  { code: "TR", name: "Gulhane Military Medical Academy", id: 6695 },
  { code: "TR", name: "Hacettepe University", id: 6696 },
  { code: "TR", name: "Halic University", id: 6697 },
  { code: "TR", name: "Harran University", id: 6698 },
  { code: "TR", name: "Iasar University", id: 6699 },
  { code: "TR", name: "Inönü University", id: 6700 },
  { code: "TR", name: "Isik University", id: 6701 },
  { code: "TR", name: "Istanbul Arel University", id: 6702 },
  { code: "TR", name: "Istanbul Bilgi University", id: 6703 },
  { code: "TR", name: "Istanbul Kultur University", id: 6704 },
  { code: "TR", name: "İstanbul Şehir University", id: 6705 },
  { code: "TR", name: "Istanbul Technical University", id: 6706 },
  { code: "TR", name: "Istanbul Ticaret University", id: 6707 },
  { code: "TR", name: "Istanbul University", id: 6708 },
  { code: "TR", name: "Izmir Economic University", id: 6709 },
  { code: "TR", name: "Izmir Institute of Technology", id: 6710 },
  { code: "TR", name: "Kadir Has University", id: 6711 },
  { code: "TR", name: "Kafkas University", id: 6712 },
  { code: "TR", name: "Kahramanmaras Sütcü Imam University", id: 6713 },
  { code: "TR", name: "Karadeniz Technical University", id: 6714 },
  { code: "TR", name: "Kilis 7 Aralık University", id: 6715 },
  { code: "TR", name: "Kirikkale University", id: 6716 },
  { code: "TR", name: "Kocaeli University", id: 6717 },
  { code: "TR", name: "Koc University", id: 6718 },
  { code: "TR", name: "Maltepe University", id: 6719 },
  { code: "TR", name: "Marmara University", id: 6720 },
  { code: "TR", name: "Mersin University", id: 6721 },
  { code: "TR", name: "Middle East Technical University", id: 6722 },
  { code: "TR", name: "Mimar Sinan University", id: 6723 },
  { code: "TR", name: "Mugla University", id: 6724 },
  { code: "TR", name: "Mustafa Kemal University", id: 6725 },
  { code: "TR", name: "Namik Kemal University", id: 6726 },
  { code: "TR", name: "Necmettin Erbakan University", id: 6727 },
  { code: "TR", name: "Nigde University", id: 6728 },
  { code: "TR", name: "Ondokuz Mayis University Samsun", id: 6729 },
  { code: "TR", name: "Osmangazi University", id: 6730 },
  { code: "TR", name: "Ozyegin University", id: 6731 },
  { code: "TR", name: "Pamukkale University", id: 6732 },
  { code: "TR", name: "Sabanci University", id: 6733 },
  { code: "TR", name: "Sakarya University", id: 6734 },
  { code: "TR", name: "Selcuk University", id: 6735 },
  { code: "TR", name: "Suleyman Demirel University", id: 6736 },
  { code: "TR", name: "Tobb Economics and Technology University", id: 6737 },
  { code: "TR", name: "Trakya University", id: 6738 },
  { code: "TR", name: "Türkisch-Deutsche Universität", id: 6739 },
  { code: "TR", name: "Turkish Military Academy", id: 6740 },
  { code: "TR", name: "Turkish Naval Academy", id: 6741 },
  { code: "TR", name: "Ufuk University", id: 6742 },
  { code: "TR", name: "Uludag University", id: 6743 },
  { code: "TR", name: "Yalova University", id: 6744 },
  { code: "TR", name: "Yasar University", id: 6745 },
  { code: "TR", name: "Yeditepe University", id: 6746 },
  { code: "TR", name: "Yildirim Beyazit University", id: 6747 },
  { code: "TR", name: "Yildiz Technical University", id: 6748 },
  { code: "TR", name: "Yüzüncü Yil (Centennial) University", id: 6749 },
  { code: "TR", name: "Zonguldak Karaelmas University", id: 6750 },
  { code: "TT", name: "University of the Southern Caribbean", id: 6751 },
  { code: "TT", name: "University of the West Indies St. Augustine", id: 6752 },
  { code: "TT", name: "University of Trinidad and Tobago", id: 6753 },
  { code: "TW", name: "Aletheia University", id: 6754 },
  { code: "TW", name: "Central Police University", id: 6755 },
  { code: "TW", name: "Chang Gung University", id: 6756 },
  { code: "TW", name: "Chang Jung University", id: 6757 },
  { code: "TW", name: "Chao Yang University of Science and Technology", id: 6758 },
  { code: "TW", name: "Chia Nana College of Pharmacy and Science", id: 6759 },
  { code: "TW", name: "China Medical College", id: 6760 },
  { code: "TW", name: "Chinese Culture University", id: 6761 },
  { code: "TW", name: "Ching Kuo Institue of Management & Health", id: 6762 },
  { code: "TW", name: "Chung Hua University", id: 6763 },
  { code: "TW", name: "Chung Shan Medical and Dental College", id: 6764 },
  { code: "TW", name: "Chung Yuan Christian University", id: 6765 },
  { code: "TW", name: "Da-Yeh University", id: 6766 },
  { code: "TW", name: "Feng Chia University", id: 6767 },
  { code: "TW", name: "Fu Jen Catholic University", id: 6768 },
  { code: "TW", name: "Hsuan Chuang University", id: 6769 },
  { code: "TW", name: "Huafan University", id: 6770 },
  { code: "TW", name: "I-Shou University", id: 6771 },
  { code: "TW", name: "Kaohsiung Medical College", id: 6772 },
  { code: "TW", name: "Kaohsuing Open University", id: 6773 },
  { code: "TW", name: "Kung Shan Institute of Technology", id: 6774 },
  { code: "TW", name: "Kun Shan University", id: 6775 },
  { code: "TW", name: "Ling Tung University", id: 6776 },
  { code: "TW", name: "LungHwa University of Science and Technology", id: 6777 },
  { code: "TW", name: "Ming Chuan University", id: 6778 },
  { code: "TW", name: "Nanhua University", id: 6779 },
  { code: "TW", name: "National Central University", id: 6780 },
  { code: "TW", name: "National Changhua University of Education", id: 6781 },
  { code: "TW", name: "National Chengchi University", id: 6782 },
  { code: "TW", name: "National Cheng Kung University", id: 6783 },
  { code: "TW", name: "National Chiao Tung University", id: 6784 },
  { code: "TW", name: "National Chiayi University", id: 6785 },
  { code: "TW", name: "National Chi Nan University", id: 6786 },
  { code: "TW", name: "National Chung Cheng University", id: 6787 },
  { code: "TW", name: "National Chung Hsing University", id: 6788 },
  { code: "TW", name: "National College of Physical Education and Sports", id: 6789 },
  { code: "TW", name: "National Dong Hwa University", id: 6790 },
  { code: "TW", name: "National Formosa University", id: 6791 },
  { code: "TW", name: "National Hsin-Chu Teachers College", id: 6792 },
  { code: "TW", name: "National Hualien Teachers College", id: 6793 },
  { code: "TW", name: "National Ilan University", id: 6794 },
  { code: "TW", name: "National Institute of the Arts", id: 6795 },
  { code: "TW", name: "National Kaohsiung First University of Science and Technology", id: 6796 },
  { code: "TW", name: "National Kaohsiung Normal University", id: 6797 },
  { code: "TW", name: "National Kaohsiung University of Applied Sciences", id: 6798 },
  { code: "TW", name: "National Open University", id: 6799 },
  { code: "TW", name: "National Pingtung Teachers College", id: 6800 },
  { code: "TW", name: "National Pingtung University of Science and Technology", id: 6801 },
  { code: "TW", name: "National Sun Yat-Sen University", id: 6802 },
  { code: "TW", name: "National Taichung Teachers College", id: 6803 },
  { code: "TW", name: "National Tainan Teachers College", id: 6804 },
  { code: "TW", name: "National Taipei College of Nursing", id: 6805 },
  { code: "TW", name: "National Taipei Teachers College", id: 6806 },
  { code: "TW", name: "National Taipei University", id: 6807 },
  { code: "TW", name: "National Taipei University of Technology", id: 6808 },
  { code: "TW", name: "National Taitung Teachers College", id: 6809 },
  { code: "TW", name: "National Taiwan College of Arts", id: 6810 },
  { code: "TW", name: "National Taiwan College of Physical Education and Sports", id: 6811 },
  { code: "TW", name: "National Taiwan College of the Arts", id: 6812 },
  { code: "TW", name: "National Taiwan Normal University", id: 6813 },
  { code: "TW", name: "National Taiwan Ocean University", id: 6814 },
  { code: "TW", name: "National Taiwan University", id: 6815 },
  { code: "TW", name: "National Taiwan University of Science and Technology", id: 6816 },
  { code: "TW", name: "National Tsinghua University", id: 6817 },
  { code: "TW", name: "National United University", id: 6818 },
  { code: "TW", name: "National University of Kaohsiung", id: 6819 },
  { code: "TW", name: "National Yang Ming Medical College", id: 6820 },
  { code: "TW", name: "National Yunlin University of Science and Technology", id: 6821 },
  { code: "TW", name: "Providence University", id: 6822 },
  { code: "TW", name: "Shih Chien University", id: 6823 },
  { code: "TW", name: "Shih Hsin University", id: 6824 },
  { code: "TW", name: "Soochow University", id: 6825 },
  { code: "TW", name: "Southern Taiwan University of Technology", id: 6826 },
  { code: "TW", name: "St. John's & St. Mary's Institute of Technology", id: 6827 },
  { code: "TW", name: "TaiChung Healthcare and Management University", id: 6828 },
  { code: "TW", name: "Tainan National College of the Arts", id: 6829 },
  { code: "TW", name: "Taipei Medical College", id: 6830 },
  { code: "TW", name: "Taipei Municipal Teachers College", id: 6831 },
  { code: "TW", name: "Taipei Physical Education College", id: 6832 },
  { code: "TW", name: "Tamkang University", id: 6833 },
  { code: "TW", name: "Tatung Institute of Technology", id: 6834 },
  { code: "TW", name: "TaTung University ", id: 6835 },
  { code: "TW", name: "Tunghai University", id: 6836 },
  { code: "TW", name: "Tzu Chi College of Medicine and Humanities", id: 6837 },
  { code: "TW", name: "Yuan Ze University", id: 6838 },
  { code: "TZ", name: "African Virtual University", id: 6839 },
  { code: "TZ", name: "Aga Khan University", id: 6840 },
  { code: "TZ", name: "Dares Salaam Institute of Technology", id: 6841 },
  { code: "TZ", name: "Hubert Kairuki Memorial University", id: 6842 },
  { code: "TZ", name: "Institute of Accountancy Arusha", id: 6843 },
  { code: "TZ", name: "Institute of Finance Management", id: 6844 },
  { code: "TZ", name: "International Medical & Technological University", id: 6845 },
  { code: "TZ", name: "Kilimanjaro Christian Medical College", id: 6846 },
  { code: "TZ", name: "Moshi University College of Cooperative and Business Studies", id: 6847 },
  { code: "TZ", name: "Mount Meru University", id: 6848 },
  { code: "TZ", name: "Muhimbili University of Health and Allied Sciences (MUHAS)", id: 6849 },
  { code: "TZ", name: "Mwalimu Nyerere Memorial Academy", id: 6850 },
  { code: "TZ", name: "Mzumbe University (Chuo Kikuu Mzumbe)", id: 6851 },
  { code: "TZ", name: "Open University of Tanzania", id: 6852 },
  { code: "TZ", name: "Sebatian Kolowa University College", id: 6853 },
  { code: "TZ", name: "Sokoine University of Agriculture", id: 6854 },
  { code: "TZ", name: "St. Augustine University of Tanzania", id: 6855 },
  { code: "TZ", name: "St. Joseph Group of Institutes", id: 6856 },
  { code: "TZ", name: "Tumaini University - Iringa University College", id: 6857 },
  { code: "TZ", name: "University of Dar Es Salaam", id: 6858 },
  { code: "TZ", name: "University of Dodoma", id: 6859 },
  { code: "UA", name: "Alfred Nobel University of Economics and Law ", id: 6860 },
  { code: "UA", name: "Bila Cerkva State Agrarian University", id: 6861 },
  { code: "UA", name: "Chernivci National University", id: 6862 },
  { code: "UA", name: "Crimea State Medical University", id: 6863 },
  { code: "UA", name: "Dneprodzerzhinsk State Technical University", id: 6864 },
  { code: "UA", name: "Dnepropetrovsk National University", id: 6865 },
  { code: "UA", name: "Dnepropetrovsk National University of Railway Transport", id: 6866 },
  { code: "UA", name: "Donetsk National Technical University", id: 6867 },
  { code: "UA", name: "Donetsk National University", id: 6868 },
  { code: "UA", name: "Donetsk State Medical University", id: 6869 },
  { code: "UA", name: "East Ukrainian National University", id: 6870 },
  { code: "UA", name: "Ivano-Frankivsk National University of Oli and Gas", id: 6871 },
  { code: "UA", name: "Kharkiv National University", id: 6872 },
  { code: "UA", name: "Kharkiv National University of Economics", id: 6873 },
  { code: "UA", name: "Kharkiv National University of Radio Electronics", id: 6874 },
  { code: "UA", name: "Kharkiv State Medical University", id: 6875 },
  { code: "UA", name: "Kharkiv State Technical University of Construction and Architecture", id: 6876 },
  { code: "UA", name: "Kharkiv State Transport Technical University", id: 6877 },
  { code: "UA", name: "Kherson State University", id: 6878 },
  { code: "UA", name: "Khmelnitsky National University", id: 6879 },
  { code: "UA", name: "Kiev National Economic University Named after Vadym Hetman", id: 6880 },
  { code: "UA", name: "Kiev National University of Construction and Architecture", id: 6881 },
  { code: "UA", name: "Kiev School Of Economics", id: 6882 },
  { code: "UA", name: "Kiev Slavonic University", id: 6883 },
  { code: "UA", name: "KROK Economics and Law University", id: 6884 },
  { code: "UA", name: "Luhansk State Medical University", id: 6885 },
  { code: "UA", name: "Luhansk Taras Shevchenko National Pedagogical University", id: 6886 },
  { code: "UA", name: "Lviv Medical University", id: 6887 },
  { code: "UA", name: "Lviv National Medical Univeristy", id: 6888 },
  { code: "UA", name: "Lviv National University Ivan Franko", id: 6889 },
  { code: "UA", name: "Lviv Polytechnic National University", id: 6890 },
  { code: "UA", name: "National Aerospace University Kharkov Aviation Institute", id: 6891 },
  { code: "UA", name: "National Agricultural University", id: 6892 },
  { code: "UA", name: "National Aviation Univesity", id: 6893 },
  { code: "UA", name: "National Medical University O.O. Bogomolets", id: 6894 },
  { code: "UA", name: "National Metallurgical Academy of Ukraine", id: 6895 },
  { code: "UA", name: "National Mining University", id: 6896 },
  { code: "UA", name: "National Pedagogical University M. Dragomanov ", id: 6897 },
  { code: "UA", name: "National Pirogov Memorial Medical University", id: 6898 },
  { code: "UA", name: "National Taras Shevchenko University of Kiev", id: 6899 },
  { code: "UA", name: "National Technical University (Kharkiv Polytechnical Institute)", id: 6900 },
  { code: "UA", name: "National Technical University (Kiev Politechnical Institute)", id: 6901 },
  { code: "UA", name: "National Transport University", id: 6902 },
  { code: "UA", name: "National University of Food Technologies", id: 6903 },
  { code: "UA", name: "National University of Internal Affairs", id: 6904 },
  { code: "UA", name: "National University of Kiev-Mohyla Academy", id: 6905 },
  { code: "UA", name: "National University of Ostroh Academy", id: 6906 },
  { code: "UA", name: "National University of Shipbuilding", id: 6907 },
  { code: "UA", name: "Odessa National Academy of Food Technologies", id: 6908 },
  { code: "UA", name: "Odessa National I.I. Mecnikov University", id: 6909 },
  { code: "UA", name: "Odessa National Marine University", id: 6910 },
  { code: "UA", name: "Odessa National Maritime Academy", id: 6911 },
  { code: "UA", name: "Odessa National Polytechnic University", id: 6912 },
  { code: "UA", name: "Odessa State Academy of Construction and Architecture", id: 6913 },
  { code: "UA", name: "Odessa State Medical University", id: 6914 },
  { code: "UA", name: "Petro Mohyla Black Sea State University", id: 6915 },
  { code: "UA", name: "Poltava National Technical University", id: 6916 },
  { code: "UA", name: "Poltava University of Consumer Cooperatives in Ukraine", id: 6917 },
  { code: "UA", name: "Precarpathian University", id: 6918 },
  { code: "UA", name: "Sevastopol National Technical University", id: 6919 },
  { code: "UA", name: "State Pedagogical University in Kryvyi Rih", id: 6920 },
  { code: "UA", name: "Sumy National Agrarian University", id: 6921 },
  { code: "UA", name: "Sumy State University", id: 6922 },
  { code: "UA", name: "Taurida National V.I.Vernadsky University", id: 6923 },
  { code: "UA", name: "Technological University of Podillya", id: 6924 },
  { code: "UA", name: "Ternopil Academy of National Economy", id: 6925 },
  { code: "UA", name: "Ternopil State Ivan Pul'uj Technical University", id: 6926 },
  { code: "UA", name: "Ukrainian Academy of Banking", id: 6927 },
  { code: "UA", name: "Ukrainian Academy of Pharmacy", id: 6928 },
  { code: "UA", name: "Ukrainian Medical Stomatological Academy", id: 6929 },
  { code: "UA", name: "Ukrainian National Forestry University", id: 6930 },
  { code: "UA", name: "Uzhgorod National University", id: 6931 },
  { code: "UA", name: "Vinnica National Technical University", id: 6932 },
  { code: "UA", name: "Volyn National University Lesja Ukrainka", id: 6933 },
  { code: "UA", name: "Zaporizhzhe National Technical University", id: 6934 },
  { code: "UA", name: "Zaporizhzhe National University", id: 6935 },
  { code: "UG", name: "Aga Khan University", id: 6936 },
  { code: "UG", name: "Bugema University", id: 6937 },
  { code: "UG", name: "Busitema University", id: 6938 },
  { code: "UG", name: "Busoga University", id: 6939 },
  { code: "UG", name: "Central Buganda University", id: 6940 },
  { code: "UG", name: "Gulu University", id: 6941 },
  { code: "UG", name: "Islamic University in Uganda", id: 6942 },
  { code: "UG", name: "Kampala International University", id: 6943 },
  { code: "UG", name: "Kampala University", id: 6944 },
  { code: "UG", name: "Kyambogo University", id: 6945 },
  { code: "UG", name: "Makerere University", id: 6946 },
  { code: "UG", name: "Mbarara University of Science and Technology", id: 6947 },
  { code: "UG", name: "Mountains of the Moon University", id: 6948 },
  { code: "UG", name: "Mutesa 1 Royal University", id: 6949 },
  { code: "UG", name: "Ndejje University", id: 6950 },
  { code: "UG", name: "Nkumba University", id: 6951 },
  { code: "UG", name: "St. Augustine International University", id: 6952 },
  { code: "UG", name: "Uganda Christian University", id: 6953 },
  { code: "UG", name: "Uganda Martyr's University", id: 6954 },
  { code: "US", name: "Abilene Christian University", id: 6955 },
  { code: "US", name: "Academy of Art College", id: 6956 },
  { code: "US", name: "Adams State College", id: 6957 },
  { code: "US", name: "Adelphi University", id: 6958 },
  { code: "US", name: "Adler School of Professional Psychology", id: 6959 },
  { code: "US", name: "Adrian College", id: 6960 },
  { code: "US", name: "Agnes Scott College", id: 6961 },
  { code: "US", name: "Air Force Institute of Technology", id: 6962 },
  { code: "US", name: "Alabama Agricultural and Mechanical University", id: 6963 },
  { code: "US", name: "Alabama State University", id: 6964 },
  { code: "US", name: "Alaska Bible College", id: 6965 },
  { code: "US", name: "Alaska Pacific University", id: 6966 },
  { code: "US", name: "Albany College of Pharmacy", id: 6967 },
  { code: "US", name: "Albany Law School", id: 6968 },
  { code: "US", name: "Albany Medical Center", id: 6969 },
  { code: "US", name: "Albany State University", id: 6970 },
  { code: "US", name: "Albertus Magnus College", id: 6971 },
  { code: "US", name: "Albion College", id: 6972 },
  { code: "US", name: "Albright College", id: 6973 },
  { code: "US", name: "Alcorn State University", id: 6974 },
  { code: "US", name: "Alderson Broaddus College", id: 6975 },
  { code: "US", name: "Alfred Adler Graduate School", id: 6976 },
  { code: "US", name: "Alfred University", id: 6977 },
  { code: "US", name: "Alice Lloyd College", id: 6978 },
  { code: "US", name: "Allegheny College", id: 6979 },
  { code: "US", name: "Allen University", id: 6980 },
  { code: "US", name: "Alma College", id: 6981 },
  { code: "US", name: "Alvernia College", id: 6982 },
  { code: "US", name: "Alverno College", id: 6983 },
  { code: "US", name: "Ambassador University", id: 6984 },
  { code: "US", name: "Amber University", id: 6985 },
  { code: "US", name: "American Academy of Nutrition", id: 6986 },
  { code: "US", name: "American Business & Technology University", id: 6987 },
  { code: "US", name: "American Conservatory of Music", id: 6988 },
  { code: "US", name: "American Conservatory Theater", id: 6989 },
  { code: "US", name: "American-European School of Management ", id: 6990 },
  { code: "US", name: "American Film Institute Center for Advanced Film and Television Studies", id: 6991 },
  { code: "US", name: "American Indian College", id: 6992 },
  { code: "US", name: "American InterContinental University - Atlanta", id: 6993 },
  { code: "US", name: "American InterContinental University - Ft. Lauderdale", id: 6994 },
  { code: "US", name: "American InterContinental University - Georgia", id: 6995 },
  { code: "US", name: "American InterContinental University Online", id: 6996 },
  { code: "US", name: "American International College", id: 6997 },
  { code: "US", name: "American Jewish University", id: 6998 },
  { code: "US", name: "American Military University", id: 6999 },
  { code: "US", name: "American Public University", id: 7000 },
  { code: "US", name: "American University", id: 7001 },
  { code: "US", name: "American World University", id: 7002 },
  { code: "US", name: "Amherst College", id: 7003 },
  { code: "US", name: "Anderson College", id: 7004 },
  { code: "US", name: "Anderson University", id: 7005 },
  { code: "US", name: "Andon College - Modesto", id: 7006 },
  { code: "US", name: "Andon College - Stockton", id: 7007 },
  { code: "US", name: "Andrew Jackson University", id: 7008 },
  { code: "US", name: "Andrews University", id: 7009 },
  { code: "US", name: "Angelo State University", id: 7010 },
  { code: "US", name: "Anna Maria College", id: 7011 },
  { code: "US", name: "Antioch New England Graduate School", id: 7012 },
  { code: "US", name: "Antioch University", id: 7013 },
  { code: "US", name: "Antioch University Los Angeles", id: 7014 },
  { code: "US", name: "Antioch University Santa Barbara", id: 7015 },
  { code: "US", name: "Antioch University Seattle", id: 7016 },
  { code: "US", name: "Appalachian Bible College", id: 7017 },
  { code: "US", name: "Appalachian State University", id: 7018 },
  { code: "US", name: "Aquinas College", id: 7019 },
  { code: "US", name: "Arcadia University", id: 7020 },
  { code: "US", name: "Argosy University", id: 7021 },
  { code: "US", name: "Argosy University - Hawaii", id: 7022 },
  { code: "US", name: "Arizona Christian University", id: 7023 },
  { code: "US", name: "Arizona State University", id: 7024 },
  { code: "US", name: "Arkansas State University", id: 7025 },
  { code: "US", name: "Arkansas Tech University", id: 7026 },
  { code: "US", name: "Arlington Baptist College", id: 7027 },
  { code: "US", name: "Armstrong Atlantic State University", id: 7028 },
  { code: "US", name: "Armstrong University", id: 7029 },
  { code: "US", name: "Art Academy of Cincinnati", id: 7030 },
  { code: "US", name: "Art Center College of Design", id: 7031 },
  { code: "US", name: "Arthur D. Little Management Education Institute", id: 7032 },
  { code: "US", name: "Art Institute of Charlotte", id: 7033 },
  { code: "US", name: "Art Institute of Southern California", id: 7034 },
  { code: "US", name: "Asbury College", id: 7035 },
  { code: "US", name: "Ashland University", id: 7036 },
  { code: "US", name: "Assumption College", id: 7037 },
  { code: "US", name: "Athenaeum of Ohio", id: 7038 },
  { code: "US", name: "Athens State College", id: 7039 },
  { code: "US", name: "Atlanta Christian College", id: 7040 },
  { code: "US", name: "Atlanta College of Art", id: 7041 },
  { code: "US", name: "Atlantic International University", id: 7042 },
  { code: "US", name: "Atlantic Union College", id: 7043 },
  { code: "US", name: "Atlantic University", id: 7044 },
  { code: "US", name: "Auburn University", id: 7045 },
  { code: "US", name: "Auburn University at Montgomery", id: 7046 },
  { code: "US", name: "Audrey Cohen College", id: 7047 },
  { code: "US", name: "Augsburg College", id: 7048 },
  { code: "US", name: "Augustana College", id: 7049 },
  { code: "US", name: "Augusta State University", id: 7050 },
  { code: "US", name: "Aurora University", id: 7051 },
  { code: "US", name: "Austin College", id: 7052 },
  { code: "US", name: "Austin Community College", id: 7053 },
  { code: "US", name: "Austin Peay State University", id: 7054 },
  { code: "US", name: "Ave Maria University", id: 7055 },
  { code: "US", name: "Averett College", id: 7056 },
  { code: "US", name: "Avila College", id: 7057 },
  { code: "US", name: "Azusa Pacific University", id: 7058 },
  { code: "US", name: "Babson College", id: 7059 },
  { code: "US", name: "Baker College of Auburn Hills", id: 7060 },
  { code: "US", name: "Baker College of Cadillac", id: 7061 },
  { code: "US", name: "Baker College of Flint", id: 7062 },
  { code: "US", name: "Baker College of Mount Clemens", id: 7063 },
  { code: "US", name: "Baker College of Muskegon", id: 7064 },
  { code: "US", name: "Baker College of Owosso", id: 7065 },
  { code: "US", name: "Baker College of Port Huron", id: 7066 },
  { code: "US", name: "Baker University", id: 7067 },
  { code: "US", name: "Baldwin-Wallace College", id: 7068 },
  { code: "US", name: "Ball State University", id: 7069 },
  { code: "US", name: "Baltimore Hebrew University", id: 7070 },
  { code: "US", name: "Baltimore International College", id: 7071 },
  { code: "US", name: "Bank Street College of Education", id: 7072 },
  { code: "US", name: "Baptist Bible College of Missouri", id: 7073 },
  { code: "US", name: "Baptist Bible College of Pennsylvania", id: 7074 },
  { code: "US", name: "Barat College", id: 7075 },
  { code: "US", name: "Barber-Scotia College", id: 7076 },
  { code: "US", name: "Barclay College", id: 7077 },
  { code: "US", name: "Bard College", id: 7078 },
  { code: "US", name: "Bard Graduate Center for Studies in the Decorative Arts", id: 7079 },
  { code: "US", name: "Barnard College - Columbia University", id: 7080 },
  { code: "US", name: "Barry University", id: 7081 },
  { code: "US", name: "Bartlesville Wesleyan College", id: 7082 },
  { code: "US", name: "Barton College", id: 7083 },
  { code: "US", name: "Bastyr University", id: 7084 },
  { code: "US", name: "Bates College", id: 7085 },
  { code: "US", name: "Bauder College", id: 7086 },
  { code: "US", name: "Baylor College of Dentistry", id: 7087 },
  { code: "US", name: "Baylor College of Medicine", id: 7088 },
  { code: "US", name: "Baylor University", id: 7089 },
  { code: "US", name: "Belhaven College", id: 7090 },
  { code: "US", name: "Bellarmine College", id: 7091 },
  { code: "US", name: "Bellevue University", id: 7092 },
  { code: "US", name: "Bellin College of Nursing", id: 7093 },
  { code: "US", name: "Belmont Abbey College", id: 7094 },
  { code: "US", name: "Belmont University", id: 7095 },
  { code: "US", name: "Beloit College", id: 7096 },
  { code: "US", name: "Bemidji State Univeristy", id: 7097 },
  { code: "US", name: "Benedict College", id: 7098 },
  { code: "US", name: "Benedictine College", id: 7099 },
  { code: "US", name: "Benedictine University", id: 7100 },
  { code: "US", name: "Bennett College", id: 7101 },
  { code: "US", name: "Bennington College", id: 7102 },
  { code: "US", name: "Bentley College", id: 7103 },
  { code: "US", name: "Berea College", id: 7104 },
  { code: "US", name: "Berean University of the Assemblies of God", id: 7105 },
  { code: "US", name: "Berklee College of Music", id: 7106 },
  { code: "US", name: "Berne University", id: 7107 },
  { code: "US", name: "Berry College", id: 7108 },
  { code: "US", name: "Bethany College California", id: 7109 },
  { code: "US", name: "Bethany College Kansas", id: 7110 },
  { code: "US", name: "Bethany College West Virginia", id: 7111 },
  { code: "US", name: "Bethel College McKenzie", id: 7112 },
  { code: "US", name: "Bethel College Mishawaka", id: 7113 },
  { code: "US", name: "Bethel College Newton", id: 7114 },
  { code: "US", name: "Beth-El College of Nursing and Health Sciences", id: 7115 },
  { code: "US", name: "Bethel College St. Paul", id: 7116 },
  { code: "US", name: "Bethune-Cookman College", id: 7117 },
  { code: "US", name: "Biola University", id: 7118 },
  { code: "US", name: "Birmingham-Southern College", id: 7119 },
  { code: "US", name: "Blackburn College", id: 7120 },
  { code: "US", name: "Black Hawk College", id: 7121 },
  { code: "US", name: "Black Hills State University", id: 7122 },
  { code: "US", name: "Blessing-Rieman College of Nursing", id: 7123 },
  { code: "US", name: "Bloomfield College", id: 7124 },
  { code: "US", name: "Bloomsburg University of Pennsylvania", id: 7125 },
  { code: "US", name: "Bluefield College", id: 7126 },
  { code: "US", name: "Bluefield State College", id: 7127 },
  { code: "US", name: "Blue Mountain College", id: 7128 },
  { code: "US", name: "Bluffton College", id: 7129 },
  { code: "US", name: "Bob Jones University", id: 7130 },
  { code: "US", name: "Boise Bible College", id: 7131 },
  { code: "US", name: "Boise State University", id: 7132 },
  { code: "US", name: "Boricua College", id: 7133 },
  { code: "US", name: "Boston Architectural Center", id: 7134 },
  { code: "US", name: "Boston College", id: 7135 },
  { code: "US", name: "Boston University", id: 7136 },
  { code: "US", name: "Bowdoin College", id: 7137 },
  { code: "US", name: "Bowie State University", id: 7138 },
  { code: "US", name: "Bowling Green State University", id: 7139 },
  { code: "US", name: "Bradley University", id: 7140 },
  { code: "US", name: "Brandeis University", id: 7141 },
  { code: "US", name: "Brandman University", id: 7142 },
  { code: "US", name: "Brenau University", id: 7143 },
  { code: "US", name: "Brescia University", id: 7144 },
  { code: "US", name: "Brevard College", id: 7145 },
  { code: "US", name: "Brewton-Parker College", id: 7146 },
  { code: "US", name: "Breyer State University (Virtual University)", id: 7147 },
  { code: "US", name: "Briar Cliff College", id: 7148 },
  { code: "US", name: "Bridgewater College", id: 7149 },
  { code: "US", name: "Bridgewater State College", id: 7150 },
  { code: "US", name: "Brigham Young University", id: 7151 },
  { code: "US", name: "Brigham Young University Hawaii", id: 7152 },
  { code: "US", name: "Brooklyn Law School", id: 7153 },
  { code: "US", name: "Brooks Institute of Photography", id: 7154 },
  { code: "US", name: "Brown University", id: 7155 },
  { code: "US", name: "Brunswick Community College", id: 7156 },
  { code: "US", name: "Bryan College", id: 7157 },
  { code: "US", name: "Bryant and Stratton College", id: 7158 },
  { code: "US", name: "Bryant University", id: 7159 },
  { code: "US", name: "Bryn Athyn College of the New Church", id: 7160 },
  { code: "US", name: "Bryn Mawr College", id: 7161 },
  { code: "US", name: "Bucknell University", id: 7162 },
  { code: "US", name: "Buena Vista University", id: 7163 },
  { code: "US", name: "Burlington College", id: 7164 },
  { code: "US", name: "Butler University", id: 7165 },
  { code: "US", name: "Cabrini College", id: 7166 },
  { code: "US", name: "Caldwell College", id: 7167 },
  { code: "US", name: "California Baptist College", id: 7168 },
  { code: "US", name: "California Coast University", id: 7169 },
  { code: "US", name: "California College for Health Sciences", id: 7170 },
  { code: "US", name: "California College of Arts and Crafts", id: 7171 },
  { code: "US", name: "California College of Podiatric Medicine", id: 7172 },
  { code: "US", name: "California College San Diego", id: 7173 },
  { code: "US", name: "California Institute of Integral Studies", id: 7174 },
  { code: "US", name: "California Institute of Technology", id: 7175 },
  { code: "US", name: "California Institute of the Arts", id: 7176 },
  { code: "US", name: "California Lutheran University", id: 7177 },
  { code: "US", name: "California Maritime Academy", id: 7178 },
  { code: "US", name: "California Polytechnic State University - San Luis Obispo", id: 7179 },
  { code: "US", name: "California School of Professional Psychology - Berkley/Alameda", id: 7180 },
  { code: "US", name: "California School of Professional Psychology - Fresno", id: 7181 },
  { code: "US", name: "California School of Professional Psychology - Los Angels", id: 7182 },
  { code: "US", name: "California School of Professional Psychology - San Diego", id: 7183 },
  { code: "US", name: "California State Polytechnic University - Pomona", id: 7184 },
  { code: "US", name: "California State University", id: 7185 },
  { code: "US", name: "California University of Management and Sciences", id: 7186 },
  { code: "US", name: "California University of Pennsylvania", id: 7187 },
  { code: "US", name: "California Western School of Law", id: 7188 },
  { code: "US", name: "Calumet College of St. Joseph", id: 7189 },
  { code: "US", name: "Calvary Bible College", id: 7190 },
  { code: "US", name: "Calvin College", id: 7191 },
  { code: "US", name: "Cambridge College", id: 7192 },
  { code: "US", name: "Cameron University", id: 7193 },
  { code: "US", name: "Campbellsville College", id: 7194 },
  { code: "US", name: "Campbell University", id: 7195 },
  { code: "US", name: "Canisius College", id: 7196 },
  { code: "US", name: "Capella University", id: 7197 },
  { code: "US", name: "Capital University", id: 7198 },
  { code: "US", name: "Capital University Law School", id: 7199 },
  { code: "US", name: "Capitol College", id: 7200 },
  { code: "US", name: "Cardinal Stritch University", id: 7201 },
  { code: "US", name: "Carleton College", id: 7202 },
  { code: "US", name: "Carlos Albizu University", id: 7203 },
  { code: "US", name: "Carlow College", id: 7204 },
  { code: "US", name: "Carnegie Mellon University", id: 7205 },
  { code: "US", name: "Carroll College Helena", id: 7206 },
  { code: "US", name: "Carroll College Waukesha", id: 7207 },
  { code: "US", name: "Carson-Newman College", id: 7208 },
  { code: "US", name: "Carthage College", id: 7209 },
  { code: "US", name: "Case Western Reserve University", id: 7210 },
  { code: "US", name: "Castleton State College", id: 7211 },
  { code: "US", name: "Catawba College", id: 7212 },
  { code: "US", name: "Catholic Theological Union", id: 7213 },
  { code: "US", name: "Cedar Crest College", id: 7214 },
  { code: "US", name: "Cedarville College", id: 7215 },
  { code: "US", name: "Centenary College", id: 7216 },
  { code: "US", name: "Centenary College of Louisiana", id: 7217 },
  { code: "US", name: "Center for Humanistic Studies", id: 7218 },
  { code: "US", name: "Central Baptist College", id: 7219 },
  { code: "US", name: "Central Bible College", id: 7220 },
  { code: "US", name: "Central Christian College of the Bible", id: 7221 },
  { code: "US", name: "Central College", id: 7222 },
  { code: "US", name: "Central Connecticut State University", id: 7223 },
  { code: "US", name: "Central Methodist College", id: 7224 },
  { code: "US", name: "Central Michigan University", id: 7225 },
  { code: "US", name: "Central State University", id: 7226 },
  { code: "US", name: "Central Washington University", id: 7227 },
  { code: "US", name: "Centre College", id: 7228 },
  { code: "US", name: "Chadron State College", id: 7229 },
  { code: "US", name: "Chaminade University of Honolulu", id: 7230 },
  { code: "US", name: "Champlain College", id: 7231 },
  { code: "US", name: "Chapman University", id: 7232 },
  { code: "US", name: "Charles R. Drew University of Medicine and Science", id: 7233 },
  { code: "US", name: "Charleston Southern University", id: 7234 },
  { code: "US", name: "Charter Oak State College", id: 7235 },
  { code: "US", name: "Chatham College", id: 7236 },
  { code: "US", name: "Chestnut Hill College", id: 7237 },
  { code: "US", name: "Cheyney University of Pennsylvania", id: 7238 },
  { code: "US", name: "Chicago State University", id: 7239 },
  { code: "US", name: "Chowan College", id: 7240 },
  { code: "US", name: "Christendom College", id: 7241 },
  { code: "US", name: "Christian Brothers University", id: 7242 },
  { code: "US", name: "Christian Heritage College", id: 7243 },
  { code: "US", name: "Christopher Newport University", id: 7244 },
  { code: "US", name: "Circleville Bible College", id: 7245 },
  { code: "US", name: "City University", id: 7246 },
  { code: "US", name: "City University of New York", id: 7247 },
  { code: "US", name: "City University of New York (CUNY) System", id: 7248 },
  { code: "US", name: "City University of New York Medical School / Sophie Davis School of Biomedical Education", id: 7249 },
  { code: "US", name: "Claflin College", id: 7250 },
  { code: "US", name: "Claremont Graduate University", id: 7251 },
  { code: "US", name: "Claremont Lincoln University", id: 7252 },
  { code: "US", name: "Claremont McKenna College", id: 7253 },
  { code: "US", name: "Clarion University", id: 7254 },
  { code: "US", name: "Clark Atlanta University", id: 7255 },
  { code: "US", name: "Clarke College", id: 7256 },
  { code: "US", name: "Clarkson College", id: 7257 },
  { code: "US", name: "Clarkson University", id: 7258 },
  { code: "US", name: "Clark University", id: 7259 },
  { code: "US", name: "Clayton College & State University", id: 7260 },
  { code: "US", name: "Clearwater Christian College", id: 7261 },
  { code: "US", name: "Cleary College", id: 7262 },
  { code: "US", name: "Clemson University", id: 7263 },
  { code: "US", name: "Cleveland Chiropractic College", id: 7264 },
  { code: "US", name: "Cleveland Institute of Art", id: 7265 },
  { code: "US", name: "Cleveland Institute of Music", id: 7266 },
  { code: "US", name: "Cleveland State University", id: 7267 },
  { code: "US", name: "Coastal Carolina University", id: 7268 },
  { code: "US", name: "Coe College", id: 7269 },
  { code: "US", name: "Cogswell Polytechnical College", id: 7270 },
  { code: "US", name: "Coker College", id: 7271 },
  { code: "US", name: "Colby College", id: 7272 },
  { code: "US", name: "Colby-Sawyer College", id: 7273 },
  { code: "US", name: "Coleman College", id: 7274 },
  { code: "US", name: "Colgate University", id: 7275 },
  { code: "US", name: "CollegeAmerica", id: 7276 },
  { code: "US", name: "College for Creative Studies", id: 7277 },
  { code: "US", name: "College for Financial Planning", id: 7278 },
  { code: "US", name: "College for Lifelong Learning", id: 7279 },
  { code: "US", name: "College Misericordia", id: 7280 },
  { code: "US", name: "College of Aeronautics", id: 7281 },
  { code: "US", name: "College of Charleston", id: 7282 },
  { code: "US", name: "College of DuPage", id: 7283 },
  { code: "US", name: "College of Idaho", id: 7284 },
  { code: "US", name: "College of Mount St. Joseph", id: 7285 },
  { code: "US", name: "College of Mount St. Vincent", id: 7286 },
  { code: "US", name: "College of New Rochelle", id: 7287 },
  { code: "US", name: "College of Notre Dame", id: 7288 },
  { code: "US", name: "College of Notre Dame of Maryland", id: 7289 },
  { code: "US", name: "College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)", id: 7290 },
  { code: "US", name: "College of St. Benedict and St. John's University", id: 7291 },
  { code: "US", name: "College of St. Catherine", id: 7292 },
  { code: "US", name: "College of St. Elizabeth", id: 7293 },
  { code: "US", name: "College of St. Joseph", id: 7294 },
  { code: "US", name: "College of St. Mary", id: 7295 },
  { code: "US", name: "College of St. Rose", id: 7296 },
  { code: "US", name: "College of St. Scholastica", id: 7297 },
  { code: "US", name: "College of the Atlantic", id: 7298 },
  { code: "US", name: "College of the Holy Cross", id: 7299 },
  { code: "US", name: "College of the Ozarks", id: 7300 },
  { code: "US", name: "College of Visual Arts", id: 7301 },
  { code: "US", name: "College of William and Mary", id: 7302 },
  { code: "US", name: "Colorado Christian University", id: 7303 },
  { code: "US", name: "Colorado College", id: 7304 },
  { code: "US", name: "Colorado School of Mines", id: 7305 },
  { code: "US", name: "Colorado State University", id: 7306 },
  { code: "US", name: "Colorado State University-Pueblo", id: 7307 },
  { code: "US", name: "Colorado Technical University", id: 7308 },
  { code: "US", name: "Columbia College Chicago", id: 7309 },
  { code: "US", name: "Columbia College Hollywood", id: 7310 },
  { code: "US", name: "Columbia College of Missouri", id: 7311 },
  { code: "US", name: "Columbia College South Carolina", id: 7312 },
  { code: "US", name: "Columbia Commonwealth University", id: 7313 },
  { code: "US", name: "Columbia International University", id: 7314 },
  { code: "US", name: "Columbia Southern University", id: 7315 },
  { code: "US", name: "Columbia Union College", id: 7316 },
  { code: "US", name: "Columbia University", id: 7317 },
  { code: "US", name: "Columbus College of Art and Design", id: 7318 },
  { code: "US", name: "Columbus State University", id: 7319 },
  { code: "US", name: "Columbus University", id: 7320 },
  { code: "US", name: "Community College of Denver", id: 7321 },
  { code: "US", name: "Concord College", id: 7322 },
  { code: "US", name: "Concordia College", id: 7323 },
  { code: "US", name: "Concordia University", id: 7324 },
  { code: "US", name: "Connecticut College", id: 7325 },
  { code: "US", name: "Converse College", id: 7326 },
  { code: "US", name: "Conway School of Landscape Design", id: 7327 },
  { code: "US", name: "Coppin State College", id: 7328 },
  { code: "US", name: "Cornell College", id: 7329 },
  { code: "US", name: "Cornell University", id: 7330 },
  { code: "US", name: "Cornish College of the Arts", id: 7331 },
  { code: "US", name: "Cosmopolitan University", id: 7332 },
  { code: "US", name: "Covenant College", id: 7333 },
  { code: "US", name: "Cranbrook Academy of Art", id: 7334 },
  { code: "US", name: "Creighton University", id: 7335 },
  { code: "US", name: "Crichton College", id: 7336 },
  { code: "US", name: "Crown College", id: 7337 },
  { code: "US", name: "Culver-Stockton College", id: 7338 },
  { code: "US", name: "Cumberland College", id: 7339 },
  { code: "US", name: "Cumberland University", id: 7340 },
  { code: "US", name: "Curry College", id: 7341 },
  { code: "US", name: "Daemen College", id: 7342 },
  { code: "US", name: "Dakota State University", id: 7343 },
  { code: "US", name: "Dakota Wesleyan University", id: 7344 },
  { code: "US", name: "Dallas Baptist University", id: 7345 },
  { code: "US", name: "Dallas Christian College", id: 7346 },
  { code: "US", name: "Dana College", id: 7347 },
  { code: "US", name: "Daniel Webster College", id: 7348 },
  { code: "US", name: "Danville Area Community College", id: 7349 },
  { code: "US", name: "Dartmouth College", id: 7350 },
  { code: "US", name: "Darton College", id: 7351 },
  { code: "US", name: "Davenport College of Business", id: 7352 },
  { code: "US", name: "Davidson College", id: 7353 },
  { code: "US", name: "Davis and Elkins College", id: 7354 },
  { code: "US", name: "Deaconess College of Nursing", id: 7355 },
  { code: "US", name: "Delaware State University", id: 7356 },
  { code: "US", name: "Delaware Valley College", id: 7357 },
  { code: "US", name: "Delta International University", id: 7358 },
  { code: "US", name: "Delta State University", id: 7359 },
  { code: "US", name: "Denison University", id: 7360 },
  { code: "US", name: "Denver Paralegal Institute", id: 7361 },
  { code: "US", name: "Denver Technical College", id: 7362 },
  { code: "US", name: "DePaul University", id: 7363 },
  { code: "US", name: "DePauw University", id: 7364 },
  { code: "US", name: "DeSales University", id: 7365 },
  { code: "US", name: "Design Institute of San Diego", id: 7366 },
  { code: "US", name: "Detroit College of Business", id: 7367 },
  { code: "US", name: "Detroit College of Business - Flint", id: 7368 },
  { code: "US", name: "Detroit College of Business - Warren", id: 7369 },
  { code: "US", name: "Detroit College of Law", id: 7370 },
  { code: "US", name: "DeVry Institute of Technology", id: 7371 },
  { code: "US", name: "Dickinson College", id: 7372 },
  { code: "US", name: "Dickinson State University", id: 7373 },
  { code: "US", name: "Dillard University", id: 7374 },
  { code: "US", name: "Divine Word College", id: 7375 },
  { code: "US", name: "Dixie College", id: 7376 },
  { code: "US", name: "Doane College", id: 7377 },
  { code: "US", name: "Dominican College", id: 7378 },
  { code: "US", name: "Dominican College of San Rafael", id: 7379 },
  { code: "US", name: "Dominican School of Philosophy and Theology", id: 7380 },
  { code: "US", name: "Dominican University", id: 7381 },
  { code: "US", name: "Dominion College", id: 7382 },
  { code: "US", name: "Dordt College", id: 7383 },
  { code: "US", name: "Dowling College", id: 7384 },
  { code: "US", name: "Drake University", id: 7385 },
  { code: "US", name: "Drew University", id: 7386 },
  { code: "US", name: "Drexel University", id: 7387 },
  { code: "US", name: "Drury College", id: 7388 },
  { code: "US", name: "Duke University", id: 7389 },
  { code: "US", name: "Duluth Business University", id: 7390 },
  { code: "US", name: "Duquesne University", id: 7391 },
  { code: "US", name: "D'Youville College", id: 7392 },
  { code: "US", name: "Earlham College", id: 7393 },
  { code: "US", name: "EarthNet Institute", id: 7394 },
  { code: "US", name: "East Carolina University", id: 7395 },
  { code: "US", name: "East Central University", id: 7396 },
  { code: "US", name: "Eastern College", id: 7397 },
  { code: "US", name: "Eastern Connecticut State University", id: 7398 },
  { code: "US", name: "Eastern Conservatory of Music", id: 7399 },
  { code: "US", name: "Eastern Illinois University", id: 7400 },
  { code: "US", name: "Eastern Kentucky University", id: 7401 },
  { code: "US", name: "Eastern Mennonite University", id: 7402 },
  { code: "US", name: "Eastern Michigan University", id: 7403 },
  { code: "US", name: "Eastern Nazarene College", id: 7404 },
  { code: "US", name: "Eastern New Mexico University", id: 7405 },
  { code: "US", name: "Eastern Oregon University", id: 7406 },
  { code: "US", name: "Eastern Virginia Medical School", id: 7407 },
  { code: "US", name: "Eastern Washington University", id: 7408 },
  { code: "US", name: "East Stroudsburg State University", id: 7409 },
  { code: "US", name: "East Tennessee State University", id: 7410 },
  { code: "US", name: "East Texas Baptist University", id: 7411 },
  { code: "US", name: "East-West University", id: 7412 },
  { code: "US", name: "Eckerd College", id: 7413 },
  { code: "US", name: "Edgewood College", id: 7414 },
  { code: "US", name: "Edinboro University of Pennsylvania", id: 7415 },
  { code: "US", name: "Edison Community College", id: 7416 },
  { code: "US", name: "Edward Waters College", id: 7417 },
  { code: "US", name: "Elizabeth City State University", id: 7418 },
  { code: "US", name: "Elizabethtown College", id: 7419 },
  { code: "US", name: "Elmhurst College", id: 7420 },
  { code: "US", name: "Elmira College", id: 7421 },
  { code: "US", name: "Elms College", id: 7422 },
  { code: "US", name: "Elon College", id: 7423 },
  { code: "US", name: "Embry-Riddle Aeronautical University", id: 7424 },
  { code: "US", name: "Embry Riddle Aeronautical University", id: 7425 },
  { code: "US", name: "Emerson College", id: 7426 },
  { code: "US", name: "Emmanuel College", id: 7427 },
  { code: "US", name: "Emmanuel College Georgia", id: 7428 },
  { code: "US", name: "Emmaus Bible College", id: 7429 },
  { code: "US", name: "Emory & Henry College", id: 7430 },
  { code: "US", name: "Emory University", id: 7431 },
  { code: "US", name: "Emporia State University", id: 7432 },
  { code: "US", name: "Erskine College", id: 7433 },
  { code: "US", name: "Eugene Bible College", id: 7434 },
  { code: "US", name: "Eureka College", id: 7435 },
  { code: "US", name: "Evangel University", id: 7436 },
  { code: "US", name: "Evergreen State College", id: 7437 },
  { code: "US", name: "Excel College", id: 7438 },
  { code: "US", name: "Excelsior College", id: 7439 },
  { code: "US", name: "Fairfield University", id: 7440 },
  { code: "US", name: "Fairleigh Dickinson University", id: 7441 },
  { code: "US", name: "Fairmont State College", id: 7442 },
  { code: "US", name: "Fashion Institute of New York", id: 7443 },
  { code: "US", name: "Faulkner University", id: 7444 },
  { code: "US", name: "Fayetteville State University", id: 7445 },
  { code: "US", name: "Felician College", id: 7446 },
  { code: "US", name: "Ferris State University", id: 7447 },
  { code: "US", name: "Ferrum College", id: 7448 },
  { code: "US", name: "Fielding Institute", id: 7449 },
  { code: "US", name: "Finch University of Health Sciences - The Chicago Medical School", id: 7450 },
  { code: "US", name: "Finlandia University", id: 7451 },
  { code: "US", name: "Fisher College", id: 7452 },
  { code: "US", name: "Fisk University", id: 7453 },
  { code: "US", name: "Fitchburg State College", id: 7454 },
  { code: "US", name: "Flagler College", id: 7455 },
  { code: "US", name: "Florida Agricultural and Mechanical University", id: 7456 },
  { code: "US", name: "Florida Atlantic University", id: 7457 },
  { code: "US", name: "Florida Christian College", id: 7458 },
  { code: "US", name: "Florida Community College at Jacksonville", id: 7459 },
  { code: "US", name: "Florida Gulf Coast University", id: 7460 },
  { code: "US", name: "Florida Institute of Technology", id: 7461 },
  { code: "US", name: "Florida International University", id: 7462 },
  { code: "US", name: "Florida Memorial College", id: 7463 },
  { code: "US", name: "Florida Metropolitan University", id: 7464 },
  { code: "US", name: "Florida Southern College", id: 7465 },
  { code: "US", name: "Florida State University", id: 7466 },
  { code: "US", name: "Florida University of Medicine", id: 7467 },
  { code: "US", name: "Fontbonne College", id: 7468 },
  { code: "US", name: "Foothill-De Anza Community College District", id: 7469 },
  { code: "US", name: "Fordham University", id: 7470 },
  { code: "US", name: "Forest Institute of Professional Psychology", id: 7471 },
  { code: "US", name: "Fort Hays State University", id: 7472 },
  { code: "US", name: "Fort Lewis College", id: 7473 },
  { code: "US", name: "Fort Valley State College", id: 7474 },
  { code: "US", name: "Framingham State College", id: 7475 },
  { code: "US", name: "Franciscan School of Theology", id: 7476 },
  { code: "US", name: "Franciscan University of Steubenville", id: 7477 },
  { code: "US", name: "Francis Marion University", id: 7478 },
  { code: "US", name: "Franklin and Marshall College", id: 7479 },
  { code: "US", name: "Franklin College", id: 7480 },
  { code: "US", name: "Franklin Pierce College", id: 7481 },
  { code: "US", name: "Franklin Pierce Law Center", id: 7482 },
  { code: "US", name: "Franklin University", id: 7483 },
  { code: "US", name: "Franklin W. Olin College of Engineering", id: 7484 },
  { code: "US", name: "Frank Lloyd Wright School of Architecture", id: 7485 },
  { code: "US", name: "Fred Hutchinson Cancer Research Center", id: 7486 },
  { code: "US", name: "Freed-Hardeman University", id: 7487 },
  { code: "US", name: "Free University (Virginia Theological University)", id: 7488 },
  { code: "US", name: "Free Will Baptist Bible College", id: 7489 },
  { code: "US", name: "Fresno City College", id: 7490 },
  { code: "US", name: "Fresno Pacific University", id: 7491 },
  { code: "US", name: "Friends University", id: 7492 },
  { code: "US", name: "Frostburg State University", id: 7493 },
  { code: "US", name: "Full Sail University", id: 7494 },
  { code: "US", name: "Furman University", id: 7495 },
  { code: "US", name: "Gallaudet University", id: 7496 },
  { code: "US", name: "Gannon University", id: 7497 },
  { code: "US", name: "Gardner Webb University", id: 7498 },
  { code: "US", name: "Gemological Institute of America", id: 7499 },
  { code: "US", name: "Geneva College", id: 7500 },
  { code: "US", name: "George Fox University", id: 7501 },
  { code: "US", name: "George Mason University", id: 7502 },
  { code: "US", name: "Georgetown College", id: 7503 },
  { code: "US", name: "Georgetown University", id: 7504 },
  { code: "US", name: "George Washington University", id: 7505 },
  { code: "US", name: "George Wythe College", id: 7506 },
  { code: "US", name: "Georgia Baptist College of Nursing", id: 7507 },
  { code: "US", name: "Georgia College & State University", id: 7508 },
  { code: "US", name: "Georgia Health Sciences University", id: 7509 },
  { code: "US", name: "Georgia Institute of Technology", id: 7510 },
  { code: "US", name: "Georgian Court College", id: 7511 },
  { code: "US", name: "Georgia School of Professional Psychology", id: 7512 },
  { code: "US", name: "Georgia Southern University", id: 7513 },
  { code: "US", name: "Georgia Southwestern University", id: 7514 },
  { code: "US", name: "Georgia State University", id: 7515 },
  { code: "US", name: "Gettysburg College", id: 7516 },
  { code: "US", name: "Glendale University College of Law", id: 7517 },
  { code: "US", name: "Glenville State College", id: 7518 },
  { code: "US", name: "Goddard College", id: 7519 },
  { code: "US", name: "God's Bible School and College", id: 7520 },
  { code: "US", name: "Golden Gate University", id: 7521 },
  { code: "US", name: "Goldey-Beacom College", id: 7522 },
  { code: "US", name: "Gonzaga University", id: 7523 },
  { code: "US", name: "Gordon College", id: 7524 },
  { code: "US", name: "Gordon Conwell Theological Seminary", id: 7525 },
  { code: "US", name: "Goshen College", id: 7526 },
  { code: "US", name: "Goucher College", id: 7527 },
  { code: "US", name: "Governors State University", id: 7528 },
  { code: "US", name: "Grace Bible College", id: 7529 },
  { code: "US", name: "Grace College", id: 7530 },
  { code: "US", name: "Graceland College", id: 7531 },
  { code: "US", name: "Grace University", id: 7532 },
  { code: "US", name: "Graduate Theological Union", id: 7533 },
  { code: "US", name: "Grambling State University", id: 7534 },
  { code: "US", name: "Grand Canyon University", id: 7535 },
  { code: "US", name: "Grand Valley State University", id: 7536 },
  { code: "US", name: "Grand View College", id: 7537 },
  { code: "US", name: "Grantham University", id: 7538 },
  { code: "US", name: "Gratz College", id: 7539 },
  { code: "US", name: "Great Lakes Christian College", id: 7540 },
  { code: "US", name: "Green Mountain College", id: 7541 },
  { code: "US", name: "Greensboro College", id: 7542 },
  { code: "US", name: "Greenville College", id: 7543 },
  { code: "US", name: "Grinnell College", id: 7544 },
  { code: "US", name: "Grove City College", id: 7545 },
  { code: "US", name: "Guilford College", id: 7546 },
  { code: "US", name: "Gustavus Adolphus College", id: 7547 },
  { code: "US", name: "Gwynedd-Mercy College", id: 7548 },
  { code: "US", name: "Hagerstown Community College", id: 7549 },
  { code: "US", name: "Hamilton College", id: 7550 },
  { code: "US", name: "Hamilton Technical College", id: 7551 },
  { code: "US", name: "Hamline University", id: 7552 },
  { code: "US", name: "Hampden-Sydney College", id: 7553 },
  { code: "US", name: "Hampshire College", id: 7554 },
  { code: "US", name: "Hampton College", id: 7555 },
  { code: "US", name: "Hampton University", id: 7556 },
  { code: "US", name: "Hannibal-Lagrange College", id: 7557 },
  { code: "US", name: "Hanover College", id: 7558 },
  { code: "US", name: "Harding University", id: 7559 },
  { code: "US", name: "Harding University Graduate School of Religion", id: 7560 },
  { code: "US", name: "Hardin-Simmons University", id: 7561 },
  { code: "US", name: "Harrington Institute of Interior Design", id: 7562 },
  { code: "US", name: "Harris-Stowe State University", id: 7563 },
  { code: "US", name: "Hartford College for Women", id: 7564 },
  { code: "US", name: "Hartford Graduate Center (Rensselaer at Hartford)", id: 7565 },
  { code: "US", name: "Hartwick College", id: 7566 },
  { code: "US", name: "Harvard University", id: 7567 },
  { code: "US", name: "Harvey Mudd College", id: 7568 },
  { code: "US", name: "Hastings College", id: 7569 },
  { code: "US", name: "Haverford College", id: 7570 },
  { code: "US", name: "Hawaii Pacific University", id: 7571 },
  { code: "US", name: "Heartland Baptist Bible College", id: 7572 },
  { code: "US", name: "Hebrew College", id: 7573 },
  { code: "US", name: "Heidelberg College", id: 7574 },
  { code: "US", name: "Henderson State Univerisity", id: 7575 },
  { code: "US", name: "Hendrix College", id: 7576 },
  { code: "US", name: "Heritage College", id: 7577 },
  { code: "US", name: "Hesser College", id: 7578 },
  { code: "US", name: "High Point University", id: 7579 },
  { code: "US", name: "Hilbert College", id: 7580 },
  { code: "US", name: "Hillsdale College", id: 7581 },
  { code: "US", name: "Hiram College", id: 7582 },
  { code: "US", name: "Hobart and William Smith Colleges", id: 7583 },
  { code: "US", name: "Hobe Sound Bible College", id: 7584 },
  { code: "US", name: "Hodges University", id: 7585 },
  { code: "US", name: "Hofstra University", id: 7586 },
  { code: "US", name: "Hollins University", id: 7587 },
  { code: "US", name: "Holy Apostles College", id: 7588 },
  { code: "US", name: "Holy Cross College", id: 7589 },
  { code: "US", name: "Holy Family College", id: 7590 },
  { code: "US", name: "Holy Names College", id: 7591 },
  { code: "US", name: "Hood College", id: 7592 },
  { code: "US", name: "Hope College", id: 7593 },
  { code: "US", name: "Hope International University", id: 7594 },
  { code: "US", name: "Houghton College", id: 7595 },
  { code: "US", name: "Houston Baptist University", id: 7596 },
  { code: "US", name: "Howard Payne University", id: 7597 },
  { code: "US", name: "Howard University", id: 7598 },
  { code: "US", name: "Humboldt State University", id: 7599 },
  { code: "US", name: "Humphreys College", id: 7600 },
  { code: "US", name: "Huntington College", id: 7601 },
  { code: "US", name: "Huron University", id: 7602 },
  { code: "US", name: "Husson College", id: 7603 },
  { code: "US", name: "Huston-Tillotson College", id: 7604 },
  { code: "US", name: "ICI University", id: 7605 },
  { code: "US", name: "ICT College", id: 7606 },
  { code: "US", name: "Idaho State University", id: 7607 },
  { code: "US", name: "Iglobal University", id: 7608 },
  { code: "US", name: "Illinois Benedictine University", id: 7609 },
  { code: "US", name: "Illinois College", id: 7610 },
  { code: "US", name: "Illinois College of Optometry", id: 7611 },
  { code: "US", name: "Illinois Institute of Technology", id: 7612 },
  { code: "US", name: "Illinois School of Professional Psychology - Chicago Campus", id: 7613 },
  { code: "US", name: "Illinois School of Professional Psychology - Meadows Campus", id: 7614 },
  { code: "US", name: "Illinois State University", id: 7615 },
  { code: "US", name: "Illinois Valley Community College", id: 7616 },
  { code: "US", name: "Illinois Wesleyan University", id: 7617 },
  { code: "US", name: "Immaculata University", id: 7618 },
  { code: "US", name: "IMPAC University", id: 7619 },
  { code: "US", name: "Indiana Institute of Technologyy", id: 7620 },
  { code: "US", name: "Indiana State University", id: 7621 },
  { code: "US", name: "Indiana University at Bloomington", id: 7622 },
  { code: "US", name: "Indiana University at Kokomo", id: 7623 },
  { code: "US", name: "Indiana University at South Bend", id: 7624 },
  { code: "US", name: "Indiana University - East", id: 7625 },
  { code: "US", name: "Indiana University - Northwest", id: 7626 },
  { code: "US", name: "Indiana University of Pennsylvania", id: 7627 },
  { code: "US", name: "Indiana University-Purdue University at Columbus", id: 7628 },
  { code: "US", name: "Indiana University-Purdue University at Fort Wayne", id: 7629 },
  { code: "US", name: "Indiana University-Purdue University at Indianapolis", id: 7630 },
  { code: "US", name: "Indiana University - Southeast", id: 7631 },
  { code: "US", name: "Indiana University (System)", id: 7632 },
  { code: "US", name: "Indiana Wesleyan University", id: 7633 },
  { code: "US", name: "Institute of Clinical Social Work", id: 7634 },
  { code: "US", name: "Institute of Paper Science and Technology", id: 7635 },
  { code: "US", name: "Institute of Textile Technology", id: 7636 },
  { code: "US", name: "Institute of Transpersonal Psychology", id: 7637 },
  { code: "US", name: "IntelliTec College - Grand Junction", id: 7638 },
  { code: "US", name: "International Academy of  Merchandising and Design Chicago", id: 7639 },
  { code: "US", name: "International Academy of  Merchandising and Design Tampa", id: 7640 },
  { code: "US", name: "International Bible College", id: 7641 },
  { code: "US", name: "International College", id: 7642 },
  { code: "US", name: "Iona College", id: 7643 },
  { code: "US", name: "Iowa State University of Science and Technology", id: 7644 },
  { code: "US", name: "Iowa Wesleyan College", id: 7645 },
  { code: "US", name: "Ithaca College", id: 7646 },
  { code: "US", name: "ITT Technical Institute Fort Wayne", id: 7647 },
  { code: "US", name: "ITT Technical Institute Indianapolis", id: 7648 },
  { code: "US", name: "ITT Technical Institute Maitland", id: 7649 },
  { code: "US", name: "ITT Technical Institute Portland", id: 7650 },
  { code: "US", name: "ITT Technical Institute West Covina", id: 7651 },
  { code: "US", name: "Jackson State University", id: 7652 },
  { code: "US", name: "Jacksonville State University", id: 7653 },
  { code: "US", name: "Jacksonville University", id: 7654 },
  { code: "US", name: "James Madison University", id: 7655 },
  { code: "US", name: "Jamestown College", id: 7656 },
  { code: "US", name: "Jarvis Christian College", id: 7657 },
  { code: "US", name: "John Brown University", id: 7658 },
  { code: "US", name: "John Carroll University", id: 7659 },
  { code: "US", name: "John F. Kennedy University", id: 7660 },
  { code: "US", name: "John Marshall Law School", id: 7661 },
  { code: "US", name: "John Paul the Great Catholic University", id: 7662 },
  { code: "US", name: "Johns Hopkins University", id: 7663 },
  { code: "US", name: "Johnson Bible College", id: 7664 },
  { code: "US", name: "Johnson County Community College", id: 7665 },
  { code: "US", name: "Johnson C. Smith University", id: 7666 },
  { code: "US", name: "Johnson State College", id: 7667 },
  { code: "US", name: "Johnson & Wales University", id: 7668 },
  { code: "US", name: "John Wesley College", id: 7669 },
  { code: "US", name: "Jones College", id: 7670 },
  { code: "US", name: "Jones International University", id: 7671 },
  { code: "US", name: "Judson College Elgin", id: 7672 },
  { code: "US", name: "Judson College Marion", id: 7673 },
  { code: "US", name: "Juniata College", id: 7674 },
  { code: "US", name: "Kalamazoo College", id: 7675 },
  { code: "US", name: "Kankakee Community College", id: 7676 },
  { code: "US", name: "Kansas City Art Institute", id: 7677 },
  { code: "US", name: "Kansas State University", id: 7678 },
  { code: "US", name: "Kansas Wesleyan University", id: 7679 },
  { code: "US", name: "Kaplan University", id: 7680 },
  { code: "US", name: "Katharine Gibbs School", id: 7681 },
  { code: "US", name: "Kean University of New Jersey", id: 7682 },
  { code: "US", name: "Keck Graduate Institute of Applied Life Sciences", id: 7683 },
  { code: "US", name: "Keene State College", id: 7684 },
  { code: "US", name: "Keller Graduate School of Management", id: 7685 },
  { code: "US", name: "Kendall College", id: 7686 },
  { code: "US", name: "Kendall College of Art and Design", id: 7687 },
  { code: "US", name: "Kennesaw State University", id: 7688 },
  { code: "US", name: "Kent State University", id: 7689 },
  { code: "US", name: "Kent State University - Ashtabula", id: 7690 },
  { code: "US", name: "Kent State University - East Liverpool", id: 7691 },
  { code: "US", name: "Kent State University - Salem", id: 7692 },
  { code: "US", name: "Kent State University - Stark", id: 7693 },
  { code: "US", name: "Kent State University - Trumbull", id: 7694 },
  { code: "US", name: "Kent State University - Tuscarawas", id: 7695 },
  { code: "US", name: "Kentucky Christian College", id: 7696 },
  { code: "US", name: "Kentucky State University", id: 7697 },
  { code: "US", name: "Kentucky Wesleyan College", id: 7698 },
  { code: "US", name: "Kenyon College", id: 7699 },
  { code: "US", name: "Kettering University (GMI)", id: 7700 },
  { code: "US", name: "Keuka College", id: 7701 },
  { code: "US", name: "King College", id: 7702 },
  { code: "US", name: "King's College", id: 7703 },
  { code: "US", name: "Kirksville College of Osteopathic Medicine", id: 7704 },
  { code: "US", name: "Kirkwood Community College", id: 7705 },
  { code: "US", name: "Knox College", id: 7706 },
  { code: "US", name: "Knoxville College", id: 7707 },
  { code: "US", name: "Kutztown University of Pennsylvania", id: 7708 },
  { code: "US", name: "Laboratory Institute of Merchandising", id: 7709 },
  { code: "US", name: "Lafayette College", id: 7710 },
  { code: "US", name: "Lagrange College", id: 7711 },
  { code: "US", name: "Lake Erie College", id: 7712 },
  { code: "US", name: "Lake Forest College", id: 7713 },
  { code: "US", name: "Lake Forest Graduate School of Management", id: 7714 },
  { code: "US", name: "Lakeland College", id: 7715 },
  { code: "US", name: "Lake Superior State University", id: 7716 },
  { code: "US", name: "Lakeview College of Nursing", id: 7717 },
  { code: "US", name: "Lamar University", id: 7718 },
  { code: "US", name: "Lamar University - Port Arthur", id: 7719 },
  { code: "US", name: "Lambuth University", id: 7720 },
  { code: "US", name: "Lancaster Bible College", id: 7721 },
  { code: "US", name: "Lander University", id: 7722 },
  { code: "US", name: "Lane College", id: 7723 },
  { code: "US", name: "Langston University", id: 7724 },
  { code: "US", name: "La Roche College", id: 7725 },
  { code: "US", name: "La Salle University", id: 7726 },
  { code: "US", name: "Lasell College", id: 7727 },
  { code: "US", name: "La Sierra University", id: 7728 },
  { code: "US", name: "Laurus Technical Institute", id: 7729 },
  { code: "US", name: "Lawrence Technological University", id: 7730 },
  { code: "US", name: "Lawrence University", id: 7731 },
  { code: "US", name: "Lebanon Valley College", id: 7732 },
  { code: "US", name: "Lees-McRae College", id: 7733 },
  { code: "US", name: "Lee University", id: 7734 },
  { code: "US", name: "Lehigh Univervsity", id: 7735 },
  { code: "US", name: "Le Moyne College", id: 7736 },
  { code: "US", name: "Le Moyne-Owen College", id: 7737 },
  { code: "US", name: "Lenoir-Rhyne College", id: 7738 },
  { code: "US", name: "Lesley University", id: 7739 },
  { code: "US", name: "Letourneau University", id: 7740 },
  { code: "US", name: "Lewis and Clark College", id: 7741 },
  { code: "US", name: "Lewis & Clark Community College", id: 7742 },
  { code: "US", name: "Lewis-Clark State College", id: 7743 },
  { code: "US", name: "Lewis University", id: 7744 },
  { code: "US", name: "Liberty University", id: 7745 },
  { code: "US", name: "Life Chiropractic College West", id: 7746 },
  { code: "US", name: "Life University", id: 7747 },
  { code: "US", name: "Limestone College", id: 7748 },
  { code: "US", name: "Lincoln Memorial University", id: 7749 },
  { code: "US", name: "Lincoln University Missouri", id: 7750 },
  { code: "US", name: "Lincoln University Pennsylvania", id: 7751 },
  { code: "US", name: "Lincoln University San Francisco", id: 7752 },
  { code: "US", name: "Lindenwood University", id: 7753 },
  { code: "US", name: "Lindsey Wilson College", id: 7754 },
  { code: "US", name: "Linfield College", id: 7755 },
  { code: "US", name: "Lipscomb University", id: 7756 },
  { code: "US", name: "Livingstone College", id: 7757 },
  { code: "US", name: "Lock Haven University of Pennsylvania", id: 7758 },
  { code: "US", name: "Logan College of Chiropractic", id: 7759 },
  { code: "US", name: "Loma Linda University", id: 7760 },
  { code: "US", name: "Long Island University", id: 7761 },
  { code: "US", name: "Longwood College", id: 7762 },
  { code: "US", name: "Loras College", id: 7763 },
  { code: "US", name: "Los Angeles College of Chiropractic", id: 7764 },
  { code: "US", name: "Louisiana Baptist University", id: 7765 },
  { code: "US", name: "Louisiana College", id: 7766 },
  { code: "US", name: "Louisiana State University and Agricultural and Mechanical College", id: 7767 },
  { code: "US", name: "Louisiana State University at Alexandria", id: 7768 },
  { code: "US", name: "Louisiana State University at Eunice", id: 7769 },
  { code: "US", name: "Louisiana State University Health Sciences Center New Orleans", id: 7770 },
  { code: "US", name: "Louisiana State University in Shreveport", id: 7771 },
  { code: "US", name: "Louisiana Tech University", id: 7772 },
  { code: "US", name: "Lourdes College", id: 7773 },
  { code: "US", name: "Loyola College in Maryland", id: 7774 },
  { code: "US", name: "Loyola Marymount University", id: 7775 },
  { code: "US", name: "Loyola University New Orleans", id: 7776 },
  { code: "US", name: "Loyola University of Chicago", id: 7777 },
  { code: "US", name: "Lubbock Christian University", id: 7778 },
  { code: "US", name: "Lutheran Bible Institute of Seattle", id: 7779 },
  { code: "US", name: "Luther College", id: 7780 },
  { code: "US", name: "Lycoming College", id: 7781 },
  { code: "US", name: "Lynchburg College", id: 7782 },
  { code: "US", name: "Lyndon State College", id: 7783 },
  { code: "US", name: "Lynn University", id: 7784 },
  { code: "US", name: "Lyon College", id: 7785 },
  { code: "US", name: "Macalester College", id: 7786 },
  { code: "US", name: "MacMurray College", id: 7787 },
  { code: "US", name: "Macon State College", id: 7788 },
  { code: "US", name: "Madison University (Distance Education)", id: 7789 },
  { code: "US", name: "Madonna University", id: 7790 },
  { code: "US", name: "Maharishi University of Management", id: 7791 },
  { code: "US", name: "Maine College of Art", id: 7792 },
  { code: "US", name: "Maine Maritime Academy", id: 7793 },
  { code: "US", name: "Malone College", id: 7794 },
  { code: "US", name: "Manchester College", id: 7795 },
  { code: "US", name: "Manhattan Christian College", id: 7796 },
  { code: "US", name: "Manhattan College", id: 7797 },
  { code: "US", name: "Manhattan School of Music", id: 7798 },
  { code: "US", name: "Manhattanville College", id: 7799 },
  { code: "US", name: "Mankato State University", id: 7800 },
  { code: "US", name: "Mansfield University of Pennsylvania", id: 7801 },
  { code: "US", name: "Maranatha Baptist Bible College", id: 7802 },
  { code: "US", name: "Marian College", id: 7803 },
  { code: "US", name: "Marian College of Fond Du Lac", id: 7804 },
  { code: "US", name: "Marietta College", id: 7805 },
  { code: "US", name: "Marist College", id: 7806 },
  { code: "US", name: "Marlboro College", id: 7807 },
  { code: "US", name: "Marquette University", id: 7808 },
  { code: "US", name: "Marshall University", id: 7809 },
  { code: "US", name: "Mars Hill College", id: 7810 },
  { code: "US", name: "Martin Luther College", id: 7811 },
  { code: "US", name: "Martin Methodist College", id: 7812 },
  { code: "US", name: "Martin University", id: 7813 },
  { code: "US", name: "Mary Baldwin College", id: 7814 },
  { code: "US", name: "Marycrest International University", id: 7815 },
  { code: "US", name: "Marygrove College", id: 7816 },
  { code: "US", name: "Marylhurst University", id: 7817 },
  { code: "US", name: "Marymount College New York", id: 7818 },
  { code: "US", name: "Marymount Manhattan College", id: 7819 },
  { code: "US", name: "Marymount University", id: 7820 },
  { code: "US", name: "Maryville College", id: 7821 },
  { code: "US", name: "Maryville University of St. Louis", id: 7822 },
  { code: "US", name: "Mary Washington College", id: 7823 },
  { code: "US", name: "Marywood University", id: 7824 },
  { code: "US", name: "Massachusetts College of Art", id: 7825 },
  { code: "US", name: "Massachusetts College of Liberal Arts", id: 7826 },
  { code: "US", name: "Massachusetts College of Pharmacy and Allied Health Sciences", id: 7827 },
  { code: "US", name: "Massachusetts Institute of Technology", id: 7828 },
  { code: "US", name: "Massachusetts Maritime Academy", id: 7829 },
  { code: "US", name: "Massachusetts School of Professional Psychology", id: 7830 },
  { code: "US", name: "Mayo Graduate School", id: 7831 },
  { code: "US", name: "Mayo Medical School", id: 7832 },
  { code: "US", name: "Mayville State University", id: 7833 },
  { code: "US", name: "McKendree College", id: 7834 },
  { code: "US", name: "McMurry University", id: 7835 },
  { code: "US", name: "McNeese State University", id: 7836 },
  { code: "US", name: "McPherson College", id: 7837 },
  { code: "US", name: "Medaille College", id: 7838 },
  { code: "US", name: "Medcenter One College of Nursing", id: 7839 },
  { code: "US", name: "Medical College of Georgia", id: 7840 },
  { code: "US", name: "Medical College of Ohio", id: 7841 },
  { code: "US", name: "Medical College of Pennsylvania and Hahnemann University", id: 7842 },
  { code: "US", name: "Medical College of Wisconsin", id: 7843 },
  { code: "US", name: "Medical University of South Carolina", id: 7844 },
  { code: "US", name: "Meharry Medical College", id: 7845 },
  { code: "US", name: "Memphis College of Art", id: 7846 },
  { code: "US", name: "Menlo College", id: 7847 },
  { code: "US", name: "Mennonite College of Nursing", id: 7848 },
  { code: "US", name: "Mercer University", id: 7849 },
  { code: "US", name: "Mercy College", id: 7850 },
  { code: "US", name: "Mercyhurst College", id: 7851 },
  { code: "US", name: "Meredith College", id: 7852 },
  { code: "US", name: "Merrimack College", id: 7853 },
  { code: "US", name: "Mesa State College", id: 7854 },
  { code: "US", name: "Messiah College", id: 7855 },
  { code: "US", name: "Methodist College", id: 7856 },
  { code: "US", name: "Metropolitan State College of Denver", id: 7857 },
  { code: "US", name: "Metropolitan State University", id: 7858 },
  { code: "US", name: "MGH Institute of Health Professions", id: 7859 },
  { code: "US", name: "Miami Dade College", id: 7860 },
  { code: "US", name: "Miami University of Ohio", id: 7861 },
  { code: "US", name: "Miami University of Ohio - Hamilton", id: 7862 },
  { code: "US", name: "Miami University of Ohio - Middletown", id: 7863 },
  { code: "US", name: "Michigan School of Professional Psychology", id: 7864 },
  { code: "US", name: "Michigan State University", id: 7865 },
  { code: "US", name: "Michigan Technological University", id: 7866 },
  { code: "US", name: "Mid-America Nazarene University", id: 7867 },
  { code: "US", name: "Mid-American Bible College", id: 7868 },
  { code: "US", name: "Mid-Continent Baptist Bible College", id: 7869 },
  { code: "US", name: "Middlebury College", id: 7870 },
  { code: "US", name: "Middle Tennessee State University", id: 7871 },
  { code: "US", name: "Midland Lutheran College", id: 7872 },
  { code: "US", name: "Midway College", id: 7873 },
  { code: "US", name: "Midwestern State University", id: 7874 },
  { code: "US", name: "Midwestern University", id: 7875 },
  { code: "US", name: "Miles College", id: 7876 },
  { code: "US", name: "Millennia Atlantic University", id: 7877 },
  { code: "US", name: "Millersville University of Pennsylvania", id: 7878 },
  { code: "US", name: "Milligan College", id: 7879 },
  { code: "US", name: "Millikin University", id: 7880 },
  { code: "US", name: "Millsaps College", id: 7881 },
  { code: "US", name: "Mills College", id: 7882 },
  { code: "US", name: "Mills Grae University", id: 7883 },
  { code: "US", name: "Milwaukee Institute of Art and Design", id: 7884 },
  { code: "US", name: "Milwaukee School of Engineering", id: 7885 },
  { code: "US", name: "Minneapolis College of Art and Design", id: 7886 },
  { code: "US", name: "Minnesota Bible College", id: 7887 },
  { code: "US", name: "Minnesota School of Professional Psychology", id: 7888 },
  { code: "US", name: "Minot State University", id: 7889 },
  { code: "US", name: "Mississippi College", id: 7890 },
  { code: "US", name: "Mississippi State University", id: 7891 },
  { code: "US", name: "Mississippi University for Women", id: 7892 },
  { code: "US", name: "Mississippi Valley State University", id: 7893 },
  { code: "US", name: "Missouri Baptist College", id: 7894 },
  { code: "US", name: "Missouri Southern State College", id: 7895 },
  { code: "US", name: "Missouri Tech", id: 7896 },
  { code: "US", name: "Missouri University of Science and Technology", id: 7897 },
  { code: "US", name: "Missouri Valley College", id: 7898 },
  { code: "US", name: "Missouri Western State College", id: 7899 },
  { code: "US", name: "Molloy College", id: 7900 },
  { code: "US", name: "Monmouth University", id: 7901 },
  { code: "US", name: "Montana State University", id: 7902 },
  { code: "US", name: "Montana State University - Billings", id: 7903 },
  { code: "US", name: "Montana State University - Northern", id: 7904 },
  { code: "US", name: "Montana Tech", id: 7905 },
  { code: "US", name: "Montclair State University", id: 7906 },
  { code: "US", name: "Monterey Institute of International Studies", id: 7907 },
  { code: "US", name: "Montreat College", id: 7908 },
  { code: "US", name: "Montserrat College of Art", id: 7909 },
  { code: "US", name: "Moody Bible Institute", id: 7910 },
  { code: "US", name: "Moore College of Art and Design", id: 7911 },
  { code: "US", name: "Moorhead State University", id: 7912 },
  { code: "US", name: "Moraine Valley Community College", id: 7913 },
  { code: "US", name: "Moravian College", id: 7914 },
  { code: "US", name: "Morehead State University", id: 7915 },
  { code: "US", name: "Morehouse College", id: 7916 },
  { code: "US", name: "Morehouse School of Medicine", id: 7917 },
  { code: "US", name: "Morgan State University", id: 7918 },
  { code: "US", name: "Morningside College", id: 7919 },
  { code: "US", name: "Morris Brown College", id: 7920 },
  { code: "US", name: "Morris College", id: 7921 },
  { code: "US", name: "Morrison College", id: 7922 },
  { code: "US", name: "Mountain State University", id: 7923 },
  { code: "US", name: "Mount Aloysius College", id: 7924 },
  { code: "US", name: "Mount Carmel College of Nursing", id: 7925 },
  { code: "US", name: "Mount Holyoke College", id: 7926 },
  { code: "US", name: "Mount Ida College", id: 7927 },
  { code: "US", name: "Mount Marty College", id: 7928 },
  { code: "US", name: "Mount Mary College", id: 7929 },
  { code: "US", name: "Mount Mercy College", id: 7930 },
  { code: "US", name: "Mount Olive College", id: 7931 },
  { code: "US", name: "Mount Senario College", id: 7932 },
  { code: "US", name: "Mount Sinai School of Medicine", id: 7933 },
  { code: "US", name: "Mount St. Clare College", id: 7934 },
  { code: "US", name: "Mount St. Mary College", id: 7935 },
  { code: "US", name: "Mount St. Mary's College California", id: 7936 },
  { code: "US", name: "Mount St. Mary's College Maryland", id: 7937 },
  { code: "US", name: "Mount Union College", id: 7938 },
  { code: "US", name: "Mount Vernon College", id: 7939 },
  { code: "US", name: "Mount Vernon Nazarene College", id: 7940 },
  { code: "US", name: "Muhlenberg College", id: 7941 },
  { code: "US", name: "Multnomah Bible College", id: 7942 },
  { code: "US", name: "Murray State University", id: 7943 },
  { code: "US", name: "Muskingum College", id: 7944 },
  { code: "US", name: "NAES College", id: 7945 },
  { code: "US", name: "National American University", id: 7946 },
  { code: "US", name: "National College of Chiropractic", id: 7947 },
  { code: "US", name: "National College of Naturopathic Medicine", id: 7948 },
  { code: "US", name: "National Defense University", id: 7949 },
  { code: "US", name: "National Hispanic University", id: 7950 },
  { code: "US", name: "National-Louis University", id: 7951 },
  { code: "US", name: "National Technological University", id: 7952 },
  { code: "US", name: "National Theatre Conservatory", id: 7953 },
  { code: "US", name: "National University", id: 7954 },
  { code: "US", name: "Naval Postgraduate School", id: 7955 },
  { code: "US", name: "Nazarene Bible College", id: 7956 },
  { code: "US", name: "Nazareth College", id: 7957 },
  { code: "US", name: "Nebraska Christian College", id: 7958 },
  { code: "US", name: "Nebraska Methodist College of Nursing and Allied Health", id: 7959 },
  { code: "US", name: "Nebraska Wesleyan University", id: 7960 },
  { code: "US", name: "Neumann College", id: 7961 },
  { code: "US", name: "Newberry College", id: 7962 },
  { code: "US", name: "New College of California", id: 7963 },
  { code: "US", name: "New College of Florida", id: 7964 },
  { code: "US", name: "New England College", id: 7965 },
  { code: "US", name: "New England College of Optometry", id: 7966 },
  { code: "US", name: "New England Conservatory of Music", id: 7967 },
  { code: "US", name: "New England Institute of Technology", id: 7968 },
  { code: "US", name: "New England School of Art and Design", id: 7969 },
  { code: "US", name: "New England School of Communications", id: 7970 },
  { code: "US", name: "New England School of Law", id: 7971 },
  { code: "US", name: "New Hampshire College", id: 7972 },
  { code: "US", name: "New Jersey City University", id: 7973 },
  { code: "US", name: "New Jersey Institute of Technology", id: 7974 },
  { code: "US", name: "Newman University", id: 7975 },
  { code: "US", name: "New Mexico Highlands University", id: 7976 },
  { code: "US", name: "New Mexico Institute of Mining and Technology", id: 7977 },
  { code: "US", name: "New Mexico State University", id: 7978 },
  { code: "US", name: "Newschool of Architecture and Design", id: 7979 },
  { code: "US", name: "New York Academy of Art", id: 7980 },
  { code: "US", name: "New York Chiropractic College", id: 7981 },
  { code: "US", name: "New York College of Podiatric Medicine", id: 7982 },
  { code: "US", name: "New York Film Academy", id: 7983 },
  { code: "US", name: "New York Institute of Technology", id: 7984 },
  { code: "US", name: "New York Law School", id: 7985 },
  { code: "US", name: "New York Medical College", id: 7986 },
  { code: "US", name: "New York School of Interior Design", id: 7987 },
  { code: "US", name: "New York University", id: 7988 },
  { code: "US", name: "Niagara University", id: 7989 },
  { code: "US", name: "Nicholls State University", id: 7990 },
  { code: "US", name: "Nichols College", id: 7991 },
  { code: "US", name: "Norfolk State University", id: 7992 },
  { code: "US", name: "North Carolina Agricultural and Technical State University", id: 7993 },
  { code: "US", name: "North Carolina Central University", id: 7994 },
  { code: "US", name: "North Carolina School of the Arts", id: 7995 },
  { code: "US", name: "North Carolina State University", id: 7996 },
  { code: "US", name: "North Carolina Wesleyan College", id: 7997 },
  { code: "US", name: "North Central Bible College", id: 7998 },
  { code: "US", name: "North Central College", id: 7999 },
  { code: "US", name: "Northcentral University", id: 8000 },
  { code: "US", name: "North Dakota State University", id: 8001 },
  { code: "US", name: "Northeastern Illinois University", id: 8002 },
  { code: "US", name: "Northeastern Ohio University College of Medicine", id: 8003 },
  { code: "US", name: "Northeastern State University", id: 8004 },
  { code: "US", name: "Northeastern University", id: 8005 },
  { code: "US", name: "Northern Arizona University", id: 8006 },
  { code: "US", name: "Northern Illinois University", id: 8007 },
  { code: "US", name: "Northern Kentucky University", id: 8008 },
  { code: "US", name: "Northern Michigan University", id: 8009 },
  { code: "US", name: "Northern State University", id: 8010 },
  { code: "US", name: "Northern Virginia Community College", id: 8011 },
  { code: "US", name: "Northface University", id: 8012 },
  { code: "US", name: "North Georgia College", id: 8013 },
  { code: "US", name: "North Greenville College", id: 8014 },
  { code: "US", name: "Northland College", id: 8015 },
  { code: "US", name: "North Park University", id: 8016 },
  { code: "US", name: "Northwest Christian College", id: 8017 },
  { code: "US", name: "Northwest College of Art", id: 8018 },
  { code: "US", name: "Northwestern College Iowa", id: 8019 },
  { code: "US", name: "Northwestern College of Chiropractic", id: 8020 },
  { code: "US", name: "Northwestern College St. Paul", id: 8021 },
  { code: "US", name: "Northwestern Oklahoma State University", id: 8022 },
  { code: "US", name: "Northwestern State University of Louisiana", id: 8023 },
  { code: "US", name: "Northwestern University", id: 8024 },
  { code: "US", name: "Northwest Missouri State University", id: 8025 },
  { code: "US", name: "Northwest Nazarene University", id: 8026 },
  { code: "US", name: "Northwest University", id: 8027 },
  { code: "US", name: "Northwood University", id: 8028 },
  { code: "US", name: "Norwich University", id: 8029 },
  { code: "US", name: "Notre Dame College", id: 8030 },
  { code: "US", name: "Notre Dame College of Ohio", id: 8031 },
  { code: "US", name: "Notre Dame de Namur University", id: 8032 },
  { code: "US", name: "Nova Southeastern University", id: 8033 },
  { code: "US", name: "Nyack College", id: 8034 },
  { code: "US", name: "Oakland City University", id: 8035 },
  { code: "US", name: "Oakland University", id: 8036 },
  { code: "US", name: "Oakton Community College", id: 8037 },
  { code: "US", name: "Oakwood College", id: 8038 },
  { code: "US", name: "Oberlin College", id: 8039 },
  { code: "US", name: "Occidental College", id: 8040 },
  { code: "US", name: "Oglala Lakota College", id: 8041 },
  { code: "US", name: "Oglethorpe University", id: 8042 },
  { code: "US", name: "Ohio College of Podiatric Medicine", id: 8043 },
  { code: "US", name: "Ohio Dominican College", id: 8044 },
  { code: "US", name: "Ohio Northern University", id: 8045 },
  { code: "US", name: "Ohio State University", id: 8046 },
  { code: "US", name: "Ohio State University - Lima", id: 8047 },
  { code: "US", name: "Ohio State University - Mansfield", id: 8048 },
  { code: "US", name: "Ohio State University - Marion", id: 8049 },
  { code: "US", name: "Ohio State University - Newark", id: 8050 },
  { code: "US", name: "Ohio University", id: 8051 },
  { code: "US", name: "Ohio University - Chillicothe", id: 8052 },
  { code: "US", name: "Ohio University - Eastern", id: 8053 },
  { code: "US", name: "Ohio University - Lancaster", id: 8054 },
  { code: "US", name: "Ohio University - Southern", id: 8055 },
  { code: "US", name: "Ohio University - Zanesville", id: 8056 },
  { code: "US", name: "Ohio Valley College", id: 8057 },
  { code: "US", name: "Ohio Wesleyan University", id: 8058 },
  { code: "US", name: "Oklahoma Baptist University", id: 8059 },
  { code: "US", name: "Oklahoma Christian University", id: 8060 },
  { code: "US", name: "Oklahoma City University", id: 8061 },
  { code: "US", name: "Oklahoma Panhandle State University", id: 8062 },
  { code: "US", name: "Oklahoma State University", id: 8063 },
  { code: "US", name: "Oklahoma State University Center for Health Sciences", id: 8064 },
  { code: "US", name: "Oklahoma State University - Institute of Technology", id: 8065 },
  { code: "US", name: "Oklahoma State University - Oklahoma City", id: 8066 },
  { code: "US", name: "Oklahoma State University - Tulsa", id: 8067 },
  { code: "US", name: "Old Dominion University", id: 8068 },
  { code: "US", name: "Olive-Harvey College", id: 8069 },
  { code: "US", name: "Olivet College", id: 8070 },
  { code: "US", name: "Olivet Nazarene University", id: 8071 },
  { code: "US", name: "O'More College of Design", id: 8072 },
  { code: "US", name: "Oral Roberts University", id: 8073 },
  { code: "US", name: "Oregon College of Arts and Crafts", id: 8074 },
  { code: "US", name: "Oregon Graduate Institute of Science and Technology", id: 8075 },
  { code: "US", name: "Oregon Health Sciences University", id: 8076 },
  { code: "US", name: "Oregon Institute of Technology", id: 8077 },
  { code: "US", name: "Oregon State University", id: 8078 },
  { code: "US", name: "Otis College of Art & Design", id: 8079 },
  { code: "US", name: "Ottawa University", id: 8080 },
  { code: "US", name: "Otterbein College", id: 8081 },
  { code: "US", name: "Ouachita Baptist University", id: 8082 },
  { code: "US", name: "Our Lady of Holy Cross College", id: 8083 },
  { code: "US", name: "Our Lady of the Lake University", id: 8084 },
  { code: "US", name: "Ozark Christian College", id: 8085 },
  { code: "US", name: "Pace University", id: 8086 },
  { code: "US", name: "Pace University  Pleasantville/Briarcliff", id: 8087 },
  { code: "US", name: "Pacifica Graduate Institute", id: 8088 },
  { code: "US", name: "Pacific College of Oriental Medicine", id: 8089 },
  { code: "US", name: "Pacific Graduate School of Psychology", id: 8090 },
  { code: "US", name: "Pacific Lutheran University", id: 8091 },
  { code: "US", name: "Pacific Northwest College of Art", id: 8092 },
  { code: "US", name: "Pacific Oaks College", id: 8093 },
  { code: "US", name: "Pacific Union College", id: 8094 },
  { code: "US", name: "Pacific University", id: 8095 },
  { code: "US", name: "Paier College of Art", id: 8096 },
  { code: "US", name: "Paine College", id: 8097 },
  { code: "US", name: "Palm Beach Atlantic University", id: 8098 },
  { code: "US", name: "Palm Beach State College", id: 8099 },
  { code: "US", name: "Palmer College of Chiropractic", id: 8100 },
  { code: "US", name: "Palmer College of Chiropractic West", id: 8101 },
  { code: "US", name: "Park College", id: 8102 },
  { code: "US", name: "Parsons School of Design", id: 8103 },
  { code: "US", name: "Paul Quinn College", id: 8104 },
  { code: "US", name: "Peace College", id: 8105 },
  { code: "US", name: "Pebble Hills University", id: 8106 },
  { code: "US", name: "Pennsylvania Academy of the Fine Arts", id: 8107 },
  { code: "US", name: "Pennsylvania College of Optometry", id: 8108 },
  { code: "US", name: "Pennsylvania Institute of Technology", id: 8109 },
  { code: "US", name: "Pennsylvania State University", id: 8110 },
  { code: "US", name: "Pennsylvania State University - Abington", id: 8111 },
  { code: "US", name: "Pennsylvania State University - Altoona", id: 8112 },
  { code: "US", name: "Pennsylvania State University at Erie - Behrend College", id: 8113 },
  { code: "US", name: "Pennsylvania State University at Harrisburg - The Capital College", id: 8114 },
  { code: "US", name: "Pennsylvania State University - Berks-Lehigh Valley College", id: 8115 },
  { code: "US", name: "Pennsylvania State University Delaware County", id: 8116 },
  { code: "US", name: "Pennsylvania State University Great Valley", id: 8117 },
  { code: "US", name: "Pennsylvania State University - Lehigh Valley", id: 8118 },
  { code: "US", name: "Pennsylvania State University - Milton S.Hershey Medical Center", id: 8119 },
  { code: "US", name: "Pennsylvania State University - Schuylkill", id: 8120 },
  { code: "US", name: "Pepperdine University", id: 8121 },
  { code: "US", name: "Peru State College", id: 8122 },
  { code: "US", name: "Pfeiffer University", id: 8123 },
  { code: "US", name: "Philadelphia College of Bible", id: 8124 },
  { code: "US", name: "Philadelphia College of Osteopathic Medicine", id: 8125 },
  { code: "US", name: "Philadelphia University", id: 8126 },
  { code: "US", name: "Philander Smith College", id: 8127 },
  { code: "US", name: "Phillips Graduate Institute", id: 8128 },
  { code: "US", name: "Phillips University", id: 8129 },
  { code: "US", name: "Piedmont Baptist College", id: 8130 },
  { code: "US", name: "Piedmont College", id: 8131 },
  { code: "US", name: "Pikeville College", id: 8132 },
  { code: "US", name: "Pillsbury Baptist Bible College", id: 8133 },
  { code: "US", name: "Pittsburg State University", id: 8134 },
  { code: "US", name: "Pitzer College", id: 8135 },
  { code: "US", name: "Plymouth State College", id: 8136 },
  { code: "US", name: "Point Loma Nazarene College", id: 8137 },
  { code: "US", name: "Point Park College", id: 8138 },
  { code: "US", name: "Polytechnic University", id: 8139 },
  { code: "US", name: "Pomona College", id: 8140 },
  { code: "US", name: "Portland Community College", id: 8141 },
  { code: "US", name: "Portland State University", id: 8142 },
  { code: "US", name: "Post University of Waterbury", id: 8143 },
  { code: "US", name: "Prairie View Agricultural and Mechanical University", id: 8144 },
  { code: "US", name: "Pratt Institute", id: 8145 },
  { code: "US", name: "Presbyterian College", id: 8146 },
  { code: "US", name: "Prescott College", id: 8147 },
  { code: "US", name: "Preston University", id: 8148 },
  { code: "US", name: "Princeton University", id: 8149 },
  { code: "US", name: "Principia College", id: 8150 },
  { code: "US", name: "Providence College", id: 8151 },
  { code: "US", name: "Puget Sound Christian College", id: 8152 },
  { code: "US", name: "Purdue University", id: 8153 },
  { code: "US", name: "Purdue University Calumet", id: 8154 },
  { code: "US", name: "Purdue University North Central", id: 8155 },
  { code: "US", name: "Quantum-Veritas International University", id: 8156 },
  { code: "US", name: "Queens College", id: 8157 },
  { code: "US", name: "Quincy University", id: 8158 },
  { code: "US", name: "Quinnipiac College", id: 8159 },
  { code: "US", name: "Radford University", id: 8160 },
  { code: "US", name: "Ramapo College of New Jersey", id: 8161 },
  { code: "US", name: "Rand Graduate School of Policy Studies", id: 8162 },
  { code: "US", name: "Randolph-Macon College", id: 8163 },
  { code: "US", name: "Randolph-Macon Woman's College", id: 8164 },
  { code: "US", name: "Rasmussen College", id: 8165 },
  { code: "US", name: "Reed College", id: 8166 },
  { code: "US", name: "Reformed Bible College", id: 8167 },
  { code: "US", name: "Regent International University", id: 8168 },
  { code: "US", name: "Regent University", id: 8169 },
  { code: "US", name: "Regis College", id: 8170 },
  { code: "US", name: "Regis University", id: 8171 },
  { code: "US", name: "Reinhardt College", id: 8172 },
  { code: "US", name: "Rensselaer Polytechnic Institute", id: 8173 },
  { code: "US", name: "Research College of Nursing - Rockhurst University", id: 8174 },
  { code: "US", name: "Rhode Island College", id: 8175 },
  { code: "US", name: "Rhode Island School of Design", id: 8176 },
  { code: "US", name: "Rhodes College", id: 8177 },
  { code: "US", name: "Rice University", id: 8178 },
  { code: "US", name: "Richard Stockton College of New Jersey", id: 8179 },
  { code: "US", name: "Rider University", id: 8180 },
  { code: "US", name: "Ringling College of Art and Design", id: 8181 },
  { code: "US", name: "Ripon College", id: 8182 },
  { code: "US", name: "Rivier College", id: 8183 },
  { code: "US", name: "Roanoke Bible College", id: 8184 },
  { code: "US", name: "Roanoke College", id: 8185 },
  { code: "US", name: "Robert Morris College", id: 8186 },
  { code: "US", name: "Robert Morris College of Chicago", id: 8187 },
  { code: "US", name: "Roberts Wesleyan College", id: 8188 },
  { code: "US", name: "Rochester College", id: 8189 },
  { code: "US", name: "Rochester Institute of Technology", id: 8190 },
  { code: "US", name: "Rockford College", id: 8191 },
  { code: "US", name: "Rockhurst College", id: 8192 },
  { code: "US", name: "Rock Valley College", id: 8193 },
  { code: "US", name: "Rocky Mountain College", id: 8194 },
  { code: "US", name: "Rocky Mountain College of Art and Design", id: 8195 },
  { code: "US", name: "Rogers State University", id: 8196 },
  { code: "US", name: "Roger Williams University", id: 8197 },
  { code: "US", name: "Rollins College", id: 8198 },
  { code: "US", name: "Roosevelt University", id: 8199 },
  { code: "US", name: "Rose-Hulman Institute of Technology", id: 8200 },
  { code: "US", name: "Rosemont College", id: 8201 },
  { code: "US", name: "Ross University", id: 8202 },
  { code: "US", name: "Rowan University", id: 8203 },
  { code: "US", name: "Rush University", id: 8204 },
  { code: "US", name: "Russel Sage College", id: 8205 },
  { code: "US", name: "Rust College", id: 8206 },
  { code: "US", name: "Rutgers", id: 8207 },
  { code: "US", name: "Sacred Heart University", id: 8208 },
  { code: "US", name: "Sage Graduate School", id: 8209 },
  { code: "US", name: "Saginaw Valley State University", id: 8210 },
  { code: "US", name: "Salem College", id: 8211 },
  { code: "US", name: "Salem International University", id: 8212 },
  { code: "US", name: "Salem State College", id: 8213 },
  { code: "US", name: "Salem Teikyo University", id: 8214 },
  { code: "US", name: "Salisbury State University", id: 8215 },
  { code: "US", name: "Salve Regina University", id: 8216 },
  { code: "US", name: "Samford University", id: 8217 },
  { code: "US", name: "Sam Houston State University", id: 8218 },
  { code: "US", name: "Samuel Merritt College", id: 8219 },
  { code: "US", name: "San Diego State University", id: 8220 },
  { code: "US", name: "San Diego University for Integrative Studies", id: 8221 },
  { code: "US", name: "Sanford-Brown Institute", id: 8222 },
  { code: "US", name: "San Francisco Art Institute", id: 8223 },
  { code: "US", name: "San Francisco Conservatory of Music", id: 8224 },
  { code: "US", name: "San Francisco State University", id: 8225 },
  { code: "US", name: "San Joaquin College of Law", id: 8226 },
  { code: "US", name: "San Jose Christian College", id: 8227 },
  { code: "US", name: "San Jose State University", id: 8228 },
  { code: "US", name: "Santa Clara University", id: 8229 },
  { code: "US", name: "Sarah Lawrence College", id: 8230 },
  { code: "US", name: "Savannah College of Art and Design", id: 8231 },
  { code: "US", name: "Savannah State University", id: 8232 },
  { code: "US", name: "Saybrook Institute", id: 8233 },
  { code: "US", name: "Schiller International University", id: 8234 },
  { code: "US", name: "Scholl College of Podiatric Medicine", id: 8235 },
  { code: "US", name: "School for International Training", id: 8236 },
  { code: "US", name: "School of the Museum of Fine Arts", id: 8237 },
  { code: "US", name: "School of the Visual Arts", id: 8238 },
  { code: "US", name: "Schreiner College", id: 8239 },
  { code: "US", name: "Scripps College", id: 8240 },
  { code: "US", name: "Seattle Pacific University", id: 8241 },
  { code: "US", name: "Seattle University", id: 8242 },
  { code: "US", name: "Seton Hall University", id: 8243 },
  { code: "US", name: "Seton Hill College", id: 8244 },
  { code: "US", name: "Shawnee Community College", id: 8245 },
  { code: "US", name: "Shawnee State University", id: 8246 },
  { code: "US", name: "Shaw University", id: 8247 },
  { code: "US", name: "Sheldon Jackson College", id: 8248 },
  { code: "US", name: "Shenandoah University", id: 8249 },
  { code: "US", name: "Shepherd College", id: 8250 },
  { code: "US", name: "Sherman College of Straight Chiropractic", id: 8251 },
  { code: "US", name: "Shimer College", id: 8252 },
  { code: "US", name: "Shippensburg University of Pennsylvania", id: 8253 },
  { code: "US", name: "Shoreline Community College", id: 8254 },
  { code: "US", name: "Shorter College", id: 8255 },
  { code: "US", name: "Siena College", id: 8256 },
  { code: "US", name: "Siena Heights University", id: 8257 },
  { code: "US", name: "Sierra Nevada College", id: 8258 },
  { code: "US", name: "Silver Lake College", id: 8259 },
  { code: "US", name: "Simmons College", id: 8260 },
  { code: "US", name: "Simon's Rock College", id: 8261 },
  { code: "US", name: "Simpson College", id: 8262 },
  { code: "US", name: "Simpson College Iowa", id: 8263 },
  { code: "US", name: "Sinte Gleska University", id: 8264 },
  { code: "US", name: "Skadron College", id: 8265 },
  { code: "US", name: "Skidmore College", id: 8266 },
  { code: "US", name: "Slippery Rock University", id: 8267 },
  { code: "US", name: "Smith College", id: 8268 },
  { code: "US", name: "Sojourner-Douglass College", id: 8269 },
  { code: "US", name: "Soka University of America", id: 8270 },
  { code: "US", name: "Sonoma State University", id: 8271 },
  { code: "US", name: "South Carolina State University", id: 8272 },
  { code: "US", name: "South Dakota School of Mines and Technology", id: 8273 },
  { code: "US", name: "South Dakota State University", id: 8274 },
  { code: "US", name: "Southeastern Baptist College", id: 8275 },
  { code: "US", name: "Southeastern Bible College", id: 8276 },
  { code: "US", name: "Southeastern College of the Assemblies of God", id: 8277 },
  { code: "US", name: "Southeastern Louisiana University", id: 8278 },
  { code: "US", name: "Southeastern Oklahoma State University", id: 8279 },
  { code: "US", name: "Southeastern University", id: 8280 },
  { code: "US", name: "Southeast Missouri State University", id: 8281 },
  { code: "US", name: "Southern Adventist University", id: 8282 },
  { code: "US", name: "Southern Arkansas University", id: 8283 },
  { code: "US", name: "Southern California College", id: 8284 },
  { code: "US", name: "Southern California College of Optometry", id: 8285 },
  { code: "US", name: "Southern California Institute of Architecture", id: 8286 },
  { code: "US", name: "Southern College of Optometry", id: 8287 },
  { code: "US", name: "Southern Connecticut State University", id: 8288 },
  { code: "US", name: "Southern Illinois University at Carbondale", id: 8289 },
  { code: "US", name: "Southern Illinois University at Edwardsville", id: 8290 },
  { code: "US", name: "Southern Methodist University", id: 8291 },
  { code: "US", name: "Southern Nazarene University", id: 8292 },
  { code: "US", name: "Southern New Hampshire University", id: 8293 },
  { code: "US", name: "Southern Oregon University", id: 8294 },
  { code: "US", name: "Southern Polytechnic State Univerisity", id: 8295 },
  { code: "US", name: "Southern University - Baton Rouge", id: 8296 },
  { code: "US", name: "Southern University - New Orleans", id: 8297 },
  { code: "US", name: "Southern University - Shreveport", id: 8298 },
  { code: "US", name: "Southern Utah University", id: 8299 },
  { code: "US", name: "Southern Vermont College", id: 8300 },
  { code: "US", name: "Southern Wesleyan University", id: 8301 },
  { code: "US", name: "South Florida Bible College & Theological Seminary", id: 8302 },
  { code: "US", name: "South Texas College of Law", id: 8303 },
  { code: "US", name: "Southwest Baptist University", id: 8304 },
  { code: "US", name: "Southwestern Adventist University", id: 8305 },
  { code: "US", name: "Southwestern Assemblies of God University", id: 8306 },
  { code: "US", name: "Southwestern Baptist Theological Seminary", id: 8307 },
  { code: "US", name: "Southwestern Christian College", id: 8308 },
  { code: "US", name: "Southwestern Christian University", id: 8309 },
  { code: "US", name: "Southwestern College Kansas", id: 8310 },
  { code: "US", name: "Southwestern College Santa Fe", id: 8311 },
  { code: "US", name: "Southwestern Oklahoma State University", id: 8312 },
  { code: "US", name: "Southwestern University", id: 8313 },
  { code: "US", name: "Southwestern University School of Law", id: 8314 },
  { code: "US", name: "Southwest Missouri State University", id: 8315 },
  { code: "US", name: "Southwest Missouri State University - West Plains", id: 8316 },
  { code: "US", name: "Southwest State University", id: 8317 },
  { code: "US", name: "Southwest Texas State University", id: 8318 },
  { code: "US", name: "Southwest University", id: 8319 },
  { code: "US", name: "Spalding University", id: 8320 },
  { code: "US", name: "Spelman College", id: 8321 },
  { code: "US", name: "Spertus Institute of Jewish Studies", id: 8322 },
  { code: "US", name: "Spring Arbor College", id: 8323 },
  { code: "US", name: "Springfield College", id: 8324 },
  { code: "US", name: "Spring Hill College", id: 8325 },
  { code: "US", name: "St. Ambrose University", id: 8326 },
  { code: "US", name: "Standford Online University", id: 8327 },
  { code: "US", name: "Standford University", id: 8328 },
  { code: "US", name: "St. Andrews Presbyterian College", id: 8329 },
  { code: "US", name: "Stanford University", id: 8330 },
  { code: "US", name: "St. Anselm College", id: 8331 },
  { code: "US", name: "St. Anthony College of Nursing", id: 8332 },
  { code: "US", name: "State University of New York at Albany", id: 8333 },
  { code: "US", name: "State University of New York at Binghamton", id: 8334 },
  { code: "US", name: "State University of New York at Buffalo", id: 8335 },
  { code: "US", name: "State University of New York at New Paltz", id: 8336 },
  { code: "US", name: "State University of New York at Oswego", id: 8337 },
  { code: "US", name: "State University of New York at Stony Brook", id: 8338 },
  { code: "US", name: "State University of New York College at Brockport", id: 8339 },
  { code: "US", name: "State University of New York College at Cortland", id: 8340 },
  { code: "US", name: "State University of New York College at Fredonia", id: 8341 },
  { code: "US", name: "State University of New York College at Geneseo", id: 8342 },
  { code: "US", name: "State University of New York College at Old Westbury", id: 8343 },
  { code: "US", name: "State University of New York College at Oneonta", id: 8344 },
  { code: "US", name: "State University of New York College at Plattsburgh", id: 8345 },
  { code: "US", name: "State University of New York College at Potsdam", id: 8346 },
  { code: "US", name: "State University of New York College at Purchase", id: 8347 },
  { code: "US", name: "State University of New York College of Agriculture and Technology at Cobleskill", id: 8348 },
  { code: "US", name: "State University of New York College of Environmental Science and Forestry", id: 8349 },
  { code: "US", name: "State University of New York College of Optometry", id: 8350 },
  { code: "US", name: "State University of New York College of Technology at Alfred", id: 8351 },
  { code: "US", name: "State University of New York College of Technology at Farmingdale", id: 8352 },
  { code: "US", name: "State University of New York Downstate Medical Center", id: 8353 },
  { code: "US", name: "State University of New York Empire State College", id: 8354 },
  { code: "US", name: "State University of New York Health Sience Centre Syracuse", id: 8355 },
  { code: "US", name: "State University of New York Institute of Technology at Utica/Rome", id: 8356 },
  { code: "US", name: "State University of New York Maritime College", id: 8357 },
  { code: "US", name: "State University of New York School of Engineering and Applied Sciences", id: 8358 },
  { code: "US", name: "State University of New York (SUNY)", id: 8359 },
  { code: "US", name: "State University of New York Upstate Medical University ", id: 8360 },
  { code: "US", name: "State University of West Georgia", id: 8361 },
  { code: "US", name: "St. Augustine's College North Carolina", id: 8362 },
  { code: "US", name: "St. Bernard's Institute", id: 8363 },
  { code: "US", name: "St. Bonaventure University", id: 8364 },
  { code: "US", name: "St. Cloud State University", id: 8365 },
  { code: "US", name: "St. Edwards University", id: 8366 },
  { code: "US", name: "Stefan University", id: 8367 },
  { code: "US", name: "Stephen F. Austin State University", id: 8368 },
  { code: "US", name: "Stephens College", id: 8369 },
  { code: "US", name: "Sterling College", id: 8370 },
  { code: "US", name: "Stetson University", id: 8371 },
  { code: "US", name: "Stevens Institute of Technology", id: 8372 },
  { code: "US", name: "St. Francis College", id: 8373 },
  { code: "US", name: "St. Francis Medical Center College of Nursing", id: 8374 },
  { code: "US", name: "St. George's University", id: 8375 },
  { code: "US", name: "Stillman College", id: 8376 },
  { code: "US", name: "St. John Fisher College", id: 8377 },
  { code: "US", name: "St. John's College Maryland", id: 8378 },
  { code: "US", name: "St. John's College New Mexico", id: 8379 },
  { code: "US", name: "St. John's Seminary", id: 8380 },
  { code: "US", name: "St. John's University", id: 8381 },
  { code: "US", name: "St. Joseph College", id: 8382 },
  { code: "US", name: "St. Joseph College of Nursing", id: 8383 },
  { code: "US", name: "St. Joseph's College", id: 8384 },
  { code: "US", name: "St. Joseph's College New York", id: 8385 },
  { code: "US", name: "St. Joseph's College of Maine", id: 8386 },
  { code: "US", name: "St. Joseph's University", id: 8387 },
  { code: "US", name: "St. Lawrence University", id: 8388 },
  { code: "US", name: "St. Leo College", id: 8389 },
  { code: "US", name: "St. Louis Christian College", id: 8390 },
  { code: "US", name: "St. Louis College of Pharmacy", id: 8391 },
  { code: "US", name: "St. Louis University", id: 8392 },
  { code: "US", name: "St. Luke's College", id: 8393 },
  { code: "US", name: "St. Martin's College", id: 8394 },
  { code: "US", name: "St. Mary College", id: 8395 },
  { code: "US", name: "St. Mary-of-the-Woods College", id: 8396 },
  { code: "US", name: "St. Mary's College Indiana", id: 8397 },
  { code: "US", name: "St. Mary's College of California", id: 8398 },
  { code: "US", name: "St. Mary's College of Maryland", id: 8399 },
  { code: "US", name: "St. Mary's University of Minnesota", id: 8400 },
  { code: "US", name: "St. Mary's University of San Antonio", id: 8401 },
  { code: "US", name: "St. Meinrad College", id: 8402 },
  { code: "US", name: "St. Michael's College", id: 8403 },
  { code: "US", name: "St. Norbert College", id: 8404 },
  { code: "US", name: "St. Olaf College", id: 8405 },
  { code: "US", name: "Stonehill College", id: 8406 },
  { code: "US", name: "St. Paul's College", id: 8407 },
  { code: "US", name: "St. Petersburg College", id: 8408 },
  { code: "US", name: "St. Peter's College", id: 8409 },
  { code: "US", name: "Strayer University", id: 8410 },
  { code: "US", name: "St. Thomas Aquinas College", id: 8411 },
  { code: "US", name: "St. Thomas University", id: 8412 },
  { code: "US", name: "St. Vincent College", id: 8413 },
  { code: "US", name: "St. Xavier University", id: 8414 },
  { code: "US", name: "Suffolk University", id: 8415 },
  { code: "US", name: "Sullivan College", id: 8416 },
  { code: "US", name: "Sul Ross State University", id: 8417 },
  { code: "US", name: "Susquehanna University", id: 8418 },
  { code: "US", name: "Swarthmore College", id: 8419 },
  { code: "US", name: "Sweet Briar College", id: 8420 },
  { code: "US", name: "Syracuse University", id: 8421 },
  { code: "US", name: "Tabor College", id: 8422 },
  { code: "US", name: "Talladega College", id: 8423 },
  { code: "US", name: "Tarleton State University", id: 8424 },
  { code: "US", name: "Taylor University", id: 8425 },
  { code: "US", name: "Teachers College", id: 8426 },
  { code: "US", name: "Temple University", id: 8427 },
  { code: "US", name: "Temple University School of Podiatric Medicine", id: 8428 },
  { code: "US", name: "Tennessee State University", id: 8429 },
  { code: "US", name: "Tennessee Technological University", id: 8430 },
  { code: "US", name: "Tennessee Temple University", id: 8431 },
  { code: "US", name: "Tennessee Wesleyan College", id: 8432 },
  { code: "US", name: "Texas A&M International University", id: 8433 },
  { code: "US", name: "Texas A&M University", id: 8434 },
  { code: "US", name: "Texas A&M University - Commerce", id: 8435 },
  { code: "US", name: "Texas A&M University - Corpus Christi", id: 8436 },
  { code: "US", name: "Texas A&M University - Galveston", id: 8437 },
  { code: "US", name: "Texas A&M University - Kingsville", id: 8438 },
  { code: "US", name: "Texas A&M University - Texarkana", id: 8439 },
  { code: "US", name: "Texas Chiropractic College", id: 8440 },
  { code: "US", name: "Texas Christian University", id: 8441 },
  { code: "US", name: "Texas College", id: 8442 },
  { code: "US", name: "Texas College of Osteopathic Medicine", id: 8443 },
  { code: "US", name: "Texas Lutheran University", id: 8444 },
  { code: "US", name: "Texas Southern University", id: 8445 },
  { code: "US", name: "Texas Tech University", id: 8446 },
  { code: "US", name: "Texas Tech University Health Science Center", id: 8447 },
  { code: "US", name: "Texas Wesleyan University", id: 8448 },
  { code: "US", name: "Texas Woman's University", id: 8449 },
  { code: "US", name: "The American College", id: 8450 },
  { code: "US", name: "The Art Institute of Boston", id: 8451 },
  { code: "US", name: "The Art Institutes International Portland", id: 8452 },
  { code: "US", name: "The Art Institutes International San Francisco", id: 8453 },
  { code: "US", name: "The Boston Conservatory", id: 8454 },
  { code: "US", name: "The Catholic University of America", id: 8455 },
  { code: "US", name: "The Chicago School of Professional Psychology", id: 8456 },
  { code: "US", name: "The College of Insurance", id: 8457 },
  { code: "US", name: "The College of New Jersey", id: 8458 },
  { code: "US", name: "The College of Santa Fe", id: 8459 },
  { code: "US", name: "The College of St. Scholastica", id: 8460 },
  { code: "US", name: "The College of Westchester", id: 8461 },
  { code: "US", name: "The College of Wooster", id: 8462 },
  { code: "US", name: "The Cooper Union for the Advancement of Science and Art", id: 8463 },
  { code: "US", name: "The Corcoran College of Art", id: 8464 },
  { code: "US", name: "The Curtis Institute of Music", id: 8465 },
  { code: "US", name: "The Defiance College", id: 8466 },
  { code: "US", name: "The Dickinson School of Law", id: 8467 },
  { code: "US", name: "The Illinois Institute of Art-Chicago", id: 8468 },
  { code: "US", name: "The Johns Hopkins University", id: 8469 },
  { code: "US", name: "The Juilliard School", id: 8470 },
  { code: "US", name: "The Leadership Institute of Seattle", id: 8471 },
  { code: "US", name: "The Maryland Institute", id: 8472 },
  { code: "US", name: "The Master's College", id: 8473 },
  { code: "US", name: "The McGregor School of Antioch University", id: 8474 },
  { code: "US", name: "The Naropa Institute", id: 8475 },
  { code: "US", name: "The New School", id: 8476 },
  { code: "US", name: "The Rockefeller University", id: 8477 },
  { code: "US", name: "The School of the Art Institute of Chicago", id: 8478 },
  { code: "US", name: "The Scripps Research Institute", id: 8479 },
  { code: "US", name: "The Southern Christian University", id: 8480 },
  { code: "US", name: "The Tulane University of New Orleans", id: 8481 },
  { code: "US", name: "The Union Institute", id: 8482 },
  { code: "US", name: "Thiel College", id: 8483 },
  { code: "US", name: "Thomas A. Edison State College", id: 8484 },
  { code: "US", name: "Thomas Aquinas College", id: 8485 },
  { code: "US", name: "Thomas College Maine", id: 8486 },
  { code: "US", name: "Thomas Jefferson University", id: 8487 },
  { code: "US", name: "Thomas More College", id: 8488 },
  { code: "US", name: "Thomas More College of Liberal Arts", id: 8489 },
  { code: "US", name: "Thomas University", id: 8490 },
  { code: "US", name: "Thunderbird School of Global Management", id: 8491 },
  { code: "US", name: "Tiffin University", id: 8492 },
  { code: "US", name: "Toccoa Falls College", id: 8493 },
  { code: "US", name: "Tomball College", id: 8494 },
  { code: "US", name: "Tougaloo College", id: 8495 },
  { code: "US", name: "Touro College", id: 8496 },
  { code: "US", name: "Touro University", id: 8497 },
  { code: "US", name: "Towson University", id: 8498 },
  { code: "US", name: "Transylvania University", id: 8499 },
  { code: "US", name: "Trevecca Nazarene University", id: 8500 },
  { code: "US", name: "Tri-College University", id: 8501 },
  { code: "US", name: "Trident University", id: 8502 },
  { code: "US", name: "Trinity Bible College", id: 8503 },
  { code: "US", name: "Trinity Christian College", id: 8504 },
  { code: "US", name: "Trinity College Connecticut", id: 8505 },
  { code: "US", name: "Trinity College of Florida", id: 8506 },
  { code: "US", name: "Trinity College of Vermont", id: 8507 },
  { code: "US", name: "Trinity International University", id: 8508 },
  { code: "US", name: "Trinity International University (Excel)", id: 8509 },
  { code: "US", name: "Trinity University", id: 8510 },
  { code: "US", name: "Tri-State University", id: 8511 },
  { code: "US", name: "Triton College", id: 8512 },
  { code: "US", name: "Troy University", id: 8513 },
  { code: "US", name: "Truman College", id: 8514 },
  { code: "US", name: "Truman State University", id: 8515 },
  { code: "US", name: "Tufts University", id: 8516 },
  { code: "US", name: "Tui Online University", id: 8517 },
  { code: "US", name: "Tusculum College", id: 8518 },
  { code: "US", name: "Tuskegee University", id: 8519 },
  { code: "US", name: "Uniformed Services Universty of the Health Sciences", id: 8520 },
  { code: "US", name: "Union College", id: 8521 },
  { code: "US", name: "Union College Kentucky", id: 8522 },
  { code: "US", name: "Union College Nebraska", id: 8523 },
  { code: "US", name: "Union Theological Seminary (UTS)", id: 8524 },
  { code: "US", name: "Union University", id: 8525 },
  { code: "US", name: "United States Air Force Academy", id: 8526 },
  { code: "US", name: "United States Coast Guard Academy", id: 8527 },
  { code: "US", name: "United States International University", id: 8528 },
  { code: "US", name: "United States Merchant Marine Academy", id: 8529 },
  { code: "US", name: "United States Military Academy", id: 8530 },
  { code: "US", name: "United States Naval Academy", id: 8531 },
  { code: "US", name: "United States Sports Academy", id: 8532 },
  { code: "US", name: "Unity College", id: 8533 },
  { code: "US", name: "University of Advancing Technology (UAT)", id: 8534 },
  { code: "US", name: "University of Akron", id: 8535 },
  { code: "US", name: "University of Alabama - Birmingham", id: 8536 },
  { code: "US", name: "University of Alabama - Huntsville", id: 8537 },
  { code: "US", name: "University of Alabama - Tuscaloosa", id: 8538 },
  { code: "US", name: "University of Alanta", id: 8539 },
  { code: "US", name: "University of Alaska - Anchorage", id: 8540 },
  { code: "US", name: "University of Alaska - Fairbanks", id: 8541 },
  { code: "US", name: "University of Alaska - Southeast", id: 8542 },
  { code: "US", name: "University of Alaska (System)", id: 8543 },
  { code: "US", name: "University of Arizona", id: 8544 },
  { code: "US", name: "University of Arkansas at Fayetteville", id: 8545 },
  { code: "US", name: "University of Arkansas at Little Rock", id: 8546 },
  { code: "US", name: "University of Arkansas at Monticello", id: 8547 },
  { code: "US", name: "University of Arkansas at Pine Bluff", id: 8548 },
  { code: "US", name: "University of Arkansas for Medical Sciences", id: 8549 },
  { code: "US", name: "University of Arkansas (System)", id: 8550 },
  { code: "US", name: "University of Baltimore", id: 8551 },
  { code: "US", name: "University of Bridgeport", id: 8552 },
  { code: "US", name: "University of California", id: 8553 },
  { code: "US", name: "University of California System", id: 8554 },
  { code: "US", name: "University of Central Arkansas", id: 8555 },
  { code: "US", name: "University of Central Florida", id: 8556 },
  { code: "US", name: "University of Central Missouri", id: 8557 },
  { code: "US", name: "University of Central Oklahoma", id: 8558 },
  { code: "US", name: "University of Central Texas", id: 8559 },
  { code: "US", name: "University of Charleston", id: 8560 },
  { code: "US", name: "University of Charleston South Carolina", id: 8561 },
  { code: "US", name: "University of Chicago", id: 8562 },
  { code: "US", name: "University of Cincinnati", id: 8563 },
  { code: "US", name: "University of Colorado at Boulder", id: 8564 },
  { code: "US", name: "University of Colorado at Colorado Springs", id: 8565 },
  { code: "US", name: "University of Colorado at Denver", id: 8566 },
  { code: "US", name: "University of Colorado Health Sciences Center", id: 8567 },
  { code: "US", name: "University of Connecticut", id: 8568 },
  { code: "US", name: "University of Connecticut at Avery Point", id: 8569 },
  { code: "US", name: "University of Connecticut at Hartford", id: 8570 },
  { code: "US", name: "University of Connecticut at Stamford", id: 8571 },
  { code: "US", name: "University of Connecticut at Waterbury", id: 8572 },
  { code: "US", name: "University of Connecticut Health Center", id: 8573 },
  { code: "US", name: "University of Dallas", id: 8574 },
  { code: "US", name: "University of Dayton", id: 8575 },
  { code: "US", name: "University of Delaware", id: 8576 },
  { code: "US", name: "University of Denver", id: 8577 },
  { code: "US", name: "University of Detroit Mercy", id: 8578 },
  { code: "US", name: "University of Dubuque", id: 8579 },
  { code: "US", name: "University of Evansville", id: 8580 },
  { code: "US", name: "University of Findlay", id: 8581 },
  { code: "US", name: "University of Florida", id: 8582 },
  { code: "US", name: "University of Georgia", id: 8583 },
  { code: "US", name: "University of Great Falls", id: 8584 },
  { code: "US", name: "University of Hartford", id: 8585 },
  { code: "US", name: "University of Hawaii - Hilo", id: 8586 },
  { code: "US", name: "University of Hawaii - Manoa", id: 8587 },
  { code: "US", name: "University Of Hawaii - System", id: 8588 },
  { code: "US", name: "University of Hawaii - West Oahu", id: 8589 },
  { code: "US", name: "University of Health Sciences", id: 8590 },
  { code: "US", name: "University of Houston", id: 8591 },
  { code: "US", name: "University of Idaho", id: 8592 },
  { code: "US", name: "University of Illinois", id: 8593 },
  { code: "US", name: "University of Illinois at Chicago", id: 8594 },
  { code: "US", name: "University of Illinois at Springfield", id: 8595 },
  { code: "US", name: "University of Illinois at Urbana-Champaign", id: 8596 },
  { code: "US", name: "University of Indianapolis", id: 8597 },
  { code: "US", name: "University of Iowa", id: 8598 },
  { code: "US", name: "University of Kansas", id: 8599 },
  { code: "US", name: "University of Kentucky", id: 8600 },
  { code: "US", name: "University of La Verne", id: 8601 },
  { code: "US", name: "University of Louisiana at Lafayette", id: 8602 },
  { code: "US", name: "University of Louisiana at Monroe", id: 8603 },
  { code: "US", name: "University of Louisville", id: 8604 },
  { code: "US", name: "University of Maine", id: 8605 },
  { code: "US", name: "University of Maine (System)", id: 8606 },
  { code: "US", name: "University of Management & Technology", id: 8607 },
  { code: "US", name: "University of Mary", id: 8608 },
  { code: "US", name: "University of Mary Hardin-Baylor", id: 8609 },
  { code: "US", name: "University of Maryland at Baltimore", id: 8610 },
  { code: "US", name: "University of Maryland at College Park", id: 8611 },
  { code: "US", name: "University of Maryland Baltimore County", id: 8612 },
  { code: "US", name: "University of Maryland Eastern Shore", id: 8613 },
  { code: "US", name: "University of Maryland Medicine", id: 8614 },
  { code: "US", name: "University of Maryland (System)", id: 8615 },
  { code: "US", name: "University of Maryland University College", id: 8616 },
  { code: "US", name: "University of Massachusetts at Amherst", id: 8617 },
  { code: "US", name: "University of Massachusetts at Boston", id: 8618 },
  { code: "US", name: "University of Massachusetts at Dartmouth", id: 8619 },
  { code: "US", name: "University of Massachusetts at Lowell", id: 8620 },
  { code: "US", name: "University of Massachusetts Medical Center at Worcester", id: 8621 },
  { code: "US", name: "University of Massachusetts (System)", id: 8622 },
  { code: "US", name: "University of Medicine and Dentistry of New Jersey", id: 8623 },
  { code: "US", name: "University of Memphis", id: 8624 },
  { code: "US", name: "University of Miami", id: 8625 },
  { code: "US", name: "University of Michigan - Ann Arbor", id: 8626 },
  { code: "US", name: "University of Michigan - Dearborn", id: 8627 },
  { code: "US", name: "University of Michigan - Flint", id: 8628 },
  { code: "US", name: "University of Minnesota - Crookston", id: 8629 },
  { code: "US", name: "University of Minnesota - Duluth", id: 8630 },
  { code: "US", name: "University of Minnesota - Morris", id: 8631 },
  { code: "US", name: "University of Minnesota - Twin Cities Campus", id: 8632 },
  { code: "US", name: "University of Mississippi", id: 8633 },
  { code: "US", name: "University of Mississippi Medical Center", id: 8634 },
  { code: "US", name: "University of Missouri - Columbia", id: 8635 },
  { code: "US", name: "University of Missouri - Kansas City", id: 8636 },
  { code: "US", name: "University of Missouri - Saint Louis", id: 8637 },
  { code: "US", name: "University of Mobile", id: 8638 },
  { code: "US", name: "University of Montana", id: 8639 },
  { code: "US", name: "University of Montana Western", id: 8640 },
  { code: "US", name: "University of Montevallo", id: 8641 },
  { code: "US", name: "University of Nebraska - Kearney", id: 8642 },
  { code: "US", name: "University of Nebraska - Lincoln", id: 8643 },
  { code: "US", name: "University of Nebraska Medical Center", id: 8644 },
  { code: "US", name: "University of Nebraska - Omaha", id: 8645 },
  { code: "US", name: "University of Nebraska (System)", id: 8646 },
  { code: "US", name: "University of Nevada - Las Vegas", id: 8647 },
  { code: "US", name: "University of Nevada - Reno", id: 8648 },
  { code: "US", name: "University of New England", id: 8649 },
  { code: "US", name: "University of New Hampshire", id: 8650 },
  { code: "US", name: "University of New Hampshire - Manchester", id: 8651 },
  { code: "US", name: "University of New Haven", id: 8652 },
  { code: "US", name: "University of New Mexico", id: 8653 },
  { code: "US", name: "University of New Orleans", id: 8654 },
  { code: "US", name: "University of North Alabama", id: 8655 },
  { code: "US", name: "University of North America", id: 8656 },
  { code: "US", name: "University of North Carolina at Asheville", id: 8657 },
  { code: "US", name: "University of North Carolina at Chapel Hill", id: 8658 },
  { code: "US", name: "University of North Carolina at Charlotte", id: 8659 },
  { code: "US", name: "University of North Carolina at Greensboro", id: 8660 },
  { code: "US", name: "University of North Carolina at Pembroke", id: 8661 },
  { code: "US", name: "University of North Carolina at Wilmington", id: 8662 },
  { code: "US", name: "University of North Dakota", id: 8663 },
  { code: "US", name: "University of Northern Colorado", id: 8664 },
  { code: "US", name: "University of Northern Iowa", id: 8665 },
  { code: "US", name: "University of Northern Virginia", id: 8666 },
  { code: "US", name: "University of Northern Washington", id: 8667 },
  { code: "US", name: "University of North Florida", id: 8668 },
  { code: "US", name: "University of North Texas", id: 8669 },
  { code: "US", name: "University of North Texas Health Science Center at Fort Worth", id: 8670 },
  { code: "US", name: "University of NorthWest", id: 8671 },
  { code: "US", name: "University of Notre Dame", id: 8672 },
  { code: "US", name: "University of Oklahoma", id: 8673 },
  { code: "US", name: "University of Oklahoma Health Sciences Center", id: 8674 },
  { code: "US", name: "University of Oregon", id: 8675 },
  { code: "US", name: "University of Osteopathic Medicine and Health Science", id: 8676 },
  { code: "US", name: "University of Pennsylvania", id: 8677 },
  { code: "US", name: "University of Phoenix", id: 8678 },
  { code: "US", name: "University of Pittsburgh", id: 8679 },
  { code: "US", name: "University of Pittsburgh at Bradford", id: 8680 },
  { code: "US", name: "University of Pittsburgh at Greensburg", id: 8681 },
  { code: "US", name: "University of Pittsburgh at Johnstown", id: 8682 },
  { code: "US", name: "University of Portland", id: 8683 },
  { code: "US", name: "University of Puget Sound", id: 8684 },
  { code: "US", name: "University of Redlands", id: 8685 },
  { code: "US", name: "University of Rhode Island", id: 8686 },
  { code: "US", name: "University of Richmond", id: 8687 },
  { code: "US", name: "University of Rio Grande", id: 8688 },
  { code: "US", name: "University of Rochester", id: 8689 },
  { code: "US", name: "University of San Diego", id: 8690 },
  { code: "US", name: "University of San Francisco", id: 8691 },
  { code: "US", name: "University of Science and Arts of Oklahoma", id: 8692 },
  { code: "US", name: "University of Scranton", id: 8693 },
  { code: "US", name: "University of Sioux Falls", id: 8694 },
  { code: "US", name: "University of South Alabama", id: 8695 },
  { code: "US", name: "University of South Carolina", id: 8696 },
  { code: "US", name: "University of South Carolina - Aiken", id: 8697 },
  { code: "US", name: "University of South Carolina - Beaufort", id: 8698 },
  { code: "US", name: "University of South Carolina - Lancaster", id: 8699 },
  { code: "US", name: "University of South Carolina - Salkehatchie", id: 8700 },
  { code: "US", name: "University of South Carolina - Spartanburg", id: 8701 },
  { code: "US", name: "University of South Carolina - Sumter", id: 8702 },
  { code: "US", name: "University of South Carolina - Union", id: 8703 },
  { code: "US", name: "University of South Dakota", id: 8704 },
  { code: "US", name: "University of Southern California", id: 8705 },
  { code: "US", name: "University of Southern Indiana", id: 8706 },
  { code: "US", name: "University of Southern Maine", id: 8707 },
  { code: "US", name: "University of Southern Mississippi", id: 8708 },
  { code: "US", name: "University of South Florida", id: 8709 },
  { code: "US", name: "University of St. Francis", id: 8710 },
  { code: "US", name: "University of St. Thomas", id: 8711 },
  { code: "US", name: "University of Tampa", id: 8712 },
  { code: "US", name: "University of Tennessee - Chattanooga", id: 8713 },
  { code: "US", name: "University of Tennessee - Knoxville", id: 8714 },
  { code: "US", name: "University of Tennessee - Martin", id: 8715 },
  { code: "US", name: "University of Tennessee - Memphis", id: 8716 },
  { code: "US", name: "University of Tennessee Space Institute", id: 8717 },
  { code: "US", name: "University of Texas", id: 8718 },
  { code: "US", name: "University of Texas at Arlington", id: 8719 },
  { code: "US", name: "University of Texas at Austin", id: 8720 },
  { code: "US", name: "University of Texas at Brownsville", id: 8721 },
  { code: "US", name: "University of Texas at Dallas", id: 8722 },
  { code: "US", name: "University of Texas at El Paso", id: 8723 },
  { code: "US", name: "University of Texas at San Antonio", id: 8724 },
  { code: "US", name: "University of Texas at Tyler", id: 8725 },
  { code: "US", name: "University of Texas Health Center at Houston", id: 8726 },
  { code: "US", name: "University of Texas Health Center at Tyler", id: 8727 },
  { code: "US", name: "University of Texas Health Science Center at San Antonio", id: 8728 },
  { code: "US", name: "University of Texas M.D. Anderson Cancer Center", id: 8729 },
  { code: "US", name: "University of Texas Medical Branch Galveston", id: 8730 },
  { code: "US", name: "University of Texas of the Permian Basin", id: 8731 },
  { code: "US", name: "University of Texas Pan American", id: 8732 },
  { code: "US", name: "University of Texas Southwestern Medical Center at Dallas", id: 8733 },
  { code: "US", name: "University of the Arts", id: 8734 },
  { code: "US", name: "University of the District of Columbia", id: 8735 },
  { code: "US", name: "University of the Incarnate World", id: 8736 },
  { code: "US", name: "University of the Ozarks", id: 8737 },
  { code: "US", name: "University of the Pacific", id: 8738 },
  { code: "US", name: "University of the Sciences in Philadelphia", id: 8739 },
  { code: "US", name: "University of the South", id: 8740 },
  { code: "US", name: "University of the Southwest", id: 8741 },
  { code: "US", name: "University of Toledo", id: 8742 },
  { code: "US", name: "University of Tulsa", id: 8743 },
  { code: "US", name: "University of Utah", id: 8744 },
  { code: "US", name: "University of Vermont", id: 8745 },
  { code: "US", name: "University of Virginia", id: 8746 },
  { code: "US", name: "University of Washington", id: 8747 },
  { code: "US", name: "University of West Alabama", id: 8748 },
  { code: "US", name: "University of West Florida", id: 8749 },
  { code: "US", name: "University of West Los Angeles", id: 8750 },
  { code: "US", name: "University of Wisconsin - Eau Claire", id: 8751 },
  { code: "US", name: "University of Wisconsin - Green Bay", id: 8752 },
  { code: "US", name: "University of Wisconsin - La Crosse", id: 8753 },
  { code: "US", name: "University of Wisconsin - Madison", id: 8754 },
  { code: "US", name: "University of Wisconsin - Milwaukee", id: 8755 },
  { code: "US", name: "University of Wisconsin - Oshkosh", id: 8756 },
  { code: "US", name: "University of Wisconsin - Parkside", id: 8757 },
  { code: "US", name: "University of Wisconsin - Platteville", id: 8758 },
  { code: "US", name: "University of Wisconsin - River Falls", id: 8759 },
  { code: "US", name: "University of Wisconsin - Stevens Point", id: 8760 },
  { code: "US", name: "University of Wisconsin - Stout", id: 8761 },
  { code: "US", name: "University of Wisconsin - Superior", id: 8762 },
  { code: "US", name: "University of Wisconsin - Whitewater", id: 8763 },
  { code: "US", name: "University of Wyoming", id: 8764 },
  { code: "US", name: "Upper Iowa University", id: 8765 },
  { code: "US", name: "Urbana University", id: 8766 },
  { code: "US", name: "Ursinus College", id: 8767 },
  { code: "US", name: "Ursuline College", id: 8768 },
  { code: "US", name: "Utah State University", id: 8769 },
  { code: "US", name: "Utah Valley State College", id: 8770 },
  { code: "US", name: "Utica College", id: 8771 },
  { code: "US", name: "Valdosta State University", id: 8772 },
  { code: "US", name: "Valley City State University", id: 8773 },
  { code: "US", name: "Valley Forge Christian College", id: 8774 },
  { code: "US", name: "Valparaiso University", id: 8775 },
  { code: "US", name: "Vanderbilt University", id: 8776 },
  { code: "US", name: "VanderCook College of Music", id: 8777 },
  { code: "US", name: "Vanguard University of Southern California", id: 8778 },
  { code: "US", name: "Vassar College", id: 8779 },
  { code: "US", name: "Vennard College", id: 8780 },
  { code: "US", name: "Vermont Law School", id: 8781 },
  { code: "US", name: "Vermont Technical College", id: 8782 },
  { code: "US", name: "Villa Julie College", id: 8783 },
  { code: "US", name: "Villanova University", id: 8784 },
  { code: "US", name: "Virginia College", id: 8785 },
  { code: "US", name: "Virginia Commonwealth University", id: 8786 },
  { code: "US", name: "Virginia Intermont College", id: 8787 },
  { code: "US", name: "Virginia International University", id: 8788 },
  { code: "US", name: "Virginia Military Institute", id: 8789 },
  { code: "US", name: "Virginia Polytechnic Institute and State University (Virginia Tech)", id: 8790 },
  { code: "US", name: "Virginia State University", id: 8791 },
  { code: "US", name: "Virginia Union University", id: 8792 },
  { code: "US", name: "Virginia Wesleyan College", id: 8793 },
  { code: "US", name: "Viterbo College", id: 8794 },
  { code: "US", name: "Voorhees College", id: 8795 },
  { code: "US", name: "Wabash College", id: 8796 },
  { code: "US", name: "Wagner College", id: 8797 },
  { code: "US", name: "Wake Forest University", id: 8798 },
  { code: "US", name: "Walden University", id: 8799 },
  { code: "US", name: "Walla Walla College", id: 8800 },
  { code: "US", name: "Walsh College of Accountancy and Business Administration", id: 8801 },
  { code: "US", name: "Walsh University", id: 8802 },
  { code: "US", name: "Warner Pacific College", id: 8803 },
  { code: "US", name: "Warner Southern College", id: 8804 },
  { code: "US", name: "Warren Wilson College", id: 8805 },
  { code: "US", name: "Wartburg College", id: 8806 },
  { code: "US", name: "Washburn University", id: 8807 },
  { code: "US", name: "Washington and Lee University", id: 8808 },
  { code: "US", name: "Washington Bible College", id: 8809 },
  { code: "US", name: "Washington College", id: 8810 },
  { code: "US", name: "Washington State University", id: 8811 },
  { code: "US", name: "Washington University in St. Louis", id: 8812 },
  { code: "US", name: "Wayland Baptist University", id: 8813 },
  { code: "US", name: "Waynesburg College", id: 8814 },
  { code: "US", name: "Wayne State College", id: 8815 },
  { code: "US", name: "Wayne State University", id: 8816 },
  { code: "US", name: "Webber College", id: 8817 },
  { code: "US", name: "Webb Institute", id: 8818 },
  { code: "US", name: "Weber State University", id: 8819 },
  { code: "US", name: "Webster University", id: 8820 },
  { code: "US", name: "Webster University North Florida", id: 8821 },
  { code: "US", name: "Weill Medical College of Cornell University", id: 8822 },
  { code: "US", name: "Wellesley College", id: 8823 },
  { code: "US", name: "Wells College", id: 8824 },
  { code: "US", name: "Wentworth Institute of Technology", id: 8825 },
  { code: "US", name: "Wesleyan College", id: 8826 },
  { code: "US", name: "Wesleyan University", id: 8827 },
  { code: "US", name: "Wesley College", id: 8828 },
  { code: "US", name: "Wesley College Mississippi", id: 8829 },
  { code: "US", name: "Westbrook University ", id: 8830 },
  { code: "US", name: "West Chester University of Pennsylvania", id: 8831 },
  { code: "US", name: "West Coast University", id: 8832 },
  { code: "US", name: "Western Baptist College", id: 8833 },
  { code: "US", name: "Western Bible College", id: 8834 },
  { code: "US", name: "Western Carolina University", id: 8835 },
  { code: "US", name: "Western Connecticut State University", id: 8836 },
  { code: "US", name: "Western Governors University", id: 8837 },
  { code: "US", name: "Western Illinois University", id: 8838 },
  { code: "US", name: "Western International University", id: 8839 },
  { code: "US", name: "Western Kentucky University", id: 8840 },
  { code: "US", name: "Western Maryland College", id: 8841 },
  { code: "US", name: "Western Michigan University", id: 8842 },
  { code: "US", name: "Western New England College", id: 8843 },
  { code: "US", name: "Western New Mexico University", id: 8844 },
  { code: "US", name: "Western Oregon University", id: 8845 },
  { code: "US", name: "Western State College", id: 8846 },
  { code: "US", name: "Western States Chiropractic College", id: 8847 },
  { code: "US", name: "Western State University College of Law", id: 8848 },
  { code: "US", name: "Western State University College of Law - Orange County", id: 8849 },
  { code: "US", name: "Western Washington University", id: 8850 },
  { code: "US", name: "Westfield State College", id: 8851 },
  { code: "US", name: "West Liberty State College", id: 8852 },
  { code: "US", name: "Westminster College Fulton", id: 8853 },
  { code: "US", name: "Westminster College New Wilmington", id: 8854 },
  { code: "US", name: "Westminster College of Salt Lake City", id: 8855 },
  { code: "US", name: "Westmont College", id: 8856 },
  { code: "US", name: "West Suburban College of Nursing", id: 8857 },
  { code: "US", name: "West Texas A&M University", id: 8858 },
  { code: "US", name: "West Virginia School of Osteopathic Medicine", id: 8859 },
  { code: "US", name: "West Virginia State College", id: 8860 },
  { code: "US", name: "West Virginia University", id: 8861 },
  { code: "US", name: "West Virginia University Institute of Technology", id: 8862 },
  { code: "US", name: "West Virginia Wesleyan College", id: 8863 },
  { code: "US", name: "Westwood College", id: 8864 },
  { code: "US", name: "Wheaton College Massachusetts", id: 8865 },
  { code: "US", name: "Wheeling Jesuit University", id: 8866 },
  { code: "US", name: "Wheelock College", id: 8867 },
  { code: "US", name: "Whitman College", id: 8868 },
  { code: "US", name: "Whittier College", id: 8869 },
  { code: "US", name: "Whitworth College", id: 8870 },
  { code: "US", name: "Wichita State University", id: 8871 },
  { code: "US", name: "Widener University", id: 8872 },
  { code: "US", name: "Wilberforce University", id: 8873 },
  { code: "US", name: "Wilbur Wright College", id: 8874 },
  { code: "US", name: "Wiley College", id: 8875 },
  { code: "US", name: "Wilkes University", id: 8876 },
  { code: "US", name: "Willamette University", id: 8877 },
  { code: "US", name: "William Carey College", id: 8878 },
  { code: "US", name: "William Jewell College", id: 8879 },
  { code: "US", name: "William Mitchell College of Law", id: 8880 },
  { code: "US", name: "William Paterson University", id: 8881 },
  { code: "US", name: "William Penn College", id: 8882 },
  { code: "US", name: "Williams Baptist College", id: 8883 },
  { code: "US", name: "Williams College", id: 8884 },
  { code: "US", name: "William Tyndale College", id: 8885 },
  { code: "US", name: "William Woods University", id: 8886 },
  { code: "US", name: "Wilmington College", id: 8887 },
  { code: "US", name: "Wilson College", id: 8888 },
  { code: "US", name: "Wingate University", id: 8889 },
  { code: "US", name: "Winona State University", id: 8890 },
  { code: "US", name: "Winston-Salem State University", id: 8891 },
  { code: "US", name: "Winthrop University", id: 8892 },
  { code: "US", name: "Wisconsin Lutheran College", id: 8893 },
  { code: "US", name: "Wisconsin School of Professional Psychology", id: 8894 },
  { code: "US", name: "Wittenberg University", id: 8895 },
  { code: "US", name: "Wofford College", id: 8896 },
  { code: "US", name: "Woodbury University", id: 8897 },
  { code: "US", name: "Worcester Polytechnic Institute", id: 8898 },
  { code: "US", name: "Worcester State College", id: 8899 },
  { code: "US", name: "Wright Institute", id: 8900 },
  { code: "US", name: "Wright State University", id: 8901 },
  { code: "US", name: "Xavier University", id: 8902 },
  { code: "US", name: "Xavier University of Louisiana", id: 8903 },
  { code: "US", name: "Yale University", id: 8904 },
  { code: "US", name: "Yeshiva University", id: 8905 },
  { code: "US", name: "York College Nebraska", id: 8906 },
  { code: "US", name: "York College of Pennsylvania", id: 8907 },
  { code: "US", name: "Yorker International University", id: 8908 },
  { code: "US", name: "York University", id: 8909 },
  { code: "US", name: "Youngstown State University", id: 8910 },
  { code: "UY", name: "Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh", id: 8911 },
  { code: "UY", name: "Universidad Católica del Uruguay", id: 8912 },
  { code: "UY", name: "Universidad de la Empresa (UDE)", id: 8913 },
  { code: "UY", name: "Universidad de la República", id: 8914 },
  { code: "UY", name: "Universidad de Montevideo", id: 8915 },
  { code: "UY", name: "Universidad ORT Uruguay", id: 8916 },
  { code: "UZ", name: "Ferghana Politechnical Institute", id: 8917 },
  { code: "UZ", name: "International Business School Kelajak ILMI", id: 8918 },
  { code: "UZ", name: "Kokand State Pedagogical Institute", id: 8919 },
  { code: "UZ", name: "Management Development Institute of Singapore", id: 8920 },
  { code: "UZ", name: "National University of Uzbekistan", id: 8921 },
  { code: "UZ", name: "Nukus State Teachers Training Institute", id: 8922 },
  { code: "UZ", name: "Samarkand State University", id: 8923 },
  { code: "UZ", name: "Tashkent Automobile and Roads Institute", id: 8924 },
  { code: "UZ", name: "Tashkent Institute of Irrigation and Melioration", id: 8925 },
  { code: "UZ", name: "Tashkent Islam University", id: 8926 },
  { code: "UZ", name: "Tashkent Medical Academy", id: 8927 },
  { code: "UZ", name: "Tashkent Pharmaceutical Institute", id: 8928 },
  { code: "UZ", name: "Tashkent School of Finance", id: 8929 },
  { code: "UZ", name: "Tashkent State Agrarian University", id: 8930 },
  { code: "UZ", name: "Tashkent State Technical University", id: 8931 },
  { code: "UZ", name: "Tashkent State University of Economics", id: 8932 },
  { code: "UZ", name: "Tashkent State University of Oriental Studies ", id: 8933 },
  { code: "UZ", name: "Tashkent University of Information Technologies", id: 8934 },
  { code: "UZ", name: "Turin Polytechnic University In Tashkent", id: 8935 },
  { code: "UZ", name: "University of World Economy and Diplomacy", id: 8936 },
  { code: "UZ", name: "Uzbek State World Languages University", id: 8937 },
  { code: "UZ", name: "Westminster International University in Tashkent", id: 8938 },
  { code: "VA", name: "Athenaeum Pontificium Regina Apostolorum", id: 8939 },
  { code: "VA", name: "Pontifcia Università Gregoriana", id: 8940 },
  { code: "VA", name: "Pontifcia Universitas a S.Thomas Aquinate in Urbe", id: 8941 },
  { code: "VA", name: "Pontifcia Universitas Lateranensis", id: 8942 },
  { code: "VA", name: "Pontifcia Università Urbaniana", id: 8943 },
  { code: "VA", name: "Università Pontifcia Salesiana", id: 8944 },
  { code: "VC", name: "Trinity University School of Medicine", id: 8945 },
  { code: "VE", name: "Universidad Bicentenaria de Aragua", id: 8946 },
  { code: "VE", name: "Universidad Bolivariana de Venezuela", id: 8947 },
  { code: "VE", name: "Universidad Católica Andres Bello", id: 8948 },
  { code: "VE", name: "Universidad Católica Cecilio Acosta", id: 8949 },
  { code: "VE", name: "Universidad Católica del Táchira", id: 8950 },
  { code: "VE", name: "Universidad Central de Venezuela", id: 8951 },
  { code: "VE", name: "Universidad Centro Occidental Lisandro Alvarado", id: 8952 },
  { code: "VE", name: "Universidad de Carabobo", id: 8953 },
  { code: "VE", name: "Universidad de Los Andes", id: 8954 },
  { code: "VE", name: "Universidad del Zulia", id: 8955 },
  { code: "VE", name: "Universidad de Oriente", id: 8956 },
  { code: "VE", name: "Universidad Dr. Rafael Belloso Chacín", id: 8957 },
  { code: "VE", name: "Universidad Fermin Toro", id: 8958 },
  { code: "VE", name: "Universidad Gran Mariscal de Ayacucho", id: 8959 },
  { code: "VE", name: "Universidad José Antonio Páez", id: 8960 },
  { code: "VE", name: "Universidad José Maria Vargas", id: 8961 },
  { code: "VE", name: "Universidad Metropolitana", id: 8962 },
  { code: "VE", name: "Universidad Monteávila", id: 8963 },
  { code: "VE", name: "Universidad Nacional Abierta", id: 8964 },
  { code: "VE", name: "Universidad Nacional Experimental de Guayana", id: 8965 },
  { code: "VE", name: "Universidad Nacional Experimental de la Fuerza Armada", id: 8966 },
  { code: "VE", name: "Universidad Nacional Experimental de los Llanos Occidentales Ezequiel Zamora", id: 8967 },
  { code: "VE", name: "Universidad Nacional Experimental del Táchira", id: 8968 },
  { code: "VE", name: "Universidad Nacional Experimental Francisco de Miranda", id: 8969 },
  { code: "VE", name: "Universidad Nacional Experimental Politécnica Antonio José de Sucre", id: 8970 },
  { code: "VE", name: "Universidad Nacional Experimental Rafael Maria Baralt", id: 8971 },
  { code: "VE", name: "Universidad Nacional Experimental Romulo Gallegos", id: 8972 },
  { code: "VE", name: "Universidad Nacional Experimental Simón Rodriguez", id: 8973 },
  { code: "VE", name: "Universidad Nacional Experimental Sur del Lago Jesús Maria Semprum", id: 8974 },
  { code: "VE", name: "Universidad Nueva Esparta", id: 8975 },
  { code: "VE", name: "Universidad Pedagógica Experimental Libertador", id: 8976 },
  { code: "VE", name: "Universidad Rafael Urdaneta", id: 8977 },
  { code: "VE", name: "Universidad Santa Maria Caracas", id: 8978 },
  { code: "VE", name: "Universidad Simón Bolivar", id: 8979 },
  { code: "VE", name: "Universidad Tecnológica del Centro", id: 8980 },
  { code: "VE", name: "Universidad Valle del Momboy", id: 8981 },
  { code: "VE", name: "Universidad Yacambu", id: 8982 },
  { code: "VI", name: "University of the Virgin Islands", id: 8983 },
  { code: "VN", name: "Banking University of Ho Chi Minh City", id: 8984 },
  { code: "VN", name: "Binh Duong University", id: 8985 },
  { code: "VN", name: "Can-Tho University", id: 8986 },
  { code: "VN", name: "Danang College Of Technology", id: 8987 },
  { code: "VN", name: "Foreign Trade University", id: 8988 },
  { code: "VN", name: "FPT University", id: 8989 },
  { code: "VN", name: "Hai Duong University", id: 8990 },
  { code: "VN", name: "Hanoi Medical University", id: 8991 },
  { code: "VN", name: "Hanoi National Economics University", id: 8992 },
  { code: "VN", name: "Hanoi Open University", id: 8993 },
  { code: "VN", name: "Hanoi University of Architecture", id: 8994 },
  { code: "VN", name: "Hanoi University of Civil Engineering", id: 8995 },
  { code: "VN", name: "Hanoi University of Mining and Geology", id: 8996 },
  { code: "VN", name: "Hanoi University of Science", id: 8997 },
  { code: "VN", name: "Hanoi University of Science & Technology", id: 8998 },
  { code: "VN", name: "Ho Chi Minh City Open University", id: 8999 },
  { code: "VN", name: "Ho Chi Minh City University of Agriculture and Forestry", id: 9000 },
  { code: "VN", name: "Ho Chi Minh City University of Architecture", id: 9001 },
  { code: "VN", name: "Ho Chi Minh City University of Economics", id: 9002 },
  { code: "VN", name: "Ho Chi Minh City University of Foreign Languages and Information Technology", id: 9003 },
  { code: "VN", name: "Ho Chi Minh City University of Law", id: 9004 },
  { code: "VN", name: "Ho Chi Minh City University of Medicine and Pharmacy", id: 9005 },
  { code: "VN", name: "Ho Chi Minh City University of Natural Sciences", id: 9006 },
  { code: "VN", name: "Ho Chi Minh City University of Pedagogics", id: 9007 },
  { code: "VN", name: "Ho Chi Minh City University of Social Sciences and Humanities", id: 9008 },
  { code: "VN", name: "Ho Chi Minh City University of Technology", id: 9009 },
  { code: "VN", name: "Ho Chi Minh City University of Transport", id: 9010 },
  { code: "VN", name: "Hong Bang University International", id: 9011 },
  { code: "VN", name: "Hue University", id: 9012 },
  { code: "VN", name: "Hue University of Agriculture and Forestry ", id: 9013 },
  { code: "VN", name: "Institute of Finance", id: 9014 },
  { code: "VN", name: "Posts & Telecommunications Institute of Technology", id: 9015 },
  { code: "VN", name: "RMIT International University Vietnam", id: 9016 },
  { code: "VN", name: "Saigon University", id: 9017 },
  { code: "VN", name: "Tay Nguyen University", id: 9018 },
  { code: "VN", name: "Thainguyen University of Agriculture and Forestry", id: 9019 },
  { code: "VN", name: "University of Da Lat", id: 9020 },
  { code: "VN", name: "University of Da Nang", id: 9021 },
  { code: "VN", name: "University of Technical Education Ho Chi Minh City", id: 9022 },
  { code: "VN", name: "University of Transport and Communications", id: 9023 },
  { code: "VN", name: "Vietnam Maritime University", id: 9024 },
  { code: "VN", name: "Vietnam National University Hanoi", id: 9025 },
  { code: "VN", name: "Vietnam National University Ho Chi Minh City", id: 9026 },
  { code: "VN", name: "Vietnam National University of Agriculture", id: 9027 },
  { code: "VN", name: "Water Resources University", id: 9028 },
  { code: "WS", name: "National University of Samoa", id: 9029 },
  { code: "WS", name: "University of the South Pacific School of Agriculture", id: 9030 },
  { code: "YE", name: "Alahgaff University", id: 9031 },
  { code: "YE", name: "Al-Eman University", id: 9032 },
  { code: "YE", name: "Al-Nasser University", id: 9033 },
  { code: "YE", name: "Hadhramout University of Science and Technology ", id: 9034 },
  { code: "YE", name: "Hodeidah University", id: 9035 },
  { code: "YE", name: "Queen Arwa University", id: 9036 },
  { code: "YE", name: "Saba University", id: 9037 },
  { code: "YE", name: "Sana'a University", id: 9038 },
  { code: "YE", name: "Taiz University", id: 9039 },
  { code: "YE", name: "Thamar University", id: 9040 },
  { code: "YE", name: "University of Aden", id: 9041 },
  { code: "YE", name: "University of Modern Sciences", id: 9042 },
  { code: "YE", name: "University of Science and Technology Sana'a", id: 9043 },
  { code: "YE", name: "Yemenia University", id: 9044 },
  { code: "ZA", name: "Cape Peninsula University of Technology", id: 9045 },
  { code: "ZA", name: "Central University of Technology", id: 9046 },
  { code: "ZA", name: "Durban Institute of Technology", id: 9047 },
  { code: "ZA", name: "Nelson Mandela Metropolitan University", id: 9048 },
  { code: "ZA", name: "Rhodes University", id: 9049 },
  { code: "ZA", name: "Tshwane University of Technology", id: 9050 },
  { code: "ZA", name: "University of Cape Town", id: 9051 },
  { code: "ZA", name: "University of Fort Hare", id: 9052 },
  { code: "ZA", name: "University of Johannesburg", id: 9053 },
  { code: "ZA", name: "University of KwaZulu-Natal", id: 9054 },
  { code: "ZA", name: "University of Limpopo", id: 9055 },
  { code: "ZA", name: "University of North West", id: 9056 },
  { code: "ZA", name: "University of Pretoria", id: 9057 },
  { code: "ZA", name: "University of South Africa", id: 9058 },
  { code: "ZA", name: "University of Stellenbosch", id: 9059 },
  { code: "ZA", name: "University of the Free State", id: 9060 },
  { code: "ZA", name: "University of the Western Cape", id: 9061 },
  { code: "ZA", name: "University of Venda", id: 9062 },
  { code: "ZA", name: "University of Witwatersrand", id: 9063 },
  { code: "ZA", name: "University of Zululand", id: 9064 },
  { code: "ZA", name: "Vaal University of Technology", id: 9065 },
  { code: "ZA", name: "Walter Sisulu University for Technology and Science", id: 9066 },
  { code: "ZM", name: "Cavendish University", id: 9067 },
  { code: "ZM", name: "Copperbelt University", id: 9068 },
  { code: "ZM", name: "Mulungushi University", id: 9069 },
  { code: "ZM", name: "Northrise University", id: 9070 },
  { code: "ZM", name: "Rusangu University", id: 9071 },
  { code: "ZM", name: "University of Lusaka", id: 9072 },
  { code: "ZM", name: "University of Zambia", id: 9073 },
  { code: "ZM", name: "Zambian Open University", id: 9074 },
  { code: "ZW", name: "Africa University", id: 9075 },
  { code: "ZW", name: "Bindura University of Science Education", id: 9076 },
  { code: "ZW", name: "Catholic University in Zimbabwe", id: 9077 },
  { code: "ZW", name: "Chinhoyi University of Technology", id: 9078 },
  { code: "ZW", name: "Great Zimbabwe University", id: 9079 },
  { code: "ZW", name: "Harare Institute of Technology", id: 9080 },
  { code: "ZW", name: "Lupane State University", id: 9081 },
  { code: "ZW", name: "Midlands State University", id: 9082 },
  { code: "ZW", name: "National University of Science and Technology Bulawayo", id: 9083 },
  { code: "ZW", name: "Reformed Church University", id: 9084 },
  { code: "ZW", name: "Solusi University", id: 9085 },
  { code: "ZW", name: "University of Zimbabwe", id: 9086 },
  { code: "ZW", name: "Women's University in Africa", id: 9087 },
  { code: "ZW", name: "Zimbabwe Ezekiel Guti University", id: 9088 },
  { code: "ZW", name: "Zimbabwe Open University", id: 9089 },
  { code: "DZ", name: "Ecole Nationale Supérieure d'Informatique", id: 9090},
  { code: "DZ", name: "École nationale polytechnique d'Alger", id: 9091},
  { code: "DZ", name: "École polytechnique d'architecture et d'urbanisme", id: 9092}
]