import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import {
    Steps,
    Form,
    Input,
    Icon,
    Select,
    Button,
    InputNumber,
    Card,
    Spin, Row, Col
} from 'antd';

import { connect } from 'react-redux';
import 'antd/dist/antd.css'

//constant
import * as inputs from '../constants/FormInputsLabels';
import * as formSteps from '../constants/Steps';

//actions
import { evaluate,showExperienceFormHint,hideExperienceFormHint,showHintDetails,hideHintDetails  } from '../actions';
import Navbar from "./Navbar";
import FormHint from "../components/FormHint";
import Footer from "./Footer";

const { Option } = Select;
const publications = [inputs.REVUE_INT, inputs.REVUE_NAT, inputs.CONF_INT, inputs.CONF_NAT, inputs.REPORT];
const products = [inputs.PATENT_ACC, inputs.PATENT_DEP, inputs.PRODUCT_DEPLOYED, inputs.PRODUCT_REALIZED, inputs.PRODUCT_DEMO, inputs.PROTOTYPE];
const stepsArray =[formSteps.RESSOURCE,formSteps.WORKSHOP,formSteps.PUBLICATION,formSteps.REALISATION,formSteps.FINISH]
const gradesArray =[inputs.NB_TECHNICIAN,inputs.NB_ENGINEER,inputs.NB_MASTER,inputs.NB_DOCTOR]
const grades = ['technician', 'engineer', 'master', 'doctor'];
const results =['workshop_capacity','publication_capacity','production_capacity'];



class Evaluation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ressource_id: 1,
            current: 0,
            steps: 5,
            confirmDirty: false,
            autoCompleteResult: [],
            grade: 0,
            nb_year: 0,
            focused_input:""
        };
    }

    componentDidMount() {
        this.props.form.setFieldsValue({
            ressources: [0],
        });
    }
    next() {
        window.scrollTo(0, 280);
        var current = this.state.current;
        this.props.hideExperienceFormHint();
        const steps = [
            ['first_names', 'last_names', 'grades', 'implications', 'experiences']
        ];
        this.props.form.validateFields(steps[current], (err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                current = this.state.current + 1;
                this.setState({ current });
            }
            if (values.grade)
                this.setState({ grade: values.grade });
            if (values.years_experience)
                this.setState({ nb_year: values.years_experience });
        });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }
    handleSubmit = e => {
        e.preventDefault();
        var { current } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.props.evaluate(values);
                current = this.state.current + 1;
                this.setState({ current });
            }
        });

        this.handleInputClick(formSteps.RESULT);
        this.showHintDetails(results)
    };
    display(step) {
        const { current } = this.state;
        if (current !== step)
            return 'none';
        return '';
    }
    grade(gr) {
        const { grade } = this.state;
        if (gr > grade)
            return 'none';
        return '';
    }
    nbYear(nb) {
        const { nb_year } = this.state;
        if (nb > nb_year)
            return 'none';
        return '';
    }
    changeLanguage = (e, lang) => {
        e.preventDefault();
        this.props.setActiveLanguage(lang);
    }
    renderLanguagePicker() {
        const { languages, activeLanguage } = this.props;
        return (
            activeLanguage ?
                (<div className="dropdown show">
                    <button className="btn btn-secondary lang-btn " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="far fa-globe-europe"></i> &nbsp;
                        {activeLanguage.name}
                    </button>
                    <div className="dropdown-menu">
                        {languages.map(lang => (
                            <a onClick={(e) => this.changeLanguage(e, lang.code)} key={lang.code} className="dropdown-item" href="#lang"><img alt="" style={{ height: '20px', marginRight: '15px' }} src={`img/icon/${lang.code}.png`}></img>{lang.name}</a>
                        ))}
                    </div>
                </div>)
                :
                null
        );
    }
    remove = k => {
        const { form } = this.props;
        // can use data-binding to get
        const ressources = form.getFieldValue('ressources');
        // We need at least one passenger
        if (ressources.length === 1) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            ressources: ressources.filter(key => key !== k),
        });

        this.setState({ ressource_id: this.state.ressource_id - 1 })
    };

    add = () => {
        const { form } = this.props;
        let { ressource_id } = this.state;
        // can use data-binding to get
        const ressources = form.getFieldValue('ressources');
        const nextRessources = ressources.concat(ressource_id++);
        this.setState({ ressource_id })
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            ressources: nextRessources,
        });
    };
    getCleanStr(str) {
        return str.toLowerCase().replace(/[éèê]/, 'e').replace(/[ ]/, '_');
    }

    handleInputClick = (input) =>{
        this.showHint(input);
    }

    showHintDetails = (values) =>{
        this.props.showHintDetails(values)
    }

    hideHintDetails = () =>{
        this.props.hideHintDetails()
    }

    showHint = (input) => {
        this.setState({
            focused_input : input,
        });
        this.props.showExperienceFormHint()
    }

    render() {
        const { Step } = Steps;
        const { current, steps } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const formItemLayout = {
            colon:false,
            labelAlign:'left',
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0},
            },
        };
        getFieldDecorator('ressources', { initialValue: [] });
        const ressources = getFieldValue('ressources');
        const formRessources = ressources.map((r, index) => (
            <Translate key={index}>
                {({ translate }) =>
                    <div key={r}>

                        <Row>
                            <Col span={24}>
                                <Form.Item {...formItemLayoutWithOutLabel}>
                                   <div>
                                       <i className="far fa-user ressource-icon"></i>
                                       <Card
                                           title={`Ressource ${r + 1}`}
                                           bordered={false}
                                           extra={r > 0 ? (
                                               <Button
                                                   type="danger"
                                                   ghost
                                                   shape="circle"
                                                   icon="minus"
                                                   size="small"
                                                   onClick={() => this.remove(r)} />
                                           ) : null}
                                       />
                                   </div>

                                </Form.Item>

                            </Col>
                            <Col span={8}>
                                <div className="mr-2">
                                    <Form.Item
                                        onFocus={()=>{
                                            setTimeout( ()=> {
                                                this.handleInputClick(inputs.GRADE);this.showHintDetails(grades)
                                            }, 300);
                                        }}
                                        label={translate(`input_labels.${inputs.GRADE}`)}>

                                        {getFieldDecorator(`grades[${r}]`, {
                                            rules: [{ required: false, message: 'Please select your grade!' }],
                                        })(
                                            <Select
                                                onBlur={() => {this.hideHintDetails()}}
                                                showSearch
                                                style={{}}
                                                placeholder="Select a grade"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {grades.map((grade, index) => (
                                                    <Option key={index} value={index}>{translate(`${inputs.GRADE}.${grade}`)}</Option>
                                                ))}
                                            </Select>,
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="mx-2">
                                    <Form.Item label={translate(`input_labels.${inputs.RESOURCE_IMPLICATION}`)}>
                                        {getFieldDecorator(`implications[${r}]`, {
                                            rules: [{ required: false, message: 'Please enter the implication!' }],
                                        })(
                                            <InputNumber
                                                onFocus={() =>{this.handleInputClick(inputs.RESOURCE_IMPLICATION)}}
                                                prefix={<Icon type="rise" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder={`${translate(`input_labels.${inputs.RESOURCE_IMPLICATION}`)} %`} min={1} max={100}
                                            />,
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="ml-2">
                                    <Form.Item label={translate(`input_labels.${inputs.RESOURCE_EXPERIENCE}`)}>
                                        {getFieldDecorator(`experiences[${r}]`, {
                                            rules: [{ required: true, message: 'Please enter the years of experience!' }],
                                        })(
                                            <InputNumber
                                                onFocus={() =>{this.handleInputClick(inputs.RESOURCE_EXPERIENCE)}}
                                                prefix={<Icon type="rise" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder={translate(`input_labels.${inputs.RESOURCE_EXPERIENCE}`)} min={1}
                                            />,
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                    </div>
                }
            </Translate>

        ));
        return (

            <Translate>
                {({ translate }) =>
                    <div className="experience-page" >
                        <Navbar renderLanguage={this.renderLanguagePicker()}/>
                        <div className="text-center pages-header pages-header-evaluation-bg" >
                            Test your project capacity !
                            {/*<div className="line-shape"></div>*/}
                        </div>

                        <div className="container mt-5" tyle={{background:'#fff'}}>
                            <Steps current={current}>
                                <Step title={translate(`steps.labels.${formSteps.RESSOURCE}`)}   />
                                <Step title={translate(`steps.labels.${formSteps.WORKSHOP}`)}   />
                                <Step title={translate(`steps.labels.${formSteps.PUBLICATION}`)}   />
                                <Step title={translate(`steps.labels.${formSteps.REALISATION}`)}   />
                                <Step title={translate(`steps.labels.${formSteps.FINISH}`)}   />
                            </Steps>

                            <div className="container">
                                <div className="row form-wrapper">
                                    <div className="col-6">
                                        <h6 className="blue-title">{translate(`steps.labels.${stepsArray[this.state.current]}`)}  </h6>
                                        <div className="step-description">
                                            <p> {translate(`steps.${stepsArray[this.state.current]}`)} </p>
                                            <p className="step-description-info"><Icon type="info-circle" /> If you don't understand any of the question click on it , a pop will appear on the right side for an explanation</p>
                                        </div>

                                        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                            <div className="steps-contentr">

                                                {/*** FIRST STEP */}
                                                <div style={{ display: this.display(0) }}>
                                                    {formRessources}
                                                    <Form.Item {...formItemLayoutWithOutLabel}>
                                                        <div className="steps-action">
                                                            <Button className="btn-regular" onClick={this.add} style={{ width: '100%' }}>
                                                                <i className="far fa-users-medical"></i> &nbsp; {"Ajouter une ressource"}
                                                            </Button>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                {/*** 2ND STEP */}
                                                <div className='table-responsive' style={{ display: this.display(1) }}>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <tr className='table-primary'>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Nombre</th>
                                                            <th scope="col">Implication %</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {grades.map((grade, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{translate(`grade.${grade}`)}</th>
                                                                <td>
                                                                    <Form.Item style={{ marginBottom: 0 }}>
                                                                        {getFieldDecorator(`form_${this.getCleanStr(grade)}_nombre`, {

                                                                        })(
                                                                            <InputNumber
                                                                                onFocus={() =>{this.handleInputClick(`nb_${grade}_evaluation`)}}
                                                                                placeholder="" min={0}
                                                                            />,
                                                                        )}
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item style={{ marginBottom: 0 }}>
                                                                        {getFieldDecorator(`form_${this.getCleanStr(grade)}_implication`, {

                                                                        })(
                                                                            <InputNumber
                                                                                onFocus={() =>{this.handleInputClick(`implication_${grade}_evaluation`)}}
                                                                                placeholder="" min={0} max={100}
                                                                            />,
                                                                        )}
                                                                    </Form.Item>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/** 3RD STEP */}
                                                <div className='table-responsive' style={{ display: this.display(2) }}>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <tr className='table-primary'>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Nombre</th>
                                                            <th scope="col">Implication %</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {publications.map((pub, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{translate(`input_labels.${pub}`)}</th>
                                                                <td>
                                                                    <Form.Item style={{ marginBottom: 0 }}>
                                                                        {getFieldDecorator(`pub_${this.getCleanStr(pub)}_nombre`, {

                                                                        })(
                                                                            <InputNumber
                                                                                onFocus={() =>{this.handleInputClick(`nb_${pub}_evaluation`)}}
                                                                                placeholder="" min={0}
                                                                            />,
                                                                        )}
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item style={{ marginBottom: 0 }}>
                                                                        {getFieldDecorator(`pub_${this.getCleanStr(pub)}_implication`, {

                                                                        })(
                                                                            <InputNumber
                                                                                onFocus={() =>{this.handleInputClick(`implication_${pub}_evaluation`)}}
                                                                                placeholder="" min={0} max={100}
                                                                            />,
                                                                        )}
                                                                    </Form.Item>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/** 4TH STEP */}
                                                <div className='table-responsive' style={{ display: this.display(3) }}>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <tr className='table-primary'>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Nombre</th>
                                                            <th scope="col">Implication %</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {products.map((prod, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{translate(`input_labels.${prod}`)}</th>
                                                                <td>
                                                                    <Form.Item style={{ marginBottom: 0 }}>
                                                                        {getFieldDecorator(`prod_${this.getCleanStr(prod)}_nombre`, {

                                                                        })(
                                                                            <InputNumber
                                                                                onFocus={() =>{this.handleInputClick(`nb_${prod}_evaluation`)}}
                                                                                placeholder="" min={0}
                                                                            />,
                                                                        )}
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item style={{ marginBottom: 0 }}>
                                                                        {getFieldDecorator(`prod_${this.getCleanStr(prod)}_implication`, {

                                                                        })(
                                                                            <InputNumber
                                                                                onFocus={() =>{this.handleInputClick(`implication_${prod}_evaluation`)}}
                                                                                placeholder="" min={0} max={100}
                                                                            />,
                                                                        )}
                                                                    </Form.Item>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/** 5TH STEP */}
                                                <div id="c1" style={{ display: this.display(4) }}>
                                                    {this.props.loading ?
                                                        <div style={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Spin size="large" />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="steps-action float-right">


                                                {current > 0 && (
                                                    <Button className="btn-regular" style={{ marginRight: 8 }} onClick={() => this.prev()}>
                                                        {'Previous'}
                                                    </Button>
                                                )}

                                                {current === steps - 2 && (
                                                    <Button className="btn-primary"  htmlType="submit">
                                                        {'Done'}
                                                    </Button>
                                                )}

                                                {current < steps - 2 && (
                                                    <Button  className="btn-primary" onClick={() => this.next()}>
                                                        {'Next'}
                                                    </Button>
                                                )}
                                            </div>

                                        </Form>

                                    </div>

                                    <div className="col-6" style={{marginTop:'30px'}}>
                                        <div className="text-center">
                                            <img src="img/forms-pic-evaluation.svg" alt="" width={350}/>
                                        </div>
                                        <FormHint  input={this.state.focused_input}  />
                                    </div>

                                </div>

                            </div>
                            <Footer/>

                        </div>
                    </div>

                }
            </Translate>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        loading: state.evaluation.loading,
        data: state.evaluation.data,
    };
}

const WrappedEvaluationForm = Form.create({ name: 'evaluation_form' })(Evaluation);

export default connect(mapStateToProps, {
    evaluate,
    showExperienceFormHint,
    hideExperienceFormHint,
    showHintDetails,
    hideHintDetails
})(withLocalize(WrappedEvaluationForm));