import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class Features extends React.Component {
    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <section className="awesome-feature-area section_padding_0_50 clearfix" id="features">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-12">
                                    {/* Heading Text */}
                                    <div className="section-heading text-center">
                                        <h2>
                                            {translate("landing.features.h2-title")}
                                        </h2>
                                        <div className="line-shape"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row text-center">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <img src="img/icon/exprience.svg" alt=""/>
                                    <h4>{translate("landing.features.feature-1-title")}</h4>
                                    <p>{translate("landing.features.feature-1-description")}</p>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <img src="img/icon/exprience-use.svg" alt=""/>
                                    <h4> {translate("landing.features.feature-2-title")}</h4>
                                    <p>{translate("landing.features.feature-2-description")}</p>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <img src="img/icon/easy-work.svg" alt=""/>

                                    <h4> {translate("landing.features.feature-3-title")}</h4>
                                    <p>{translate("landing.features.feature-3-description")} </p>
                                </div>
                            </div>

                        </div>
                    </section>
                }
            </Translate>
        )
    }
}

export default withLocalize(Features);
