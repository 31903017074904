export const NAME = 'name';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const EMAIL = 'email';
export const COUNTRY = 'country';
export const GRADE = 'grade';
export const OBTAINING_DATE = 'obtaining_date';
export const OBTAINING_COUNTRY = 'obtaining_country';
export const PLEASE_SELECT_OBTAINING_COUNTRY = 'please_select_obtaining_country';
export const UNIVERSITY = 'university';
export const FACULTY = 'faculty';
export const DEPARTMENT = 'department';
export const FIELD = 'field';
export const SPECIALTY = 'specialty';
export const TYPE_OF_EXPERIENCE = 'type_of_experience';
export const YEARS_OF_EXPERIENCE = 'years_of_experience';
export const NB_DOCTOR = 'nb_doctor';
export const NB_MASTER = 'nb_master';
export const NB_ENGINEER = 'nb_engineer';
export const NB_TECHNICIAN = 'nb_technician';
export const REVUE_INT = 'revue_int';
export const REVUE_NAT = 'revue_nat';
export const CONF_INT = 'conf_int';
export const CONF_NAT = 'conf_nat';
export const REPORT = 'report';
export const PATENT_ACC = 'patent_acc';
export const PATENT_DEP = 'patent_dep';
export const PRODUCT_DEPLOYED = 'product_deployed';
export const PRODUCT_REALIZED = 'product_realized';
export const PRODUCT_DEMO = 'product_demo';
export const PROTOTYPE = 'prototype';
export const RESOURCE_EXPERIENCE = 'ressource_experience';
export const RESOURCE_IMPLICATION = 'ressource_implication';



