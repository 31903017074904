export const docs_fr =
    `

## Introduction

Les projets R&D ont souvent un caractère d&#39;imprévisibilité quand aux résultats attendus, comme par exemple une technique qu&#39;on découvre ou une
innovation qu&#39;on développe, etc. Cette imprévisibilité fait que les chercheurs ont de la difficulté à estimer les résultats, ou en d&#39;autres
termes, à estimer les valorisations du projet. D&#39;une manière générale, les projets R&D sont valorisés par les trois valorisations qui sont les
publications des résultats scientifiques, la formation d&#39;étudiants et la réalisation de produits. Faire une estimation objective des valorisations
pour un projet R&D de trois ans par exemple n&#39;est pas chose facile. Cependant, ne pas avoir une idée très claire sur notre capacité de production
de valorisations, n&#39;aide surement pas à bien exécuter le projet.

\`Bahith\` a l&#39;ambition d&#39;aider les chercheurs à bien décrire leurs projets R&D, entre autre à bien estimer ses valorisations.
À partir de l&#39;expertise de la communauté,  \`Bahith\`  crée une forme de référence par rapport à beaucoup d&#39;aspects des projets R&D,
entre autre l&#39;expertise de l&#39;équipe du projet, son domaine, le type de projet, etc.  Avec cette référence,  \`Bahith\`  va guider le chercheur
à bien situer la capacité de l&#39;équipe de projet par rapport à cette référence, et ainsi l&#39;aider à bien estimer les valorisations de son projet.
Une telle description plus ou moins intelligente peut en effet être considérée comme un plan de projet réaliste et augmenter ses chances de réussite.

\`Bahith\` considère seulement les valorisations objectives du projet. Il ne traite pas l&#39;aspect subjectif, généralement associé au métier
et relatif dans le cas des projets R&D à la qualité de la recherche,. De toutes les façons, d&#39;une manière ou d&#39;une autre,
une recherche de qualité aura plus de chance d&#39;être publiée dans des revues de qualité, et \`Bahith\` à l&#39;intention de le traiter plus tard!

> ### Récoltes d'expertises
>
> La noyau intelligent de \`Bahith\` repose sur l&#39;expertise des chercheurs. \`Bahith\` possède une structure permettant aux chercheurs
d&#39;inclure leurs expertises. Autant que le nombre d&#39;expertises est grand autant la référence de  \`Bahith\`  est plus réaliste.
Nous comptons sur vous à cet effet en remplissant les formulaires d&#39;expertises.
>
>



## Description de Bahith

\`Bahith\` peut être utilisé comme un noyau intelligent qui peut s&#39;intégrer dans les protocoles de description des projets R&D.
Cette possible intelligence est produite à partir d&#39;un modèle de recueil d&#39;expertises des chercheurs, ou d&#39;une manière globale,
des ressources humaines impliquées dans les projets R&D, quelque soit leurs grades et expériences.

La figure suivante illustre l&#39;architecture fonctionnelle de \`Bahith\`.

![Bahith - how to image](/img/Bahith%20-%20How%20to.png)

### Projet R&D

Étant donnée une équipe de projet avec un ou plusieurs membres. Cette équipe possède une capacité lui permettant de valoriser ce projet
en termes de publications, de réalisations de produits ainsi que de formation d&#39;étudiants. Par exemple, il est logique qu&#39;un
docteur ait une capacité de publication dans un journal plus grande que celle d&#39;un ingénieur. Est-il possible d&#39;estimer cette
capacité de valorisation?  \`Bahith\`  propose le calcul de cette capacité à partir de l&#39;expertise des chercheurs.

### Expertise

Une expertise (Grade, expérience) est le couple formé du grade ou diplôme, suivi d&#39;au moins une année d&#39;expérience de travail dans le domaine du grade.

Un ingénieur qui a travaillé 2 ans comme ingénieur, puis a fait une maîtrise, suivi d&#39;un doctorat, et il est actuellement docteur depuis 5 ans,
aura deux expertises. La première comme ingénieur (ingénieur, 2 ans), et la seconde comme docteur (docteur, 5 ans).

### Valorisations

Dans \`Bahith\`, un projet R&D est valorisé par trois différentes valorisations qui sont les publications, les formations d&#39;étudiants ainsi
 que les réalisations de produits. Chaque chercheur, d&#39;après son expertise possède une capacité à produire ces valorisations.

### Capacités de valorisation

Il n&#39;y a pas de règles générales sur le calcul d&#39;une capacité théorique d&#39;un chercheur à produire des valorisations,
malgrés que ces dernières constituent les éléments de base sur lesquels est bâtie une quelconque évaluation du projet. Beaucoup
de chercheurs ne prennent pas le temps de bien analyser leur projet, et sont contraints de mettre des quantités de valorisations
plus au moins au pif. \`Bahith\` a entre autre l&#39;objectif d&#39;aider ces chercheurs à établir des bonnes estimations de valorisations.

### Valorisations estimées

À partir de la capacité de valorisation de l&#39;équipe de recherche, il est possible de calculer pour chaque valorisation les
quantités de valorisations probables, compte tenu de l&#39;expertise des chercheurs.

### Comparaison

Nous comparons les valorisations estimées avec celles proposées dans le projet sur une échelle allant de Très Faible à Très Risquée,
ou Faible signifie que la capacité de production est grande, mais les valorisations proposées sont peu, et Risquée quand
la capacité de valorisation est petite et les valorisations proposées sont grandes.

### Interprétations

Nous afficherons les résultats détaillés de la comparaison pour donner la possibilité au chercheur de les interpréter et en prendre compte 
pour améliorer la description du projet.

## Expertise des chercheurs

Pour pouvoir y inclure une forme d&#39;intelligence permettant de guider les chercheurs dans la description des projets,
 \`Bahith\`  propose une modélisation de l&#39;expertise des chercheurs comme suit :

### Grade

Nous définissons dans  \`Bahith\`  quatre grades (diplôme) qui sont,  Docteur, Master, Ingénieur, Technicien.

- \`Docteur\` : 3eme cycle (Ph.D.)

- \`Master\` : 2eme cycle (Magistère, Master II)

- \`Ingénieur\` : École d&#39;ingénieur (Master I)

- \`Technicien\` : Trois année d&#39;études techniques. La licence de 3 ans est catégorisée comme Technique.

### Expérience

nous modélisons l&#39;expérience avec le nombre d&#39;années passée à travailler avec le grade, suivant l&#39;échelle 1, 2, 5, 10 et 20 ans d&#39;expérience.

### Type d&#39;expertise et type de projet

Nous considérons dans \`Bahith\` trois types d&#39;expertises et de projets, Recherche, Développement et recherche et Développement (R&D).

- \`Recherche\`

C&#39;est les projets qui incluent principalement des études et analyses afin d&#39;arriver à une nouvelle compréhension, principalement valorisée par des publications.

- \`Développement\`

C&#39;est les projets qui ont l&#39;objectif de réaliser un produit final, avec une connaissance à priori des spécifications du produit.

- \`R&D\`

C&#39;est des projets qui incluent des études et analyses afin de déterminer les spécifications d&#39;un produit à réaliser dans le projet.

### Structure de représentation de l&#39;expertise

Dans Bahith, nous utilisons la structure universitaire pour classifier les domaines de la science :

\`Université ⇒ Faculté ⇒ Département ⇒ Domaine ⇒ Spécialité\`


Comme approche globale, nous considérons ce qui suit :

- Un docteur doit donner l&#39;information sur sa faculté, département, domaine et enfin spécialité de son doctorat.
- Un Master doit donner l&#39;information sur sa faculté, département et domaine
- Un Ingénieur doit donner l&#39;information sur sa faculté et son département
- Un Technicien doit donner l&#39;information sur sa faculté et son département.

## Valorisations

Dans \`Bahith\`, un projet R&D est valorisé par trois différentes valorisations qui sont les publications, les formations d&#39;étudiants
ainsi que les réalisations de produits.

### Formation

Dans le travail d&#39;un chercheur ou même d&#39;un diplômé, il y a toujours la noble tâche de formation, principalement la formation d&#39;étudiants.
À cet effet, la formation est l&#39;une des valorisations d&#39;un travail de chercheur. Nous utilisons les mêmes grades cités ci-haut
(Docteur, Master, Ingénieur, Technicien) comme résultats possibles de formation, avec la contrainte qu&#39;un chercheur ne peut
former un grade au dessus du son propre grade.

### Publication

La publication des résultats d&#39;un travail de recherche, principalement dans des revues spécialisées avec comité de lecture,
constitue la valorisation la plus prisée. En effet, cette valorisation inclut une grande partie de l&#39;évaluation subjective
de la qualité de recherche. Une bonne publication justifie par elle même la qualité et du travail de recherche, et des résultats obtenues.
Nous catégorisons dans \`Bahith\` les publications comme suit :

- Revue internationale avec comité de lecture
- Revue nationale avec comité de lecture
- Conférence internationale
- Conférence nationale
- Rapport

\`Co-auteur\`

Dans la majorité des cas, une publication a plusieurs auteurs, et d&#39;une manière générale la position du nom de l&#39;auteur donne
l&#39;information sur sa contribution, le premier auteur étant le contributeur principal. Nous ne considérons pas dans cette première
version la position du nom de l&#39;auteur dans la publication, mais juste sa présence.

\`Le facteur d'impact des revues\`

Dans cette première version de \`Bahith\`, nous travaillons seulement avec un seul niveau de hiérarchie pour décrir les catégories de
publications. Il est possible plus tard, pour raffiner le modèle, d&#39;ajouter des couches dans la hiérarchie de chaque catégorie,
comme le cas d&#39;un tri de revues par leur facteur d&#39;impact.

### Réalisation

La réalisation de produit est une valorisation en général pour les projets en relation avec le développement. Nous apprécions
à cet effet l&#39;expertise des personnes qui ont travaillées dans l&#39;industrie. En effet, la majeure partie des projets industriels sont de type réalisation.

Nous catégorisons les réalisations dans cette première version de \`Bahith\` par :

- \`Brevet\` (patent)
- \`Produit déployé\` : Produit qui est utilisé par d&#39;autres personnes clientes.
- \` Produit finalisé\` : Produit réalisé, mais pas déployé
- \`Produit de démonstration\` : Produit qui sert à démontrer la capabilité de la solution pour les clients
- \`Prototype\` : L&#39;implémentation d&#39;un concept à partir duquel d&#39;autres formes ou modèles sont réalisés




`
