import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class Navbar extends React.Component {

    render() {

        const {activeLanguage}  = this.props
        const docLink =(
            activeLanguage ?
            <a className="nav-link" href={`/doc/${activeLanguage.code}`}>documentation</a> : ''
        )

        return (
            <Translate>
                {({ translate }) =>
                    <nav className="navbar navbar-expand-lg navbar-dark" id="pages-navbar" >
                        <a className="navbar-brand" href="/">
                            <img alt="" height="40px" className="d-inline-block align-top" src="/img/logo/bahith-logo-inline.svg"/>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i  className="fal fa-bars"></i>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item ">
                                    <a className="nav-link" href="/">{translate("landing.navbar.home")} <span className="sr-only">(current)</span></a>
                                </li>
                                {/*<li className="nav-item active">*/}
                                {/*    <a className="nav-link" href="/evaluation">Evaluation </a>*/}
                                {/*</li>*/}
                                <li className="nav-item ">
                                    <a className="nav-link" href="/experience">Experience </a>
                                </li>
                                <li className="nav-item ">
                                    {docLink}
                                </li>
                            </ul>
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    {this.props.renderLanguage}
                                </li>
                            </ul>
                        </div>
                    </nav>
                }
            </Translate>
        )
    }
}

export default withLocalize(Navbar);