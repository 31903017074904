export default [
    { id: 1, name: 'Département des droit', faculty_id: 1},
    { id: 2, name: 'Département des science politique', faculty_id: 1},
    { id: 3, name: 'Département d\'Histoire', faculty_id: 2},
    { id: 4, name: 'Département de Bibliothéconomie et de la documentation', faculty_id: 2},
    { id: 5, name: 'Département de Philosophie', faculty_id: 2},
    { id: 6, name: 'Département De Psychologie', faculty_id: 3},
    { id: 7, name: 'Département De Sociologie Et De Démographie', faculty_id: 3},
    { id: 8, name: 'Département D\'Orthophonie', faculty_id: 3},
    { id: 9, name: 'Département Des Sciences De L\'éducation', faculty_id: 3},
    { id: 10, name: 'Département de langue et littérature arabes', faculty_id: 4},
    { id: 11, name: 'Département des sciences de la langue', faculty_id: 4},
    { id: 12, name: 'Département De Français', faculty_id: 4},
    { id: 13, name: 'Département D\'anglais', faculty_id: 4},
    { id: 14, name: 'Département D\'allemand', faculty_id: 4},
    { id: 15, name: 'Département D\'espagnol', faculty_id: 4},
    { id: 16, name: 'Département D\'Italien', faculty_id: 4},
    { id: 17, name: 'Département de langue turque', faculty_id: 4},
    { id: 18, name: 'Département de langue russe', faculty_id: 4},
    { id: 19, name: 'Département médecine', faculty_id: 5},
    { id: 20, name: 'Département Pharmacie', faculty_id: 5},
    { id: 21, name: 'Département médecine dentaire', faculty_id: 5},
    { id: 22, name: 'Departement des Sciences Biologiques', faculty_id: 6},
    { id: 23, name: 'Departement de Physique', faculty_id: 6},
    { id: 24, name: 'Département des Mathématiques', faculty_id: 6},
    { id: 25, name: 'Département informatique', faculty_id: 6},
    { id: 26, name: 'Département Sciences de la terre et Aménagement du Territoire', faculty_id: 6},
    { id: 27, name: 'Département Architecture', faculty_id: 6},
    { id: 28, name: 'Département Sciences De La Matière', faculty_id: 6},
    { id: 29, name: 'Département Sciences De La Nature Et De La Vie', faculty_id: 6},
    { id: 30, name: 'Département Télécommunication', faculty_id: 6},
    { id: 31, name: 'Département des croyances et religions', faculty_id: 7},
    { id: 32, name: 'Département de la charia et du droit', faculty_id: 7},
    { id: 33, name: 'Département de langue et civilisation arabo-islamiques', faculty_id: 7},
    { id: 34, name: 'Département industrie pétrochimique', faculty_id: 11},
    { id: 35, name: 'Département de Finance et Comptabilité', faculty_id: 8},
    { id: 36, name: 'Département des sciences commerciales', faculty_id: 8},
    { id: 37, name: 'Département des sciences de gestion', faculty_id: 8},
    { id: 38, name: 'Département des sciences économiques', faculty_id: 8},
    { id: 39, name: 'Département des médias', faculty_id: 9},
    { id: 40, name: 'Département de communication', faculty_id: 9},
    { id: 41, name: 'Département de langue et littérature arabes', faculty_id: 10},
    { id: 42, name: 'Département de langue et littératures anglaises', faculty_id: 10},
    { id: 43, name: 'Département de langue et littérature françaises', faculty_id: 10},
    { id: 44, name: 'Département Automatique', faculty_id: 11},
    { id: 45, name: 'Département Electrotechnique', faculty_id: 11},
    { id: 46, name: 'Département Electronique', faculty_id: 11},
    { id: 47, name: 'Département Génie Industriel', faculty_id: 11},
    { id: 48, name: 'Département Génie Chimique', faculty_id: 11},
    { id: 49, name: 'Département Génie Civil', faculty_id: 11},
    { id: 50, name: 'Département Génie de l\'environnement', faculty_id: 11},
    { id: 51, name: 'Département Génie Mécanique', faculty_id: 11},
    { id: 52, name: 'Département Génie Minier', faculty_id: 11},
    { id: 53, name: 'Département Métallurgie', faculty_id: 11},
    { id: 54, name: 'Département maîtrise des risques industriels et environnementaux', faculty_id: 11},
    { id: 55, name: 'Département Hydraulique', faculty_id: 11},
    { id: 56, name: 'Département Génie de Procédés', faculty_id: 11},
]