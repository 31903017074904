export default [
    { id: 1, name: 'Faculté de droit et Sciences Politiques'},
    { id: 2, name: 'Faculté des sciences humaines'},
    { id: 3, name: 'Faculté des sciences sociales'},
    { id: 4, name: 'Faculté des langues'},
    { id: 5, name: 'Faculté de médecine'},
    { id: 6, name: 'Faculté des sciences'},
    { id: 7, name: 'Faculté des science islamique'},
    { id: 8, name: 'Faculté de des science economique et commercials'},
    { id: 9, name: 'Faculté des Sciences de l’Information et de la Communication'},
    { id: 10, name: 'Faculté de littérature'},
    { id: 11, name: 'Faculté d\'ingénierie et technologie'},
]