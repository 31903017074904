import React from 'react';
import { Router } from 'react-router-dom';
import translations from './translations/index.json';
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import history from './history';
import ReactGA from 'react-ga'
// Routes

import Routes from './Routes';

// eslint-disable-next-line no-unused-vars
function initializeReactGA(location) {
  ReactGA.initialize('UA-163683056-1');
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
}

class App extends React.Component {
  constructor(props) {
    super(props);
    var languageString = navigator.language || navigator.userLanguage || '';
    var language = languageString.split(/[_-]/)[0].toLowerCase();
    var lang = 'en';
    const languages = [
      { name: "English", code: "en" },
      { name: "Français", code: "fr" }
    ];
    var lg = languages.find(function (lang) {
      return lang.code === language;
    });
    if (lg)
      lang = language;

    this.props.initialize({
      languages,
      options: { renderToStaticMarkup }
    });

    this.props.addTranslation(translations);
    try {
      this.props.setActiveLanguage(lang);
    } catch (error) {
      this.props.setActiveLanguage('en');
    }
  }

  render() {
    initializeReactGA(history.location)
    return (
      <Router history={history}>
        <Routes />
      </Router>
    );
  }
}


export default withLocalize(App);
