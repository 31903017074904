import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import {Icon} from "antd";
import {Animated} from "react-animated-css";
import {connect} from "react-redux";
import {hideExperienceFormHint} from "../actions";

class FormHint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible : false,
        };
    }

    hideHint () {
        this.props.hideExperienceFormHint();
    }

    render() {
        const input = this.props.input
        return (
            <Translate>
                {({ translate }) =>
                        <div className={`hint-container ${this.props.isVisible ? `d-block` : `d-none` }`}>
                            <div className="hint-wrapper">
                                <div className="hint-title float-left">
                                    <Icon type="bulb" /> Hint !
                                </div>
                                <span aria-hidden="true" onClick={()=>{this.hideHint()}} className="float-right close-btn">&times;</span>

                                <span className="float-none"> </span>
                                <div className="hint-description">
                                    {/*Hint Title*/}
                                    <h6> {translate(`input_labels.${input}`)}</h6>
                                    {/*Hint Description*/}
                                    <p>{translate(`experience_form_hint.${input}`)}</p>
                                    {/*Hint More Details if it a select with options */}
                                    {this.props.hintDetails ? this.props.detailsValues.map((value,index) => (
                                        <p key={index}> <strong className="hint-strong"> {translate(`${input}.${value}`)}: </strong>  {translate(`experience_form_hint.${input}_description.${value}`)} </p>
                                    )): ''}

                                </div>
                            </div>
                        </div>

                }
            </Translate>
        )
    }
}

function mapStateToProps(state) {
    return {
        isVisible: state.formHint.isVisible,
        hintDetails:state.formHint.hintDetails,
        detailsValues:state.formHint.values
    };
}

export default connect(mapStateToProps, { hideExperienceFormHint })(withLocalize(FormHint));
