import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import {connect} from "react-redux";
import {Menu} from 'antd';
import {Scrollbars} from "react-custom-scrollbars";



class TocMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const {tocArray} = this.props;
        return (
            <Scrollbars
                autoHide ={true}
                autoHideTimeout={500}
                autoHideDuration={100}
                autoHeight
                autoHeightMin={100}
                autoHeightMax={500}
                style={{ width: 'auto'}}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    style={{ background: '#fff', borderRight: 0,
                    }}
                >
                    <div className="padding-100">
                    </div>

                    {
                        tocArray.map((toc, index) => (
                            <Menu.Item
                                className={`ant-menu-item-${toc.level}`}
                                key={index}>
                                {toc.level===3? '-' : ''} {toc.title}
                                <a href={`#${toc.id}`}></a>
                            </Menu.Item>
                        ))
                    }
                    <div className="padding-100"></div>
                </Menu>
            </Scrollbars>


        )
    }
}

function mapStateToProps(state) {
    return {

    };
}

export default connect(mapStateToProps, {  })(withLocalize(TocMenu));
