import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";

class Home extends React.Component {
    render() {

        const {activeLanguage}  = this.props
        return (
            <Translate>
                {({ translate }) =>
                    <section className="wellcome_area clearfix" id="home">
                        <div className="container h-100">
                            <div className="row h-100 align-items-center">
                                <div className="col-12 col-md">
                                    <div className="wellcome-heading">

                                        <div className="info-timeline">
                                            <h5>{translate("landing.home.blue-h5-title")}</h5>
                                            <ul>
                                                <li><span className="timeline-circle  jumbo-circle-1"></span> <span className="jumbo-title"><i>Bahith</i>  </span> </li>
                                                <li><span className="timeline-circle jumbo-line-1"></span>
                                                    <div className="circle-content1">
                                                        <p> {translate("landing.home.description-l1")} </p>
                                                        <p> {translate("landing.home.description-l2")} </p>

                                                    </div>
                                                </li>

                                                <li><span className="timeline-circle jumbo-circle-3 jumbo-line-2"></span>
                                                    <div className="circle-content2">
                                                        <p> {translate("landing.home.description-l3")}</p>
                                                    </div>
                                                </li>

                                            </ul>

                                            <div className="get-start-area">
                                                {/* Form Start */}
                                                <div className="col-12 col-lg-2">
                                                    <div className="sing-up-button d-none d-lg-block">
                                                        <a href={`/doc/${activeLanguage ? activeLanguage.code : ''}`} target="_blank" className="submit">{translate("landing.home.get_started")}</a>
                                                    </div>
                                                </div>

                                                {/* Form End */}
                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* Welcome thumb */}
                        <div className="welcome-thumb wow fadeInDown" data-wow-delay="0.5s">
                            {/*<img src="img/file_searching_duff.svg" alt="" />*/}
                        </div>
                    </section>
                }
            </Translate>
        )
    }
}

export default withLocalize(Home);
