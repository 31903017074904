import {FETCH_FACTS} from '../actions/types';

const INITIAL_STATE = {
    factsCount: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_FACTS :
            return {...state,factsCount:action.payload.data};
        default:
            return state;
    }
};
