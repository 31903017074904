import {CREATE_FEEDBACK_SUCCESS, CREATE_FEEDBACK, SHOW_EXPERIENCE_FORM_HINT} from '../actions/types';

const INITIAL_STATE = {
	loading: false , experience_form_hint: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  	case CREATE_FEEDBACK:
      return { ...state, loading: true };
    case CREATE_FEEDBACK_SUCCESS:
      return { ...state, loading: false };
    case SHOW_EXPERIENCE_FORM_HINT :
          return {...state,experience_form_hint:true};
    default:
      return state;
  }
};
