import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import 'antd/dist/antd.css';
import { Home, Header, About, Features, Video, Facts, ScreenShots, Testimonial, Team, Contact, Footer } from './Sections';
import Experience from "./Experience";
import EvaluationSection from "./Evaluation";

var loadjs = require('loadjs');

class Landing extends React.Component {
  componentDidMount() {
    //gapi.load('client:auth2', this.initClient);
    loadjs('js/footer-reveal.min.js');
    loadjs('js/active.js');
  }

  render() {
    const {activeLanguage}  = this.props

    return (
      <Translate>
        {({ translate }) =>
          <div className="App">
            {/* Preloader Start */}
            <div id="preloader">
              {/*<div className="colorlib-load"></div>*/}
            </div>

            {/* ***** Header Area Start ***** */}
            <Header />
            {/* ***** Header Area End ***** */}

            {/* ***** Wellcome Area Start ***** */}
            <Home  />
            {/* ***** Wellcome Area End ***** */}

            {/* ***** About Area Start ***** */}
            <About />
            {/* ***** About Area End ***** */}

            {/* ***** Awesome Features Start ***** */}
            <Features />

            <Video />
            {/* ***** Video Area End ***** */}

            {/* ***** Cool Facts Area Start ***** */}
            <Facts />
            {/* ***** Cool Facts Area End ***** */}

            {/*<EvaluationSection/>*/}
            {/*/!* ***** App Screenshots Area Start ***** *!/*/}
            {/*<ScreenShots />*/}
            {/*/!* ***** App Screenshots Area End *****====== *!/*/}


            {/*/!* ***** Client Feedback Area Start ***** *!/*/}
            {/*<Testimonial />*/}
            {/* ***** Client Feedback Area End ******/}

              {/* ***** Awesome Features End ***** */}
              <Experience/>
              {/* ***** Video Area Start ***** */}

            {/* ***** CTA Area Start ***** */}
            <section className="section_padding_50 clearfix" id="documentation">
              <div className="container text-center">
                <div className="row align-items-center">
                  <div className="col-12 col-md-8">
                    <div className="membership-description">
                      <h2>{translate("landing.doc.title")}</h2>
                      <p>{translate("landing.doc.content")}</p>
                    </div>
                  </div>
                  <div className="col-23 col-md-4">
                    {/*<img src="img/docuemtnation.svg" alt="documentation image" width={300}/>*/}
                    <div>
                      <a className="btn btn-info mt-5 mt-md-0" href={`/doc/${activeLanguage ? activeLanguage.code : '#'}`} >
                        <i className="far fa-file-alt"></i> &nbsp;
                        {translate("landing.doc.action-btn")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ***** CTA Area End ***** */}


            {/* ***** Our Team Area Start ***** */}
            <Team />
            {/* ***** Our Team Area End ***** */}

            {/* ***** Contact Us Area Start ***** */}
            <Contact />
            {/* ***** Contact Us Area End ***** */}

            {/* ***** Footer Area Start ***** */}
            <Footer />
          </div>
        }
      </Translate>
    );
  }
}

export default withLocalize(Landing);
