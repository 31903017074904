import { EVALUATION, EVALUATION_SUCCESS } from '../actions/types';

const INITIAL_STATE = {
    loading: false,
    data: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  	case EVALUATION:
      return { ...state, loading: true };
    case EVALUATION_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
};